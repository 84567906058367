import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import LampBackgroundZone from './LampBackgroundZone';
import TechnicalLampZone from './TechnicalLampZone';
import HeatmapLampZone from './HeatmapLampZone';
import {isRoute} from '../utils/helper';
import {ROUTE_CONFIG, ROUTE_MOVEMENT, ROUTE_ENERGY} from '../constants/const';

const LineZone = ({line, planRotation, xMap, yMap, currentRoute}) => {
  const {x, y, lampIds, rotation} = line;
  const transform = `translate(${x},${y}),rotate(${rotation})`;

  const zoneEls = [];
  lampIds.forEach(id => {
    let el;
    if (isRoute(currentRoute, ROUTE_CONFIG)) {
      el = <LampBackgroundZone key={id} id={id} />;
    }else if(isRoute(currentRoute, ROUTE_MOVEMENT) || isRoute(currentRoute, ROUTE_ENERGY)) {
      el = <HeatmapLampZone key={id} id={id} colorProp={isRoute(currentRoute, ROUTE_MOVEMENT) ? 'movColor':'energyColor'} />;
    }else {
      el = <TechnicalLampZone key={id} id={id} />;
    }
    zoneEls.push(el);

  });

  return (
    <g transform={transform} className={"line " + (line.selected === true ? 'selected':'')}>
      {zoneEls}
    </g>
  );
};

LineZone.propTypes = {
  line: PropTypes.object,
  id: PropTypes.string.isRequired,
  planRotation: PropTypes.number.isRequired,
  xMap: PropTypes.number.isRequired,
  yMap: PropTypes.number.isRequired,
  currentRoute: PropTypes.string.isRequired,
};

function mapStateToProps(state, props) {
  const {xMap, yMap} = state.planReducer;

  return {
    line: state.lineReducer[props.id],
    xMap,
    yMap,
  };
}

export default connect(
  mapStateToProps,
  null,
)(LineZone);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Dialog from '../components/DialogWrapper';
import * as actions from '../actions/clientActions';
import ClientLink from './ClientLink';
import '../styles/clients-dialog.scss';
import { getTranslate } from 'react-localize-redux';

const ClientsDialog = ({actions, clientLetters, showSelectClientDialog, translate}) => {
  if (showSelectClientDialog === false) {
    return null;
  }

  const { setShowSelectClientDialog } = actions;
  const clickAction = () => {
    setShowSelectClientDialog(false);
  };

  const body = (
    <div>
      {clientLetters.map((clientIds) => {
        const letter = clientIds[0];
        return (
          <fieldset key={letter} className="client">
            <legend>
              {letter.toUpperCase()}
            </legend>
            <ul>
              {clientIds.map((clientId, i) => {
                if (i === 0) {
                  return null;
                }
                return <ClientLink id={clientId} key={clientId} clickAction={clickAction} />;
              })}
            </ul>
          </fieldset>
        );
      })}
    </div>
  );

  return (
    <span className="clients-dialog">
      <Dialog title={translate('client_select_client')} visible={true} body={body} closeLabel={translate('global_close')}
        closeAction={() => setShowSelectClientDialog(false)} />
    </span>
  );
};

ClientsDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  clientLetters: PropTypes.array.isRequired,
  showSelectClientDialog: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {clientReducer} = state;
  const {showSelectClientDialog, clientLetters} = clientReducer;
  const translate = getTranslate(state.localeReducer);
  return {
    showSelectClientDialog,
    clientLetters,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientsDialog);

import {
  UPDATE_DATAPORT,
  EXECUTE_DATAPORT_COMMAND,
  EXECUTE_DATAPORTS_COMMAND,
  DATAPORT_COMMAND_CODE_EVENT,
  DATAPORT_COMMAND_STDOUT_EVENT,
  UPDATE_DATAPORTS_PACKAGE,
  UPDATE_DATAPORT_PACKAGE,
} from '../constants/dataportActionTypes';
import {execDataportCommandRpc, updateDataportPackageRpc} from '../actions/dataportActions';
import {subscribeDataportEvent} from '../actions/siteActions';
import { push } from 'connected-react-router';
import uuid from 'uuid-random';

export default store => next => action => {
  const {dispatch} = store;
  if (action.type === UPDATE_DATAPORT.SUCCESS) {
    const {prevRoute} = store.getState().routingReducer;
    next(action);
    dispatch(push(prevRoute));
  }else if(action.type === EXECUTE_DATAPORT_COMMAND) {
    const {dataportId, cmd} = action;
    const {name : dataportName, sessionId : oldSessionId, code} = store.getState().dataportReducer[dataportId];

    if (oldSessionId !== undefined && code === -99) {
      dispatch(execDataportCommandRpc(dataportId, dataportName, 'stop', oldSessionId, false));
    }

    const sessionId = uuid();
    const stdOutUrl = `agent/command/stdout/${sessionId}`;
    const codeUrl = `agent/command/code/${sessionId}`;

    dispatch(subscribeDataportEvent(dataportName, codeUrl, DATAPORT_COMMAND_CODE_EVENT, {dataportId, sessionId}));
    dispatch(subscribeDataportEvent(dataportName, stdOutUrl, DATAPORT_COMMAND_STDOUT_EVENT, {dataportId, sessionId}));
    dispatch(execDataportCommandRpc(dataportId, dataportName, cmd, sessionId, true));
  }else if(action.type === EXECUTE_DATAPORTS_COMMAND) {
    const {cmd} = action;
    const {dataportReducer} = store.getState();
    const {dataportIds} = dataportReducer;
    dataportIds.forEach(dpId => {
      const dataport = dataportReducer[dpId];
      if (dataport.selected) {
        const sessionId = uuid();
        const codeUrl = `agent/command/code/${sessionId}`;
        dispatch(execDataportCommandRpc(dpId, dataport.name, cmd, sessionId, false));
        dispatch(subscribeDataportEvent(dataport.name, codeUrl, DATAPORT_COMMAND_CODE_EVENT, {dataportId: dpId, sessionId}));
      }
    });
  }else if(action.type === UPDATE_DATAPORT_PACKAGE) {
    const {dataportId, packageName} = action;
    const {name : dataportName} = store.getState().dataportReducer[dataportId];
    const sessionId = uuid();
    const stdOutUrl = `agent/command/stdout/${sessionId}`;
    const codeUrl = `agent/command/code/${sessionId}`;

    dispatch(subscribeDataportEvent(dataportName, codeUrl, DATAPORT_COMMAND_CODE_EVENT, {dataportId, sessionId}));
    dispatch(subscribeDataportEvent(dataportName, stdOutUrl, DATAPORT_COMMAND_STDOUT_EVENT, {dataportId, sessionId}));
    dispatch(updateDataportPackageRpc(dataportId, dataportName, packageName, sessionId));
  }else if(action.type === UPDATE_DATAPORTS_PACKAGE) {
    const {packageName} = action;
    const {dataportReducer} = store.getState();
    const {dataportIds} = dataportReducer;
    dataportIds.forEach(dpId => {
      const dataport = dataportReducer[dpId];
      if (dataport.selected) {
        const sessionId = uuid();
        const codeUrl = `agent/command/code/${sessionId}`;
        dispatch(updateDataportPackageRpc(dpId, dataport.name, packageName, sessionId, false));
        dispatch(subscribeDataportEvent(dataport.name, codeUrl, DATAPORT_COMMAND_CODE_EVENT, {dataportId: dpId, sessionId}));
      }
    });
  }
  return next(action);
};

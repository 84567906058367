import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ActionButton from '../components/ActionButton';
import * as buildingActions from '../actions/buildingActions';

const ReportItem = ({report, buildingActions, clientId, deleteAction}) => {
  const {setBuildingRoute} = buildingActions;
  const {language, frequency, email_to, id} = report;

  return (
    <tr>
      <th>{frequency}</th>
      <th>{language}</th>
      <td>{email_to.join(', ')}</td>
      <td>
        <ActionButton text={'Edit'} action={() => setBuildingRoute('/plan/report/', `/${id}`)} />
      </td>
      <td>
        <ActionButton icon="fa-remove" action={() => deleteAction(id)} />
      </td>
    </tr>
  );
};

ReportItem.propTypes = {
  id: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  report: PropTypes.object.isRequired,
  buildingActions: PropTypes.object.isRequired,
  deleteAction: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    report: state.reportsReducer[props.id],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    buildingActions: bindActionCreators(buildingActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportItem);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/userActions';
import RoleSelect from '../components/RoleSelect';

const UserBuildingItem = ({ building, roleIds, actions, selectedClientId, configRoleOptions }) => {
  const {setEditUserProp} = actions;
  if (!building) {
    return null;
  }

  const { name, id } = building;
  return (
    <div>
      {name} - <RoleSelect name="role" value={roleIds} roleOptions={configRoleOptions}
        onChange={(value) => setEditUserProp(['clientBuildingRoles', selectedClientId, id], value.map(({value}) => value))} />
    </div>
  );
};

UserBuildingItem.propTypes = {
  actions: PropTypes.object.isRequired,
  building: PropTypes.object,
  roleIds: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
  configRoleOptions: PropTypes.array.isRequired,
  selectedClientId: PropTypes.number.isRequired,
};

function mapStateToProps(state, {id, selectedClientId}) {
  const {buildingReducer, userReducer, roleReducer} = state;
  const building = buildingReducer[id];
  let roleIds = [];
  let obj = userReducer.editUser.clientBuildingRoles[selectedClientId];
  if (obj) {
    roleIds = obj[id];
  }
  const {configRoleOptions} = roleReducer;

  return {
    building,
    configRoleOptions,
    roleIds: roleIds ? roleIds:[],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserBuildingItem);

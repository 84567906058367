import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import '../styles/not-found-page.scss';
import * as actions from '../actions/firmwareActions';
import ActionButton from '../components/ActionButton';

const FirmwareOverview = ({syncFirmwarePsStatus, syncFirmwareDriverStatus, actions, psFiles, lampFiles}) => {
  const {syncFirmwarePs, syncFirmwareDriver, getFirmwareFiles} = actions;

  useEffect(() => {
    getFirmwareFiles();
  }, []);

  return (
    <div className="row">
      <div className="col-md-6">
        <h2>
          Powersupply{' '}
          <ActionButton action={() => syncFirmwarePs()} status={syncFirmwarePsStatus}
            text="Sync"/>
        </h2>
        <ul className="list-group">
          { psFiles.map((fileName) => {
            return <li key={fileName} className="list-group-item">{fileName}</li>;
          })}
        </ul>
      </div>
      <div className="col-md-6">
        <h2>
          Driver{' '}
          <ActionButton action={() => syncFirmwareDriver()} status={syncFirmwareDriverStatus}
            text="Sync"/>
        </h2>
        <ul className="list-group">
          { lampFiles.map((fileName) => {
            return <li key={fileName} className="list-group-item">{fileName}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

FirmwareOverview.propTypes = {
  syncFirmwarePsStatus: PropTypes.string,
  syncFirmwareDriverStatus: PropTypes.string,
  actions: PropTypes.object.isRequired,
  lampFiles: PropTypes.array.isRequired,
  psFiles: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  const {syncFirmwarePsStatus, syncFirmwareDriverStatus, psFiles, lampFiles } = state.firmwareReducer;
  return {
    syncFirmwarePsStatus,
    syncFirmwareDriverStatus,
    psFiles,
    lampFiles,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FirmwareOverview);

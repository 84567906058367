import {PROFILE_ON, PROFILE_STANDBY, PROFILE_OFF} from '../constants/profileTypes';
import {DEPICTION_RECT} from '../constants/lampTypeTypes';

export function getLiveModeMovementStyle(profileState) {
  let className = '';
  if (PROFILE_ON === profileState) {
    className += 'profile-on';
  } else if(PROFILE_STANDBY === profileState) {
    className += 'profile-standby';
  } else if(PROFILE_OFF === profileState) {
    className += 'profile-off';
  } else {
    className += 'profile-off';
  }

  return className;
}

export function getLiveModeEnergyMonitoringStyle(profileState, profile) {
  let className = '';
  let profileStatePerc = null;
  if (profile === undefined || profileState === undefined) {
    profileStatePerc = null;
  } else if (PROFILE_ON === profileState) {
    profileStatePerc = profile.percent_on;
  } else if (PROFILE_STANDBY === profileState) {
    profileStatePerc = profile.percent_standby;
  } else if (PROFILE_OFF === profileState) {
    profileStatePerc = 0;
  }

  if (profileStatePerc === null) {
    className += 'profile-off';
  } else if (profileStatePerc === 0) {
    className += 'profile-0';
  } else if (profileStatePerc <= 10) {
    className += 'profile-10';
  } else if (profileStatePerc <= 20) {
    className += 'profile-20';
  } else if (profileStatePerc <= 30) {
    className += 'profile-30';
  } else if (profileStatePerc <= 40) {
    className += 'profile-40';
  } else if (profileStatePerc <= 50) {
    className += 'profile-50';
  } else if (profileStatePerc <= 60) {
    className += 'profile-60';
  } else if (profileStatePerc <= 70) {
    className += 'profile-70';
  } else if (profileStatePerc <= 80) {
    className += 'profile-80';
  } else if (profileStatePerc <= 90) {
    className += 'profile-90';
  } else if (profileStatePerc <= 100) {
    className += 'profile-100';
  }

  return className;
}

export function getLeftRightByType(left, right, lampType) {
  if(lampType.depictionType === DEPICTION_RECT) {
    left -= lampType.length / 2;
    right += lampType.length / 2;
  }
  return {right, left};
}

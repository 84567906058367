import {batch} from 'react-redux';
import { RECALCULATE_FILTERS, SET_FILTER_NODE_TYPE_VERSION_VALUE, SET_FILTER_REDUCER_PROPS_AND_RECALC,
RESET_FILTERS} from '../constants/filterActionTypes';
import {recalculateFilters, addSingleNodeTypeVersion} from '../actions/filterActions';
import {REACHABLE, UNREACHABLE, LINKED, NOT_LINKED,
  CONFIGURED, NOT_CONFIGURED, SYNCED, NOT_SYNCED} from '../constants/filterTypes';
import {GET_NODES_UPGRADE_SYNCED} from '../constants/firmwareActionTypes';
import {PP_ADDED_EVENT, PP_DECOMMISSIONED_EVENT, NODE_ADDED_EVENT} from '../constants/siteActionTypes';
import {GET_APS} from '../constants/apActionTypes';

const filterMiddleware = store => next => action => {
  const {dispatch} = store;
  const {type} = action;

  if (type === RECALCULATE_FILTERS) {
    const {apReducer, buildingReducer, nodeReducer, filterReducer, lampReducer, firmwareReducer, ppReducer} = store.getState();
    const {reachableFilter, linkedFilter, configuredFilter, syncedFilter,
    ppSearchValue, apSearchValue, upgradeStatusInProgressFilter,
    upgradeStatusNotUpgradingFilter, upgradeStatusFailedFilter, upgradeStatusUpgradedFilter} = filterReducer;
    const {maxFails} = firmwareReducer;
    const {activeBuildingId} = buildingReducer;
    const {dataportSlug} = buildingReducer[activeBuildingId];
    const lampMap = lampReducer.lampMacMap;
    const ids = apReducer[`${dataportSlug}/ids`];
    let apIds = [];
    let apObj = {};
    let ppCount = 0;

    if (!ids) {
      action.apIds = apIds;
      action.apObj = apObj;
      action.ppCount = ppCount;
      return next(action);
    }
    const showPpFunc = (ppNode, ppId) => {
      if (!ppNode) {
        if (configuredFilter === CONFIGURED) {
          return false;
        }else {
          return true;
        }
      }

      if (!ppNode.objects) {
        return true;
      }

      if (CONFIGURED === configuredFilter && ppNode.objects.length === 0) {
        return false;
      }else if(NOT_CONFIGURED === configuredFilter && ppNode.objects.length > 0) {
        return false;
      }

      if (REACHABLE === reachableFilter && ppNode.reachable === false) {
        return false;
      }else if(UNREACHABLE === reachableFilter && ppNode.reachable === true) {
        return false;
      }

      if (LINKED === linkedFilter && (!lampMap || !lampMap[ppId])) {
        return false;
      }

      if (NOT_LINKED === linkedFilter && (!lampMap || lampMap[ppId])) {
        return false;
      }

      if (SYNCED === syncedFilter && ppNode.isSynced !== true) {
        return false;
      }

      if (NOT_SYNCED === syncedFilter && (ppNode.isSynced === undefined || ppNode.isSynced !== false)) {
        return false;
      }

      if (upgradeStatusFailedFilter === false && ppNode.fails && ppNode.fails === maxFails && ppNode.upgraded === false) {
        return false;
      }

      if (upgradeStatusInProgressFilter === false && ppNode.progress && ppNode.progress > 0 && ppNode.progress < 100) {
        return false;
      }

      if (upgradeStatusUpgradedFilter === false && ppNode.upgraded !== undefined && ppNode.upgraded === true) {
        return false;
      }

      if (upgradeStatusNotUpgradingFilter === false && ppNode.upgraded === undefined) {
        return false;
      }

      if (ppSearchValue.length > 0 && (ppId.indexOf(ppSearchValue) === -1 && ppNode.name.indexOf(ppSearchValue) === -1)) {
        return false;
      }

      return filterReducer[`${dataportSlug}/${ppNode.node_type}/${ppNode.software_version}`] === true;
    };

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const ap = apReducer[id];
      const apNode = nodeReducer[id];
      if (apNode) {
        if(filterReducer[`${dataportSlug}/${apNode.node_type}/${apNode.software_version}`] !== true) {
          continue;
        }
        if (apSearchValue.length > 0 && id.indexOf(apSearchValue) === -1 && apNode.name.indexOf(apSearchValue) === -1) {
          continue;
        }
      }else if(apSearchValue.length > 0 && id.indexOf(apSearchValue) !== -1) {
        continue;
      }

      apIds.push(id);

      const ppIds = [];
      ap.clientIds.forEach(clientId  => {
        const pp = ppReducer[clientId];
        const ppNode = nodeReducer[pp.ipui];
        const show = showPpFunc(ppNode, pp.ipui);
        if (show === true) {
          ppIds.push(pp.id);
        }
      });

      apObj[id] = ppIds;
      ppCount += ppIds.length;
    }

    action.apIds = apIds;
    action.apObj = apObj;
    action.ppCount = ppCount;
    return next(action);
  }else if(type === SET_FILTER_NODE_TYPE_VERSION_VALUE || type === SET_FILTER_REDUCER_PROPS_AND_RECALC ||
    type === GET_NODES_UPGRADE_SYNCED.SUCCESS || type === PP_ADDED_EVENT || type === GET_APS.SUCCESS ||
    type === NODE_ADDED_EVENT || type === PP_DECOMMISSIONED_EVENT || type === RESET_FILTERS) {
    if (type === NODE_ADDED_EVENT) {
      const {node_type, software_version} = action.data.node;
      if (node_type === '') {
        action.data.node_type = 'Unknown';
      }
      dispatch(addSingleNodeTypeVersion(node_type, software_version));
    }
    batch(() => {
      next(action);
      dispatch(recalculateFilters());
    });
  }else {
    return next(action);
  }
};

export default filterMiddleware;

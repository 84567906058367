import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {setPlanSvgEl, calcPlanSizeProps} from '../actions/planActions';
import {ZOOM, EDIT_HITZONE_MODE} from '../constants/customTypes';
import PropTypes from 'prop-types';
import Line from './Line';
import Image from './Image';
import PlanDrag from './PlanDrag';
import SvgGrid from '../components/SvgGrid';
import GroupControl from './GroupControl';
import Zoom from './Zoom';
import Minimap from './Minimap';
import LineZone from './LineZone';
import LineBorderZone from './LineBorderZone';
import PlanLegend from '../components/PlanLegend';
import '../styles/plan.scss';
import Lamp from './Lamp';
import TechnicalLampZone from './TechnicalLampZone';
import HeatmapLampZone from './HeatmapLampZone';
import LampBackgroundZone from './LampBackgroundZone';
import LampClickZone from './LampClickZone';
import LampBorderZone from './LampBorderZone';
import DragBorderZone from './BaseDragBorderZone';
import LineDragZone from './LineDragZone';
import {isRoute} from '../utils/helper';
import {ROUTE_TECHNICAL, ROUTE_MOVEMENT, ROUTE_ENERGY, ROUTE_CONFIG} from '../constants/const';

let resizeTimeoutId;
let svgPt;

const Plan = ({building, currentRoute, planWidth, planHeight,
  zoomRectX, zoomRectY, mode, groupMode,
    className, dispatch, interactive,
    zoomRectWidth, zoomRectHeight, showGrid, showLegend, points}) => {

  const svgRef = useRef(null);

  useEffect(() => {
    svgPt = svgRef.current.createSVGPoint();
    dispatch(setPlanSvgEl(svgRef.current, svgPt));

    const onResize = () => {
      clearTimeout(resizeTimeoutId);
      resizeTimeoutId = setTimeout(() => {
        dispatch(calcPlanSizeProps());
      }, 1000);
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [svgRef]);

  const {width, height, lineIds, rotation, zoomX, zoomY, zoomK, lampIds } = building;
  const halfWidth = width / 2;
  const halfHeight = height / 2;
  let zoneOpacity = 1;
  if (isRoute(currentRoute, ROUTE_CONFIG)) {
    zoneOpacity = 0.65;
  }

  const linesZoneEls = [];
  const linesZoneBorderEls = [];
  const linesEls = [];
  const lineDragZoneEls = [];

  lineIds.forEach(id => {
    if (isRoute(currentRoute, ROUTE_TECHNICAL) || isRoute(currentRoute, ROUTE_CONFIG) || isRoute(currentRoute, ROUTE_MOVEMENT) || isRoute(currentRoute, ROUTE_ENERGY)) {
      linesZoneEls.push(
        <LineZone id={id} key={id} planRotation={rotation} currentRoute={currentRoute} />
      );
    }

    if (isRoute(currentRoute, ROUTE_TECHNICAL) && groupMode === EDIT_HITZONE_MODE) {
      lineDragZoneEls.push(
        <LineDragZone id={id} key={id} planRotation={rotation} />
      );
    }

    linesZoneBorderEls.push(
      <LineBorderZone id={id} key={id} />
    );

    linesEls.push(
      <Line id={id} key={id} planRotation={rotation} />
    );
  });

  const lampClickZones = [];
  const lampZoneEls = [];
  const lampBorderZoneEls = [];
  const standaloneLampEls = [];
  const lampDragZoneEls = [];
  lampIds.forEach(lampId => {
    standaloneLampEls.push(
      <Lamp key={lampId} id={lampId} />
    );

    if (isRoute(currentRoute, ROUTE_CONFIG)) {
      lampZoneEls.push(
        <LampBackgroundZone key={lampId} id={lampId} />
      );
    }else if(isRoute(currentRoute, ROUTE_MOVEMENT) || isRoute(currentRoute, ROUTE_ENERGY)) {
      lampZoneEls.push(
        <HeatmapLampZone key={lampId} id={lampId} colorProp={isRoute(currentRoute, ROUTE_MOVEMENT) ? 'movColor':'energyColor'} />
      );
    }else if(isRoute(currentRoute, ROUTE_TECHNICAL)) {
      lampZoneEls.push(
        <TechnicalLampZone key={lampId} id={lampId} />
      );

      if (groupMode === EDIT_HITZONE_MODE) {
        lampDragZoneEls.push(
          <DragBorderZone key={lampId} id={lampId} className="drag-zone" extraRotation={rotation} />
        );
      }
    }

    lampClickZones.push(
      <LampClickZone key={lampId} id={lampId} />
    );

    lampBorderZoneEls.push(
      <LampBorderZone key={lampId} id={lampId} />
    );
  });

  let gridEl = null;
  if (showGrid === true) {
    gridEl = (
      <SvgGrid width={width} height={height} />
    );
  }

  const drawingEl = (
    <g transform={`rotate(${rotation},${halfWidth},${halfHeight})`}>
      <Image width={width} height={height} />
      {/*<g transform={`translate(${halfWidth}, ${halfHeight}) scale(${config.scale}) translate(${-halfWidth}, ${-halfHeight})`}>*/}
      {gridEl}
      <g className="zones" opacity={zoneOpacity}>
        {linesZoneEls}
        {linesZoneBorderEls}
        {lampZoneEls}
        {lampBorderZoneEls}
        {lampDragZoneEls}
        {lineDragZoneEls}
      </g>
      <g className="lines">
        {linesEls}
      </g>
      <g className="lamps">
        {standaloneLampEls}
        {lampClickZones}
      </g>
      {points.map(({x, y}, i) => {
        return <circle key={i} cx={x} cy={y} r="40" stroke="black" strokeWidth="4" fill="red" />;
      })}
    </g>
  );

  let legendEl = null;
  if (showLegend === true) {
    legendEl = (
      <PlanLegend />
    );
  }

  return (
    <span className={`plan ${className}`}>
      <GroupControl />
      <Minimap width={width} height={height} planX={zoomRectX} planY={zoomRectY} planWidth={zoomRectWidth} planHeight={zoomRectHeight}
      svgHeight={planHeight} svgWidth={planWidth}>
        {drawingEl}
      </Minimap>
      <div className="plan-svg-wrapper" style={{height: planHeight}}>
        <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`} className={"main-plan"}>
          <Zoom>
            <rect x={zoomRectX} y={zoomRectY} className={"zoom-rect " + (mode === ZOOM ? 'active':'')} width={zoomRectWidth} height={zoomRectHeight} />
          </Zoom>
          <g transform={`translate(${zoomX}, ${zoomY})scale(${zoomK})`} className={interactive ? '':'not-interactive'}>
            {drawingEl}
          </g>
          <PlanDrag x={zoomRectX} y={zoomRectY} width={zoomRectWidth} height={zoomRectHeight} pt={svgPt} svgNode={svgRef.current}  />
        </svg>
      </div>
      {legendEl}
    </span>
  );

  // testing
};

Plan.propTypes = {
  building: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  dataport: PropTypes.string,
  mode: PropTypes.string.isRequired,
  currentRoute: PropTypes.string.isRequired,
  zoomRectX: PropTypes.number.isRequired,
  zoomRectY: PropTypes.number.isRequired,
  zoomRectWidth: PropTypes.number.isRequired,
  zoomRectHeight: PropTypes.number.isRequired,
  planWidth: PropTypes.number.isRequired,
  planHeight: PropTypes.number.isRequired,
  points: PropTypes.array.isRequired,
  showGrid: PropTypes.bool.isRequired,
  showLegend: PropTypes.bool.isRequired,
  xMap: PropTypes.number.isRequired,
  yMap: PropTypes.number.isRequired,
  groupMode: PropTypes.string.isRequired,
  className: PropTypes.string,
  interactive: PropTypes.bool.isRequired,
};

Plan.defaultProps = {
  showLegend: false,
  showGrid: false,
  interactive: true,
};

function mapStateToProps(state) {
  const {buildingReducer, planReducer, routingReducer} = state;
  const {currentRoute} = routingReducer;
  const {points, mode, zoomRectX, zoomRectY, zoomRectWidth, zoomRectHeight,
    xMap, yMap, groupMode,
  planWidth, planHeight} = planReducer;
  const {activeBuildingId} = buildingReducer;
  const building = buildingReducer[activeBuildingId];

  return {
    building,
    mode,
    currentRoute,
    points,
    zoomRectX,
    zoomRectY,
    zoomRectWidth,
    zoomRectHeight,
    planWidth,
    planHeight,
    groupMode,
    xMap,
    yMap,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Plan);

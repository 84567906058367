import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Dialog from '../components/DialogWrapper';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/filterActions';
import FilterNodeType from './FilterNodeType';
import { getTranslate } from 'react-localize-redux';
import {REACHABLE_BOTH, REACHABLE, UNREACHABLE,
  LINKED_ALL, LINKED, NOT_LINKED,
  CONFIGURED_ALL, CONFIGURED, NOT_CONFIGURED,
  SYNCED_ALL, SYNCED, NOT_SYNCED,
} from '../constants/filterTypes';
import Checkbox from '../components/Checkbox';
import ObjectsFilter from './ObjectsFilter';

const FilterDialog = ({actions, showFilterDialog, nodeTypes, translate, reachableFilter, linkedFilter,
  upgradeStatusNotUpgradingFilter, upgradeStatusUpgradedFilter, upgradeStatusFailedFilter,
  apSearchValue, ppSearchValue, showSelectionInfo, showSelectionCommands,
  dataportSlug,
  upgradeStatusInProgressFilter, configuredFilter, syncedFilter}) => {
  if (showFilterDialog === false || !nodeTypes) {
    return null;
  }

  const {setFilterReducerProps, setFilterReducerPropsAndRecalc, resetFilters} = actions;
  const body = (
    <div className="row">
      <div className="col-md-6">
        <span className="btn btn-primary" onClick={() => resetFilters()}>Reset filters</span>
        <div className="form-group">
          <label htmlFor="ap-search">Ps id/name:</label>
          <input type="text" value={apSearchValue} className="form-control" id="ap-search"
            onChange={(e) => setFilterReducerPropsAndRecalc({apSearchValue: e.target.value})} />
        </div>
        <div className="form-group">
          <label htmlFor="pp-search">Lamp id/name:</label>
          <input type="text" value={ppSearchValue} className="form-control" id="pp-search"
            onChange={(e) => setFilterReducerPropsAndRecalc({ppSearchValue: e.target.value})} />
        </div>
        <div className="form-group">
          <label htmlFor="reachable-filter">Reachable:</label>
          <select value={reachableFilter} id="reachable-filter" onChange={(e) => setFilterReducerPropsAndRecalc({reachableFilter: e.target.value})}>
            <option value={REACHABLE_BOTH}>All</option>
            <option value={REACHABLE}>Reachable</option>
            <option value={UNREACHABLE}>Not reachable</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="linked-filter">Linked:</label>
          <select value={linkedFilter} id="linked-filter" onChange={(e) => setFilterReducerPropsAndRecalc({linkedFilter: e.target.value})}>
            <option value={LINKED_ALL}>All</option>
            <option value={LINKED}>Linked</option>
            <option value={NOT_LINKED}>Not linked</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="configured-filter">Configured:</label>
          <select value={configuredFilter} id="configured-filter" onChange={(e) => setFilterReducerPropsAndRecalc({configuredFilter: e.target.value})}>
            <option value={CONFIGURED_ALL}>All</option>
            <option value={CONFIGURED}>Configured</option>
            <option value={NOT_CONFIGURED}>Not Configured</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="sync-filter">Synced after upgrade:</label>
          <select value={syncedFilter} id="sync-filter" onChange={(e) => setFilterReducerPropsAndRecalc({syncedFilter: e.target.value})}>
            <option value={SYNCED_ALL}>All</option>
            <option value={SYNCED}>Synced</option>
            <option value={NOT_SYNCED}>Not Synced</option>
          </select>
        </div>
        <h5>Upgrade status:</h5>
        <div className="form-group">
          <Checkbox id="not-upgrading" checked={upgradeStatusNotUpgradingFilter}
            onChange={() => setFilterReducerPropsAndRecalc({upgradeStatusNotUpgradingFilter: !upgradeStatusNotUpgradingFilter})}>
            Not upgrading
          </Checkbox>
        </div>
        <div className="form-group">
          <Checkbox id="upgraded" checked={upgradeStatusUpgradedFilter}
            onChange={() => setFilterReducerPropsAndRecalc({upgradeStatusUpgradedFilter: !upgradeStatusUpgradedFilter})}>
            Upgraded
          </Checkbox>
        </div>
        <div className="form-group">
          <Checkbox id="in-progress" checked={upgradeStatusInProgressFilter}
            onChange={() => setFilterReducerPropsAndRecalc({upgradeStatusInProgressFilter: !upgradeStatusInProgressFilter})}>
            In progress
          </Checkbox>
        </div>
        <div className="form-group">
          <Checkbox id="failed" checked={upgradeStatusFailedFilter}
            onChange={() => setFilterReducerPropsAndRecalc({upgradeStatusFailedFilter: !upgradeStatusFailedFilter})}>
            Failed
          </Checkbox>
        </div>
        <div>
          <h4>Extra</h4>
          <Checkbox
            checked={showSelectionCommands}
            onChange={() => setFilterReducerProps({showSelectionCommands: !showSelectionCommands})}
          >
            Show Commands
          </Checkbox>
          <Checkbox
            checked={showSelectionInfo}
            onChange={() => setFilterReducerProps({showSelectionInfo: !showSelectionInfo})}
          >
            Show Info
          </Checkbox>
        </div>
      </div>
      <div className="col-md-6">
        <div>
          <h3>Versions</h3>
          {nodeTypes.map((nodeType) => {
            return <FilterNodeType key={nodeType} dataportSlug={dataportSlug} nodeType={nodeType} />;
          })
          }
        </div>
        <ObjectsFilter />
      </div>
    </div>
  );

  return (
    <span className="filter-dialog">
      <Dialog title={`Filters`} visible={true} body={body}
        closeLabel={translate('global_close')}
        closeAction={() => setFilterReducerProps({showFilterDialog: false})} />
    </span>
  );
};

FilterDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  showFilterDialog: PropTypes.bool.isRequired,
  nodeTypes: PropTypes.array,
  translate: PropTypes.func.isRequired,
  reachableFilter: PropTypes.string.isRequired,
  linkedFilter: PropTypes.string.isRequired,
  configuredFilter: PropTypes.string.isRequired,
  syncedFilter: PropTypes.string.isRequired,
  upgradeStatusNotUpgradingFilter: PropTypes.bool.isRequired,
  upgradeStatusUpgradedFilter: PropTypes.bool.isRequired,
  upgradeStatusInProgressFilter: PropTypes.bool.isRequired,
  upgradeStatusFailedFilter: PropTypes.bool.isRequired,
  apSearchValue: PropTypes.string.isRequired,
  ppSearchValue: PropTypes.string.isRequired,
  showSelectionInfo: PropTypes.bool.isRequired,
  showSelectionCommands: PropTypes.bool.isRequired,
  dataportSlug: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {filterReducer, buildingReducer} = state;
  const {showFilterDialog, reachableFilter, linkedFilter, configuredFilter,
    syncedFilter, ppSearchValue, apSearchValue, showSelectionInfo, showSelectionCommands,
    upgradeStatusNotUpgradingFilter, upgradeStatusUpgradedFilter, upgradeStatusFailedFilter,
  upgradeStatusInProgressFilter} = filterReducer;
  const translate = getTranslate(state.localeReducer);
  const {activeBuildingId} = buildingReducer;
  const {dataportSlug} = buildingReducer[activeBuildingId];
  const nodeTypes = filterReducer[`${dataportSlug}/nodeTypes`];
  return {
    showFilterDialog,
    nodeTypes,
    translate,
    reachableFilter,
    linkedFilter,
    configuredFilter,
    syncedFilter,
    upgradeStatusNotUpgradingFilter,
    upgradeStatusUpgradedFilter,
    upgradeStatusInProgressFilter,
    upgradeStatusFailedFilter,
    ppSearchValue,
    apSearchValue,
    showSelectionInfo,
    showSelectionCommands,
    dataportSlug,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterDialog);

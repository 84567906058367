import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../actions/groupActions';
import {bindActionCreators} from 'redux';
import '../styles/group-control.scss';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';
import {isRoute} from '../utils/helper';
import {ROUTE_CONFIG} from '../constants/const';

const GroupControl = ({ actions, groupEditing, currentRoute, translate }) => {
  if (!isRoute(currentRoute, ROUTE_CONFIG)) {
    return null;
  }

  const { saveGroup, createGroup, groupLampsRedo, groupLampsUndo, cancelEditGroup } = actions;
  let saveGroupEl = null;
  let undoBtns = null;
  let cancelBtn = null;
  let createGroupEl = (
    <ActionButton action={() => createGroup(undefined, [])}
      extraClass="btn-primary"
      text={translate('profile_create_group')} />
  );
  if (groupEditing === true) {
    saveGroupEl = (
      <ActionButton action={() => saveGroup()}
        extraClass="btn-primary" icon="fa-floppy-o"
        text={translate('profile_save_group')} />
    );
    undoBtns = (
      <span className="btn-group">
        <ActionButton action={() => groupLampsUndo()}
          extraClass="btn-default" icon="fa-undo"
          text={translate('global_undo')} />
        <ActionButton action={() => groupLampsRedo()}
          extraClass="btn-default" icon="fa-repeat"
          text={translate('global_redo')} />
      </span>
    );
    cancelBtn = (
      <ActionButton action={() => cancelEditGroup()}
        extraClass="btn-danger" icon="fa-ban"
        text={translate('global_cancel')} />
    );

    createGroupEl = null;

  }

  return (
    <span className="group-control">
      {' '}
      {undoBtns}
      {' '}
      {createGroupEl}
      {saveGroupEl}
      {' '}
      {cancelBtn}
    </span>
  );
};

GroupControl.propTypes = {
  actions: PropTypes.object.isRequired,
  groupEditing: PropTypes.bool.isRequired,
  currentRoute: PropTypes.string.isRequired,
  groupEditingId: PropTypes.string,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {groupReducer, routingReducer} = state;

  const {currentRoute} = routingReducer;
  const {groupEditing, editingId} = groupReducer;

  const translate = getTranslate(state.localeReducer);

  return {
    groupEditing,
    currentRoute,
    groupEditingId : editingId,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupControl);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/nodeActions';
import Variable from './Variable';

const Variables = ({mac, objects, objectsObj}) => {
  if (!mac) {
    return null;
  }

  return (
    <span>
      {objects.map((objectName, i) => {
        const obj = objectsObj[`${mac}/${objectName}`];
        return (
          <div key={i} className="col-md-6">
          <h4>{objectName}</h4>
          {obj.variables.map((variableName, j) => {
            return (
              <span key={j} className="variable-line">
                <Variable mac={mac} objectName={objectName} variableName={variableName} showLabel={true}/>
              </span>
            );
          })}
          </div>
        );
      })}
    </span>
  );
};

Variables.propTypes = {
  mac: PropTypes.string,
  objects: PropTypes.array.isRequired,
  objectsObj: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    objectsObj: state.objectReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Variables);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import * as actions from '../actions/clientActions';
import * as buildingActions from '../actions/buildingActions';
import FormInput from '../components/FormInput';
import {slugify} from '../utils/helper';
import BuildingsOverview from '../containers/BuildingsOverview';
import '../styles/edit-client-form.scss';

class EditClientForm extends Component {
  constructor(props) {
    super(props);

    this.state = {...props.client};
    this.save = this.save.bind(this);
  }
  componentDidMount() {
    const {getBuildings} = this.props.buildingActions;
    if (this.props.client) {
      const {buildingIds, slug} = this.props.client;
      if (buildingIds === undefined) {
        getBuildings(slug, false);
      }
    }
  }

  save() {
    const {updateClient} = this.props.actions;
    const clientCp = {...this.state};
    delete clientCp.buildingIds;
    updateClient(clientCp);
  }

  render() {
    if (!this.props.client) {
      return null;
    }
    const {name, slug, id} = this.state;
    const {buildingIds} = this.props.client;

    return (
      <form onSubmit={(e) => { e.preventDefault(); this.save();}} className="edit-client-form">
        <FormInput id="name" type="text" label="Name" value={name}
          onChange={(value) => { this.setState({name: value}); this.setState({slug: slugify(value)});}} required />
        <p>Slug: {slug}</p>
        <p>Buildings:</p>
        <BuildingsOverview buildingIds={buildingIds} clientId={id} />
        <div className="create-building">
          <Link className="btn btn-primary" to={`/building/new/${id}`}>Create new building</Link>
        </div>
        <input type="submit" className="btn btn-primary" value="Save" />
      </form>
    );
  }
}

EditClientForm.propTypes = {
  id: PropTypes.string.isRequired,
  client: PropTypes.object,
  actions: PropTypes.object.isRequired,
  buildingActions: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    client: state.clientReducer[props.id],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    buildingActions: bindActionCreators(buildingActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditClientForm);

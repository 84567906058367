import React, {memo} from 'react';
import PropTypes from 'prop-types';
import ReactTableCell from './ReactTableCell';

const ReactTableRow = ({row}) => {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell, i) => <ReactTableCell key={i} cell={cell} />)}
    </tr>
  );
};

ReactTableRow.propTypes = {
  row: PropTypes.object,
};

export default memo(ReactTableRow);


export const SET_LINES = 'SET_LINES';
export const SET_SHOW_LINE_CONTROL = 'SET_SHOW_LINE_CONTROL';
export const SET_LINE_DRAG = 'SET_LINE_DRAG';
export const SET_LINE_PROPS = 'SET_LINE_PROPS';
export const SELECT_LINE = 'SELECT_LINE';
export const DELETE_LINES = 'DELETE_LINES';
export const CREATE_LINE = 'CREATE_LINE';
export const ADD_LAMP_TO_LINE = 'ADD_LAMP_TO_LINE';
export const REMOVE_LAMP_FROM_LINE = 'REMOVE_LAMP_FROM_LINE';
export const SPREAD_LINE_LAMPS_EVENLY = 'SPREAD_LINE_LAMPS_EVENLY';
export const COPY_LINES = 'COPY_LINES';
export const CLICK_LINE = 'CLICK_LINE';
export const DRAG_LINE = 'DRAG_LINE';
export const CHANGE_SELECT_ALL_LINES = 'CHANGE_SELECT_ALL_LINES';
export const ALIGN_SELECTED_LINES = 'ALIGN_SELECTED_LINES';

import React from 'react';
import PropTypes from 'prop-types';

const SvgText = ({text, rotation, offsetY, offsetX, fontSize}) => {
  if (!text) {
    return null;
  }
  return (
    <text transform={`rotate(${-rotation}) translate(${offsetX}, ${offsetY})`}
    fontFamily="Verdana" fontSize={fontSize} stroke="none">
      {text}
    </text>
  );
};
SvgText.propTypes = {
  rotation: PropTypes.number,
  text: PropTypes.string,
  offsetY: PropTypes.number.isRequired,
  offsetX: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired,
};
export default SvgText;

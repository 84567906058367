import React from 'react';
import PropTypes from 'prop-types';
import RotationControl from './RotationControl';
import DisplayModeControl from './DisplayModeControl';
import '../styles/mode-bar.scss';

const ModeBar = () => {

  return (
    <div className="mode-bar">
      <DisplayModeControl />
      <div className="right">
        <RotationControl />
      </div>
    </div>
  );
};

ModeBar.propTypes = {
  activeBuildingId: PropTypes.number,
};

export default ModeBar;

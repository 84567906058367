export function hasPermission(permissions, permission) {
  return permissions.indexOf(permission) > -1;
}

export const PERMISSION_TECHNICAL = 'PERMISSION_TECHNICAL';
export const PERMISSION_LIVE = 'PERMISSION_LIVE';
export const PERMISSION_CONFIG = 'PERMISSION_CONFIG';

export const PERMISSION_CONFIG_MOVEMENT_DATA = 'PERMISSION_CONFIG_MOVEMENT_DATA';
export const PERMISSION_CONFIG_ENERGY_DATA = 'PERMISSION_CONFIG_ENERGY_DATA';

export const PERMISSION_CLIENT_REPORTS = 'PERMISSION_CLIENT_REPORTS';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/lampActions';
import { getTranslate } from 'react-localize-redux';
import Checkbox from '../components/Checkbox';

const DragLampsCheckbox = ({dragLamps, translate, actions}) => {
  const {setDragLamps} = actions;
  return (
    <Checkbox checked={dragLamps} onChange={() => setDragLamps(!dragLamps)}>
      {translate('technical_line_lamps_drag')}
    </Checkbox>
  );
};

DragLampsCheckbox.propTypes = {
  dragLamps: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {dragLamps} = state.lampReducer;
  const translate = getTranslate(state.localeReducer);
  return {
    dragLamps,
    translate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DragLampsCheckbox);

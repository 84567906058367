let uid = 0;
let groupUid = 0;

export const getUniqueId = () => {
  uid++;
  return uid;
};

export const getGroupUKey = () => {
  groupUid++;
  return groupUid + "";
};

export function revertUid(uid) {
  const id = uid.match(/_([^_]*)$/)[1];
  return parseInt(id);
}

export function revertUidNoParse(uid) {
  const id = uid.match(/_([^_]*)$/)[1];
  return id;
}

export const makeUid = (parentId, id) => `${parentId}_${id}`;

export const clientKey = (clientId) => `c_${clientId}`;

import {GET_MOVEMENT_DATA, SET_MOVEMENT_REDUCER_PROPS} from '../constants/movementActionTypes';
import {TRIVIEW_API} from '../constants/urls';
import {API, GET} from '../constants/customTypes';

export function getMovementData(configId, startDate, endDate) {

  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      url: TRIVIEW_API.MOVEMENT.GET.replace(':configId', configId),
      urlParams: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
      method: GET,
      success: GET_MOVEMENT_DATA.SUCCESS,
      error: GET_MOVEMENT_DATA.ERROR,
      start: GET_MOVEMENT_DATA.START,
    },
  };
}

export function setMovementReducerProps(obj) {
  return {
    type: SET_MOVEMENT_REDUCER_PROPS,
    obj,
  };
}

import {OVERWRITE_FROM_CLOUD,
  } from '../constants/siteActionTypes';
import {INITIAL} from '../constants/customTypes';
import {statusHandler} from '../utils/reducerHelper';

const initialState = {
  overwriteCloudStatus: INITIAL,
};

const statusHandlerFunc = statusHandler([
  {
    apiAction: OVERWRITE_FROM_CLOUD,
    statusPropName: 'overwriteCloudStatus',
  },
]);

export default function siteReducer(originalState = initialState, action) {
  const state = statusHandlerFunc(originalState, action);
  switch (action.type) {

    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import PlanUpload from './PlanUpload';
import ImgForm from './ImgForm';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/planActions';
import { getTranslate } from 'react-localize-redux';
import SetPlanImgDefaultView from '../containers/SetPlanImgDefaultView';
import '../styles/img-control.scss';

const ImgControl = ({showPlanControl, actions, translate}) => {
  const {setShowPlanControl} = actions;
  let control = null;
  if (showPlanControl) {
    control = (
      <div className="row">
        <div className="col-md-12 default-view">
          <SetPlanImgDefaultView />
        </div>
        <div className="col-md-12">
          <ImgForm />
        </div>
        <div className="col-md-12">
          <PlanUpload />
        </div>
      </div>
    );
  }
  return (
    <span className="img-control">
      <div className="row">
        <div className="col-md-12">
          <h4 onClick={() => setShowPlanControl(!showPlanControl)}
          className="pointer">
          <i className={"fa " + (showPlanControl ? "fa-minus":"fa-plus")}
          aria-hidden="true"/>
            {translate('technical_plan_control')}
          </h4>
        </div>
      </div>
      {control}
    </span>
  );
};

ImgControl.propTypes = {
  actions: PropTypes.object.isRequired,
  showPlanControl: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {showPlanControl} = state.planReducer;
  const translate = getTranslate(state.localeReducer);
  return {
    showPlanControl,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImgControl);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/lampActions';
import * as lineActions from '../actions/lineActions';
import '../styles/line-lamp-item.scss';
import SubLampItem from './SubLampItem';
import {parseIfInt} from '../utils/helper';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';
import LampTypeOption from '../containers/LampTypeOption';
import {LAMP_TYPE_MASTER} from '../constants/lampTypeTypes';

const LineLampItem = ({actions, lamp, lineActions, lineId, translate, lampType, lampTypeIds, lampTypeSlaveIds}) => {
  const {setLampType, addLampToLamp, setLampProps} = actions;
  const {removeLampFromLine} = lineActions;
  const {active, showSubLamps} = lamp;
  let subLampEl = null;
  let collapseEl = null;
  if (lampType.relationType === LAMP_TYPE_MASTER) {
    if (showSubLamps === true) {
      subLampEl = (
        <span>
          <div>
            {lamp.lampIds.map((lampId) => {
              return <SubLampItem key={lampId} id={lampId} parentLampId={lamp.id} lineId={lineId} />;
            })}
          </div>
          <ActionButton action={() => addLampToLamp(lamp.id, lampTypeSlaveIds[0])}
            extraClass="btn-primary"
            text={translate('technical_line_addsingleline')} />
        </span>
      );
    }
    collapseEl = (
      <span onClick={() => setLampProps(lamp.id, {showSubLamps: !showSubLamps})} style={{marginRight: '5px'}}
      className="pointer">
        <i className={"fa " + (showSubLamps === true ? 'fa-minus':'fa-plus')} aria-hidden="true" />
      </span>
    );
  }

  let className = "line-lamp-item";
  if (active === true) {
    className += ' active';
  }

  return (
    <div className={className}>
      {collapseEl}
      <select value={lamp.typeId} onChange={(e) => setLampType(lamp.id, e.target.value)}>
        {lampTypeIds.map(lampTypeId => (
          <LampTypeOption key={lampTypeId} id={lampTypeId} />
        ))}
      </select>
      &nbsp;
      <label htmlFor="x">x:</label><input type="number" step="1" min="0" id="x" value={lamp.x} className="form-control"
        onChange={(e) => setLampProps(lamp.id, {x: parseIfInt(e.target.value)})} />

      <ActionButton action={() => removeLampFromLine(lineId, lamp.id)}
        extraClass="btn-danger" icon="fa-times"
        title={translate('technical_line_deletelampfromline')} />
      {subLampEl}
    </div>
  );
};

LineLampItem.propTypes = {
  actions: PropTypes.object.isRequired,
  lineActions: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  lineId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  lampTypeIds: PropTypes.array.isRequired,
  lampTypeSlaveIds: PropTypes.array.isRequired,
};

function mapStateToProps(state, props) {
  const {lampTypeReducer, lampReducer} = state;
  const lamp = lampReducer[props.id];
  const lampType = lampTypeReducer[lamp.typeId];
  const {lampTypeLineIds : lampTypeIds, lampTypeSlaveIds} = lampTypeReducer;
  return {
    lamp: lampReducer[props.id],
    lampType,
    lampTypeIds,
    lampTypeSlaveIds,
    translate: getTranslate(state.localeReducer),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    lineActions: bindActionCreators(lineActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LineLampItem);

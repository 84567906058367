import {CLICK_LAMP, SET_LAMP_PROPS, CHANGE_SELECT_LAMPS, SET_LAMPS_GROUP, ADD_SELECTED_LAMPS_PROPS,
  CREATE_LAMP, DELETE_LAMP, SET_LAMP_TYPE, SET_DRAG_LAMPS, ADD_LAMP_TO_LAMP, DELETE_SUB_LAMP,
  SET_LAMPS_PROPS, DRAG_LAMP, DRAG_SUB_LAMP, DRAG_HITZONE, LOCATE_LAMP, REMOVE_LAMP_LINK,
  SET_SHOW_STANDALONE_LAMP_CONTROL, CREATE_STANDALONE_LAMP, DELETE_SELECTED_STANDALONE_LAMPS,
  ALIGN_SELECTED_STANDALONE_LAMPS, COPY_SELECTED_STANDALONE_LAMPS, SET_SELECTED_LAMP_IDS,
SET_LAMP_MAC_ENTRY, DELETE_LAMP_MAC_ENTRY, CHANGE_SELECT_ALL_LAMPS, LAMP_ZONE_CLICK} from '../constants/lampActionTypes';

export function clickLamp(id) {
  return {
    type: CLICK_LAMP,
    id,
  };
}

export function setLampProps(id, obj) {
  return {
    type: SET_LAMP_PROPS,
    id,
    obj,
  };
}

export function addSelectedLampsProps(id, obj) {
  return {
    type: ADD_SELECTED_LAMPS_PROPS,
    id,
    obj,
  };
}

export function setLampMacEntry(mac, id) {
  return {
    type: SET_LAMP_MAC_ENTRY,
    id,
    mac,
  };
}

export function deleteLampMacEntry(mac) {
  return {
    type: DELETE_LAMP_MAC_ENTRY,
    mac,
  };
}

export function changeSelectLamps(ids, selected) {
  return {
    type: CHANGE_SELECT_LAMPS,
    ids,
    selected,
  };
}

export function changeSelectAllLamps(selected) {
  return {
    type: CHANGE_SELECT_ALL_LAMPS,
    selected,
  };
}

export function setLampsGroup(lampIds, lampGroupObj) {
  return {
    type: SET_LAMPS_GROUP,
    lampIds,
    lampGroupObj,
  };
}

export function lampZoneClick(id, groupId) {
  return {
    type: LAMP_ZONE_CLICK,
    id,
    groupId,
  };
}

export function createLamp(id, lampTypeId, extraProps) {
  return {
    type: CREATE_LAMP,
    id,
    extraProps,
    lampTypeId,
  };
}

export function deleteLamp(id) {
  return {
    type: DELETE_LAMP,
    id,
  };
}

export function setLampType(id, lampTypeId) {
  return {
    type: SET_LAMP_TYPE,
    id,
    lampTypeId,
  };
}

export function setDragLamps(dragLamps) {
  return {
    type: SET_DRAG_LAMPS,
    dragLamps,
  };
}

export function addLampToLamp(id, lampTypeId) {
  return {
    type: ADD_LAMP_TO_LAMP,
    id,
    lampTypeId,
  };
}

export function deleteSubLamp(lineId, lampId, subLampId) {
  return {
    type: DELETE_SUB_LAMP,
    lineId,
    lampId,
    subLampId,
  };
}

export function setLampsProps(lamps) {
  return {
    type: SET_LAMPS_PROPS,
    lamps,
  };
}

export function dragLamp(id, deltaX, deltaY) {
  return {
    type: DRAG_LAMP,
    id,
    deltaX,
    deltaY,
  };
}

export function dragSubLamp(id, deltaX, deltaY) {
  return {
    type: DRAG_SUB_LAMP,
    id,
    deltaX,
    deltaY,
  };
}

export function dragHitzone(id, dragType, deltaX, deltaY) {
  return {
    type: DRAG_HITZONE,
    id,
    dragType,
    deltaX,
    deltaY,
  };
}

export function locateLamp(id) {
  return {
    type: LOCATE_LAMP,
    id,
  };
}

export function removeLampLink(mac) {
  return {
    type: REMOVE_LAMP_LINK,
    mac,
  };
}

export function setShowStandaloneLampControl(value) {
  return {
    type: SET_SHOW_STANDALONE_LAMP_CONTROL,
    value,
  };
}

export function createStandaloneLamp() {
  return {
    type: CREATE_STANDALONE_LAMP,
  };
}

export function deleteSelectedStandaloneLamps() {
  return {
    type: DELETE_SELECTED_STANDALONE_LAMPS,
  };
}

export function alignSelectedStandaloneLamps() {
  return {
    type: ALIGN_SELECTED_STANDALONE_LAMPS,
  };
}

export function copySelectedStandaloneLamps() {
  return {
    type: COPY_SELECTED_STANDALONE_LAMPS,
  };
}

export function setSelectedLampIds(selectedLampIds) {
  return {
    type: SET_SELECTED_LAMP_IDS,
    selectedLampIds,
  };
}

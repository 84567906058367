import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/planActions';
import {TEXT_MAC, TEXT_NONE, TEXT_NAME} from '../constants/customTypes';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const ShowTextControl = ({actions, showLampText, translate}) => {
  const {setPlanReducerProps} = actions;
  return (
    <span className="show-text-control">
      <div className="btn-group" role="group">
        <ActionButton action={() => setPlanReducerProps({showLampText: TEXT_NONE})}
          extraClass={"btn-default " + (showLampText === TEXT_NONE ? 'active':'')}
          text={translate('technical_none')} />
        <ActionButton action={() => setPlanReducerProps({showLampText: TEXT_MAC})}
          extraClass={"btn-default " + (showLampText === TEXT_MAC ? 'active':'')}
          text={translate('technical_mac')} />
        <ActionButton action={() => setPlanReducerProps({showLampText: TEXT_NAME})}
          extraClass={"btn-default " + (showLampText === TEXT_NAME ? 'active':'')}
          text={translate('technical_name')} />
      </div>
    </span>
  );
};

ShowTextControl.propTypes = {
  actions: PropTypes.object.isRequired,
  showLampText: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    showLampText: state.planReducer.showLampText,
    translate: getTranslate(state.localeReducer)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShowTextControl);

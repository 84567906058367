import {DS_RPC, DS_EVENT_SUB, DS_EVENT_UNSUB, MOVEMENT_EVENT,
} from '../constants/customTypes';
import {OVERWRITE_FROM_CLOUD,
GROUP_UPDATE_EVENT} from '../constants/siteActionTypes';

export function subscribeMovement(dataport, buildingId) {
  return {
    type: DS_EVENT_SUB,
    payload: {
      url: `${dataport}/${buildingId}/live/movement`,
      type: MOVEMENT_EVENT,
    },
  };
}

export function unsubscribeMovement(dataport, buildingId) {
  return {
    type: DS_EVENT_UNSUB,
    payload: {
      url: `${dataport}/${buildingId}/live/movement`,
    },
  };
}

export function subscribeGroupUpdates(dataport, buildingId) {
  return {
    type: DS_EVENT_SUB,
    payload: {
      url: `${dataport}/${buildingId}/live/groupUpdates`,
      dataAction: GROUP_UPDATE_EVENT,
    }
  };
}

export function unsubscribeGroupUpdates(dataport, buildingId) {
  return {
    type: DS_EVENT_UNSUB,
    payload: {
      url: `${dataport}/${buildingId}/live/groupUpdates`,
    }
  };
}

export function subscribeDataportEvent(dataport, url, dataAction, extraProps) {
  return {
    type: DS_EVENT_SUB,
    payload: {
      url: `${dataport.toLowerCase()}/${url}`,
      dataAction,
    },
    props: {
      ...extraProps,
      dataport,
    },
  };
}

export function unsubscribeDataportEvent(dataport, url, dataAction) {
  return {
    type: DS_EVENT_UNSUB,
    payload: {
      url: `${dataport.toLowerCase()}/${url}`,
      dataAction,
    },
    props: {
      siteName: dataport,
    },
  };
}

export function overwriteFromCloud() {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: `:site/overwriteFromCloud`,
      success: OVERWRITE_FROM_CLOUD.SUCCESS,
      error: OVERWRITE_FROM_CLOUD.ERROR,
      start: OVERWRITE_FROM_CLOUD.START,
    }
  };
}

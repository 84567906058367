import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../actions/planActions';
import {bindActionCreators} from 'redux';
import {ZOOM, SELECT_PSES, SELECT_LAMPS} from '../constants/customTypes';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const ModeControl = ({ actions, mode, translate, showSelectPses }) => {

  const { setMode } = actions;
  let selectPsesEl = null;
  if (showSelectPses) {
    selectPsesEl = (
      <ActionButton action={() => setMode(SELECT_PSES)}
        extraClass={"btn-default" + (mode === SELECT_PSES ? ' active':'')}
        text={translate('technical_mode_select_ps')} />
    );
  }

  return (
    <span className="mode-control">
      <span className="btn-group">
        <ActionButton action={() => setMode(ZOOM)}
          extraClass={"btn-default" + (mode === ZOOM ? ' active':'')}
          text={translate('technical_mode_zoom')} />

        <ActionButton action={() => setMode(SELECT_LAMPS)}
          extraClass={"btn-default" + (mode === SELECT_LAMPS ? ' active':'')}
          text={translate('technical_mode_select_lamps')} />

        {selectPsesEl}
      </span>
    </span>
  );
};

ModeControl.propTypes = {
  actions: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  showSelectPses: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    mode: state.planReducer.mode,
    translate: getTranslate(state.localeReducer)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModeControl);

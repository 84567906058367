import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ActionButton from '../components/ActionButton';
import * as liveActions from '../actions/liveActions';
import {LIVE_MODE_MOVEMENT, LIVE_MODE_ENERGY_MONITORING, LIVE_MODE_LAMP_NAMES} from '../constants/livePropTypes';
import '../styles/live-bar.scss';
import { getTranslate } from 'react-localize-redux';

const LiveBar = ({translate, liveMode, liveActions}) => {
  const {setLiveMode} = liveActions;

  return (
    <div className="live-bar">
      <span className="btn-group" role="group">
        <ActionButton action={() => setLiveMode(LIVE_MODE_MOVEMENT)}
          extraClass={"btn-default" + (liveMode === LIVE_MODE_MOVEMENT ? ' active':'')}
          icon="fa-flash"
          text={translate('live_movement')} />
        <ActionButton action={() => setLiveMode(LIVE_MODE_ENERGY_MONITORING)}
          extraClass={"btn-default " + (liveMode === LIVE_MODE_ENERGY_MONITORING ? 'active':'')}
          icon="fa-signal"
          text={translate('live_energymonitoring')} />
        <ActionButton action={() => setLiveMode(LIVE_MODE_LAMP_NAMES)}
          extraClass={"btn-default " + (liveMode === LIVE_MODE_LAMP_NAMES ? 'active':'')}
          icon="fa-signal"
          text={translate('live_lamp_names')} />
      </span>
    </div>
  );
};

LiveBar.propTypes = {
  liveMode: PropTypes.string.isRequired,
  liveActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    liveMode: state.liveReducer.liveMode,
    translate: getTranslate(state.localeReducer)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    liveActions: bindActionCreators(liveActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiveBar);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as ppActions from '../actions/ppActions';
import * as nodeActions from '../actions/nodeActions';
import * as lampActions from '../actions/lampActions';
import ActionButton from '../components/ActionButton';
import UpgradeFirmwareLabels from '../components/UpgradeFirmwareLabels';
import SyncLabel from '../components/SyncLabel';
import { getTranslate } from 'react-localize-redux';

const LampItem = ({ node, lamp, nodeActions, firmwareStatus, translate, lampActions, ipui, id, selected,
ppActions,}) => {
  const {setLampDialogMac, pollNodes, setLinking, wink, setNodeProps} = nodeActions;
  const {locateLamp, removeLampLink} = lampActions;
  const {setPpProps} = ppActions;
  let name, linking, pollStatus, upgraded, syncing, progress, fails, online, isSynced, configured;
  if (node) {
    name = node.name;
    linking = node.linking;
    pollStatus = node.pollStatus;
    upgraded = node.upgraded;
    syncing = node.syncing;
    progress = node.progress;
    fails = node.fails;
    online = node.online;
    isSynced = node.isSynced;
    configured = node.configured;
  }

  let locateBtn = null;
  let removeLinkBtn = null;

  let linkingClass = 'btn-default';
  if (linking) {
    linkingClass = 'btn-primary';
  }else if(lamp !== undefined) {
    linkingClass = 'btn-success';
    locateBtn = (
      <ActionButton action={() => locateLamp(lamp.id)}
        icon="fa-map-marker" extraClass="btn-sm btn-default"
        title="Locate"/>
    );

    removeLinkBtn = (
      <ActionButton action={() => removeLampLink(lamp.mac)}
        extraClass="btn-default btn-sm"
        icon="fa-ban"
        title="Remove link" />
    );
  }
  let lampClassName = "lamp-item";
  if (lamp && lamp.active === true) {
    lampClassName += ' active';
  }else if(configured === false) {
    lampClassName += ' unconfigured';
  }else if(online === false) {
    lampClassName += ' offline';
  }

  return (
    <div className={lampClassName} onMouseOver={() => setNodeProps(ipui, {active: true})}
    onMouseOut={() => setNodeProps(ipui, {active: false})}>
      <span className="lamp-header">
        {/*<i className="fa fa-male movement" aria-hidden="true" />*/}
        {name} | {ipui}
      </span>
      &nbsp;
      <span className="btn-group">
        <ActionButton action={() => wink(ipui)}
          extraClass="btn-default btn-sm"
          icon="fa-lightbulb-o"
          title={translate('technical_wink')} />
        <ActionButton action={() => setLinking(ipui, !linking)}
          extraClass={'btn-sm ' + linkingClass}
          icon="fa-external-link"
          title={translate('technical_linktonode')} />
        {removeLinkBtn}
        {locateBtn}
        <ActionButton action={() => {setLampDialogMac(ipui, id); pollNodes([ipui]); }}
          extraClass="btn-default btn-sm"
          icon="fa-info"
          title={translate('technical_info')} />
        <ActionButton action={() => pollNodes([ipui])}
          status={pollStatus} icon="fa-refresh" extraClass="btn-sm"
          title={translate('technical_refresh')}/>
        <ActionButton action={() => setPpProps(id, {selected: !selected})}
          extraClass={"btn-sm btn-default" + (selected === true ? ' active':'')}
          icon="fa-bullseye"
          title={translate('technical_selectlamp')} />
      </span>
      <UpgradeFirmwareLabels status={firmwareStatus} upgraded={upgraded} syncing={syncing} progress={progress} fails={fails} />
      <SyncLabel isSynced={isSynced} />
    </div>
  );
};

LampItem.propTypes = {
  nodeActions: PropTypes.object.isRequired,
  lampActions: PropTypes.object.isRequired,
  ppActions: PropTypes.object.isRequired,
  node: PropTypes.object,
  // pp: PropTypes.object,
  id: PropTypes.string.isRequired,
  lamp: PropTypes.object,
  firmwareStatus: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  ipui: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

function mapStateToProps(state, props) {
  const {firmwareReducer, lampReducer} = state;
  const {status} = firmwareReducer;
  const pp = state.ppReducer[props.id];
  const {ipui, selected} = pp;
  let lamp;
  const lampMap = lampReducer.lampMacMap;
  const lampId = lampMap[ipui];
  lamp = lampReducer[lampId];

  const translate = getTranslate(state.localeReducer);

  return {
    node: state.nodeReducer[ipui],
    ipui,
    lamp: lamp,
    firmwareStatus: status,
    translate,
    selected,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    nodeActions: bindActionCreators(nodeActions, dispatch),
    lampActions: bindActionCreators(lampActions, dispatch),
    ppActions: bindActionCreators(ppActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LampItem);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import NumberFormat from 'react-number-format';
import '../styles/live-legend.scss';
import { getTranslate } from 'react-localize-redux';
import C3Gauge from '../components/C3Gauge';

const LiveEnergyMonitoringLegend = ({translate, totalPower, maxPower, relativePower, totalPowerConsumption,
totalPowerRounded, maxPowerRounded}) => {
  if (totalPower === undefined || maxPower === undefined) {
    return (
      <div className="live-legend">
        <p>
          <span>{translate('live_legend_energymonitoring_not_available')}</span>
        </p>
      </div>
    );
  }

  let data = [];
  data.push(relativePower);

  return (
    <div className="live-legend">
      <p align="center">
        <span>{translate('live_legend_energymonitoring_header')}</span>
        <br/>
        <span>
          <strong>
          <NumberFormat thousandSeparator={translate('global_number_thousand_separator')}
              decimalSeparator={translate('global_number_decimal_separator')}
              displayType={'text'} value={totalPowerConsumption} />
          {' '}
          {translate('live_power_unit_consumption')}
          </strong>
        </span>
        <br/>
        <span>
          <NumberFormat thousandSeparator={translate('global_number_thousand_separator')}
              decimalSeparator={translate('global_number_decimal_separator')}
              displayType={'text'} value={totalPowerRounded} />
          {' '}
          {translate('live_power_unit')}
          {' / '}
          <NumberFormat thousandSeparator={translate('global_number_thousand_separator')}
              decimalSeparator={translate('global_number_decimal_separator')}
              displayType={'text'} value={maxPowerRounded} />
          {' '}
          {translate('live_power_unit')}
        </span>
        <br/>
        <span>{translate('live_legend_energymonitoring_footer')}</span>
      </p>
      <div>
        <C3Gauge data={data} height={100} />
      </div>
    </div>
  );

};

LiveEnergyMonitoringLegend.propTypes = {
  translate: PropTypes.func.isRequired,
  totalPower: PropTypes.number,
  maxPower: PropTypes.number,
  relativePower: PropTypes.number,
  totalPowerConsumption: PropTypes.number,
  maxPowerRounded: PropTypes.number,
  totalPowerRounded: PropTypes.number,
};

function mapStateToProps({liveReducer, localeReducer}) {
  const translate = getTranslate(localeReducer);
  const {totalPower, maxPower} = liveReducer;

  let totalPowerConsumption = 0;
  let relativePower = 0;
  let totalPowerRounded = 0;
  let maxPowerRounded = 0;
  if (totalPower && maxPower) {
    relativePower = Math.round((totalPower / maxPower) * 100);

    totalPowerConsumption = Math.round(totalPower / 1000 * 100) / 100;
    maxPowerRounded = Math.round(maxPower * 100) / 100;
    totalPowerRounded = Math.round(totalPower * 100) / 100;
  }

  return {
    translate,
    totalPower,
    maxPower,
    relativePower,
    totalPowerConsumption,
    totalPowerRounded,
    maxPowerRounded,
  };
}

export default connect(
  mapStateToProps,
  null,
)(LiveEnergyMonitoringLegend);

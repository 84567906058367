import React from 'react';
import PropTypes from 'prop-types';
import BaseLampZone from '../containers/BaseLampZone';
import {DEPICTION_RECT, DEPICTION_CLOCK} from '../constants/lampTypeTypes';

const LampZoneDepiction = ({lamp, lampType, ...rest}) => {
  const {depictionType, length} = lampType;
  const {x, y, rotation, top, bottom, left, right} = lamp;

  if (left === undefined) {
    return null;
  }

  const width = left + right;
  const height = top + bottom;
  let leftX, leftY;
  if (depictionType === DEPICTION_RECT) {
    leftX = -left + (length / 2);
    leftY = -top;
  }else if(depictionType == DEPICTION_CLOCK) {
    leftX = -left;
    leftY = -top;
  }
  return <BaseLampZone x={x} y={y} rotation={rotation} width={width} height={height} leftX={leftX} leftY={leftY} {...rest} />;
};

LampZoneDepiction.propTypes = {
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
};

export default LampZoneDepiction;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ShowTextControl from './ShowTextControl';
import ModeControl from './ModeControl';
import HitzoneControl from './HitzoneControl';
import SaveConfig from './SaveConfig';
import * as nodeActions from '../actions/nodeActions';
import * as apActions from '../actions/apActions';
import * as planActions from '../actions/planActions';
import * as firmwareActions from '../actions/firmwareActions';
import {CONFIG_MODE_NVI_STATE, CONFIG_MODE_NVO_RSSI,
LAMP_CLICK_MODE_DIALOG, LAMP_CLICK_MODE_WINK} from '../constants/customTypes';
import SelectionControl from './SelectionControl';
import '../styles/technical-bar.scss';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const TechnicalBar = ({dataportSlug, configMode, nodeActions, translate, apActions, planActions, lampClickMode,
  activeBuildingId, saveSmaliStatus,
firmwareActions, getSyncedStatus, reSyncStatus}) => {
  if (configMode === undefined) {
    return null;
  }
  let refreshEl = null;
  const {getNodes, saveSmaliObjects} = nodeActions;
  const {getAps} = apActions;
  const {setLampClickMode, setPlanReducerProps} = planActions;
  const {getNodesUpgradeSynced, reSyncNodesUpgrade} = firmwareActions;

  if (dataportSlug !== undefined) {
    refreshEl = (
      <ActionButton action={() => { getNodes(dataportSlug); getAps(dataportSlug); }}
        extraClass="btn-default"
        icon="fa-refresh"
        text={translate('technical_refresh')} />
    );
  }

  return (
    <div className="technical-bar">
      <ModeControl showSelectPses={true} />
      <SelectionControl />
      {refreshEl}
      <span className="btn-group" role="group">
        <ActionButton action={() => setLampClickMode(LAMP_CLICK_MODE_DIALOG)}
          extraClass={"btn-default" + (lampClickMode === LAMP_CLICK_MODE_DIALOG ? ' active':'')}
          icon="fa-refresh"
          text="Dialog" />
        <ActionButton action={() => setLampClickMode(LAMP_CLICK_MODE_WINK)}
          extraClass={"btn-default " + (lampClickMode === LAMP_CLICK_MODE_WINK ? 'active':'')}
          icon="fa-lightbulb-o"
          text="Wink" />
      </span>
      <span className="btn-group" role="group">
        <ActionButton action={() => setPlanReducerProps({configMode: CONFIG_MODE_NVI_STATE})}
          extraClass={"btn-default" + (configMode === CONFIG_MODE_NVI_STATE ? ' active':'')}
          icon="fa-lightbulb-o"
          text={translate('technical_nvi_state')} />
        <ActionButton action={() => setPlanReducerProps({configMode: CONFIG_MODE_NVO_RSSI})}
          extraClass={"btn-default " + (configMode === CONFIG_MODE_NVO_RSSI ? 'active':'')}
          icon="fa-signal"
          text={translate('technical_nvo_rssi')} />
      </span>
      <ShowTextControl />
      <HitzoneControl />
      <ActionButton action={() => getNodesUpgradeSynced()}
        status={getSyncedStatus}
        text="Get Synced" />
      <ActionButton action={() => reSyncNodesUpgrade()}
        status={reSyncStatus}
        text="ReSync" />
      <SaveConfig />
      <ActionButton action={() => saveSmaliObjects(activeBuildingId)}
        text="Save Smali"
        status={saveSmaliStatus}
      />
    </div>
  );
};

TechnicalBar.propTypes = {
  dataportSlug: PropTypes.string,
  configMode: PropTypes.string,
  nodeActions: PropTypes.object.isRequired,
  apActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  planActions: PropTypes.object.isRequired,
  firmwareActions: PropTypes.object.isRequired,
  lampClickMode: PropTypes.string.isRequired,
  getSyncedStatus: PropTypes.string.isRequired,
  reSyncStatus: PropTypes.string.isRequired,
  activeBuildingId: PropTypes.number.isRequired,
  saveSmaliStatus: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {buildingReducer, planReducer, firmwareReducer, nodeReducer} = state;
  const {saveSmaliStatus} = nodeReducer;
  const {activeBuildingId} = buildingReducer;
  const {lampClickMode} = planReducer;
  const {getSyncedStatus, reSyncStatus} = firmwareReducer;
  const building = buildingReducer[activeBuildingId];
  if (building === undefined) {
    return {};
  }
  return {
    dataportSlug: building.dataportSlug,
    lampClickMode,
    configMode: planReducer.configMode,
    translate: getTranslate(state.localeReducer),
    reSyncStatus,
    getSyncedStatus,
    activeBuildingId,
    saveSmaliStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    nodeActions: bindActionCreators(nodeActions, dispatch),
    apActions: bindActionCreators(apActions, dispatch),
    planActions: bindActionCreators(planActions, dispatch),
    firmwareActions: bindActionCreators(firmwareActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TechnicalBar);

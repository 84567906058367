import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DragBorderZone from './BaseDragBorderZone';

const LineDragZone = ({line, planRotation}) => {
  const {x, y, lampIds, rotation} = line;
  const transform = `translate(${x},${y}),rotate(${rotation})`;

  return (
    <g transform={transform}>
      {
        lampIds.map((id) => {
          return (
            <DragBorderZone key={id} id={id} className="drag-zone" extraRotation={rotation + planRotation} />
          );
        })
      }
    </g>
  );
};

LineDragZone.propTypes = {
  line: PropTypes.object,
  id: PropTypes.string.isRequired,
  planRotation: PropTypes.number.isRequired,
};

function mapStateToProps(state, props) {

  return {
    line: state.lineReducer[props.id],
  };
}

export default connect(
  mapStateToProps,
  {},
)(LineDragZone);

import {SET_OBJECTS, SET_VARIABLES, SET_LAMP_DIALOG_MAC, SET_PS_DIALOG_MAC,
  SET_VARIABLE_VALUE, SET_VARIABLE_EDITING, GET_NODES,
  PING_NODES, POLL_NODES, SET_NODE_PROPS, WINK, SET_NODES_MULTI_PROPS,
  RESET_NODE, CHANGE_SELECT_NODES, SET_NODES_PROPS, SET_LINKING,
SET_LINKING_SUCCESS, FORCE_READ_CONFIG,
  SAVE_SMALI_OBJECTS,
SET_VARIABLES_VALUE_API} from '../constants/nodeActionTypes';
import {DS_RPC} from '../constants/customTypes';
import {serialRpcAction} from './helperActions';

export function setObjects(objects) {
  return {
    type: SET_OBJECTS,
    objects,
  };
}

export function setVariables(variables) {
  return {
    type: SET_VARIABLES,
    variables,
  };
}

export function setLampDialogMac(mac, ppId) {
  return {
    type: SET_LAMP_DIALOG_MAC,
    mac,
    ppId,
  };
}

export function setPsDialogMac(mac) {
  return {
    type: SET_PS_DIALOG_MAC,
    mac,
  };
}

export function setVariableValue(path, value) {
  return {
    type: SET_VARIABLE_VALUE,
    path,
    value,
  };
}

export function setVariableEditing(path, editing) {
  return {
    type: SET_VARIABLE_EDITING,
    path,
    editing,
  };
}

export function getNodes() {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: ':site/nodes',
      success: GET_NODES.SUCCESS,
      error: GET_NODES.ERROR,
      start: GET_NODES.START,
    },
  };
}

export function setVariablesValueApi(macs, objectName, variableName, value) {
  const intValue = parseInt(value);
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: `:site/variables/set`,
      data: {
        objectName,
        macs,
        value: intValue,
        variableName,
      },
      success: SET_VARIABLES_VALUE_API.SUCCESS,
      error: SET_VARIABLES_VALUE_API.ERROR,
      start: SET_VARIABLES_VALUE_API.START,
    },
    props: {
      objectName,
      variableName,
      macs,
    }
  };
}

export function pollNodes(ids) {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: `:site/nodes/poll`,
      data: {
        ids,
      },
      success: POLL_NODES.SUCCESS,
      error: POLL_NODES.ERROR,
      start: POLL_NODES.START,
    },
    props: {
      ids,
    },
  };
}

export function pingNodes(macs) {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: `:site/nodes/ping`,
      data: macs,
      success: PING_NODES.SUCCESS,
      error: PING_NODES.ERROR,
      start: PING_NODES.START,
    },
    props: {
      macs,
    },
  };
}

export function wink(id) {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: `:site/wink`,
      data: {
        id,
      },
      success: WINK.SUCCESS,
      error: WINK.ERROR,
      start: WINK.START,
    },
    props: {
      id,
    }
  };
}

export function resetNode(mac) {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: `:site/node/reset`,
      data: {
        id: mac,
      },
      success: RESET_NODE.SUCCESS,
      error: RESET_NODE.ERROR,
      start: RESET_NODE.START,
    },
    props: {
      id: mac,
    },
  };
}

export function forceReadConfig(id) {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: `:site/node/forceReadConfig`,
      data: {id},
      success: FORCE_READ_CONFIG.SUCCESS,
      error: FORCE_READ_CONFIG.ERROR,
      start: FORCE_READ_CONFIG.START,
    },
    props: {
      id,
    },
  };
}

export function serialWink(ids) {
  return serialRpcAction(':site/wink', ids, 'id', true, WINK);
}

export function setNodeProps(id, obj) {
  return {
    type: SET_NODE_PROPS,
    id,
    obj,
  };
}

export function setNodesProps(ids, obj) {
  return {
    type: SET_NODES_PROPS,
    ids,
    obj
  };
}

export function changeSelectNodes(ids, selected) {
  return {
    type: CHANGE_SELECT_NODES,
    ids,
    selected,
  };
}

export function setLinking(linkingId, linking) {
  return {
    type: SET_LINKING,
    linkingId,
    linking,
  };
}

export function setLinkingSuccess() {
  return {
    type: SET_LINKING_SUCCESS,
  };
}

export function setNodesMultiProps(ids, obj) {
  return {
    type: SET_NODES_MULTI_PROPS,
    ids,
    obj,
  };
}

export function saveSmaliObjects(configId) {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: ':site/saveSmaliObjects',
      data: {configId},
      success: SAVE_SMALI_OBJECTS.SUCCESS,
      error: SAVE_SMALI_OBJECTS.ERROR,
      start: SAVE_SMALI_OBJECTS.START,
    },
  };
}

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ActionButton from '../components/ActionButton';
import * as energyActions from '../actions/energyActions';
import * as energyRetentionActions from '../actions/energyRetentionActions';
import * as scenarioActions from '../actions/scenarioActions';
import { getTranslate } from 'react-localize-redux';
import ScenarioSelect from '../containers/ScenarioSelect';
import {revertUid} from '../utils/idHelper';
import '../styles/energy-bar.scss';
import StartDateEndDateInterval from './StartDateEndDateInterval';
import {pgDurationToMs} from '../utils/dateHelper';
import {DEFAULT_DATE_FORMAT} from '../constants/customTypes';
import {floatToCommaString} from '../utils/helper';

const EnergyBar = ({energyActions, activeBuildingId, getEnergyStatus,
  getSimulateEnergyStatus,
  simulatedEnergyText,
  actualActive,
  daylightSaved,
  actualEnergyText, scenarioOptions, activeScenarioId,
  startDate, endDate,
  selectedScenarioId,
  scenarioActions,
  retentionObject,
  energyRetentionActions,
  translate,
}) => {

  const {getEnergyData, getSimulateEnergy, setEnergyReducerProps} = energyActions;
  const {setSelectedScenario} = scenarioActions;
  const {getEnergyRetentionPeriods} = energyRetentionActions;

  useEffect(() => {
    if (retentionObject === undefined) {
      getEnergyRetentionPeriods(activeBuildingId);
    }
  }, []);

  if (!retentionObject) {
    return null;
  }
  const {simulationStartDate, energyCaptureStarted} = retentionObject;

  if (!energyCaptureStarted) {
    return (
      <div>
        <p>{translate('energy_capture_not_started')}.</p>
      </div>
    );
  }

  const endDateBeforeStartDate = endDate <= startDate;
  let disableSimulation = false;

  let actualEnergyEl = null;
  const energyActiveClassName = 'energy-active';
  if (actualEnergyText !== undefined) {
    let daylightSavedEl = null;
    if (daylightSaved > -1) {
      daylightSavedEl = (
        <h4>
          {floatToCommaString(daylightSaved)} {translate('energy_daylight_percent_saved')}
        </h4>
      );
    }

    actualEnergyEl = (
      <>
        <h3 className={actualActive ? energyActiveClassName:''}>
          {translate('energy_actual_energy')}: {actualEnergyText}
        </h3>
        {daylightSavedEl}
      </>
    );
  }

  let simulatedEnergyEl = null;
  if (simulatedEnergyText !== undefined) {
    simulatedEnergyEl = (
      <h3 className={actualActive ? '':energyActiveClassName}>
        {translate('energy_simulated_energy')}: {simulatedEnergyText}
      </h3>
    );
  }

  let simulatedTimeBetweenEl = null;
  if (endDate - startDate > pgDurationToMs({weeks: 2})) {
    disableSimulation = true;
    simulatedTimeBetweenEl = (
      <p className="err-info">
        {translate('energy_time_between_simulation_2_weeks_err')}.
      </p>
    );
  }

  let simulatedDateBeforeEl = null;
  if (startDate < simulationStartDate) {
    disableSimulation = true;
    simulatedDateBeforeEl = (
      <p className="err-info">
        {translate('energy_simulation_date_before')} {moment(simulationStartDate).format(DEFAULT_DATE_FORMAT)}.
      </p>
    );
  }

  return (
    <div className="energy-bar">
      <StartDateEndDateInterval
        retentionObject={retentionObject}
        startDate={startDate} endDate={endDate}
        setStartDate={(date) => setEnergyReducerProps({startDate: date})}
        setEndDate={(date) => setEnergyReducerProps({endDate: date})}
      />
      <div>
        <ActionButton action={() => getEnergyData(activeBuildingId, startDate, endDate)} disabled={endDateBeforeStartDate}
          status={getEnergyStatus}
          text={translate('energy_get_actual_consumption')}/>
      </div>

      {actualEnergyEl}

      <div className="simulate-block">
        <ScenarioSelect value={selectedScenarioId} name="scenario" activeScenarioId={activeScenarioId}
          scenarioOptions={scenarioOptions}
          onChange={({value}) => setSelectedScenario(value)}
        />

        <ActionButton action={() => getSimulateEnergy(activeBuildingId, revertUid(selectedScenarioId), startDate, endDate)}
          disabled={endDateBeforeStartDate || selectedScenarioId === undefined || disableSimulation}
          status={getSimulateEnergyStatus}
          text={translate('energy_simulate_consumption')}
          extraClass="simulate-btn btn-primary"
        />
      </div>

      {simulatedTimeBetweenEl}
      {simulatedDateBeforeEl}
      {simulatedEnergyEl}
    </div>
  );
};

EnergyBar.propTypes = {
  energyActions: PropTypes.object.isRequired,
  energyRetentionActions: PropTypes.object.isRequired,
  activeBuildingId: PropTypes.number.isRequired,
  translate: PropTypes.func.isRequired,
  getEnergyStatus: PropTypes.string.isRequired,
  getSimulateEnergyStatus: PropTypes.string.isRequired,
  actualEnergyText: PropTypes.string,
  simulatedEnergyText: PropTypes.string,
  activeScenarioId: PropTypes.string.isRequired,
  scenarioOptions: PropTypes.array.isRequired,
  actualActive: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  selectedScenarioId: PropTypes.string.isRequired,
  scenarioActions: PropTypes.object.isRequired,
  retentionObject: PropTypes.object,
  daylightSaved: PropTypes.number.isRequired,
};

function mapStateToProps({localeReducer, buildingReducer, energyReducer, energyRetentionReducer}) {
  const {activeBuildingId} = buildingReducer;
  const {actualEnergyText, simulatedEnergyText, getEnergyStatus, getSimulateEnergyStatus, actualActive,
  endDate, startDate, daylightSaved} = energyReducer;
  const building = buildingReducer[activeBuildingId];
  const {activeScenarioId, scenarioOptions, selectedScenarioId} = building;
  const retentionObject = energyRetentionReducer[activeBuildingId];
  return {
    translate: getTranslate(localeReducer),
    activeBuildingId,
    getEnergyStatus,
    getSimulateEnergyStatus,
    actualEnergyText,
    simulatedEnergyText,
    scenarioOptions,
    activeScenarioId,
    actualActive,
    startDate,
    endDate,
    selectedScenarioId,
    retentionObject,
    daylightSaved,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    energyActions: bindActionCreators(energyActions, dispatch),
    energyRetentionActions: bindActionCreators(energyRetentionActions, dispatch),
    scenarioActions: bindActionCreators(scenarioActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnergyBar);

import {apiAction} from './helper';

// export const GET_PS_NODES = apiAction('GET_PS_NODES');
export const SET_AP_PROPS = 'SET_AP_PROPS';
export const SET_COMMISSIONING = apiAction('SET_COMMISSIONING');
export const SET_MULTI_PS_NODES_PROPS = 'SET_MULTI_PS_NODES_PROPS';
export const SET_PS_NODES = 'SET_PS_NODES';
export const GET_APS = apiAction('GET_APS');
export const SET_MULTI_AP_PROPS = 'SET_MULTI_AP_PROPS';
export const CHANGE_SELECT_APS = 'CHANGE_SELECT_APS';
export const CHANGE_PROP_ALL_APS = 'CHANGE_PROP_ALL_APS';
export const DECOMMISSION = apiAction('DECOMMISSION');
export const SET_AP_ENABLED = apiAction('SET_AP_ENABLED');
export const CHANGE_PROP_NODE_ALL_APS = 'CHANGE_PROP_NODE_ALL_APS';
export const SET_APS = 'SET_APS';
export const DECOMMISSION_NODE = 'DECOMMISSION_NODE';
export const DECOMMISSION_SELECTED = 'DECOMMISSION_SELECTED';

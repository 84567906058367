import React from 'react';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';
import * as actions from '../actions/dataportActions';
import OnOff from '../components/OnOff';
import Checkbox from '../components/Checkbox';

const DataportsTechnicalRow = ({name, online, id, selected, actions, vpn_ip, packageVersions}) => {
  const {setDataportProps} = actions;
  let packageVersionEl = (
    <React.Fragment>
      <td/>
      <td/>
      <td/>
    </React.Fragment>
  );

  if (packageVersions) {
    const {tricommand, triagent, triviewLocal} = packageVersions;
    packageVersionEl = (
      <React.Fragment>
        <td>{tricommand}</td>
        <td>{triagent}</td>
        <td>{triviewLocal}</td>
      </React.Fragment>
    );
  }

  return (
    <tr>
      <td><Checkbox checked={selected} noMargin onChange={() => setDataportProps(id, {selected: !selected})} /></td>
      <td><Link to={"/monitor/" + id}>{name}</Link></td>
      {packageVersionEl}
      <td><OnOff on={online}/></td>
      <td>{vpn_ip}&nbsp;
        <CopyToClipboard text={vpn_ip}><span className="btn btn-default"><i className="fa fa-clipboard" aria-hidden="true" /></span></CopyToClipboard>
      </td>
    </tr>
  );
};

DataportsTechnicalRow.propTypes = {
  name: PropTypes.string.isRequired,
  online: PropTypes.bool,
  id: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  vpn_ip: PropTypes.string,
  actions: PropTypes.object.isRequired,
  packageVersions: PropTypes.object,
};

function mapStateToProps(state, {id}) {
  const {name, online, selected, vpn_ip, packageVersions} = state.dataportReducer[id];
  return {
    selected,
    name,
    online,
    vpn_ip,
    packageVersions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataportsTechnicalRow);

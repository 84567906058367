import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import '../styles/live-legend.scss';
import {LIVE_MODE_MOVEMENT, LIVE_MODE_ENERGY_MONITORING} from '../constants/livePropTypes';
import LiveMovementLegend from '../components/LiveMovementLegend';
import LiveEnergyMonitoringLegend from '../components/LiveEnergyMonitoringLegend';
import MovementHeatmapLegend from '../components/MovementHeatmapLegend';
import EnergyHeatmapLegend from '../components/EnergyHeatmapLegend';
import {isRoute} from '../utils/helper';
import {ROUTE_LIVE, ROUTE_MOVEMENT, ROUTE_ENERGY} from '../constants/const';

const PlanLegend = ({currentRoute, liveMode}) => {
  if (isRoute(currentRoute, ROUTE_LIVE)) {
    if (LIVE_MODE_MOVEMENT === liveMode) {
      return (<LiveMovementLegend/>);
    } else if (LIVE_MODE_ENERGY_MONITORING === liveMode) {
      return (<LiveEnergyMonitoringLegend/>);
    }
  }else if (isRoute(currentRoute, ROUTE_MOVEMENT)) {
    return (
      <MovementHeatmapLegend />
    );
  }else if (isRoute(currentRoute, ROUTE_ENERGY)) {
    return (
      <EnergyHeatmapLegend />
    );
  }
  return null;
};

PlanLegend.propTypes = {
  currentRoute: PropTypes.string.isRequired,
  liveMode: PropTypes.string
};

function mapStateToProps(state) {
  return {
    currentRoute : state.routingReducer.currentRoute,
    liveMode : state.liveReducer.liveMode
  };
}

export default connect(
  mapStateToProps,
  null,
)(PlanLegend);

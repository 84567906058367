import React from 'react';
import PropTypes from 'prop-types';
import RoleSelect from '../components/RoleSelect';

const UserClientRolesSelect = ({ roleIds, roleOptions, selectedClientId, onChange }) => {
  if (!selectedClientId) {
    return null;
  }

  return (
    <RoleSelect
      name="role"
      value={roleIds}
      roleOptions={roleOptions}
      onChange={(value) => onChange(value.map(({value}) => value))}
    />
  );
};

UserClientRolesSelect.propTypes = {
  selectedClientId: PropTypes.number,
  roleIds: PropTypes.array.isRequired,
  roleOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UserClientRolesSelect;

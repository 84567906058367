import React from 'react';
import PropTypes from 'prop-types';
import Drag from '../components/Drag';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/buildingActions';
import * as planActions from '../actions/planActions';

const Zoom = ({children, actions, planActions}) => {
  const {dragZoom, zoomZoom} = actions;
  const {setPlanDragStart} = planActions;
  const zoom = (event) => {
    event.preventDefault();
    const eventDelta = -event.deltaY * (event.deltaMode ? 120:1) / 500;
    zoomZoom(event.pageX, event.pageY, eventDelta);

  };

  const drag = (dX, dY) => {
    dragZoom(dX, dY);
  };

  const dragStart = () => {
    setPlanDragStart(new Date());
  };

  return (
    <Drag action={drag} dragStart={dragStart}>
      <g onWheel={(e) => zoom(e)}>
        {children}
      </g>
    </Drag>
  );
};

Zoom.propTypes = {
  children: PropTypes.node.isRequired,
  actions: PropTypes.object.isRequired,
  activeBuildingId: PropTypes.number.isRequired,
  planActions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {activeBuildingId} = state.buildingReducer;
  return {activeBuildingId};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    planActions: bindActionCreators(planActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Zoom);

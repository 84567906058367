import React from 'react';
import PropTypes from 'prop-types';
import LampDepiction from '../containers/LampDepiction';

const SlaveLamp = ({lamp, lampType, extraRotation, lineStartX}) => {
  const {x, y} = lamp;
  return (
    <g className="sub-lamp">
      <line className="sub-line" x1={lineStartX} y1="0" x2={x} y2={y} />
      <LampDepiction lamp={lamp} lampType={lampType} extraRotation={extraRotation} />
    </g>
  );
};

SlaveLamp.propTypes = {
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  extraRotation: PropTypes.number.isRequired,
  lineStartX: PropTypes.number.isRequired,
};

export default SlaveLamp;

import {GENERATE_ENERGY_MONITORING_STATS, SET_LIVE_MODE} from '../constants/liveActionTypes';
import {generateEnergyMonitoringStats} from '../actions/liveActions';
import {LIVE_MODE_ENERGY_MONITORING} from '../constants/livePropTypes';
import {PROFILE_ON, PROFILE_STANDBY} from '../constants/profileTypes';
import {setLampsProps} from '../actions/lampActions';
import {calculatePower} from '../utils/power';

const liveMiddleware = store => next => action => {
  const {dispatch} = store;
  if(action.type === SET_LIVE_MODE && action.mode === LIVE_MODE_ENERGY_MONITORING) {
    dispatch(generateEnergyMonitoringStats());
    return next(action);
  } else if (action.type === GENERATE_ENERGY_MONITORING_STATS) {
    const {groupReducer, lampReducer, lampTypeReducer, scenarioReducer, buildingReducer, profileReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {activeScenarioId} = buildingReducer[activeBuildingId];
    const {groupIds} = scenarioReducer[activeScenarioId];
    let totalPower = 0;
    let totalMaxPower = 0;

    const lamps = [];
    for (const groupId of groupIds) {
      const {profileState, lampIds, profileId} = groupReducer[groupId];
      const profile = profileReducer[profileId];
      let lampPercent = 0;
      if (profileState === PROFILE_STANDBY) {
        lampPercent = profile.percent_standby;
      }else if(profileState === PROFILE_ON) {
        lampPercent = profile.percent_on;
      }

      const masterLampObject = {};
      for (const lampId of lampIds) {
        const {parentId} = lampReducer[lampId];
        if (parentId === undefined) {
          masterLampObject[lampId] = true;
        }else {
          masterLampObject[parentId] = true;
        }
      }

      const masterLampIds = Object.keys(masterLampObject);

      for (const lampId of masterLampIds) {
        const lamp = lampReducer[lampId];
        const {typeId, powerFormula} = lamp;
        if (powerFormula === undefined) {
          continue;
        }

        if (lamp.lampIds && lamp.lampIds.length > 0) {
          const calculatedPower = calculatePower(powerFormula.type, powerFormula.max, powerFormula.limit, lampPercent);
          const maxCalculatedPower = calculatePower(powerFormula.type, powerFormula.max, powerFormula.limit, 100);
          for (const subLampId of lamp.lampIds) {
            const {typeId} = lampReducer[subLampId];
            const {maxTheoreticalPower, basePower} = lampTypeReducer[typeId];
            let power = basePower;
            let maxPower = basePower;

            power += maxTheoreticalPower * calculatedPower;

            maxPower += maxTheoreticalPower * maxCalculatedPower;

            totalPower += power;
            totalMaxPower += maxPower;

            lamps.push({
              id: subLampId,
              power: Math.round(power * 10) / 10,
            });
          }

          const {basePower, maxTheoreticalPower} = lampTypeReducer[typeId];
          const power = basePower + (maxTheoreticalPower * calculatedPower);
          totalPower += basePower;
          totalMaxPower += basePower;
          lamps.push({
            id: lampId,
            power,
          });

        }else {
          const {maxTheoreticalPower, basePower} = lampTypeReducer[typeId];
          let power = basePower;
          let maxPower = basePower;

          power += maxTheoreticalPower * calculatePower(powerFormula.type, powerFormula.max, powerFormula.limit, lampPercent);

          maxPower += maxTheoreticalPower * calculatePower(powerFormula.type, powerFormula.max, powerFormula.limit, 100);

          totalPower += power;
          totalMaxPower += maxPower;

          lamps.push({
            id: lampId,
            power: Math.round(power * 10) / 10,
          });
        }

      }
    }
    dispatch(setLampsProps(lamps));
    action.maxPower = totalMaxPower;
    action.totalPower = totalPower;
    return next(action);
  }else {
    return next(action);
  }
};

export default liveMiddleware;

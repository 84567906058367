import objectAssign from 'object-assign';
import {SET_PLAN_IMG, SET_PLAN_IMG_PROPS, UPLOAD_PLAN_IMG,
SET_IMG_DRAG, SET_PLAN_IMG_DEFAULT_VIEW} from '../constants/planImgActionTypes';
import {GET_BUILDINGS} from '../constants/buildingActionTypes';
// import {STARTED} from '../constants/customTypes';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {
  imgDrag: false,
  planImgDefaultsSet: false,
};

export default function planImgReducer(state = initialState, action) {

  switch (action.type) {
    case GET_BUILDINGS.SUCCESS:{
      const newState = objectAssign({}, state);
      action.response.forEach(({planImg, id}) => {
        newState[id] = objectAssign({}, state[id], planImg);
      });
      return newState;
    }
    // case GET_PLAN_IMG.START:{
    //   let newState = objectAssign({}, state);
    //   const {id} = action.props;
    //   newState[id] = objectAssign({}, state[id], {status: STARTED});
    //   return newState;
    // }
    case SET_PLAN_IMG:{
      let newState = objectAssign({}, state);
      const {planImg, id} = action;
      newState[id] = objectAssign({}, state[id], planImg);
      return newState;
    }
    case SET_PLAN_IMG_PROPS:{
      const {id, obj} = action;
      let newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[id], obj);
      return newState;
    }
    case UPLOAD_PLAN_IMG.SUCCESS:{
      const {response, props} = action;
      const {buildingId} = props;
      let newState = objectAssign({}, state);
      newState[buildingId] = objectAssign({}, state[buildingId], response);
      return newState;
    }
    case SET_IMG_DRAG:{
      const {imgDrag} = action;
      return objectAssign({}, state, {imgDrag});
    }
    case SET_PLAN_IMG_DEFAULT_VIEW:{
      const {id, view} = action;
      const {zoomX, zoomY, zoomK, rotation} = view;
      const newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[id], {
        defaultK: zoomK,
        defaultX: zoomX,
        defaultY: zoomY,
        defaultRotation: rotation,
      });
      newState.planImgDefaultsSet = true;
      return newState;
    }

    default:
      return state;
  }
}

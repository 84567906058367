import {DELETE_PROFILE, SET_DELETE_PROFILE_SHOW, SAVE_PROFILE,
SET_PROFILE_FILTER, CREATE_PROFILE, CANCEL_EDIT_PROFILE, SET_PROFILE_VALIDATION_ERRORS,
SET_PROFILE_EDITING, SET_PROFILE_PROPS} from '../constants/profileActionTypes';

export function deleteProfile(id) {
  return {
    type: DELETE_PROFILE,
    id,
  };
}

export function setDeleteProfileShow(show) {
  return {
    type: SET_DELETE_PROFILE_SHOW,
    show,
  };
}

export function setProfileEditing(id) {
  return {
    type: SET_PROFILE_EDITING,
    id,
  };
}

export function setProfileProps(id, obj) {
  return {
    type: SET_PROFILE_PROPS,
    id,
    obj,
  };
}

export function saveProfile(id) {
  return {
    type: SAVE_PROFILE,
    id,
  };
}

export function setProfileFilter(profileFilter) {
  return {
    type: SET_PROFILE_FILTER,
    profileFilter,
  };
}

export function createProfile() {
  return {
    type: CREATE_PROFILE,
  };
}

export function cancelEditProfile(id) {
  return {
    type: CANCEL_EDIT_PROFILE,
    id,
  };
}

export function setProfileValidationErrors(errObj) {
  return {
    type: SET_PROFILE_VALIDATION_ERRORS,
    errObj,
  };
}

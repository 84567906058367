// import objectAssign from 'object-assign';
import { TRANSFORM_ALL_OBJECTS, GET_NODES,  } from '../constants/nodeActionTypes';
import {setObjects, setVariables, setVariablesValueApi, setNodesProps} from '../actions/nodeActions';
import {setFilterNodeTypeVersions, recalculateFilters} from '../actions/filterActions';
import {setSelectedCopyVar} from '../actions/selectedVariablesActions';
import {CHANGE_PROP_NODE_ALL_APS} from '../constants/apActionTypes';
import {CHANGE_SELECT_ALL_PPS} from '../constants/ppActionTypes';
import {setMultiPpProps} from '../actions/ppActions';
// import {INITIAL} from '../constants/customTypes';
import {NODE_ADDED_EVENT, POLL_SUC_EVENT} from '../constants/siteActionTypes';
import {COPY_SELECTED_VARS} from '../constants/selectedVariablesActionTypes';
import {getUniqueNodeVersions} from '../utils/versionHelper';
import {setUniqueObjectsAndVars} from '../actions/uniqueObjectsActions';

const nodeMiddleware = store => next => action => {
  const {dispatch} = store;
  const {type} = action;

  if (action.midd && action.midd.dataportSlug) {
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {dataportSlug} = buildingReducer[activeBuildingId];
    action.dataportSlug = dataportSlug;
  }

  if (type === POLL_SUC_EVENT) {
    const {objects, id} = action.data.node;
    dispatch({
      type: TRANSFORM_ALL_OBJECTS,
      objects: [{id, objects}],
      id,
    });
    action.data.node.objects = objects.map(object => object.name);
    return next(action);
  }else if(type === TRANSFORM_ALL_OBJECTS) {
    const variables = {};
    const objects = {};
    const uObjects = {};
    action.objects.forEach((obj) => {
      const {id} = obj;
      obj.objects.forEach((object) => {
        const variableNames = object.variables.map((variable) => {
          const {value, name, writeable} = variable;
          const key = `${id}/${object.name}/${name}`;
          variables[key] = {value: parseInt(value, 16), writeable};
          uObjects[`${object.name}/${name}`] = true;
          return name;
        });
        objects[`${id}/${object.name}`] = {
          name: object.name,
          variables: variableNames,
        };
      });
    });

    dispatch(setObjects(objects));
    dispatch(setVariables(variables));
    dispatch(setUniqueObjectsAndVars(uObjects));

  }else if(type === CHANGE_PROP_NODE_ALL_APS) {
    const {filterReducer} = store.getState();
    const ids = filterReducer.filteredApIds;
    dispatch(setNodesProps(ids, action.obj));
    // return next(action);
  }else if(type === CHANGE_SELECT_ALL_PPS) {
    const {filterReducer} = store.getState();
    let ids = [];
    filterReducer.filteredApIds.forEach(apId => {
      const clientIds = filterReducer[apId];
      ids = ids.concat(clientIds);
    });
    dispatch(setMultiPpProps(ids, {selected: action.selected}));
    // return next(action);

  }else if(type === GET_NODES.SUCCESS) {
    const objects = action.response.map((node) => {
      return {
        id: node.id,
        objects: node.objects,
      };
    });

    dispatch({
      type: TRANSFORM_ALL_OBJECTS,
      objects,
    });

    action.response.forEach((node) => {
      if (node.node_type === '') {
        node.node_type = 'Unknown';
      }
      node.objects = node.objects.map(object => object.name);
    });

    next(action);
    const {nodeTypes, nodeTypeObj, versionObj} = getUniqueNodeVersions(action.response);
    dispatch(setFilterNodeTypeVersions(nodeTypes, nodeTypeObj, versionObj));
    dispatch(recalculateFilters());

  }else if(type === NODE_ADDED_EVENT) {
    const {objects, id} = action.data.node;
    dispatch({
      type: TRANSFORM_ALL_OBJECTS,
      objects: [{id, objects}],
      id,
    });
    action.data.node.objects = objects.map(object => object.name);
    return next(action);

  }else if(type === COPY_SELECTED_VARS) {
    const {originPath, destPath} = action;
    const {planReducer, variableReducer} = store.getState();
    const {showLampSelectionDialog, selectedLampMacs, selectedPsMacs} = planReducer;
    let macs = selectedPsMacs;
    if (showLampSelectionDialog === true) {
      macs = selectedLampMacs;
    }
    macs.forEach((mac) => {
      const {value} = variableReducer[`${mac}/${originPath}`];
      const pathSplits = destPath.split('/');
      dispatch(setVariablesValueApi([mac], pathSplits[0], pathSplits[1], value));
    });
    dispatch(setSelectedCopyVar(originPath, false));
  }else {
    return next(action);
  }
};

export default nodeMiddleware;

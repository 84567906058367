import React from 'react';
import PropTypes from 'prop-types';

const FormSelect = ({id, label, onChange, value, extraClass, options, ...rest}) => {
  return (
    <div className="form-group">
      <label htmlFor={id}>{label}:</label>
      <select className="form-control" value={value} id={id}
        onChange={(event) => onChange(event.target.value)}
      >
        {options.map(({value, label}, i) => (
          <option key={i} value={value}>{label}</option>
        ))}
      </select>
    </div>
  );
};

FormSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  extraClass: PropTypes.string.isRequired,
};

FormSelect.defaultProps = {
  extraClass: '',
};


export default FormSelect;

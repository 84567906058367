import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import LampZoneDepiction from '../containers/LampZoneDepiction';

const LampZoneWrapper = (props) => {
  return <LampZoneDepiction {...props} />;
};

LampZoneWrapper.propTypes = {
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
};

function mapStateToProps(state, {id}) {
  const {lampReducer, lampTypeReducer} = state;
  const lamp = lampReducer[id];
  const {typeId} = lamp;
  const lampType = lampTypeReducer[typeId];
  return {
    lamp,
    lampType,
  };
}

export default connect(
  mapStateToProps,
  {},
)(LampZoneWrapper);

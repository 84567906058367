import React from 'react';
import PropTypes from 'prop-types';
import {STANDARD_FLOW} from '../constants/profileTypes';
import {connect} from 'react-redux';
import '../styles/profile-graph.scss';
import { getTranslate } from 'react-localize-redux';

function secondsToHms(d) {
  d = Number(d);

  const h = Math.floor(d / 3600);
  const m = Math.floor(d % 3600 / 60);
  const s = Math.floor(d % 3600 % 60);

  if (h > 0) {
    let str = h;
    if (m > 0 && s === 0) {
      str += ":" + ('0' + m).slice(-2);
    }

    if (s > 0) {
      str += ":" + ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);
    }
    return str + ' h';
  }

  if (m > 0) {
    let str = m;

    if (s > 0) {
      str += ":" + ('0' + s).slice(-2);
    }
    return str + ' m';
  }

  return s + ' s';
}

const ProfileGraph = ({profile, browserWidth, translate}) => {
  let onLen = profile.timeout_on / profile.timeout_standby * 100;
  let standbyLen = 100 - onLen;
  const aspect = 1.95 * (1 / (1919 / browserWidth));
  const bottomPadding = 10;
  const leftPadding = 40 * aspect;
  const rightPadding = 20 * aspect;
  const graphPadding = leftPadding / 2 + (aspect * 5);
  const percentOn = profile.percent_on;
  const percentStandby = profile.percent_standby;

  if (onLen > 70) {
    onLen = 70;
    standbyLen = 30;
  }else if (onLen < 30) {
    onLen = 30;
    standbyLen = 70;
  }
  onLen = aspect * onLen + leftPadding;
  standbyLen = aspect * standbyLen + onLen;

  const onY = -percentOn - bottomPadding;
  const standbyY = -percentStandby - bottomPadding;
  const totalLen = leftPadding + (aspect * 100) + rightPadding;
  const totalY = 128;
  let standbyTimeLineEl = null;
  let standbyTimeTextEl = null;
  let extraFillEl = null;

  let path;
  if (profile.flow === STANDARD_FLOW) {
    path = `M${leftPadding} ${-bottomPadding} V ${onY} H ${onLen} L ${aspect * 5 + onLen} ${standbyY} H ${standbyLen} L ${totalLen - (rightPadding / 2)} ${-bottomPadding}`;
    standbyTimeTextEl = (
      <text className="time-text" x={standbyLen + aspect} y={0}>{secondsToHms(profile.timeout_standby)}</text>
    );

    standbyTimeLineEl = (
      <line className="time-line" x1={standbyLen} y1={0} x2={standbyLen} y2={standbyY} stroke="black" strokeWidth="0.5"
      strokeDasharray="4, 4"/>
    );
  }else {
    path = `M ${graphPadding} ${standbyY} H ${leftPadding} V ${onY} H ${onLen} L ${aspect * 5 + onLen} ${standbyY} H ${totalLen}`;
    const extraFillPath = `M ${graphPadding} ${-bottomPadding} V ${standbyY} H ${totalLen} V ${-bottomPadding}`;
    extraFillEl = (
      <path className="graph-path-fill" d={extraFillPath} />
    );
  }

  return (
    <span className="profile-graph">
      <svg viewBox={`0 ${-totalY} ${totalLen} ${totalY}`} preserveAspectRatio="xMidYMid">
        <path className="graph-path-fill" d={path} />
        {extraFillEl}

        <line x1={graphPadding} y1={-bottomPadding} x2={totalLen - (aspect * 2)} y2={-bottomPadding} stroke="black" strokeWidth="0.5" />
        <line x1={graphPadding} y1={-bottomPadding} x2={graphPadding} y2={-totalY + 3} stroke="black" strokeWidth="0.5" />

        <text className="fa-svg-text" x={graphPadding + (aspect * 2)} y={-totalY + (aspect * 8)}>&#xf0eb;</text>
        <text className="fa-svg-text" x={totalLen - (aspect * 7)} y={-bottomPadding - (aspect * 1)}>&#xf017;</text>

        <text className="legend-text" x={graphPadding - (aspect * 1)} y={onY} textAnchor="end">{translate('global_live_on')}</text>
        <text className="legend-text" x={graphPadding - (aspect * 1)} y={standbyY} textAnchor="end">{translate('global_live_standby_abbr')}</text>

        <line className="percent-line" x1={graphPadding} y1={standbyY} x2={totalLen - (aspect * 2)} y2={standbyY} stroke="black" strokeWidth="0.5"
        strokeDasharray="4, 4"/>
        <line className="percent-line" x1={graphPadding} y1={onY} x2={totalLen - (aspect * 2)} y2={onY} stroke="black" strokeWidth="0.5"
        strokeDasharray="4, 4"/>

        <line className="time-line" x1={onLen} y1={0} x2={onLen} y2={onY} stroke="black" strokeWidth="0.5"
        strokeDasharray="4, 4"/>
        {standbyTimeLineEl}

        <path className="graph-path" d={path} />
        <text className="fa-svg-text movement-icon" x={leftPadding + (aspect * 1)} y={-bottomPadding - (aspect * 1)}>&#xf1ae;</text>

        <text className="percent-text" x={leftPadding + ((onLen - leftPadding) / 2)} y={onY - (aspect * 3)} textAnchor="middle">{percentOn}%</text>
        <text className="percent-text" x={onLen + ((standbyLen - onLen) / 2)} y={standbyY - (aspect * 3)} textAnchor="start">{percentStandby}%</text>

        <text className="time-text" x={onLen + aspect} y={0}>{secondsToHms(profile.timeout_on)}</text>
        {standbyTimeTextEl}
      </svg>
    </span>
  );
};
ProfileGraph.propTypes = {
  profile: PropTypes.object.isRequired,
  browserWidth: PropTypes.number.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const translate = getTranslate(state.localeReducer);
  return {
    translate
  };
}


export default connect(
  mapStateToProps,
  null,
)(ProfileGraph);

import objectAssign from 'object-assign';
import {CREATE_SCENARIO, DELETE_SCENARIO, CREATE_SCENARIO_API, UPDATE_SCENARIO,
  SET_SELECTED_SCENARIO, SET_LAMP_GROUP_MAP_BY_SCENARIO,
  ACTIVATE_SCENARIO, SAVE_SCENARIO} from '../constants/scenarioActionTypes';
import {SET_PROFILES} from '../constants/profileActionTypes';
import {SET_GROUPS} from '../constants/groupActionTypes';
import {setBuildingProps} from '../actions/buildingActions';
import {setLampGroupMap} from '../actions/groupActions';
import {setProfileFilter} from '../actions/profileActions';
import {setSelectedScenario, saveScenarioApi, createScenarioApi, setLampGroupMapByScenario} from '../actions/scenarioActions';
import {revertUid, makeUid} from '../utils/idHelper';

function sanitizeScenario(scenario) {
  delete scenario.profileIds;
  delete scenario.groupIds;
  return scenario;
}

export default store => next => action => {
  const {dispatch} = store;
  if (action.type === CREATE_SCENARIO) {
    const {buildingReducer, scenarioReducer, profileReducer, groupReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lastScenarioId, scenarioIds, selectedScenarioId, scenarioOptions} = buildingReducer[activeBuildingId];
    const selectedScenario = scenarioReducer[selectedScenarioId];
    const newScenarioId = lastScenarioId + 1;
    const scenario = objectAssign({}, action.scenario, {id: newScenarioId});
    sanitizeScenario(scenario);

    let lastProfileId = 0;
    const oldToNewProfileId = {};
    const profiles = selectedScenario.profileIds.map(profileId => {
      const newProfileId = ++lastProfileId;
      oldToNewProfileId[profileId] = newProfileId;
      return objectAssign({}, profileReducer[profileId], {id: newProfileId});
    });

    let lastGroupId = 0;
    const groups = selectedScenario.groupIds.map(groupId => {
      const group = groupReducer[groupId];
      const lampIds = group.lampIds.map(revertUid);
      const newGroupId = ++lastGroupId;
      return objectAssign({}, group, {
        id: newGroupId,
        profileId: oldToNewProfileId[group.profileId],
        lampIds,
      });
    });

    const customScenarioProps = {
      defaultProfileId: oldToNewProfileId[selectedScenario.defaultProfileId],
      lastProfileId,
      lastGroupId,
    };

    const scenarioUid = makeUid(activeBuildingId, scenario.id);
    const buildingProps = {
      lastScenarioId: lastScenarioId + 1,
      scenarioIds: scenarioIds.concat(scenarioUid),
      scenarioOptions: scenarioOptions.concat([{value: scenarioUid, label: scenario.name}]),
      scenarioEditingId: undefined,
      selectedScenarioId: scenarioUid,
    };

    dispatch(createScenarioApi(activeBuildingId, scenario, groups, profiles, customScenarioProps, buildingProps));

  }else if(action.type === CREATE_SCENARIO_API.SUCCESS) {
    const {groups, profiles, buildingId, customScenarioProps, buildingProps} = action.props;
    const {scenario} = action.response;
    const scenarioUid = makeUid(buildingId, scenario.id);
    customScenarioProps.defaultProfileId = makeUid(scenarioUid, customScenarioProps.defaultProfileId);
    customScenarioProps.needsSave = false;
    const newScenario = objectAssign({}, scenario, {id: scenarioUid}, customScenarioProps);
    const groupObj = {};
    newScenario.groupIds = groups.map(group => {
      group.id = makeUid(newScenario.id, group.id);
      group.profileId = makeUid(newScenario.id, group.profileId);
      group.lampIds = group.lampIds.map(id => makeUid(buildingId, id));
      groupObj[group.id] = group;
      return group.id;
    });

    const profileObj = {};
    newScenario.profileIds = profiles.map(profile => {
      profile.id = makeUid(newScenario.id, profile.id);
      profileObj[profile.id] = profile;
      return profile.id;
    });

    dispatch({
      type: SET_PROFILES,
      obj: profileObj,
    });

    dispatch({
      type: SET_GROUPS,
      obj: groupObj,
    });

    action.scenario = newScenario;
    next(action);

    dispatch(setBuildingProps(buildingId, buildingProps));
    dispatch(setSelectedScenario(newScenario.id));

  }else if(action.type === UPDATE_SCENARIO) {
    const {buildingReducer, scenarioReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {scenario} = action;

    const buildingChanges = {
      scenarioEditingId: undefined,
    };

    if (scenario.name !== scenarioReducer[scenario.id].name) {
      const scenarioOptions = buildingReducer[activeBuildingId].scenarioOptions.map(option => ({...option}));
      const index = scenarioOptions.map(option => option.value).indexOf(scenario.id);
      scenarioOptions[index].label = scenario.name;
      buildingChanges.scenarioOptions = scenarioOptions;
    }

    next(action);
    dispatch(setBuildingProps(activeBuildingId, buildingChanges));
  }else if(action.type === ACTIVATE_SCENARIO.SUCCESS) {
    const {buildingId, id} = action.props;
    dispatch(setBuildingProps(buildingId, {activeScenarioId: id}));
  }else if(action.type === SAVE_SCENARIO) {
    const {scenarioReducer, groupReducer, profileReducer} = store.getState();
    const {id} = action;
    const scenario = scenarioReducer[id];
    const [buildingId] = id.split('_');

    const groups = scenario.groupIds.map(groupId => {
      const group = groupReducer[groupId];
      const id = revertUid(group.id);
      const profileId = revertUid(group.profileId);
      const lampIds = group.lampIds.map(revertUid);
      return objectAssign({}, group, {id, profileId, lampIds});
    });

    const profiles = scenario.profileIds.map(profileId => {
      const profile = profileReducer[profileId];
      const id = revertUid(profile.id);
      return objectAssign({}, profile, {id});
    });

    const scenarioCp = objectAssign({}, scenario, {id: revertUid(scenario.id)});
    sanitizeScenario(scenarioCp);

    dispatch(saveScenarioApi(buildingId, scenarioCp, groups, profiles));

  }else if(action.type === DELETE_SCENARIO.SUCCESS) {
    const {id} = action.props;
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {scenarioIds, scenarioOptions} = buildingReducer[activeBuildingId];
    const filteredScenarioIds = scenarioIds.filter(scenarioId => scenarioId !== id);
    dispatch(setBuildingProps(activeBuildingId, {
      scenarioIds: filteredScenarioIds,
      scenarioOptions: scenarioOptions.filter(({value}) => value !== id),
    }));
    dispatch(setSelectedScenario(filteredScenarioIds[0]));
    return next(action);

  }else if(action.type === SET_SELECTED_SCENARIO) {
    const {id} = action;
    const {buildingReducer, profileReducer} = store.getState();
    const {profileFilter} = profileReducer;
    const {activeBuildingId} = buildingReducer;

    dispatch(setLampGroupMapByScenario(id));
    dispatch(setBuildingProps(activeBuildingId, {
      selectedScenarioId: id,
    }));

    next(action);

    dispatch(setProfileFilter(profileFilter));
  }else if(action.type === SET_LAMP_GROUP_MAP_BY_SCENARIO) {
    const {id} = action;
    const {lampReducer, scenarioReducer, groupReducer} = store.getState();

    const scenario = scenarioReducer[id];
    const lampGroupMap = {};
    scenario.groupIds.forEach(groupId => {
      const group = groupReducer[groupId];
      group.lampIds.forEach(lampId => {
        const {parentId} = lampReducer[lampId];
        if (parentId !== undefined) {
          lampGroupMap[parentId] = groupId;
        }else {
          lampGroupMap[lampId] = groupId;
        }
      });
    });

    dispatch(setLampGroupMap(lampGroupMap));
  }else {
    return next(action);
  }
};

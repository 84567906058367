import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ProfileEdit from './ProfileEdit';
import ScenarioContainer from './ScenarioContainer';
import ProfileControl from './ProfileControl';
import ProfileList from './ProfileList';
import ScenarioEdit from './ScenarioEdit';

const PlanRightContainer = ({profileEditingId, scenarioEditingId, activeBuildingId}) => {
  if (profileEditingId !== undefined) {
    return (
      <div>
        <ProfileEdit />
      </div>
    );
  }else if(scenarioEditingId !== undefined) {
    return (
      <div>
        <ScenarioEdit scenarioEditingId={scenarioEditingId} activeBuildingId={activeBuildingId} />
      </div>
    );
  }
  return (
    <div>
      <ScenarioContainer />
      <ProfileControl />
      <ProfileList />
    </div>
  );
};

PlanRightContainer.propTypes = {
  profileEditingId: PropTypes.string,
  scenarioEditingId: PropTypes.string,
  activeBuildingId: PropTypes.number.isRequired,
};

function mapStateToProps({buildingReducer, profileReducer}) {
  const {activeBuildingId} = buildingReducer;
  const building = buildingReducer[activeBuildingId];
  const {scenarioEditingId} = building;
  const {profileEditingId} = profileReducer;
  return {
    profileEditingId,
    scenarioEditingId,
    activeBuildingId,
  };
}

// function mapDispatchToProps(dispatch) {
//   return {
//     actions: bindActionCreators(actions, dispatch),
//     buildingActions: bindActionCreators(buildingActions, dispatch),
//   };
// }

export default connect(
  mapStateToProps,
  null
)(PlanRightContainer);

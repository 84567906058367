import React from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, usePagination } from 'react-table';
import ReactTableRow from './ReactTableRow';
import ReactTableHeader from './ReactTableHeader';
import ReactTablePagination from './ReactTablePagination';
import '../styles/react-table.scss';

const ReactTable = ({ columns, data, idProp }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex},
  } = useTable(
    {
      columns,
      data,
      isMultiSortEvent: () => true,
    },
    useSortBy,
    usePagination,
  );

  const renderRows = () => {
    return page.map((row) => {
      prepareRow(row);
      return <ReactTableRow key={row.values[idProp]} row={row} idProp={idProp} />;
    });
  };

  return (
    <>
      <table {...getTableProps()} className="table react-table">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) => (
                <ReactTableHeader key={j} column={column} isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {renderRows()}
        </tbody>
      </table>
      <ReactTablePagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        pageIndex={pageIndex}
      />
    </>
  );
};

ReactTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  idProp: PropTypes.string.isRequired,
};

ReactTable.defaultProps = {
  idProp: 'id',
};

export default ReactTable;

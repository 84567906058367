import objectAssign from 'object-assign';

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDeltaXDeltaYNormalized(deltaX, deltaY, xMap, yMap, zoomK, rotation) {
  const calcRotation = rotation % 360;
  let newX, newY;
  switch(calcRotation ) {
    case 0:{
      newX = deltaX;
      newY = deltaY;
      break;
    }
    case 90:{
      newX = deltaY;
      newY = -deltaX;
      break;
    }
    case 180:{
      newX = -deltaX;
      newY = -deltaY;
      break;
    }
    case 270:{
      newX = -deltaY;
      newY = deltaX;
      break;
    }
  }
  const inverseZoomK = 1 / zoomK;
  return {
    nDeltaX: newX * xMap * inverseZoomK,
    nDeltaY: newY * yMap * inverseZoomK,
  };
}

export function parseIfInt(val) {
  let returnVal = val;
  let parsed = parseInt(val);
  if (!isNaN(parsed)) {
    returnVal = parsed;
  }
  return returnVal;
}

export function removeDuplicatesArray(array) {
  const obj = {};
  const dedupArray = [];
  for (const el of array) {
    if (obj[el] === undefined) {
      dedupArray.push(el);
      obj[el] = true;
    }
  }
  return dedupArray;
}

export function addPrefixToObjectKeys(prefix, originalObj) {
  const obj = objectAssign({}, originalObj);
  const keys = Object.keys(obj);
  for (const key of keys) {
    obj[`${prefix}${key}`] = obj[key];
    delete obj[key];
  }
  return obj;
}

export function slugify(toSlugStr) {
  const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp(a.split('').join('|'), 'g');
  return toSlugStr.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with ‘and’
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple — with single -
    .replace(/^-+/, ''); // Trim — from start of text .replace(/-+$/, '') // Trim — from end of text
}

export function deleteExtraBuildingProps(building) {
  const legalProps = [
    'created',
    'updated',
    'height',
    'width',
    'slug',
    'name',
    'id',
  ];

  Object.keys(building).filter(key => legalProps.indexOf(key) === -1).forEach(key => delete building[key]);
}

export function removeItemsFromArray(array, toRemoveItems) {
  const newArr = [];
  for (const item of array) {
    if (toRemoveItems.indexOf(item) === -1) {
      newArr.push(item);
    }
  }
  return newArr;
}

export function removeSingleItemFromArray(array, item) {
  const arrCp = array.concat([]);
  const index = arrCp.indexOf(item);
  if (index > -1) {
    arrCp.splice(index, 1);
  }
  return arrCp;
}

export function arrsIntersect(smallArr, largeArr) {
  for (let i = 0; i < smallArr.length; i++) {
    const el = smallArr[i];
    if (largeArr.indexOf(el) > -1) {
      return true;
    }
  }
  return false;
}

export function isString(toTestVal) {
  return Object.prototype.toString.call(toTestVal) === "[object String]";
}

export function isObject(toTestVal) {
  if (toTestVal === null) { return false;}
  return typeof toTestVal === 'object';
}

export function roundFloat(number, precision) {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}

export function floatToCommaString(float) {
  return float.toString().replace('.', ',');
}

export function isRoute(route, urlPart) {
  return route.indexOf(urlPart) > -1;
}

export function setDeep(obj, propName, value) {
  if (isObject(propName)) {
    let lastObj = obj;
    propName.slice(0, -1).forEach(key => {
      if (lastObj[key] === undefined) {
        lastObj[key] = {};
      }
      lastObj = lastObj[key];
    });
    lastObj[propName.slice(-1)] = value;

  }else {
    obj[propName] = value;
  }
}

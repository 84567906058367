import React from 'react';
import PropTypes from 'prop-types';
import PlanPageWrapper from './PlanPageWrapper';
import Plan from './Plan';
import EnergyBar from './EnergyBar';

const PlanEnergyPage = ({planHeight}) => {
  return (
    <>
      <div className="plan-left">
        <Plan className="border-right" showLegend
          interactive={false}
        />
      </div>
      <div className="plan-right-wrapper">
        <div className="plan-right" style={{height: planHeight}}>
          <EnergyBar />
        </div>
      </div>
    </>
  );
};

PlanEnergyPage.propTypes = {
  planHeight: PropTypes.number.isRequired,
};

export default PlanPageWrapper(PlanEnergyPage, 'page_title_plan_energy');

import {SET_BROWSER_DIMENSIONS} from '../constants/generalActionTypes';

export function setBrowserDimensions(browserWidth, browserHeight) {
  return {
    type: SET_BROWSER_DIMENSIONS,
    browserWidth,
    browserHeight,
  };
}


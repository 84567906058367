import React from 'react';
import PropTypes from 'prop-types';

const SvgGrid = ({width, height}) => {
  const size = 2000;
  const stepsY = height / size;
  const stepsX = width / size;
  let linesX = [];
  let linesY = [];
  for (let i = 0; i < stepsX; i++) {
    const step = i * size;
    linesX.push(
      <line key={i} stroke="black" strokeWidth="10" x1={step} y1="0" x2={step} y2={height} />
    );
  }

  for (let i = 0; i < stepsY; i++) {
    const step = i * size;
    linesY.push(
      <line key={i} stroke="black" strokeWidth="10" x1="0" y1={step} x2={width} y2={step} />
    );
  }

  return (
    <g className="grid">
      {linesX}
      {linesY}
    </g>
  );
};
SvgGrid.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
export default SvgGrid;

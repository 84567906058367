export function getColorNumber(colorNumbers) {
  colorNumbers.sort((a, b) => a - b);

  let index = 0;
  let newColorNumber;
  for (const colorNumber of colorNumbers) {
    if (colorNumber > index) {
      newColorNumber = index;
      break;
    }
    const nextColorNumberIndex = index + 1;
    if (nextColorNumberIndex >= colorNumbers.length) {
      newColorNumber = nextColorNumberIndex;
    }else if(colorNumbers[nextColorNumberIndex] !== nextColorNumberIndex) {
      newColorNumber = nextColorNumberIndex;
      break;
    }
    index++;
  }
  return newColorNumber;
}

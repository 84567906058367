import {ADD_ALERT, REMOVE_ALERT} from '../constants/alertActionTypes';
import objectAssign from 'object-assign';

const initialState = {
  alertIds: [],
};

export default function alertReducer(state = initialState, action) {
let newState;
  switch (action.type) {
    case ADD_ALERT:
      newState = objectAssign({}, state);
      newState.alertIds = [].concat(state.alertIds).concat([action.id]);
      newState[action.id] = {
        id: action.id,
        msg: action.msg,
      };
      return newState;
    case REMOVE_ALERT:
      newState = objectAssign({}, state);
      newState.alertIds = state.alertIds.filter((id) => {
        return id !== action.id;
      });
      delete newState[action.id];
      return newState;

    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import BaseLampBorderZone from '../containers/BaseLampBorderZone';
import {DEPICTION_RECT} from '../constants/lampTypeTypes';
import {arrsIntersect} from '../utils/helper';

const LampBorderZoneDepiction = ({lamp, lampType, lampIds, ...rest}) => {
  const {depictionType, length} = lampType;
  const {x, y, rotation, top, bottom, left, right, lefts, rights, bots, tops, id} = lamp;

  if (left === undefined) {
    return null;
  }

  const shows = {
    showRight: !arrsIntersect(rights, lampIds),
    showLeft: !arrsIntersect(lefts, lampIds),
    showTop: !arrsIntersect(tops, lampIds),
    showBot: !arrsIntersect(bots, lampIds),
  };
  // const shows = {
  //   showRight: true,
  //   showLeft: true,
  //   showTop: true,
  //   showBot: true,
  // };

  let leftC = left, rightC = right, topC = top, botC = bottom;
  if(depictionType === DEPICTION_RECT) {
    leftC -= length / 2;
    rightC += length / 2;
  }
  return (
    <BaseLampBorderZone x={x} y={y} rotation={rotation} padding={60}
      {...shows}
    left={leftC} right={rightC} top={topC} bottom={botC} {...rest} />
  );
};

LampBorderZoneDepiction.propTypes = {
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  lampIds: PropTypes.array.isRequired,
};

export default LampBorderZoneDepiction;

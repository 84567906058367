import {SET_PROFILES, DELETE_PROFILE, SET_DELETE_PROFILE_SHOW, SET_PROFILE_FILTER,
  CANCEL_EDIT_PROFILE, SET_PROFILE_VALIDATION_ERRORS, SAVE_PROFILE,
  SET_PROFILE_EDITING, SET_PROFILE_PROPS, CREATE_PROFILE} from '../constants/profileActionTypes';
import {PROFILE_FILTER_BUILDING, PROFILE_VALIDATION_ERRORS_OBJ} from '../constants/profileTypes';
import objectAssign from 'object-assign';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = objectAssign({
  showDeleteProfileDialog: false,
  profileEditingId: undefined,
  profileFilter: PROFILE_FILTER_BUILDING,
  filteredProfileIds: [],
}, PROFILE_VALIDATION_ERRORS_OBJ);



export default function profileReducer(state = initialState, action) {

  switch (action.type) {
    case SET_PROFILES:{
      let {obj} = action;
      return objectAssign({}, state, obj);
    }
    case DELETE_PROFILE:{
      let newState = objectAssign({}, state);
      delete newState[action.id];
      return newState;
    }
    case SET_DELETE_PROFILE_SHOW:{
      return objectAssign({}, state, {showDeleteProfileDialog: action.show});
    }
    case SET_PROFILE_EDITING:{
      const beforeEditProfile = objectAssign({}, state[action.id]);
      return objectAssign({}, state, {profileEditingId: action.id, beforeEditProfile});
    }
    case SET_PROFILE_PROPS:{
      const {id, obj} = action;
      let newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[id], obj);
      return newState;
    }
    case SET_PROFILE_FILTER:{
      const {profileFilter, filteredProfileIds} = action;
      return objectAssign({}, state, {profileFilter, filteredProfileIds});
    }
    case CREATE_PROFILE:{
      const {defaultProfileId, id, colorNumber} = action;
      let newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[defaultProfileId], {
        id,
        isNew: true,
        name: 'New profile',
        color_number: colorNumber,
        created: new Date().toISOString(),
      });
      newState.profileEditingId = id;
      return newState;
    }
    case CANCEL_EDIT_PROFILE:{
      let newState = objectAssign({}, state, PROFILE_VALIDATION_ERRORS_OBJ);
      const {profileEditingId} = state;
      const profile = state[profileEditingId];
      if (profile.isNew === true) {
        delete newState[profileEditingId];
      }else {
        newState[profileEditingId] = state.beforeEditProfile;
      }
      newState.profileEditingId = undefined;
      return newState;
    }
    case SET_PROFILE_VALIDATION_ERRORS:{
      const {errObj} = action;
      return objectAssign({}, state, errObj);
    }
    case SAVE_PROFILE:{
      const {id, profile} = action;
      const newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[id], profile, {
        updated: new Date().toISOString(),
      });
      delete newState[id].isNew;
      return newState;
    }


    default:
      return state;
  }
}

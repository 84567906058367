import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/buildingActions';

const TriviewNavLink = ({pathName, children, actions}) => {
  const {setBuildingRoute} = actions;
  return (
    <li className={/^\/plan/.test(pathName) ? 'active':''}>
      <a href="#" onClick={() => setBuildingRoute('/plan/config/')}>
        {children}
      </a>
    </li>
  );
};

TriviewNavLink.propTypes = {
  children: PropTypes.string.isRequired,
  pathName: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    pathName: state.router.location.pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TriviewNavLink);

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/authActions';
import { getTranslate } from 'react-localize-redux';
import ActionButton from '../components/ActionButton';

const ChangePasswordPage = ({actions, translate, changePasswordStatus, userId}) => {
  const {changePassword} = actions;
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [validationErrors, setValidationErrors] = useState({
    match: false,
    len: false,
  });

  let matchErrorEl = null;
  if (validationErrors.match) {
    matchErrorEl = (
      <p className="err-info">{translate('password_err_match')}.</p>
    );
  }

  let passwordLenErrorEl = null;
  if (validationErrors.len) {
    passwordLenErrorEl = (
      <p className="err-info">{translate('password_err_len')}.</p>
    );
  }

  const submit = (event) => {
    event.preventDefault();
    if(newPassword.length < 8) {
      setValidationErrors({...validationErrors, len: true});
    }else if (repeatPassword !== newPassword) {
      setValidationErrors({...validationErrors, match: true});
    }else {
      changePassword(userId, currentPassword, newPassword);
    }
  };

  return (
    <div className="change-password-page">
      <Helmet>
        <title>{translate('page_title_change_password')}</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{paddingTop: '10px'}}>
            <h2>{translate('password_change')}</h2>
            <form onSubmit={submit}>
              <p>
                <label htmlFor="current-password">{translate('password_current')}:</label>
                <input type="password" value={currentPassword} id="current-password" className="form-control"
                  onChange={(event) => setCurrentPassword(event.target.value)} />
              </p>
              <p>
                <label htmlFor="new-password">{translate('password_new')}:</label>
                <input type="password" value={newPassword} id="new-password" className="form-control"
                  onChange={(event) => setNewPassword(event.target.value)} />
              </p>
              {passwordLenErrorEl}
              <p>
                <label htmlFor="repeat-password">{translate('password_repeat')}:</label>
                <input type="password" value={repeatPassword} id="repeat-password" className="form-control"
                  onChange={(event) => setRepeatPassword(event.target.value)} />
              </p>
              {matchErrorEl}
              <ActionButton
                isSubmit
                extraClass="btn-primary" status={changePasswordStatus}
                text={translate('change_password_submit')} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

ChangePasswordPage.propTypes = {
  actions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  changePasswordStatus: PropTypes.string.isRequired,
  userId: PropTypes.number,
};

function mapStateToProps(state) {
  const {changePasswordStatus, id} = state.authReducer;
  const translate = getTranslate(state.localeReducer);
  return {
    changePasswordStatus,
    translate,
    userId: id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordPage);

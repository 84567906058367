import {GET_ENERGY_RETENTION_PERIODS} from '../constants/energyRetentionActionTypes';
import {TRIVIEW_API} from '../constants/urls';
import {API, GET} from '../constants/customTypes';

export function getEnergyRetentionPeriods(configId) {

  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      url: TRIVIEW_API.ENERGY.RETENTION.replace(':configId', configId),
      method: GET,
      success: GET_ENERGY_RETENTION_PERIODS.SUCCESS,
      error: GET_ENERGY_RETENTION_PERIODS.ERROR,
      start: GET_ENERGY_RETENTION_PERIODS.START,
    },
    props: {
      configId,
    },
  };
}

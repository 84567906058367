import {SAVE_GROUP, CREATE_GROUP, DELETE_GROUP,
CANCEL_EDIT_GROUP,GET_GROUPS_PROFILE_STATE} from '../constants/groupActionTypes';
import {deleteGroup, groupLampsUndo, setGroupEditing} from '../actions/groupActions';
import {GROUP_UPDATE_EVENT} from '../constants/siteActionTypes';
import {setProfileFilter} from '../actions/profileActions';
import {generateEnergyMonitoringStats} from '../actions/liveActions';
import {setScenarioProps} from '../actions/scenarioActions';

const groupMiddleware = store => next => action => {
  const {dispatch} = store;
  if (action.type === SAVE_GROUP) {
    const {groupReducer, profileReducer, buildingReducer, scenarioReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {selectedScenarioId} = buildingReducer[activeBuildingId];
    const group = groupReducer[groupReducer.editingId];
    const {groupIds} = scenarioReducer[selectedScenarioId];
    action.id = group.id;
    if (groupIds.indexOf(group.id) !== -1 && group.lampIds.length === 0) {
      dispatch(deleteGroup(group.id));
    }

    const {groupsChanged} = groupReducer;
    Object.keys(groupsChanged).filter((key) => groupsChanged[key] > 0).forEach((key) => {
      const group = groupReducer[key];
      if (group.lampIds.length === 0) {
        dispatch(deleteGroup(group.id));
      }
    });

    if (group.lampIds.length > 0) {
      // getState again after possibly dispatching delete group
      const {groupIds, lastGroupId} = store.getState().scenarioReducer[selectedScenarioId];
      next(action);
      if (groupIds.indexOf(action.id) === -1) {
        dispatch(setScenarioProps(selectedScenarioId, {
          groupIds: groupIds.concat([action.id]),
          lastGroupId: lastGroupId + 1,
        }));
      }
    }
    dispatch(setScenarioProps(selectedScenarioId, {
      needsSave: true,
    }));
    dispatch(setProfileFilter(profileReducer.profileFilter));
  }else if(action.type === CREATE_GROUP) {
    const {buildingReducer, scenarioReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {selectedScenarioId} = buildingReducer[activeBuildingId];

    const {defaultProfileId, lastGroupId} = scenarioReducer[selectedScenarioId];
    action.profileId = defaultProfileId;

    const {id} = action;
    if (id === undefined) {
      const newGroupId = lastGroupId + 1;
      action.id = `${selectedScenarioId}_${newGroupId}`;
    }

    next(action);

  }else if(action.type === CANCEL_EDIT_GROUP) {
    const {past} = store.getState().groupReducer;
    past.forEach(() => {
      dispatch(groupLampsUndo());
    });
    next(action);
    dispatch(setGroupEditing(undefined, false));
  }else if(action.type === DELETE_GROUP) {
    const {id} = action;
    const {profileReducer, scenarioReducer, buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {selectedScenarioId} = buildingReducer[activeBuildingId];
    const {groupIds} = scenarioReducer[selectedScenarioId];
    dispatch(setScenarioProps(selectedScenarioId, {
      groupIds: groupIds.filter(groupId => groupId !== id),
    }));
    next(action);
    dispatch(setProfileFilter(profileReducer.profileFilter));
  }else if(action.type === GET_GROUPS_PROFILE_STATE.SUCCESS) {
    const {buildingId} = action.props;
    const {buildingReducer} = store.getState();
    const {activeScenarioId} = buildingReducer[buildingId];
    action.activeScenarioId = activeScenarioId;
    next(action);
    dispatch(generateEnergyMonitoringStats());
  }else if(action.type === GROUP_UPDATE_EVENT) {
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {activeScenarioId} = buildingReducer[activeBuildingId];
    action.scenarioId = activeScenarioId;
    next(action);
    dispatch(generateEnergyMonitoringStats());
  }else {
    return next(action);
  }

};

export default groupMiddleware;

export const SET_PSES = 'SET_PSES';
export const SET_PS_PROPS = 'SET_PS_PROPS';
export const CLICK_PS = 'CLICK_PS';
export const SET_DRAG_PS = 'SET_DRAG_PS';
export const SET_PS_MAC_MAP = 'SET_PS_MAC_MAP';
export const SET_PS_MAC_ENTRY = 'SET_PS_MAC_ENTRY';
export const DELETE_PS_MAC_ENTRY = 'DELETE_PS_MAC_ENTRY';
export const CHANGE_SELECT_PSES = 'CHANGE_SELECT_PSES';
export const CHANGE_SELECT_ALL_PSES = 'CHANGE_SELECT_ALL_PSES';
export const CREATE_PS = 'CREATE_PS';
export const DRAG_PS = 'DRAG_PS';
export const LOCATE_PS = 'LOCATE_PS';
export const REMOVE_PS_LINK = 'REMOVE_PS_LINK';

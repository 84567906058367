import {apiAction} from './helper';

export const GROUP_UPDATE_EVENT = 'GROUP_UPDATE_EVENT';

export const SET_VAR_SUC_EVENT = 'SET_VAR_SUC_EVENT';
export const SET_VAR_FAIL_EVENT = 'SET_VAR_FAIL_EVENT';
export const PP_ADDED_EVENT = 'PP_ADDED_EVENT';
export const AP_ADDED_EVENT = 'AP_ADDED_EVENT';
export const NODE_ADDED_EVENT = 'NODE_ADDED_EVENT';
export const PP_DECOMMISSIONED_EVENT = 'PP_DECOMMISSIONED_EVENT';
export const PING_SUC_EVENT = 'PING_SUC_EVENT';
export const PING_FAIL_EVENT = 'PING_FAIL_EVENT';
export const POLL_SUC_EVENT = 'POLL_SUC_EVENT';
export const POLL_FAIL_EVENT = 'POLL_FAIL_EVENT';
export const OVERWRITE_FROM_CLOUD = apiAction('OVERWRITE_FROM_CLOUD');

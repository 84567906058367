import {CLICK_PS, DRAG_PS, LOCATE_PS, CHANGE_SELECT_ALL_PSES, REMOVE_PS_LINK} from '../constants/psActionTypes';
import {setPsProps, setPsMacEntry, deletePsMacEntry, changeSelectPses} from '../actions/psActions';
import {setPsDialogMac, pollNodes, setLinkingSuccess} from '../actions/nodeActions';
// import {nodeSortFunction} from '../utils/sortHelper';
import {getDeltaXDeltaYNormalized} from '../utils/helper';

export const psMiddleware = store => next => action => {
  const {dispatch} = store;
  const {type} = action;
  // if (type === SET_PS_LINKING) {
  //   const {linking} = store.getState().nodeReducer;
  //   if ((linking && !action.linking) || (!linking && action.linking)) {
  //     return next(action);
  //   }
  if(type === CLICK_PS) {
    const {nodeReducer, buildingReducer, psReducer} = store.getState();
    const {linking, linkingId} = nodeReducer;
    const ps = psReducer[action.id];
    const {activeBuildingId} = buildingReducer;
    const prevMac = ps.mac;
    if (linking === true) {
      const psMacMap = psReducer[activeBuildingId];
      if (prevMac) {
        dispatch(setPsProps(psMacMap[prevMac], {mac: ''}));
        dispatch(deletePsMacEntry(activeBuildingId, prevMac));
      }
      if (psMacMap[linkingId] !== undefined) {
        dispatch(setPsProps(psMacMap[linkingId], {mac: ''}));
      }
      dispatch(setPsProps(action.id, {mac: linkingId}));
      dispatch(setLinkingSuccess());
      dispatch(setPsMacEntry(activeBuildingId, linkingId, action.id));
    }else if(prevMac !== "") {
      if (ps.selected === true) {
        dispatch(changeSelectPses([action.id], false));
      }else {

        dispatch(setPsDialogMac(prevMac));
        dispatch(pollNodes([prevMac]));
      }
    }

  }else if(action.type === DRAG_PS) {
    const {planReducer, buildingReducer, psReducer, lineReducer} = store.getState();
    const {id, deltaX, deltaY} = action;
    const {xMap, yMap} = planReducer;
    const {activeBuildingId} = buildingReducer;
    const {zoomK, rotation} = buildingReducer[activeBuildingId];
    const ps = psReducer[id];
    const lineRotation = lineReducer[ps.lineId].rotation;
    const {nDeltaX} = getDeltaXDeltaYNormalized(deltaX, deltaY, xMap, yMap, zoomK, lineRotation + rotation);

    dispatch(setPsProps(id, {
      x: ps.x + nDeltaX,
    }));
  }else if(type === CHANGE_SELECT_ALL_PSES) {
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {psIds} = buildingReducer[activeBuildingId];
    action.psIds = psIds;
    return next(action);
  }else if(action.type === LOCATE_PS) {
    const {id} = action;
    const cb = locateCbs[id];
    if (cb) {
      cb();
    }

    return next(action);
  }else if(action.type === REMOVE_PS_LINK) {
    const {mac} = action;
    const {psReducer, buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const psMacMap = psReducer[activeBuildingId];
    const id = psMacMap[mac];

    dispatch(setPsProps(id, {mac: ''}));
    dispatch(deletePsMacEntry(activeBuildingId, mac));
  }else {
    return next(action);
  }
};

const locateCbs = {};
export const registerLocateCb = (id, cb) => {
  locateCbs[id] = cb;
};

export const unregisterLocateCb = (id) => {
  delete locateCbs[id];
};

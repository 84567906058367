
export function fetchJson(url, params) {
  const {json, headers, ...rest} = params;
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(json),
    ...rest,
  });
}

import tinygradient from 'tinygradient';
import {GET_MOVEMENT_DATA} from '../constants/movementActionTypes';
import {setLampsProps} from '../actions/lampActions';
import {makeUid} from '../utils/idHelper';

export default store => next => action => {
  const {dispatch} = store;
  if(action.type === GET_MOVEMENT_DATA.SUCCESS) {
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {allLampIds} = buildingReducer[activeBuildingId];
    const {response} = action;
    const lampIds = Object.keys(response);
    let maxCount = 0;
    lampIds.forEach(lampId => {
      const count = response[lampId];
      maxCount = Math.max(maxCount, count);
    });

    const obj = {};
    allLampIds.forEach(lampId => {
      obj[lampId] = {
        id: lampId,
        movCount: 0,
        movColor: 'white',
      };
    });
    const gradient = tinygradient([
      'white',
      'yellow',
      'red',
    ]);
    const colorRange = gradient.rgb(255);

    lampIds.forEach(lampId => {
      const lampUid = makeUid(activeBuildingId, lampId);
      const count = response[lampId];
      let index = 0;
      if (maxCount > 0) {
        index = Math.round(count / maxCount * (colorRange.length - 1));
      }
      obj[lampUid] = {
        id: lampUid,
        movCount: count,
        movColor: colorRange[index].toHexString(),
      };
    });

    const lamps = Object.keys(obj).map(key => obj[key]);
    dispatch(setLampsProps(lamps));

    action.maxMovements = maxCount;
    next(action);
  }else {
    return next(action);
  }
};

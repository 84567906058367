import {SET_PLAN_PROPS, SET_SHOW_PLAN_CONTROL, INITIALIZE_SELECT_RECT,
  SET_COORD_MAP, SET_ROTATION, STOP_SELECT_RECT,
  SET_SHOW_PS_SELECTION_DIALOG, SET_SELECTED_PS_MACS,
  SET_GROUP_MODE, SET_PLAN_DRAG_START, SET_PLAN_REDUCER_PROPS,
  SET_SHOW_PS_NODES_SELECTION_DIALOG, SHOW_LAMP_NODES_SELECTION_DIALOG,
  SHOW_LAMP_PPS_SELECTION_DIALOG, SET_POINTS,
  SET_ZOOM_RECT, SET_PLAN_WIDTH_HEIGHT, SET_LAMP_CLICK_MODE,
  CLOSE_LAMP_SELECTION_DIALOG,
  DRAG_SELECT_RECT, SET_MODE} from '../constants/planActionTypes';
import {ZOOM, MODE_NONE, LAMP_CLICK_MODE_DIALOG, CONFIG_MODE_NVI_STATE, TEXT_NONE,
LAMP_SELECTION_DIALOG_MODE_PPS, LAMP_SELECTION_DIALOG_MODE_NODES} from '../constants/customTypes';
import {PP_DECOMMISSIONED_EVENT} from '../constants/siteActionTypes';
import objectAssign from 'object-assign';
import {makeUid} from '../utils/idHelper';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {
  planIds: [],
  showPlanControl: false,
  rotation: 0,
  xMap: 0,
  yMap: 0,
  svgCoordOffsetX: 0,
  svgCoordOffsetY: 0,
  selectX: 0,
  selectY: 0,
  selectWidth: 0,
  selectHeight: 0,
  mode: ZOOM,
  configMode: CONFIG_MODE_NVI_STATE,
  showLampSelectionDialog: false,
  showPsSelectionDialog: false,
  selectedLampMacs: [],
  selectedPpIds: [],
  selectedPsMacs: [],
  groupMode: MODE_NONE,
  zoneActive: false,
  points: [],
  zoomRectX: 0,
  zoomRectY: 0,
  zoomRectWidth: 0,
  zoomRectHeight: 0,
  planHeight: 600,
  planWidth: 800,
  lampClickMode: LAMP_CLICK_MODE_DIALOG,
  showLampText: TEXT_NONE,
  lampSelectionDialogMode: LAMP_SELECTION_DIALOG_MODE_PPS,
};

export default function planReducer(state = initialState, action) {

  switch (action.type) {
    // case GET_PLAN.SUCCESS:{
    //   let newState = objectAssign({}, state);
    //   const {response} = action;
    //   const {id} = action.props;
    //   newState[id] = objectAssign({}, newState[id], response);
    //   return newState;
    // }
    case SET_PLAN_PROPS:{
      let newState = objectAssign({}, state);
      const {id, obj} = action;
      newState[id] = objectAssign({}, newState[id], obj);
      return newState;
    }
    case SET_SHOW_PLAN_CONTROL:{
      return objectAssign({}, state, {showPlanControl: action.showPlanControl});
    }
    case SET_COORD_MAP:{
      const {xMap, yMap, svgCoordOffsetX, svgCoordOffsetY} = action;
      return objectAssign({}, state, {xMap, yMap, svgCoordOffsetX, svgCoordOffsetY});
    }
    case SET_ROTATION:{
      const {rotation} = action;
      return objectAssign({}, state, {rotation});
    }
    case INITIALIZE_SELECT_RECT:{
      const {x, y} = action;
      return objectAssign({}, state, {selectX: x, selectY: y, selectHeight: 0, selectWidth: 0});
    }
    case DRAG_SELECT_RECT:{
      const {width, height} = action;
      return objectAssign({}, state, {selectWidth: width, selectHeight: height});
    }
    case STOP_SELECT_RECT:{
      return objectAssign({}, state, {selectWidth: 0, selectHeight: 0});
    }
    case SET_MODE:{
      return objectAssign({}, state, {mode: action.mode});
    }
    case SHOW_LAMP_PPS_SELECTION_DIALOG:{
      return objectAssign({}, state, {
        showLampSelectionDialog: true,
        lampSelectionDialogMode: LAMP_SELECTION_DIALOG_MODE_PPS,
        selectedPpIds: action.selectedPpIds,
        selectedLampMacs: action.lampMacs,
      });
    }
    case SHOW_LAMP_NODES_SELECTION_DIALOG:{
      return objectAssign({}, state, {
        showLampSelectionDialog: true,
        lampSelectionDialogMode: LAMP_SELECTION_DIALOG_MODE_NODES,
        selectedLampMacs: action.selectedLampMacs,
      });
    }
    case SET_SHOW_PS_NODES_SELECTION_DIALOG:
    case SET_SHOW_PS_SELECTION_DIALOG:{
      const {show} = action;
      return objectAssign({}, state, {showPsSelectionDialog: show});
    }
    case SET_SELECTED_PS_MACS:{
      return objectAssign({}, state, {selectedPsMacs: action.psMacs});
    }
    case SET_GROUP_MODE:{
      if (state.groupMode === action.mode) {
        return objectAssign({}, state, {groupMode: MODE_NONE});
      }else {
        return objectAssign({}, state, {groupMode: action.mode});
      }
    }
    case SET_POINTS:{
      return objectAssign({}, state, {points: action.points});
    }
    case SET_ZOOM_RECT:{
      const {x, y, width, height} = action;
      return objectAssign({}, state, {
        zoomRectX: x,
        zoomRectY: y,
        zoomRectWidth: width,
        zoomRectHeight: height,
      });
    }
    case SET_PLAN_WIDTH_HEIGHT:{
      const {height, width} = action;
      return objectAssign({}, state, { planHeight: height, planWidth: width});
    }
    case PP_DECOMMISSIONED_EVENT:{
      const {id, terminalId, apId} = action.data;
      let newState = objectAssign({}, state);
      let selectedLampMacs = [].concat(state.selectedLampMacs);
      const index = selectedLampMacs.indexOf(id);
      if (index > -1) {
        selectedLampMacs.splice(index, 1);
        newState.selectedLampMacs = selectedLampMacs;
      }

      let selectedPpIds = [].concat(state.selectedPpIds);
      const key = makeUid(apId, terminalId);
      const indexPp = selectedPpIds.indexOf(key);
      if (indexPp > -1) {
        selectedPpIds.splice(indexPp, 1);
        newState.selectedPpIds = selectedPpIds;
      }
      return newState;
    }
    case SET_LAMP_CLICK_MODE:{
      const {lampClickMode} = action;
      return objectAssign({}, state, {lampClickMode});
    }
    case SET_PLAN_DRAG_START:{
      const {dragStartDate} = action;
      return objectAssign({}, state, {dragStartDate});
    }
    case SET_PLAN_REDUCER_PROPS:{
      const {obj} = action;
      return objectAssign({}, state, obj);
    }
    case CLOSE_LAMP_SELECTION_DIALOG:{
      return objectAssign({}, state, {showLampSelectionDialog: false});
    }

    default:
      return state;
  }
}

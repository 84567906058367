import {apiAction} from './helper';

export const SET_FIRMWARE_VERSIONS = 'SET_FIRMWARE_VERSIONS';
export const SET_SHOW_UPGRADE_DIALOG = 'SET_SHOW_UPGRADE_DIALOG';
export const GET_FIRMWARE_FILES = apiAction('GET_FIRMWARE_FILES');
export const SET_FIRMWARE_SW_VERSION_FILE = 'SET_FIRMWARE_SW_VERSION_FILE';
export const START_UPGRADE_API = apiAction('START_UPGRADE_API');
export const START_UPGRADE = 'START_UPGRADE';
export const UPGRADE_SELECTION = 'UPGRADE_SELECTION';
export const SET_FIRMWARE_REDUCER_PROPS = 'SET_FIRMWARE_REDUCER_PROPS';
export const GET_UPGRADE_STATUS = apiAction('GET_UPGRADE_STATUS');
export const STOP_UPGRADE = apiAction('STOP_UPGRADE');
export const CLEAR_UPGRADE = apiAction('CLEAR_UPGRADE');
export const SYNC_FIRMWARE_DRIVER = apiAction('SYNC_FIRMWARE_DRIVER');
export const SYNC_FIRMWARE_PS = apiAction('SYNC_FIRMWARE_PS');
export const GET_SCHEDULED_UPGRADE = apiAction('GET_SCHEDULED_UPGRADE');
export const CANCEL_SCHEDULED_UPGRADE = apiAction('CANCEL_SCHEDULED_UPGRADE');
export const GET_NODES_UPGRADE_SYNCED = apiAction('GET_NODES_UPGRADE_SYNCED');
export const RE_SYNC_NODES_UPGRADE_API = apiAction('RE_SYNC_NODES_UPGRADE_API');
export const RE_SYNC_NODES_UPGRADE = 'RE_SYNC_NODES_UPGRADE';

import React from 'react';
import PropTypes from 'prop-types';
import SoftwareVersion from './SoftwareVersion';

const PsInfo = ({node, translate, ap}) => {
  let nodeType, hwVersion, swVersion, dectMode, dectVersion, ip;
  if (node !== undefined) {
    nodeType = node.node_type;
    hwVersion = node.hardware_version;
    swVersion = node.software_version;
  }
  if (ap !== undefined) {
    dectMode = ap.mode;
    dectVersion = ap.version;
    ip = ap.ip_address;
  }

  return (
    <span className="extra-node-info">
      <table className="table">
        <tbody>
          <tr>
            <th>{translate('technical_nodetype')}:</th>
            <td>{nodeType}</td>
          </tr>
          <tr>
            <th>{translate('technical_hardwareversion')}:</th>
            <td>{hwVersion}</td>
          </tr>
          <tr>
            <th>{translate('technical_softwareversion')}:</th>
            <td><SoftwareVersion softwareVersion={swVersion} /></td>
          </tr>
          <tr>
            <th>{translate('technical_dectmode')}:</th>
            <td>{dectMode}</td>
          </tr>
          <tr>
            <th>{translate('technical_dectversion')}:</th>
            <td>{dectVersion}</td>
          </tr>
          <tr>
            <th>{translate('technical_ip')}:</th>
            <td>{ip}</td>
          </tr>
        </tbody>
      </table>
    </span>
  );
};
PsInfo.propTypes = {
  node: PropTypes.object,
  ap: PropTypes.object,
  translate: PropTypes.func.isRequired
};

export default PsInfo;

import {apiAction} from './helper';

export const SET_SCENARIOS = 'SET_SCENARIOS';
export const CREATE_SCENARIO = 'CREATE_SCENARIO';
export const CREATE_SCENARIO_API = apiAction('CREATE_SCENARIO_API');
export const UPDATE_SCENARIO = 'UPDATE_SCENARIO';
export const DELETE_SCENARIO = apiAction('DELETE_SCENARIO');
export const SET_SCENARIO_REDUCER_PROPS = 'SET_SCENARIO_REDUCER_PROPS';
export const SET_SCENARIO_PROPS = 'SET_SCENARIO_PROPS';
export const SET_SELECTED_SCENARIO = 'SET_SELECTED_SCENARIO';
export const ACTIVATE_SCENARIO = apiAction('ACTIVATE_SCENARIO');
export const SAVE_SCENARIO = 'SAVE_SCENARIO';
export const SAVE_SCENARIO_API = apiAction('SAVE_SCENARIO_API');
export const SET_LAMP_GROUP_MAP_BY_SCENARIO = 'SET_LAMP_GROUP_MAP_BY_SCENARIO';

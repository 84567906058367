import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/reportHistoryActions';
import ReactTable from '../components/ReactTable';
import {clientKey} from '../utils/idHelper';
import {DEFAULT_DATE_FORMAT} from '../constants/customTypes';
import ActionButton from '../components/ActionButton';

const ReportHistoryOverview = ({reportHistories, actions, clientId}) => {
  const {getReportHistories, downloadReportHistory} = actions;

  const data = useMemo(() => reportHistories);

  const columns = useMemo(() => [
    {
      Header: 'Date sent',
      accessor: 'date_sent',
      Cell: ({cell : {value}}) => moment(value).format(DEFAULT_DATE_FORMAT),
    },
    {
      Header: 'Frequency',
      accessor: 'frequency',
    },
    {
      Header: 'Emailed to',
      accessor: 'email_to',
    },
    {
      accessor: 'id',
      disableSortBy: true,
      Cell: ({row : {values}}) => (
        <ActionButton action={() => downloadReportHistory(clientId, values.id, `TriLED_report_${moment(values.date_sent).format('DD_MM_YYYY')}.pdf`)} text={'Download'}/>
      ),
    },
  ]);

  useEffect(() => {
    if (!reportHistories) {
      getReportHistories(clientId);
    }
  }, []);

  if (!reportHistories) {
    return null;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2>Reports History</h2>
          <ReactTable columns={columns} data={data} />
        </div>
      </div>
    </div>
  );
};

ReportHistoryOverview.propTypes = {
  reportHistories: PropTypes.array,
  clientId: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps({clientReducer, reportHistoryReducer}) {
  const {activeClientKey} = clientReducer;
  const reportHistories = reportHistoryReducer[clientKey(activeClientKey)];

  return {
    reportHistories,
    clientId: activeClientKey,
    reducer: reportHistoryReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportHistoryOverview);

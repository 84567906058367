import {SET_VARIABLES, SET_VARIABLE_VALUE, SET_VARIABLES_VALUE_API,
SET_VARIABLE_EDITING} from '../constants/nodeActionTypes';
import {SET_VAR_SUC_EVENT, SET_VAR_FAIL_EVENT, } from '../constants/siteActionTypes';
import objectAssign from 'object-assign';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {};

export default function variableReducer(state = initialState, action) {

  switch (action.type) {
    // case :{
    //   const {value, path, id} = action.props;
    //   const key = `${id}/${path}`;
    //   let newState = objectAssign({}, state);
    //   newState[key] = value;
    //   return newState;
    // }
    case SET_VARIABLES:{
      return objectAssign({}, state, action.variables);
    }
    case SET_VARIABLE_VALUE:{
      const {path, value} = action;
      let newState = objectAssign({}, state);
      newState[path] = objectAssign({}, newState[path], {strValue: value});
      return newState;
    }
    case SET_VARIABLE_EDITING:{
      const {path, editing} = action;
      let newState = objectAssign({}, state);
      if (editing === true) {
        newState[path] = objectAssign({}, newState[path], {editing, strValue: newState[path].value.toString()});
      }else {
        newState[path] = objectAssign({}, newState[path], {editing});
      }
      return newState;
    }
    case SET_VARIABLES_VALUE_API.SUCCESS:{
      const {objectName, variableName, macs} = action.props;
      let newState = objectAssign({}, state);
      const subPath = `${objectName}/${variableName}`;
      macs.forEach((mac) => {
        const fullPath = `${mac}/${subPath}`;
        newState[fullPath] = objectAssign({}, newState[fullPath], {fails: 0});
      });
      return newState;
    }
    case SET_VAR_SUC_EVENT:{
      const {mac, objectName, variableName, value} = action.data;
      let newState = objectAssign({}, state);
      const path = `${mac}/${objectName}/${variableName}`;
      newState[path] = objectAssign({}, state[path], {value});
      return newState;
    }
    case SET_VAR_FAIL_EVENT:{
      const {mac, objectName, variableName} = action.data;
      let newState = objectAssign({}, state);
      const path = `${mac}/${objectName}/${variableName}`;
      const fails = state[path] && state[path].fails !== undefined ? state[path].fails + 1:1;
      newState[path] = objectAssign({}, state[path], {fails});
      return newState;
    }

    default:
      return state;
  }
}

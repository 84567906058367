import { SET_FILTER_NODE_TYPE_VERSIONS, RECALCULATE_FILTERS,
SET_FILTER_NODE_TYPE_VERSION_VALUE, SET_FILTER_REDUCER_PROPS,
  SET_FILTER_REDUCER_PROPS_AND_RECALC, RESET_FILTERS,
ADD_SINGLE_NODE_TYPE_VERSION,
} from '../constants/filterActionTypes';
import {REACHABLE_BOTH, LINKED_ALL, CONFIGURED_ALL, SYNCED_ALL} from '../constants/filterTypes';
import objectAssign from 'object-assign';

const defaultFilters = {
  reachableFilter: REACHABLE_BOTH,
  linkedFilter: LINKED_ALL,
  configuredFilter: CONFIGURED_ALL,
  syncedFilter: SYNCED_ALL,
  upgradeStatusNotUpgradingFilter: true,
  upgradeStatusUpgradedFilter: true,
  upgradeStatusInProgressFilter: true,
  upgradeStatusFailedFilter: true,
  apSearchValue: '',
  ppSearchValue: '',
  showSelectionInfo: true,
  showSelectionCommands: true,
};

const initialState = objectAssign({
  filteredApIds: [],
  nodeTypes: [],
  showFilterDialog: false,
  ppCount: 0,
}, defaultFilters);

export default function filterReducer(state = initialState, action) {

  switch (action.type) {
    case SET_FILTER_NODE_TYPE_VERSIONS:{
      const {nodeTypes, nodeTypeObj, versionObj, dataportSlug} = action;
      let newState = objectAssign({}, state);
      newState[`${dataportSlug}/nodeTypes`] = nodeTypes;
      const oldNodeTypes = state[`${dataportSlug}/nodeTypes`];
      if (oldNodeTypes) {
        // delete versions that no longer exist
        oldNodeTypes.forEach(nodeType => {
          if (nodeTypes.indexOf(nodeType) === -1) {
            state[nodeType].forEach(swVersion => {
              delete newState[`${dataportSlug}/${nodeType}/${swVersion}`];
            });
          }else {
            state[nodeType].forEach(swVersion => {
              if (nodeTypeObj[nodeType].indexOf(swVersion) === -1) {
                delete newState[`${dataportSlug}/${nodeType}/${swVersion}`];
              }
            });
          }
        });
      }

      Object.keys(versionObj).forEach(key => {
        const dpedKey = `${dataportSlug}/${key}`;
        if (state[dpedKey] === undefined) {
          newState[dpedKey] = true;
        }
      });
      nodeTypes.forEach((nodeType) => {
        newState[`${dataportSlug}/${nodeType}`] = nodeTypeObj[nodeType];
      });

      return newState;
    }
    case SET_FILTER_NODE_TYPE_VERSION_VALUE:{
      const {key, value} = action;
      let newState = objectAssign({}, state);
      newState[key] = value;
      return newState;
    }
    case RECALCULATE_FILTERS:{
      const {apIds, apObj, ppCount} = action;
      return objectAssign({}, state, {filteredApIds: apIds, ppCount}, apObj);
    }
    case SET_FILTER_REDUCER_PROPS_AND_RECALC:
    case SET_FILTER_REDUCER_PROPS:{
      const {obj} = action;
      return objectAssign({}, state, obj);
    }
    case RESET_FILTERS:{
      let newState = objectAssign({}, state, defaultFilters);
      const {dataportSlug} = action;
      const nodeTypes = state[`${dataportSlug}/nodeTypes`];
      if (nodeTypes) {
        nodeTypes.forEach(nodeType => {
          state[`${dataportSlug}/${nodeType}`].forEach(swVersion => {
            newState[`${dataportSlug}/${nodeType}/${swVersion}`] = true;
          });
        });
      }
      return newState;
    }
    case ADD_SINGLE_NODE_TYPE_VERSION:{
      const {nodeType, swVersion, dataportSlug} = action;
      let newState = objectAssign({}, state);
      const oldNodeTypes = state[`${dataportSlug}/nodeTypes`];
      const dpedNodeType = `${dataportSlug}/${nodeType}`;

      if (oldNodeTypes) {
        if (oldNodeTypes.indexOf(nodeType) === -1) {
          newState.nodeTypes = state.nodeTypes.concat([nodeType]);
          newState[dpedNodeType] = [swVersion];
          newState[`${dataportSlug}/${nodeType}/${swVersion}`] = true;
        }else if(state[nodeType].indexOf(swVersion) === -1) {
          newState[dpedNodeType] = state[dpedNodeType].concat([swVersion]);
          newState[`${dataportSlug}/${nodeType}/${swVersion}`] = true;
        }
      }else {
        newState[`${dataportSlug}/nodeTypes`] = [nodeType];
        newState[dpedNodeType] = [swVersion];
        newState[`${dataportSlug}/${nodeType}/${swVersion}`] = true;
      }
      return newState;
    }

    default:
      return state;
  }
}

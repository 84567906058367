import {SET_PSES, SET_PS_PROPS, SET_PS_MAC_MAP, DELETE_PS_MAC_ENTRY,
  CHANGE_SELECT_PSES, CHANGE_SELECT_ALL_PSES, CREATE_PS,
SET_DRAG_PS, SET_PS_MAC_ENTRY } from '../constants/psActionTypes';
import objectAssign from 'object-assign';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {
  canDragPs: false,
  psMacMap: {},
};

export default function psReducer(state = initialState, action) {

  switch (action.type) {
    case SET_PSES:
      return objectAssign({}, state, action.pses);
    case SET_PS_PROPS:{
      let newState = objectAssign({}, state);
      const {id, obj} = action;
      newState[id] = objectAssign({}, newState[id], obj);
      return newState;
    }
    case SET_DRAG_PS:{
      return objectAssign({}, state, {canDragPs: action.canDragPs});
    }
    case SET_PS_MAC_MAP:{
      const {psMacMap, key} = action;
      const newState = objectAssign({}, state);
      newState[key] = objectAssign({}, state[key], psMacMap);
      return newState;
    }
    case SET_PS_MAC_ENTRY:{
      const newState = objectAssign({}, state);
      const {id, mac, key} = action;
      let obj = {};
      obj[mac] = id;
      newState[key] = objectAssign({}, state[key], obj);
      return newState;
    }
    case DELETE_PS_MAC_ENTRY:{
      const newState = objectAssign({}, state);
      const {mac, key} = action;
      const newMap = objectAssign({}, state[key]);
      delete newMap[mac];
      newState[key] = newMap;
      return newState;
    }
    case CHANGE_SELECT_PSES:{
      let newState = objectAssign({}, state);
      action.ids.forEach((id) => {
        newState[id] = objectAssign({}, state[id], {selected: action.selected});
      });
      return newState;
    }
    case CHANGE_SELECT_ALL_PSES:{
      let newState = objectAssign({}, state);
      action.psIds.forEach((id) => {
        newState[id] = objectAssign({}, state[id], {selected: action.selected});
      });
      return newState;
    }
    case CREATE_PS:{
      let newState = objectAssign({}, state);
      const {psId, lineId} = action;
      newState[psId] = {
        x: 0,
        id: psId,
        mac: "",
        lineId,
      };
      return newState;
    }


    default:
      return state;
  }
}

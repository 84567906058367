import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import * as actions from '../actions/authActions';
import '../styles/login-page.scss';
import { getTranslate } from 'react-localize-redux';

const LoginPage = (props) => {
  const translate = props.translate;
  const {userIdentifier, password} = props;
  const { setUserIdentifier, setPassword, login } = props.actions;
  const submit = (event) => {
    event.preventDefault();
    login(userIdentifier, password);
  };
  let userInputType, userInputId, userInputLabel;
  if (CLOUD) {
    userInputType = 'email';
    userInputId = 'email';
		userInputLabel = translate('global_email');
  }else if (LOCAL) {
    userInputType = 'text';
    userInputId = 'username';
		userInputLabel = translate('global_username');
  }

  return (
    <div className="login-page">
      <Helmet>
        <title>{translate('page_title_login')}</title>
      </Helmet>
      <div className="login-form">
        <div className="triled-logo">
          <img src="/static/images/TriLed_logo.png" height="80em" />
        </div>
        <form onSubmit={submit} className="test-inline">
            <fieldset>
              <p>
                <label htmlFor={userInputId}>{userInputLabel}:</label>
                <input type={userInputType} value={userIdentifier} id={userInputId} className="form-control"
                  onChange={(event) => setUserIdentifier(event.target.value)} />
              </p>
              <p>
                <label htmlFor="password">{translate('global_password')}:</label>
                <input type="password" value={password} id="password" className="form-control"
                  onChange={(event) => setPassword(event.target.value)} />
              </p>
            </fieldset>
            {CLOUD ? <p className="forgot"><Link to="/user/forgot-password">{translate('forgot_password_title')}</Link></p>:null}
          <div>
            <button type="submit" className="btn btn-primary login-btn">
              <i className="fa fa-user" aria-hidden="true" />
              {translate('global_login')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  actions: PropTypes.object.isRequired,
  userIdentifier: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {userIdentifier, password} = state.authReducer;
  const translate = getTranslate(state.localeReducer);
  return {
    userIdentifier,
    password,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);

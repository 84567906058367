import React from 'react';
import {Helmet} from 'react-helmet';
import ClientsOverview from '../containers/ClientsOverview';

const ClientsPage = () => {
  return (
    <div>
      <Helmet>
        <title>Clients | TriVIEW</title>
      </Helmet>
      <div className="container">
        <h1>Clients</h1>
        <ClientsOverview />
      </div>
    </div>
  );
};

export default ClientsPage;

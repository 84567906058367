import React from 'react';
import PropTypes from 'prop-types';
import BaseLamp from '../containers/BaseLamp';
import {LAMP_TYPE_MASTER} from '../constants/lampTypeTypes';

const RectLamp = ({lamp, lampType, children, extraRotation}) => {
  const {x, y, selected, mac, id, rotation, ...rest} = lamp;
  const {length, width} = lampType;

  let rectEl = <rect width={length} y={`-${width / 2}`} height={width}/>;
  let newRotation = rotation;
  if(lampType.relationType === LAMP_TYPE_MASTER) {
    newRotation = 0;
    rectEl = (
      <g transform={`rotate(${rotation}, ${length / 2}, 0)`}>
        {rectEl}
      </g>
    );
  }
  return (
    <BaseLamp x={x} y={y} mac={mac} id={id} selected={selected} rotation={newRotation} extraRotation={extraRotation}
      circleAnimationX={length / 2} circleAnimationY={0} {...rest}>
      {rectEl}
      {children}
    </BaseLamp>
  );
};

RectLamp.propTypes = {
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  children: PropTypes.node,
  extraRotation: PropTypes.number.isRequired,
};

export default RectLamp;

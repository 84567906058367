import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setActiveBuilding} from '../actions/buildingActions';
import PlanImg from './PlanImg';
import '../styles/building-link.scss';

const BuildingLink = ({ building, dispatch, toggleShow, activeBuildingId }) => {
  if (!building) {
    return null;
  }

  const { name, id } = building;
  return (
    <div className={"building-link pointer" + (activeBuildingId === id ? ' active':'')} onClick={() => {
      dispatch(setActiveBuilding(id));
      toggleShow(false);
    }}>
      <PlanImg id={id} />
      {name}
    </div>
  );
};

BuildingLink.propTypes = {
  building: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  toggleShow: PropTypes.func.isRequired,
  activeBuildingId: PropTypes.number,
};

function mapStateToProps(state, props) {
  const {buildingReducer} = state;
  const building = buildingReducer[props.id];
  const {activeBuildingId} = buildingReducer;

  return {
    building,
    activeBuildingId,
  };
}

export default connect(
  mapStateToProps,
  null,
)(BuildingLink);

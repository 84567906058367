export const PROFILE_COLORS = [
  '#ff7f0e',
  '#f7b6d2',
  '#98df8a',
  '#e377c2',
  '#ff9896',
  '#bcbd22',
  '#17becf',
  '#bdbdbd',
];

export const HIGHLIGHT_PROFILE_COLORS = ["#ffac62", "#fad0e2", "#bceab3", "#eda7d7", "#ffbcbb", "#d3d46f", "#68d5e0", "#d4d4d4"];
export const DARKLIGHT_PROFILE_COLORS = ["#cc8a4e", "#c8a6b5", "#96bb8f", "#be86ac", "#cc9696", "#a9aa59", "#53aab3", "#aaaaaa"];

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import LampBorderZoneDepiction from '../containers/LampBorderZoneDepiction';
import {LAMP_TYPE_MASTER} from '../constants/lampTypeTypes';
import LampBorderZoneWrapper from '../containers/LampBorderZoneWrapper';
import {isRoute} from '../utils/helper';
import {ROUTE_CONFIG} from '../constants/const';

const LampBorderZone = ({group, currentRoute, x, y, rotation,
  lampType, lamp, ...rest}) => {
  if (isRoute(currentRoute, ROUTE_CONFIG) && group !== undefined) {
    const {lampIds} = group;
    if (lampType.relationType === LAMP_TYPE_MASTER && lamp.lampIds.length > 0) {
      return (
        <g transform={`translate(${x},${y}),rotate(${rotation})`}>
          {lamp.lampIds.map(id => <LampBorderZoneWrapper key={id} id={id} lampIds={lampIds} borderThickness={60} />)}
        </g>
      );
    }
    return <LampBorderZoneDepiction borderThickness={60} {...rest} lamp={lamp} lampType={lampType}  lampIds={lampIds} />;
  }
    return null;
};

LampBorderZone.propTypes = {
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  group: PropTypes.object,
  currentRoute: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
};

LampBorderZone.defaultProps = {
  y: 0,
  rotation: 0,
};

function mapStateToProps(state, {id}) {
  const {lampReducer, lampTypeReducer, groupReducer, routingReducer} = state;
  const {currentRoute} = routingReducer;
  const {lampGroupMap} = groupReducer;
  const lamp = lampReducer[id];
  const {typeId, x, y, rotation} = lamp;
  const lampType = lampTypeReducer[typeId];
  const groupId = lampGroupMap[id];
  const group = groupReducer[groupId];
  return {
    lamp,
    lampType,
    group,
    currentRoute,
    x,
    y,
    rotation,
  };
}

export default connect(
  mapStateToProps,
  {},
)(LampBorderZone);

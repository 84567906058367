import React from 'react';
import PropTypes from 'prop-types';

const SyncLabel = ({isSynced}) => {
  if (isSynced === true) {
    return (
      <span className="label label-success">
        Synced
      </span>
    );
  }else if (isSynced === false) {
    return (
      <span className="label label-danger">
        SyncFail
      </span>
    );
  }
  return null;
};

SyncLabel.propTypes = {
  isSynced: PropTypes.bool,
};

export default SyncLabel;

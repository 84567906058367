import React from 'react';
import PropTypes from 'prop-types';

const FormInput = ({id, label, onChange, value, extraClass, ...rest}) => {
  return (
    <div className="form-group">
      <label htmlFor={id}>{label}:</label>
      <input id={id} value={value} {...rest} className={`form-control ${extraClass}`}
        onChange={(e) => onChange(e.target.value)} />
    </div>
  );
};

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  extraClass: PropTypes.string.isRequired,
};

FormInput.defaultProps = {
  type: 'text',
  extraClass: '',
};


export default FormInput;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/lineActions';
import * as psActions from '../actions/psActions';
import LinesList from './LinesList';
import ActionButton from '../components/ActionButton';
import '../styles/drawing-control.scss';
import { getTranslate } from 'react-localize-redux';
import DragLampsCheckbox from './DragLampsCheckbox';
import Checkbox from '../components/Checkbox';

const LineControl = ({showLineControl, actions, psActions,
    canDragLine, canDragPs, selectedLineIds, translate}) => {

  const {setShowLineControl, setLineDrag, createLine,
  copyLines, deleteLines} = actions;
  const {setDragPs} = psActions;
  let defaultEl = null;
  let selectedLineControlEl = null;
  if (showLineControl) {
    selectedLineControlEl = (
      <LinesList />
    );
    defaultEl = (
      <div className="inline">
        <div>
          <ActionButton action={() => createLine()}
            extraClass="btn-primary"
            text={translate('technical_line_create')} />
        </div>
        <div>
          <ActionButton action={() => copyLines(selectedLineIds)}
            extraClass="btn-primary"
            text={translate('technical_line_copy')} />
        </div>
        <div>
          <ActionButton action={() => deleteLines(selectedLineIds)}
            extraClass="btn-primary"
            text={translate('technical_line_delete')} />
        </div>
        <Checkbox checked={canDragLine} onChange={() => setLineDrag(!canDragLine)}>
          {translate('technical_line_line_drag')}
        </Checkbox>
        <Checkbox checked={canDragPs} onChange={() => setDragPs(!canDragPs)}>
          {translate('technical_line_ps_drag')}
        </Checkbox>
        <DragLampsCheckbox />
      </div>
    );
  }

  return (
    <span className="drawing-control">
      <div className="row">
        <div className="col-md-12">
          <h4 onClick={() => setShowLineControl(!showLineControl)}
          className="pointer">
          <i className={"fa " + (showLineControl ? "fa-minus":"fa-plus")}
          aria-hidden="true"/>
            {translate('technical_line_control')}
          </h4>
        </div>
      </div>
      {defaultEl}
      {selectedLineControlEl}
    </span>
  );
};

LineControl.propTypes = {
  actions: PropTypes.object.isRequired,
  showLineControl: PropTypes.bool.isRequired,
  psActions: PropTypes.object.isRequired,
  canDragPs: PropTypes.bool.isRequired,
  canDragLine: PropTypes.bool.isRequired,
  selectedLineIds: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {lineReducer, psReducer} = state;
  const {showLineControl, canDragLine, selectedLineIds} = lineReducer;
  const {canDragPs} = psReducer;
  const translate = getTranslate(state.localeReducer);
  return {
    canDragLine,
    canDragPs,
    showLineControl,
    selectedLineIds,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    psActions: bindActionCreators(psActions, dispatch),
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LineControl);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import '../styles/building-list.scss';
import BuildingLink from './BuildingLink';
import {bindActionCreators} from 'redux';
import onClickOutside from 'react-onclickoutside';
import * as actions from '../actions/buildingActions';

class BuildingList extends Component {
  handleClickOutside() {
    this.props.actions.setShowBuildingsList(false);
  }
  render() {
    const {buildingIds, show, actions} = this.props;
    const {setShowBuildingsList} = actions;
    if (!buildingIds || show === false) {
      return null;
    }

    return (
      <div className="buildings-list">
        {buildingIds.map((id) => {
          return (
            <BuildingLink key={id} id={id}
            toggleShow={setShowBuildingsList} />
          );
        })}
      </div>
    );
  }
}

BuildingList.propTypes = {
  buildingIds: PropTypes.array,
  activeBuildingName: PropTypes.string,
  actions: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {clientReducer, buildingReducer} = state;
  const {showBuildingsList} = buildingReducer;
  let buildingIds = [];
  const client = clientReducer[clientReducer.activeClientKey];
  if (client) {
    buildingIds = client.buildingIds;
  }
  return {
    buildingIds: buildingIds,
    show: showBuildingsList,
    outsideClickIgnoreClass: 'selected-building',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(onClickOutside(BuildingList));

import {ADD_ALERT, REMOVE_ALERT} from '../constants/alertActionTypes';

export function addAlert(msg, timeout) {
  return {
    type: ADD_ALERT,
    msg,
    timeout,
  };
}

export function removeAlert(id) {
  return {
    type: REMOVE_ALERT,
    id,
  };
}

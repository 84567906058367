import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const RoleSelect = ({roleOptions, name, value, onChange}) => {
  if (roleOptions.length === 0) {
    return <p>Loading...</p>;
  }

  return (
    <Select
      classNamePrefix="select"
      closeMenuOnSelect={false}
      isClearable={true}
      isSearchable={true}
      isMulti
      onChange={onChange}
      name={name}
      value={roleOptions.filter(option => value.indexOf(option.value) > -1)}
      options={roleOptions}
    />
  );
};

RoleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  roleOptions: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RoleSelect;

export const GREEN_TO_RED = 0;
export const RED_TO_GREEN = 1;

const GREEN_TO_RED_PATTERN = ['#00FF00', '#33ff00', '#66ff00', '#99ff00', '#ccff00', '#FFFF00',
    '#FFCC00', '#ff9900', '#ff6600', '#FF3300', '#FF0000'];
const RED_TO_GREEN_PATTERN = ['#FF0000', '#FF3300', '#ff6600', '#ff9900', '#FFCC00', '#FFFF00',
    '#ccff00', '#99ff00', '#66ff00', '#33ff00', '#00FF00'];

export function getColorPattern(colorPattern) {
  if (colorPattern === GREEN_TO_RED) {
    return GREEN_TO_RED_PATTERN;
  } else if (colorPattern === RED_TO_GREEN) {
    return RED_TO_GREEN_PATTERN;
  } else {
    return GREEN_TO_RED_PATTERN; // default
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ExtraLampVar from '../containers/ExtraLampVar';

const ExtraLampVars = ({id}) => {
  if (!id) {
    return null;
  }

  return (
    <span className="extra-lamp-vars">
      <h4>Extra (not synced to lamp)</h4>
      <span className="variable-line">
        <ExtraLampVar id={id} name="luxMax" />
      </span>
    </span>
  );
};

ExtraLampVars.propTypes = {
  id: PropTypes.string,
  mac: PropTypes.string.isRequired,
};

function mapStateToProps(state, props) {
  const {buildingReducer, lampReducer} = state;
  const {activeBuildingId} = buildingReducer;
  const lampMap = lampReducer.lampMacMap;
  const id = lampMap[props.mac];

  return {
    id,
  };
}

export default connect(
  mapStateToProps,
  null,
)(ExtraLampVars);

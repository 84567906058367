import {GET_USERS, CREATE_USER, UPDATE_USER,
  DELETE_USER_BY_ID, SET_USER_REDUCER_PROPS,
  SAVE_USER, GET_USER_CLIENT_ROLES,
  SET_EDIT_USER_PROP,
  START_EDIT_USER,
  GET_USER_BUILDING_ROLES,
} from '../constants/userActionTypes';
import {API, POST, GET, PUT, DELETE} from '../constants/customTypes';
import {TRIVIEW_API} from '../constants/urls';

export function getUsers() {
  return {
    type: API,
    idProp: 'id',
    auth: true,
    payload: {
      method: GET,
      url: TRIVIEW_API.USERS.GET,
      success: GET_USERS.SUCCESS,
      start: GET_USERS.START,
      error: GET_USERS.ERROR,
    }
  };
}

export function saveUser(user, wasUserDefined) {
  return {
    type: SAVE_USER,
    user,
    wasUserDefined,
  };
}

export function createUser(user) {
  return {
    type: API,
    auth: true,
    payload: {
      method: POST,
      url: TRIVIEW_API.USERS.POST,
      body: {
        user,
      },
      success: CREATE_USER.SUCCESS,
      start: CREATE_USER.START,
      error: CREATE_USER.ERROR,
    },
    props: {
      user,
    }
  };
}

export function updateUser(user) {
  return {
    type: API,
    auth: true,
    payload: {
      method: PUT,
      url: TRIVIEW_API.USERS.POST,
      body: {
        user,
      },
      success: UPDATE_USER.SUCCESS,
      start: UPDATE_USER.START,
      error: UPDATE_USER.ERROR,
    }
  };
}

export function deleteUserById(id) {
  return {
    type: API,
    auth: true,
    payload: {
      method: DELETE,
      url: TRIVIEW_API.USERS.DELETE.replace(':userId', id),
      success: DELETE_USER_BY_ID.SUCCESS,
      start: DELETE_USER_BY_ID.START,
      error: DELETE_USER_BY_ID.ERROR,
    },
    props: {
      id,
    },
  };
}

export function setUserReducerProps(obj) {
  return {
    type: SET_USER_REDUCER_PROPS,
    obj,
  };
}

export function getUserClientRoles(userId, clientId) {
  return {
    type: API,
    auth: true,
    payload: {
      method: GET,
      url: TRIVIEW_API.USER_CLIENT_ROLES.GET.replace(':userId', userId).replace(':clientId', clientId),
      success: GET_USER_CLIENT_ROLES.SUCCESS,
      start: GET_USER_CLIENT_ROLES.START,
      error: GET_USER_CLIENT_ROLES.ERROR,
    },
    props: {
      userId,
      clientId,
    },
  };
}

export function startEditUser(userId) {
  return {
    type: START_EDIT_USER,
    userId,
  };
}

export function setEditUserProp(propName, value) {
  return {
    type: SET_EDIT_USER_PROP,
    propName,
    value,
  };
}

export function getUserBuildingRoles(userId, clientId) {
  return {
    type: API,
    auth: true,
    payload: {
      method: GET,
      url: TRIVIEW_API.USER_BUILDING_ROLES.GET.replace(':clientId', clientId).replace(':userId', userId),
      success: GET_USER_BUILDING_ROLES.SUCCESS,
      start: GET_USER_BUILDING_ROLES.START,
      error: GET_USER_BUILDING_ROLES.ERROR,
    },
    props: {
      userId,
      clientId,
    },
  };
}

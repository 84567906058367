import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import LampZoneDepiction from '../containers/LampZoneDepiction';
import {LAMP_TYPE_MASTER} from '../constants/lampTypeTypes';
import LampZoneWrapper from '../containers/LampZoneWrapper';

const HeatmapLampZone = ({x, y, rotation,
  color, colorProp,
  lampType, lamp, colorValue, ...rest}) => {

  let newColor = 'white';
  if (color === undefined) {
    if (colorValue !== undefined) {
      newColor = colorValue;
    }
  }else {
    newColor = color;
  }

  const extra = {
    color: newColor,
    opacity: 0.8,
    className: 'heatmap-zone',
  };

  if (lampType.relationType === LAMP_TYPE_MASTER && lamp.lampIds.length > 0) {
    return (
      <g transform={`translate(${x},${y}),rotate(${rotation})`}>
        {lamp.lampIds.map(id => <LampZoneWrapper key={id} id={id} {...extra} />)}
      </g>
    );
  }
  return <LampZoneDepiction {...rest} lamp={lamp} lampType={lampType} {...extra} />;
};

HeatmapLampZone.propTypes = {
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  colorProp: PropTypes.string.isRequired,
  colorValue: PropTypes.string,
  color: PropTypes.string,
};

HeatmapLampZone.defaultProps = {
  y: 0,
  rotation: 0,
};

function mapStateToProps(state, {id, colorProp}) {
  const {lampReducer, lampTypeReducer} = state;
  const lamp = lampReducer[id];
  const {typeId, x, y, rotation} = lamp;
  const colorValue = lamp[colorProp];
  const lampType = lampTypeReducer[typeId];
  return {
    lamp,
    lampType,
    x,
    y,
    rotation,
    colorValue,
  };
}

export default connect(
  mapStateToProps,
  {},
)(HeatmapLampZone);

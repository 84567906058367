import {CREATE_SCENARIO, UPDATE_SCENARIO, DELETE_SCENARIO, SET_SCENARIO_REDUCER_PROPS,
  CREATE_SCENARIO_API, SET_LAMP_GROUP_MAP_BY_SCENARIO,
SET_SELECTED_SCENARIO, SAVE_SCENARIO, SAVE_SCENARIO_API,
ACTIVATE_SCENARIO, SET_SCENARIO_PROPS} from '../constants/scenarioActionTypes';
import {TRIVIEW_API} from '../constants/urls';
import {API, DS_RPC, PUT, DELETE, POST} from '../constants/customTypes';
import {makeUid} from '../utils/idHelper';

export function setScenarioReducerProps(obj) {
  return {
    type: SET_SCENARIO_REDUCER_PROPS,
    obj,
  };
}

export function createScenario(scenario) {
  return {
    type: CREATE_SCENARIO,
    scenario,
  };
}

export function createScenarioApi(configId, scenario, groups, profiles, customScenarioProps, buildingProps) {
  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      url: TRIVIEW_API.SCENARIOS.PUT.replace(':configId', configId),
      method: POST,
      body: {
        scenario,
        groups,
        profiles,
      },
      success: CREATE_SCENARIO_API.SUCCESS,
      error: CREATE_SCENARIO_API.ERROR,
      start: CREATE_SCENARIO_API.START,
    },
    props: {
      scenario,
      groups,
      profiles,
      buildingId: configId,
      customScenarioProps,
      buildingProps,
    },
  };
}

export function updateScenario(scenario) {
  return {
    type: UPDATE_SCENARIO,
    scenario,
  };
}

export function deleteScenario(id) {
  const [configId, scenarioId] = id.split('_');
  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      url: TRIVIEW_API.SCENARIOS.DELETE.replace(':configId', configId).replace(':scenarioId', scenarioId),
      method: DELETE,
      success: DELETE_SCENARIO.SUCCESS,
      error: DELETE_SCENARIO.ERROR,
      start: DELETE_SCENARIO.START,
    },
    props: {
      id,
      buildingId: configId,
    },
  };
}

export function setSelectedScenario(id) {
  return {
    type: SET_SELECTED_SCENARIO,
    id,
  };
}

export function activateScenario(id) {
  const [buildingId, scenarioId] = id.split('_');
  return {
    type: DS_RPC,
    replaceSite: true,
    auth: true,
    showErrors: true,
    payload: {
      url: `:site/${buildingId}/config/scenario/activate`,
      data: {scenarioId: parseInt(scenarioId)},
      success: ACTIVATE_SCENARIO.SUCCESS,
      error: ACTIVATE_SCENARIO.ERROR,
      start: ACTIVATE_SCENARIO.START,
    },
    props: {
      buildingId,
      scenarioId,
      id,
    },
  };
}

export function saveScenario(id) {
  return {
    type: SAVE_SCENARIO,
    id,
  };
}

export function saveScenarioApi(configId, scenario, groups, profiles) {
  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      url: TRIVIEW_API.SCENARIOS.PUT.replace(':configId', configId),
      method: PUT,
      body: {
        scenario,
        groups,
        profiles,
      },
      success: SAVE_SCENARIO_API.SUCCESS,
      error: SAVE_SCENARIO_API.ERROR,
      start: SAVE_SCENARIO_API.START,
    },
    props: {
      scenarioId: makeUid(configId, scenario.id),
    },
  };
}

export function setScenarioProps(id, obj) {
  return {
    type: SET_SCENARIO_PROPS,
    id,
    obj,
  };
}

export function setLampGroupMapByScenario(scenarioId) {
  return {
    type: SET_LAMP_GROUP_MAP_BY_SCENARIO,
    id: scenarioId,
  };
}

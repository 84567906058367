import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/dataportActions';

const newDataport= {
  name: 'newDataport',
};

class CreateDataportItem extends Component {
  constructor(props) {
    super(props);

    this.state = {...newDataport};
    this.create = this.create.bind(this);
  }

  create() {
    const {createDataport} = this.props.actions;
    createDataport(this.state);
    this.setState({...newDataport});
  }

  render() {
    const {name} = this.state;

    return (
      <tr>
        <td>
          <input type="text" value={name} className="form-control"
          onChange={(e) => this.setState({name: e.target.value})} />
        </td>
        <td>
          <button className="btn btn-primary" onClick={this.create}>Create</button>
        </td>
      </tr>
    );
  }
}

CreateDataportItem.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(CreateDataportItem);

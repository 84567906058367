export function createEnum(constants) {
  return buildEnum(constants, null, null);
}

export function createPrependedEnum(constants, prepend) {
  return buildEnum(constants, prepend, null);
}

export function createAppendedEnum(constants, append) {
  return buildEnum(constants, null, append);
}

function buildEnum(constants, prepend, append) {
  for (let k in constants) {
    if (typeof(constants[k]) === 'string') {
      constants[k] = (prepend ? prepend : '') + constants[k] + (append ? append : '');
    }
    if (typeof(constants[k]) === 'object') {
      constants[k] = buildEnum(constants[k], prepend, append);
    }
  }
  return constants;
}

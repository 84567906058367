import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import LampZoneDepiction from '../containers/LampZoneDepiction';
import {LAMP_TYPE_MASTER} from '../constants/lampTypeTypes';
import LampZoneWrapper from '../containers/LampZoneWrapper';

const TechnicalLampZone = ({x, y, rotation,
  lampType, lamp, ...rest}) => {

  const extra = {
    color: 'blue',
    opacity: 0.3,
    className: 'technical-zone',
  };

  if (lampType.relationType === LAMP_TYPE_MASTER && lamp.lampIds.length > 0) {
    return (
      <g transform={`translate(${x},${y}),rotate(${rotation})`}>
        {lamp.lampIds.map(id => <LampZoneWrapper key={id} id={id} {...extra} />)}
      </g>
    );
  }
  return <LampZoneDepiction {...rest} lamp={lamp} lampType={lampType} {...extra} />;
};

TechnicalLampZone.propTypes = {
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
};

TechnicalLampZone.defaultProps = {
  y: 0,
  rotation: 0,
};

function mapStateToProps(state, {id}) {
  const {lampReducer, lampTypeReducer} = state;
  const lamp = lampReducer[id];
  const {typeId, x, y, rotation} = lamp;
  const lampType = lampTypeReducer[typeId];
  return {
    lamp,
    lampType,
    x,
    y,
    rotation,
  };
}

export default connect(
  mapStateToProps,
  {},
)(TechnicalLampZone);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import '../styles/heatmap-legend.scss';

const MovementHeatmapLegend = ({maxMovements, translate}) => {

  return (
    <div className="legend-right heatmap-legend">
      0<div className="gradient-block" />{maxMovements} {translate('global_movements')}
    </div>
  );
};

MovementHeatmapLegend.propTypes = {
  translate: PropTypes.func.isRequired,
  maxMovements: PropTypes.number.isRequired,
};

function mapStateToProps({localeReducer, movementReducer}) {
  const translate = getTranslate(localeReducer);
  const {maxMovements} = movementReducer;
  return {
    translate,
    maxMovements,
  };
}

export default connect(
  mapStateToProps,
  null,
)(MovementHeatmapLegend);

import {batch} from 'react-redux';
import {CREATE_LINE, ADD_LAMP_TO_LINE, REMOVE_LAMP_FROM_LINE, COPY_LINES,
  SPREAD_LINE_LAMPS_EVENLY, DELETE_LINES, CLICK_LINE, CHANGE_SELECT_ALL_LINES,
  DRAG_LINE, ALIGN_SELECTED_LINES,
} from '../constants/lineActionTypes';
import {setBuildingProps} from '../actions/buildingActions';
import {createLamp, deleteLamp, setLampProps} from '../actions/lampActions';
import {createExtraLampVars} from '../actions/extraVariableActions';
import {createPs, setPsProps} from '../actions/psActions';
import {selectLine} from '../actions/lineActions';
import objectAssign from 'object-assign';
import {getDeltaXDeltaYNormalized} from '../utils/helper';

const lineMiddleware = store => next => action => {
  const {dispatch} = store;
  if (action.type === CREATE_LINE) {
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {id: buildingId, lastLineId, lineIds, lastPsId, psIds} = buildingReducer[activeBuildingId];
    let newLastLineId = lastLineId;
    let newLastPsId = lastPsId;
    action.lineId = `${buildingId}_${++newLastLineId}`;
    action.psId = `${buildingId}_${++newLastPsId}`;
    let lineIdsCp = [].concat(lineIds);
    lineIdsCp.push(action.lineId);

    let psIdsCp = [].concat(psIds);
    psIdsCp.push(action.psId);

    dispatch(createPs(action.lineId, action.psId));
    next(action);
    dispatch(setBuildingProps(activeBuildingId, {lastLineId: newLastLineId, lastPsId: newLastPsId, lineIds: lineIdsCp, psIds: psIdsCp}));

  }else if(action.type === DELETE_LINES) {
    const {buildingReducer, lineReducer, lampReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lineIds, allLampIds} = buildingReducer[activeBuildingId];

    let lampIdsCopy = [].concat(allLampIds);
    let lineIdsCopy = [].concat(lineIds);
    let toDeleteLampIds = [];

    action.lineIds.forEach((lineId) => {
      lineIdsCopy.splice(lineIdsCopy.indexOf(lineId), 1);
      const line = lineReducer[lineId];
      line.lampIds.forEach((lampId) => {
        const lamp = lampReducer[lampId];
        toDeleteLampIds.concat(lamp.lampIds);
        toDeleteLampIds.push(lampId);
        lampIdsCopy.splice(lampIdsCopy.indexOf(lampId), 1);
      });
    });

    batch(() => {
      dispatch(setBuildingProps(activeBuildingId, {allLampIds: lampIdsCopy, lineIds: lineIdsCopy}));
      next(action);
      toDeleteLampIds.forEach((lampId) => {
        dispatch(deleteLamp(lampId));
      });
    });

  }else if(action.type === ADD_LAMP_TO_LINE) {
    const {buildingReducer, lampTypeReducer} = store.getState();
    const {lineId} = action;
    const {lampTypeLineIds} = lampTypeReducer;
    const {activeBuildingId} = buildingReducer;
    const {id: buildingId, lastLampId, allLampIds} = buildingReducer[activeBuildingId];
    let newLastLampId = lastLampId;
    action.lampId = `${buildingId}_${++newLastLampId}`;

    let copy = [].concat(allLampIds);
    copy.push(action.lampId);

    dispatch(createLamp(action.lampId, lampTypeLineIds[0], {
      lineId,
      x: 0,
    }));
    dispatch(setBuildingProps(activeBuildingId, {lastLampId: newLastLampId, allLampIds: copy}));

    next(action);
  }else if(action.type === SPREAD_LINE_LAMPS_EVENLY) {
    const {lineReducer, lampReducer, lampTypeReducer} = store.getState();
    let totalLampLength = 0;
    const line = lineReducer[action.lineId];

    line.lampIds.forEach((id) => {
      const lamp = lampReducer[id];
      const lampType = lampTypeReducer[lamp.typeId];
      totalLampLength = totalLampLength + lampType.length;
    });

    let padding = (line.length - totalLampLength) / (line.lampIds.length - 1);
    let runningX = 0;
    line.lampIds.forEach((id) => {
      const lamp = lampReducer[id];
      const lampType = lampTypeReducer[lamp.typeId];

      dispatch(setLampProps(id, {x: runningX}));
      runningX = runningX + lampType.length + padding;
    });

  }else if(action.type === REMOVE_LAMP_FROM_LINE) {
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {allLampIds} = buildingReducer[activeBuildingId];
    let copy = [].concat(allLampIds);
    copy.splice(copy.indexOf(action.lampId), 1);
    dispatch(setBuildingProps(activeBuildingId, {allLampIds: copy}));
    next(action);
    dispatch(deleteLamp(action.lampId));
  }else if(action.type === COPY_LINES) {
    const {buildingReducer, lineReducer, lampReducer, psReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {id: buildingId, lastLineId, lastLampId, lastPsId, allLampIds, lineIds: buildingLineIds} = buildingReducer[activeBuildingId];
    const {lineIds} = action;
    let extraLineIds = [];
    let lampIdsCopy = [].concat(allLampIds);
    let lineObj = {};
    let newLastLineId = lastLineId;
    let newLastLampId = lastLampId;
    let newLastPsId = lastPsId;
    lineIds.forEach((lineId) => {
      const line = lineReducer[lineId];
      let lampIds = [];
      let subLampIds = [];
      line.lampIds.forEach((lampId) => {
        const lamp = lampReducer[lampId];
        const parentId = `${buildingId}_${++newLastLampId}`;
        lampIds.push(parentId);

        let lampCopy;
        if (lamp.lampIds !== undefined) {
          const subLampIdsCopy = lamp.lampIds.map((subLampId) => {
            const subLamp = lampReducer[subLampId];
            const id = `${buildingId}_${++newLastLampId}`;
            let subLampCopy = objectAssign({}, subLamp, {id, mac: "", parentId});
            dispatch(setLampProps(id, subLampCopy));
            lampIdsCopy.push(id);
            return id;
          });
          subLampIds = subLampIds.concat(subLampIdsCopy);
          lampCopy = objectAssign({}, lamp, {id: parentId, mac: "", lampIds: subLampIdsCopy});
        }else {
          lampCopy = objectAssign({}, lamp, {id: parentId, mac: ""});
        }

        dispatch(setLampProps(parentId, lampCopy));
        dispatch(createExtraLampVars(parentId));
        lampIdsCopy.push(parentId);
      });
      const newLineId = `${buildingId}_${++newLastLineId}`;
      let lineCopy = objectAssign({}, line, {selected: true, id: newLineId, lampIds, subLampIds});
      lineObj[newLineId] = lineCopy;
      extraLineIds.push(newLineId);

      const psId = `${buildingId}_${++newLastPsId}`;
      lineCopy.psId = psId;

      let psCopy = objectAssign({}, psReducer[line.psId], {id: psId});
      dispatch(setPsProps(psId, psCopy));
      lampIdsCopy = lampIdsCopy.concat(lampIds);
    });
    action.obj = lineObj;
    action.selectedLineIds = extraLineIds;
    const lineIdsCopy = buildingLineIds.concat(extraLineIds);
    next(action);
    dispatch(setBuildingProps(activeBuildingId, {
      lineIds: lineIdsCopy,
      allLampIds: lampIdsCopy,
      lastLampId: newLastLampId,
      lastLineId: newLastLineId,
      lastPsId: newLastPsId,
    }));

  }else if(action.type === CLICK_LINE) {
    const {lineReducer, lampReducer, psReducer,} = store.getState();
    const {dragLamps} = lampReducer;
    const {canDragPs} = psReducer;
    const {canDragLine} = lineReducer;
    if (canDragLine === false && canDragPs === false && dragLamps === false) {
      dispatch(selectLine(action.lineId));
    }

  }else if(action.type === CHANGE_SELECT_ALL_LINES) {
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lineIds} = buildingReducer[activeBuildingId];
    action.lineIds = lineIds;
    return next(action);

  }else if(action.type === DRAG_LINE) {
    const {buildingReducer, planReducer} = store.getState();
    const { activeBuildingId} = buildingReducer;
    const {zoomK, rotation} = buildingReducer[activeBuildingId];
    const {deltaX, deltaY} = action;
    const {xMap, yMap} = planReducer;
    const {nDeltaX, nDeltaY} = getDeltaXDeltaYNormalized(deltaX, deltaY, xMap, yMap, zoomK, rotation);

    action.deltaX = nDeltaX;
    action.deltaY = nDeltaY;

    return next(action);

  }else if(action.type === ALIGN_SELECTED_LINES) {
    const {buildingReducer, lineReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lineIds} = buildingReducer[activeBuildingId];

    const selectedLineIds = lineIds.filter(lineId => lineReducer[lineId].selected);

    if (selectedLineIds.length < 2) {
      return;
    }
    const lamp1 = lineReducer[selectedLineIds[0]];
    const lamp2 = lineReducer[selectedLineIds[1]];
    const diffX = Math.abs(lamp1.x - lamp2.x);
    const diffY = Math.abs(lamp1.y - lamp2.y);
    let sum = 0;
    selectedLineIds.forEach((lineId) => {
      const line = lineReducer[lineId];
      if (diffX < diffY) {
        sum += line.x;
      }else {
        sum += line.y;
      }
    });

    const avg = Math.round(sum / selectedLineIds.length * 100) / 100;
    action.selectedLineIds = selectedLineIds;
    action.avg = avg;
    action.prop = diffX < diffY ? 'x':'y';

    return next(action);

  }else {
    return next(action);
  }

};

export default lineMiddleware;

import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/dataportActions';
import SubNav from '../components/SubNav';
import ActionButton from '../components/ActionButton';
import FormInput from '../components/FormInput';
import DataportsCommandTable from '../containers/DataportsCommandTable';
import {TRICOMMAND, TRIAGENT, TRIVIEW_LOCAL} from '../constants/customTypes';
import '../styles/dataports-command-page.scss';

const DataportsCommandPage = ({dataportsCommand, actions}) => {
  const {setDataportReducerProps, execDataportsCommand, updateDataportsPackage} = actions;
  return (
    <div className="dataports-command-page">
      <Helmet>
        <title>TriLED - Dataport Commands</title>
      </Helmet>
      <SubNav parentNav="monitor" />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <FormInput id="command" type="text" label="Command" value={dataportsCommand} onChange={(val) => setDataportReducerProps({dataportsCommand: val})} />
            <ActionButton text="Execute" action={() => execDataportsCommand(dataportsCommand)}  />
            <ActionButton text="TriCommand" action={() => updateDataportsPackage(TRICOMMAND)} />
            <ActionButton text="TriAgent" action={() => updateDataportsPackage(TRIAGENT)} />
            <ActionButton text="Triview-Local" action={() => updateDataportsPackage(TRIVIEW_LOCAL)} />
            <DataportsCommandTable />
          </div>
        </div>
      </div>
    </div>
  );
};

DataportsCommandPage.propTypes = {
  dataportsCommand: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {dataportsCommand} = state.dataportReducer;
  return {
    dataportsCommand,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataportsCommandPage);

import { LOGIN, RE_AUTH, RESET_PASSWORD} from '../constants/authActionTypes';
import { push, replace } from 'connected-react-router';
import {getClients} from '../actions/clientActions';
import {getMe} from '../actions/authActions';

const forwardLoginMiddleware = store => next => action => {
  const {dispatch} = store;
  if (action.type === LOGIN.SUCCESS) {
    next(action);
    dispatch(getClients());
    dispatch(getMe());
    const {prevRoute} = store.getState().authReducer;
    if (prevRoute === '' || prevRoute === '/login') {
      dispatch(push('/plan'));
    }else {
      dispatch(push(prevRoute));
    }
  }else if(action.type === RESET_PASSWORD.SUCCESS) {
    next(action);
    setTimeout(() => {
      dispatch(replace('/login'));
    }, 500);

  }else if(action.type === RE_AUTH.ERROR) {
    delete localStorage.trimonitor_token;
    dispatch(replace('/login'));
  } else if (action.type === LOGIN.ERROR) {
    delete localStorage.trimonitor_token;
    return next(action);
  }else {
    return next(action);
  }

};

export default forwardLoginMiddleware;

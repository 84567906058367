export const DELETE_PROFILE = 'DELETE_PROFILE';
export const SET_PROFILES = 'SET_PROFILES';
export const SET_DELETE_PROFILE_SHOW = 'SET_DELETE_PROFILE_SHOW';
export const SET_PROFILE_EDITING = 'SET_PROFILE_EDITING';
export const SET_PROFILE_PROPS = 'SET_PROFILE_PROPS';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const SET_PROFILE_FILTER = 'SET_PROFILE_FILTER';
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const CANCEL_EDIT_PROFILE = 'CANCEL_EDIT_PROFILE';
export const SET_PROFILE_VALIDATION_ERRORS = 'SET_PROFILE_VALIDATION_ERRORS';
export const SET_DELETE_PROFILE_ERROR = 'SET_DELETE_PROFILE_ERROR';

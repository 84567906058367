import {apiAction} from './helper';

export const GET_DATAPORT = apiAction('GET_DATAPORT');
export const GET_DATAPORTS = apiAction('GET_DATAPORTS');
export const GET_DATAPORTS_TECHNICAL = apiAction('GET_DATAPORTS_TECHNICAL');
export const UPDATE_DATAPORT = apiAction('UPDATE_DATAPORT');
export const DELETE_DATAPORT = apiAction('DELETE_DATAPORT');
export const CREATE_DATAPORT = apiAction('CREATE_DATAPORT');
export const SET_DATAPORT_REDUCER_PROPS = 'SET_DATAPORT_REDUCER_PROPS';
export const SET_DATAPORT_PROPS = 'SET_DATAPORT_PROPS';
export const GET_DATAPORT_INFO = apiAction('GET_DATAPORT_INFO');
export const REFRESH_DATAPORT_INFO = apiAction('REFRESH_DATAPORT_INFO');
export const UPDATE_DATAPORT_PACKAGE_RPC = apiAction('UPDATE_DATAPORT_PACKAGE_RPC');
export const UPDATE_DATAPORT_PACKAGE = 'UPDATE_DATAPORT_PACKAGE';
export const UPDATE_DATAPORTS_PACKAGE = 'UPDATE_DATAPORTS_PACKAGE';
export const EXECUTE_DATAPORT_COMMAND = 'EXECUTE_DATAPORT_COMMAND';
export const EXECUTE_DATAPORT_COMMAND_RPC = apiAction('EXECUTE_DATAPORT_COMMAND_RPC');
export const DATAPORT_COMMAND_CODE_EVENT = 'DATAPORT_COMMAND_CODE_EVENT';
export const DATAPORT_COMMAND_STDOUT_EVENT = 'DATAPORT_COMMAND_STDOUT_EVENT';
export const GET_DATAPORT_COMMAND_OUTPUT = apiAction('GET_DATAPORT_COMMAND_OUTPUT');
export const EXECUTE_DATAPORTS_COMMAND = 'EXECUTE_DATAPORTS_COMMAND';

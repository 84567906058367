import React from 'react';
import {Helmet} from 'react-helmet';
import '../styles/not-found-page.scss';
import {Link} from 'react-router-dom';
import LampTypesOverview from '../containers/LampTypesOverview';

const LampTypesPage = () => {
  return (
    <div className="lamp-types-page">
      <Helmet>
        <title>Lamp Types | TriVIEW</title>
      </Helmet>
      <div className="container">
        <h1>Lamp Types</h1>
        <Link className="btn btn-primary" to="/lamp-type/new">Create</Link>
        <LampTypesOverview />
      </div>
    </div>
  );
};

export default LampTypesPage;

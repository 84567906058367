import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/uniqueObjectsActions';
import Checkbox from '../components/Checkbox';

const ObjectVariableFilter = ({objectName, variableName, selected, actions}) => {
  const {setUniqueVarSelected} = actions;

    return (
      <Checkbox checked={selected} onChange={() => setUniqueVarSelected(`${objectName}/${variableName}`, !selected)}>
        {variableName}
      </Checkbox>
    );
};

ObjectVariableFilter.propTypes = {
  objectName: PropTypes.string.isRequired,
  variableName: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, {objectName, variableName}) {
  const selected = state.uniqueObjectsReducer[`${objectName}/${variableName}`];
  return {
    selected,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ObjectVariableFilter);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AlertsList from '../containers/AlertsList';
import Nav from '../containers/Nav';
import { hot } from 'react-hot-loader';
import Routes from 'Routes';

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.
class App extends Component {
  render() {
    return (
      <span>
        <AlertsList />
        <div>
          <Nav />
        </div>
        <Routes />
      </span>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);

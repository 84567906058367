import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/uniqueObjectsActions';
import ObjectVariableFilter from './ObjectVariableFilter';
import Checkbox from '../components/Checkbox';

const ObjectNameFilter = ({objectName, variableNames, actions}) => {
  const {changeUniqueObjectSelectedAll} = actions;
    return (
      <div>
        <h5>{objectName} <Checkbox checked inline onChange={() => changeUniqueObjectSelectedAll(objectName, true)}/> <Checkbox inline onChange={() => changeUniqueObjectSelectedAll(objectName, false)} /></h5>
        {variableNames.map(variableName => (
          <ObjectVariableFilter key={variableName}  objectName={objectName} variableName={variableName} />
        ))}
      </div>
    );
};

ObjectNameFilter.propTypes = {
  objectName: PropTypes.string.isRequired,
  variableNames: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, {objectName}) {
  const variableNames = state.uniqueObjectsReducer[objectName];
  return {
    variableNames,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ObjectNameFilter);

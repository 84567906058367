import {SET_PS_PROPS, CLICK_PS, DELETE_PS_MAC_ENTRY, CHANGE_SELECT_PSES, CHANGE_SELECT_ALL_PSES,
  DRAG_PS, LOCATE_PS, REMOVE_PS_LINK,
SET_DRAG_PS, SET_PS_MAC_ENTRY, CREATE_PS} from '../constants/psActionTypes';

export function setPsProps(id, obj) {
  return {
    type: SET_PS_PROPS,
    obj,
    id,
  };
}

export function psClick(id) {
  return {
    type: CLICK_PS,
    id,
  };
}

export function setDragPs(canDragPs) {
  return {
    type: SET_DRAG_PS,
    canDragPs,
  };
}

export function setPsMacEntry(key, mac, id) {
  return {
    type: SET_PS_MAC_ENTRY,
    id,
    mac,
    key,
  };
}

export function deletePsMacEntry(key, mac) {
  return {
    type: DELETE_PS_MAC_ENTRY,
    mac,
    key,
  };
}

export function changeSelectPses(ids, selected) {
  return {
    type: CHANGE_SELECT_PSES,
    ids,
    selected,
  };
}

export function changeSelectAllPses(selected) {
  return {
    type: CHANGE_SELECT_ALL_PSES,
    selected,
  };
}

export function createPs(lineId, psId) {
  return {
    type: CREATE_PS,
    psId, lineId,
  };
}

export function dragPs(id, deltaX, deltaY) {
  return {
    type: DRAG_PS,
    id,
    deltaX,
    deltaY,
  };
}

export function locatePs(id) {
  return {
    type: LOCATE_PS,
    id,
  };
}

export function removePsLink(mac) {
  return {
    type: REMOVE_PS_LINK,
    mac,
  };
}

import { LOGOUT, GET_ME, GET_DEEPSTREAM_TOKEN, CHANGE_PASSWORD} from '../constants/authActionTypes';
import {dsDisconnect, dsConnect} from '../actions/dsActions';
import {getDsToken} from '../actions/authActions';
import { push } from 'connected-react-router';

const authMiddleware = store => next => action => {
  const {dispatch} = store;
  if(action.type === LOGOUT.SUCCESS || action.type === CHANGE_PASSWORD.SUCCESS) {
    dispatch(push('/login'));
    dispatch(dsDisconnect());
    dispatch({ type: 'RESET_REDUX' });
    return next(action);
  }else if (action.type === GET_ME.SUCCESS) {
    if (LOCAL) {
      const {id, username} = action.response;
      dispatch(getDsToken(username, id));
    }else if(CLOUD) {
      const {id, email} = action.response;
      dispatch(getDsToken(email, id));
    }
    return next(action);
  }else if (action.type === GET_DEEPSTREAM_TOKEN.SUCCESS) {
    const {token} = action.response;
    const {userId, username} = action.props;
    dispatch(dsConnect(username, userId, token));
    if (LOCAL) {
      const {tokenAuthenticate} = require('../actions/localActions');
      dispatch(tokenAuthenticate(userId, token));
    }
  }else {
    return next(action);
  }

};

export default authMiddleware;

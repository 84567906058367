export const apiAction = (type) => {
  return {
    START: `${type}_START`,
    SUCCESS: `${type}_SUCCESS`,
    ERROR: `${type}_ERROR`,
  };
};

export const recordAction = (type) => {
  return {
    DATA: `${type}_DATA`,
  };
};

export const listAction = (type) => {
  return {
    GET_ENTRIES: `${type}_GET_ENTRIES`,
    ENTRY_ADDED: `${type}_ENTRY_ADDED`,
  };
};

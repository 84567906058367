import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/dataportActions';
import DataportItem from '../containers/DataportItem';
import ConfirmDialog from '../containers/ConfirmDialog';
import CreateDataportItem from '../components/CreateDataportItem';

class DataportsOverview extends Component {
  componentDidMount() {
    const {actions, dataportIds} = this.props;
    const {getDataports} = actions;
    if (dataportIds.length === 0) {
      getDataports();
    }
  }

  render() {
    const {dataportIds, deleteDataportId, actions} = this.props;
    const {setDataportReducerProps, deleteDataport} = actions;
    if (dataportIds.length === 0) {
      return <p>...Loading</p>;
    }

    return (
      <div className="dataports-overview">
        <ConfirmDialog show={deleteDataportId !== undefined} closeAction={() => setDataportReducerProps({deleteDataportId: undefined})}
          confirmAction={() => deleteDataport(deleteDataportId)} text="Are you sure you want to delete this dataport? Make sure this dataport is not used in any buildings"
          title="Delete Dataport" />
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            <CreateDataportItem />
            {dataportIds.map(dataportId => {
              return <DataportItem key={dataportId} id={dataportId} />;
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

DataportsOverview.propTypes = {
  dataportIds: PropTypes.array,
  deleteDataportId: PropTypes.number,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {deleteDataportId, dataportIds} = state.dataportReducer;
  return {
    dataportIds,
    deleteDataportId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataportsOverview);

import {SERIAL_RPC, SERIAL_RPC_API, DS_RPC} from '../constants/customTypes';

let queueObj = {};

const serialMiddleware = store => next => action => {
  const {dispatch} = store;

  function dispatchNextAction(keyName, key, url, replaceSite, resultAction) {
    let data = {};
    data[keyName] = key;
    let props = {
      keyName,
      url,
      replaceSite,
      resultAction,
    };
    props[keyName] = key;

    dispatch({
      type: DS_RPC,
      replaceSite,
      payload: {
        url,
        data,
        success: SERIAL_RPC_API.SUCCESS,
        error: SERIAL_RPC_API.ERROR,
        start: SERIAL_RPC_API.START,
      },
      props,
    });
  }

  function getNextAction(action) {
    const { keyName, url, replaceSite, resultAction} = action.props;
    const keys = queueObj[url];
    if (keys.length > 0) {
      const key = queueObj[url].shift();
      dispatchNextAction(keyName, key, url, replaceSite, resultAction);
    }
  }


  if (action.type === SERIAL_RPC) {
    const {keyName, url, replaceSite, resultAction} = action;
    let keys = [].concat(action.keys);
    const key = keys.shift();
    queueObj[url] = keys;
    dispatchNextAction(keyName, key, url, replaceSite, resultAction);

  }else if(action.type === SERIAL_RPC_API.SUCCESS) {
    const {resultAction} = action.props;
    const {response, props} = action;
    let dispatchObj = {
      type: resultAction.SUCCESS,
      props,
      response,
    };
    dispatchObj[props.keyName] = props.key;
    dispatch(dispatchObj);
    getNextAction(action);

  }else if(action.type === SERIAL_RPC_API.ERROR) {
    const {resultAction} = action.props;
    const {err, props} = action;
    let dispatchObj = {
      type: resultAction.ERROR,
      props,
      err,
    };
    dispatchObj[props.keyName] = props.key;
    dispatch(dispatchObj);
    getNextAction(action);

  }else if(action.type === SERIAL_RPC_API.START) {
    const {resultAction} = action.props;
    const {props} = action;
    let dispatchObj = {
      type: resultAction.START,
      props,
    };
    dispatchObj[props.keyName] = props.key;
    dispatch(dispatchObj);

  }else {
    return next(action);
  }

};

export default serialMiddleware;

import {SET_ACTIVE_BUILDING, GET_BUILDINGS, SET_SHOW_BUILDINGS_LIST, SET_BUILDING_PROPS,
  SET_BUILDING_REDUCER_PROPS, CREATE_BUILDING, DELETE_BUILDING, UPDATE_BUILDING,
  SET_BUILDING_ROTATION, DRAG_ZOOM, ZOOM_ZOOM, SAVE_BUILDING_CONFIG, SAVE_BUILDING_CONFIG_REMOTE,
  SET_BUILDING_ROUTE_SLUG,
  SET_BUILDING_ROUTE,
  GUARD_PERMISSIONS,
GET_CONFIG} from '../constants/buildingActionTypes';
import {TRIVIEW_API} from '../constants/urls';
import {API, GET, PUT, DELETE, POST} from '../constants/customTypes';
import {deleteExtraBuildingProps} from '../utils/helper';

export function getConfig(clientSlug, buildingSlug, buildingId){
  return {
    type: API,
    auth: true,
    payload: {
      url: TRIVIEW_API.CONFIGURATION.GET.replace(':clientSlug', clientSlug).replace(':buildingSlug', buildingSlug),
      method: GET,
      success: GET_CONFIG.SUCCESS,
      error: GET_CONFIG.ERROR,
      start: GET_CONFIG.START,
    },
    props: {
      buildingId,
    }
  };
}

export function getBuildings(slug, forwardAfter) {
  return {
    type: API,
    auth: true,
    payload: {
      url: TRIVIEW_API.BUILDINGS.GET.replace(':slug', slug),
      method: GET,
      success: GET_BUILDINGS.SUCCESS,
      error: GET_BUILDINGS.ERROR,
      start: GET_BUILDINGS.START,
    },
    props: {
      slug,
      forwardAfter,
    }
  };
}

export function setBuildingRouteSlug(slug) {
  return {
    type: SET_BUILDING_ROUTE_SLUG,
    slug,
  };
}

export function setActiveBuilding(id) {
  return {
    type: SET_ACTIVE_BUILDING,
    id,
  };
}

export function setShowBuildingsList(show) {
  return {
    type: SET_SHOW_BUILDINGS_LIST,
    show,
  };
}

export function setBuildingProps(id, obj) {
  return {
    type: SET_BUILDING_PROPS,
    id,
    obj,
  };
}

export function setBuildingRotation(id, rotation) {
  if (rotation > 270) {
    rotation = 0;
  }else if (rotation < 0) {
    rotation = 270;
  }
  return {
    type: SET_BUILDING_ROTATION,
    rotation,
    id,
  };
}

export function dragZoom(deltaX, deltaY) {
  return {
    type: DRAG_ZOOM,
    deltaX,
    deltaY,
  };
}

export function zoomZoom(pageX, pageY, eventDelta) {
  return {
    type: ZOOM_ZOOM,
    pageX,
    pageY,
    eventDelta,
  };
}

export function saveBuildingConfig(buildingId) {
  return {
    type: SAVE_BUILDING_CONFIG,
    buildingId,
  };
}

export function createBuilding(building, clientId) {
  const dataport_id = building.dataportId;
  const buildingCp = {...building};
  deleteExtraBuildingProps(buildingCp);
  buildingCp.dataport_id = dataport_id;
  buildingCp.client_id = clientId;
  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      method: POST,
      body: buildingCp,
      url: TRIVIEW_API.BUILDINGS.POST,
      success: CREATE_BUILDING.SUCCESS,
      error: CREATE_BUILDING.ERROR,
      start: CREATE_BUILDING.START,
    },
    props: {
      clientId,
    },
  };
}

export function updateBuilding(building) {
  const dataport_id = building.dataportId;
  const buildingCp = {...building};
  deleteExtraBuildingProps(buildingCp);
  buildingCp.dataport_id = dataport_id;
  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      method: PUT,
      body: buildingCp,
      url: TRIVIEW_API.BUILDINGS.PUT,
      success: UPDATE_BUILDING.SUCCESS,
      error: UPDATE_BUILDING.ERROR,
      start: UPDATE_BUILDING.START,
    },
    props: {
      building,
    },
  };
}

export function deleteBuilding(clientId, id) {
  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      method: DELETE,
      url: TRIVIEW_API.BUILDINGS.DELETE.replace(':id', id),
      success: DELETE_BUILDING.SUCCESS,
      error: DELETE_BUILDING.ERROR,
      start: DELETE_BUILDING.START,
    },
    props: {
      id,
      clientId,
    },
  };
}


export function saveBuildingConfigRemote(configObj) {
  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      url: TRIVIEW_API.CONFIGURATION.PUT,
      method: PUT,
      body: configObj,
      success: SAVE_BUILDING_CONFIG_REMOTE.SUCCESS,
      error: SAVE_BUILDING_CONFIG_REMOTE.ERROR,
      start: SAVE_BUILDING_CONFIG_REMOTE.START,
    },
    props: {
      id: configObj.id,
    },
  };
}

export function setBuildingReducerProps(obj) {
  return {
    type: SET_BUILDING_REDUCER_PROPS,
    obj,
  };
}

export function setBuildingRoute(route, postFix = '') {
  return {
    type: SET_BUILDING_ROUTE,
    route,
    postFix,
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import PlanPageWrapper from './PlanPageWrapper';
import Plan from './Plan';
import TechnicalBar from './TechnicalBar';
import LampDialog from './LampDialog';
import LampSelectionDialog from './LampSelectionDialog';
import PsSelectionDialog from './PsSelectionDialog';
import UpgradeBar from './UpgradeBar';
import PsList from './PsList';
import UpgradeDialog from './UpgradeDialog';
import PsDialog from './PsDialog';
import ItemSelectionControl from './ItemsSelectionControl';
import FilterDialog from './FilterDialog';

const PlanTechnicalPage = ({planHeight}) => {
  return (
    <>
      <UpgradeDialog />
      <LampDialog />
      <LampSelectionDialog />
      <PsDialog />
      <PsSelectionDialog />
      <FilterDialog />
      <TechnicalBar />
      <div className="container-fluid control-container">
        <div className="col-md-3 left-el" style={{height: planHeight, marginTop: 5}}>
          <ItemSelectionControl />
          <UpgradeBar />
          <PsList />
        </div>
        <div className="col-md-9">
          <Plan className="border" />
        </div>
      </div>
    </>
  );
};

PlanTechnicalPage.propTypes = {
  planHeight: PropTypes.number.isRequired,
};

export default PlanPageWrapper(PlanTechnicalPage, 'page_title_plan_technical');

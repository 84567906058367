import {GET_LAMP_TYPES, CREATE_LAMP_TYPE, UPDATE_LAMP_TYPE, DELETE_LAMP_TYPE} from '../constants/lampTypeActionTypes';
import {LOCATION_STANDALONE, LOCATION_LINE, LAMP_TYPE_SLAVE} from '../constants/lampTypeTypes';
import {removeSingleItemFromArray} from '../utils/helper';
import {statusHandler} from '../utils/reducerHelper';
import {INITIAL} from '../constants/customTypes';
import objectAssign from 'object-assign';

const initialState = {
  lampTypeIds: [],
  lampTypeSlaveIds: [],
  lampTypeLineIds: [],
  lampTypeAloneIds: [],
  lampTypeAloneSlaveIds: [],
  lampTypesFetched: false,
  createLampTypeStatus: INITIAL,
  deleteLampTypeStatus: INITIAL,
  updateLampTypeStatus: INITIAL,
};

const statusHandlerFunc = statusHandler([
  {
    apiAction: CREATE_LAMP_TYPE,
    statusPropName: 'createLampTypeStatus',
  },
  {
    apiAction: UPDATE_LAMP_TYPE,
    statusPropName: 'updateLampTypeStatus',
  },
  {
    apiAction: DELETE_LAMP_TYPE,
    statusPropName: 'deleteLampTypeStatus',
  },
], false);

function handleLampTypeId(lampType, {lampTypeSlaveIds, lampTypeLineIds, lampTypeAloneIds, lampTypeAloneSlaveIds}) {
  const {id, relationType, locations} = lampType;
  if (locations.indexOf(LOCATION_LINE) > -1) {
    if (relationType === LAMP_TYPE_SLAVE) {
      lampTypeSlaveIds.push(id);
    }else {
      lampTypeLineIds.push(id);
    }
  }
  if (locations.indexOf(LOCATION_STANDALONE) > -1) {
    if (relationType === LAMP_TYPE_SLAVE) {
      lampTypeAloneSlaveIds.push(id);
    }else {
      lampTypeAloneIds.push(id);
    }
  }
}

export default function lampTypeReducer(state = initialState, action) {
  state = statusHandlerFunc(state, action);

  switch (action.type) {
    case GET_LAMP_TYPES.SUCCESS:{
      const {obj, ids} = action;

      const lampTypeSlaveIds = [];
      const lampTypeLineIds = [];
      const lampTypeAloneIds = [];
      const lampTypeAloneSlaveIds = [];
      for (const id of ids) {
        const lampType = obj[id];
        handleLampTypeId(lampType, {lampTypeAloneIds, lampTypeLineIds, lampTypeSlaveIds, lampTypeAloneSlaveIds});
      }

      return objectAssign({}, state, obj, {
        lampTypeIds: ids,
        lampTypeSlaveIds,
        lampTypeLineIds,
        lampTypeAloneIds,
        lampTypeAloneSlaveIds,
        lampTypesFetched: true
      });
    }
    case CREATE_LAMP_TYPE.SUCCESS:{
      const {response} = action;
      const newState = objectAssign({}, state);
      newState.lampTypeIds = state.lampTypeIds.concat([response.id]);
      const lampTypeSlaveIds = [].concat(state.lampTypeSlaveIds);
      const lampTypeLineIds = [].concat(state.lampTypeLineIds);
      const lampTypeAloneIds = [].concat(state.lampTypeAloneIds);
      const lampTypeAloneSlaveIds = [].concat(state.lampTypeAloneSlaveIds);
      handleLampTypeId(response, {lampTypeAloneIds, lampTypeLineIds, lampTypeAloneSlaveIds});
      newState.lampTypeSlaveIds = lampTypeSlaveIds;
      newState.lampTypeLineIds = lampTypeLineIds;
      newState.lampTypeAloneIds = lampTypeAloneIds;
      newState.lampTypeAloneSlaveIds = lampTypeAloneSlaveIds;
      newState[response.id] = response;
      return newState;
    }
    case UPDATE_LAMP_TYPE.SUCCESS:{
      const {response} = action;
      const newState = objectAssign({}, state);
      newState[response.id] = response;
      return newState;
    }
    case DELETE_LAMP_TYPE.SUCCESS:{
      const {props} = action;
      const {id} = props;
      const newState = objectAssign({}, state);
      newState.lampTypeIds = removeSingleItemFromArray(state.lampTypeIds, id);
      newState.lampTypeSlaveIds = removeSingleItemFromArray(state.lampTypeSlaveIds, id);
      newState.lampTypeLineIds = removeSingleItemFromArray(state.lampTypeLineIds, id);
      newState.lampTypeAloneIds = removeSingleItemFromArray(state.lampTypeAloneIds, id);
      newState.lampTypeAloneSlaveIds = removeSingleItemFromArray(state.lampTypeAloneSlaveIds, id);
      delete newState[id];
      return newState;
    }

    default:
      return state;
  }
}

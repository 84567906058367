import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {TEXT_MAC, TEXT_NONE, TEXT_NAME} from '../constants/customTypes';
import {LIVE_MODE_LAMP_NAMES} from '../constants/livePropTypes';
import SvgText from '../components/SvgText';
import {revertUidNoParse} from '../utils/idHelper';
import {isRoute} from '../utils/helper';
import {ROUTE_LIVE} from '../constants/const';

const LampSvgText = ({showLampText, rotation, mac, liveMode, currentRoute, id, parentId}) => {
  const isLiveModeName = liveMode === LIVE_MODE_LAMP_NAMES && isRoute(currentRoute, ROUTE_LIVE);
  if (showLampText === TEXT_NONE && isLiveModeName === false) {
    return null;
  }
  let text;

  if (isLiveModeName || showLampText === TEXT_NAME) {
    if (parentId !== undefined) {
      text = parentId;
    }else {
      text = id;
    }
    text = revertUidNoParse(text);
  }else if (showLampText === TEXT_MAC) {
    text = mac;
  }

  if (text === undefined) {
    return null;
  }
  const fontSize = 200;
  let x = 0;
  let y = -200;
  switch(rotation) {
    case 180:{
      x = -1500;
      break;
    }
    case 270:{
      x = 200;
      y = -600;
      break;
    }
    case 90:{
      x = 200;
      y = 1000;
      break;
    }
  }

  return (
    <g className="lamp-svg-text">
      <SvgText rotation={rotation} offsetX={x} offsetY={y} text={text}
      fontSize={fontSize} />
    </g>
  );
};

LampSvgText.propTypes = {
  showLampText: PropTypes.string.isRequired,
  rotation: PropTypes.number.isRequired,
  mac: PropTypes.string,
  liveMode: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  currentRoute: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {liveMode} = state.liveReducer;
  const {currentRoute} = state.routingReducer;
  return {
    liveMode,
    currentRoute,
    showLampText: state.planReducer.showLampText
  };
}

export default connect(
  mapStateToProps,
  null,
)(LampSvgText);

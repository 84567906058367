import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ActionButton from '../components/ActionButton';
import * as actions from '../actions/siteActions';
import * as dataportActions from '../actions/dataportActions';

const DataportOverview = ({dataportId, dataport, actions, dataportActions, overwriteCloudStatus}) => {
  const {overwriteFromCloud} = actions;
  const {getDataportInfo} = dataportActions;

  useEffect(() => {
    getDataportInfo(dataportId);
  }, []);

  if (dataportId === undefined || dataport === undefined) {
    return (
      <div className="row">
        <div className="col-md-12">
          <h2>No Dataport!</h2>
        </div>
      </div>
    );
  }
  const {name, packageVersions, vpn_ip} = dataport;

  let versionsTableEl = null;
  if (packageVersions !== undefined) {
    const {tricommand, triagent, triviewLocal} = packageVersions;
    versionsTableEl = (
      <table className="table">
        <tbody>
          <tr>
            <th>TriCommand</th>
            <td>{tricommand}</td>
          </tr>
          <tr>
            <th>TriAgent</th>
            <td>{triagent}</td>
          </tr>
          <tr>
            <th>TriView local</th>
            <td>{triviewLocal}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  let nameEl = name;
  let vpnIpEl = null;
  if (CLOUD) {
    nameEl = (
      <Link to={"/monitor/" + dataportId}>{name}</Link>
    );

    vpnIpEl = (
      <div className="row" style={{marginTop: '15px'}}>
        <div className="col-md-12">
          <p>VpnIp: {vpn_ip}&nbsp;<CopyToClipboard text={vpn_ip}><span className="btn btn-default"><i className="fa fa-clipboard" aria-hidden="true" /></span></CopyToClipboard></p>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2>{nameEl}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <ActionButton action={() => overwriteFromCloud()}
            status={overwriteCloudStatus}
            text="Overwrite from cloud"/>
        </div>
      </div>
      {vpnIpEl}
      <div className="row">
        <div className="col-md-12">
          <h3>Software versions</h3>
          {versionsTableEl}
        </div>
      </div>
    </div>
  );
};

DataportOverview.propTypes = {
  actions: PropTypes.object.isRequired,
  dataportId: PropTypes.number,
  dataport: PropTypes.object,
  dataportActions: PropTypes.object.isRequired,
  overwriteCloudStatus: PropTypes.string,
};

function mapStateToProps({buildingReducer, dataportReducer, siteReducer}) {
  const {activeBuildingId} = buildingReducer;
  const {dataportId} = buildingReducer[activeBuildingId];
  const dataport = dataportReducer[dataportId];
  const {overwriteCloudStatus} = siteReducer;

  return {
    dataportId,
    dataport,
    overwriteCloudStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    dataportActions: bindActionCreators(dataportActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataportOverview);

import immer from 'immer';
import {
  GET_REPORT_HISTORIES,
} from '../constants/reportHistoryActionTypes';
import {clientKey} from '../utils/idHelper';


const initialState = {
};

const reducer = immer((draft, action) => {

  switch (action.type) {
    case GET_REPORT_HISTORIES.SUCCESS:{
      const {response, props} = action;
      const {clientId} = props;
      // draft[clientKey(clientId)] = response.map(reportHistory => {
      //   draft[reportHistory.id] = {...draft[reportHistory.id], ...reportHistory};
      //   return reportHistory.id;
      // });
      draft[clientKey(clientId)] = response;
      return;
    }

    default:
      return;
  }
}, initialState);

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import '../styles/heatmap-legend.scss';

const EnergyHeatmapLegend = ({maxEnergyText}) => {

  return (
    <div className="legend-right heatmap-legend">
      0<div className="gradient-block" />{maxEnergyText}
    </div>
  );
};

EnergyHeatmapLegend.propTypes = {
  translate: PropTypes.func.isRequired,
  maxEnergyText: PropTypes.string.isRequired,
};

function mapStateToProps({localeReducer, energyReducer}) {
  const translate = getTranslate(localeReducer);
  const {maxEnergyText} = energyReducer;
  return {
    translate,
    maxEnergyText,
  };
}

export default connect(
  mapStateToProps,
  null,
)(EnergyHeatmapLegend);

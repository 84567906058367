import { apiAction } from './helper';

export const SET_GROUPS = 'SET_GROUPS';
export const GET_GROUPS_PROFILE_STATE = apiAction('GET_GROUPS_PROFILE_STATE');
export const SET_GROUP_PROPS = 'SET_GROUP_PROPS';
export const SET_GROUP_EDITING = 'SET_GROUP_EDITING';
export const SET_GROUP_LAMPS = 'SET_GROUP_LAMPS';
export const SAVE_GROUP = 'SAVE_GROUP';
export const CREATE_GROUP = 'CREATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const GROUP_LAMPS_REDO = 'GROUP_LAMPS_REDO';
export const GROUP_LAMPS_UNDO = 'GROUP_LAMPS_UNDO';
export const SET_DOUBLE_GROUP_LAMPS = 'SET_DOUBLE_GROUP_LAMPS';
export const SET_EDITING_GROUP_PROFILE = 'SET_EDITING_GROUP_PROFILE';
export const CANCEL_EDIT_GROUP = 'CANCEL_EDIT_GROUP';
export const SET_LAMP_GROUP_MAP = 'SET_LAMP_GROUP_MAP';
export const SET_LAMP_GROUP_MAP_ENTRY = 'SET_LAMP_GROUP_MAP_ENTRY';

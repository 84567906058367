import React from 'react';
// import PropTypes from 'prop-types';
import PlanPageWrapper from './PlanPageWrapper';
import DataportOverview from './DataportOverview';

const PlanDataportPage = () => {
  return (
    <DataportOverview />
  );
};

// PlanDataportPage.propTypes = {
// };

export default PlanPageWrapper(PlanDataportPage, 'page_title_plan_dataport');

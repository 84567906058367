import objectAssign from 'object-assign';
import { push } from 'connected-react-router';
import {SET_LINES} from '../constants/lineActionTypes';
import {SET_LAMPS, SET_LAMP_MAC_MAP} from '../constants/lampActionTypes';
import {SET_PSES, SET_PS_MAC_MAP} from '../constants/psActionTypes';
import {SELECT_LAMPS, SELECT_PSES,
ZOOM} from '../constants/customTypes';
import {SET_PROFILES} from '../constants/profileActionTypes';
import {getNodes} from '../actions/nodeActions';
import {STOP_SELECT_RECT, CHANGE_SELECT_ALL, SET_SHOW_PS_SELECTION_DIALOG, SHOW_LAMP_NODES_SELECTION_DIALOG,
  SET_SHOW_PS_NODES_SELECTION_DIALOG,
SHOW_LAMP_PPS_SELECTION_DIALOG,
EXEC_PLAN_SUBSCRIBES, EXEC_PLAN_UNSUBSCRIBES,
AUTO_CALC_HITZONES, CALC_ADJACENCY, SET_PLAN_SVG_EL, CALC_PLAN_SIZE_PROPS} from '../constants/planActionTypes';
import {changeSelectLamps, changeSelectAllLamps, setLampProps, setLampsProps} from '../actions/lampActions';
import {changeSelectPses, changeSelectAllPses} from '../actions/psActions';
import {setSelectedPsMacs,
  setZoomRect, setCoordMap, calcPlanSizeProps, setPlanWidthHeight, setMode,
  execPlanSubscribes,
  // setPoints,
} from '../actions/planActions';
import {setLiveMode, clearLiveProps} from '../actions/liveActions';
import {getGroupsProfileState} from '../actions/groupActions';
import {setSelectedObjects} from '../actions/selectedObjectActions';
import {setSelectedVars} from '../actions/selectedVariablesActions';
import {subscribeMovement, unsubscribeMovement, subscribeGroupUpdates, unsubscribeGroupUpdates,
subscribeDataportEvent, unsubscribeDataportEvent} from '../actions/siteActions';
import {SET_GROUPS} from '../constants/groupActionTypes';
import {getUpgradeStatus, getScheduledUpgrade} from '../actions/firmwareActions';
import {getAps} from '../actions/apActions';
import {SET_SCENARIOS} from '../constants/scenarioActionTypes';
import {initExtraVars} from '../actions/extraVariableActions';
import {recalculateFilters} from '../actions/filterActions';
import {setUserConfigPermissions} from '../actions/authActions';
import {SET_BUILDING, SAVE_BUILDING_CONFIG, CREATE_BUILDING, GET_CONFIG} from '../constants/buildingActionTypes';
import {SET_PLAN_IMG} from '../constants/planImgActionTypes';
import {saveBuildingConfigRemote, setBuildingRoute} from '../actions/buildingActions';
import {revertUid} from '../utils/idHelper';
import {deleteExtraBuildingProps, isRoute} from '../utils/helper';
import {setSelectedScenario, setLampGroupMapByScenario} from '../actions/scenarioActions';
import {ROUTE_CONFIG, ROUTE_LIVE, ROUTE_TECHNICAL, hasRoutePermission} from '../constants/const';
import {DEPICTION_RECT, DEPICTION_CLOCK, LAMP_TYPE_MASTER} from '../constants/lampTypeTypes';

import {SET_VAR_SUC_EVENT, SET_VAR_FAIL_EVENT, PP_ADDED_EVENT, AP_ADDED_EVENT, NODE_ADDED_EVENT,
  POLL_SUC_EVENT, POLL_FAIL_EVENT,
PING_SUC_EVENT, PING_FAIL_EVENT, PP_DECOMMISSIONED_EVENT} from '../constants/siteActionTypes';
import {SET_VAR_SUC_URL, SET_VAR_FAIL_URL, PING_SUC_URL, PING_FAIL_URL, PP_ADDED_URL, AP_ADDED_URL,
  POLL_FAIL_URL, POLL_SUC_URL,
NODE_ADDED_URL, PP_DECOMMISSIONED_URL} from '../constants/dsUrls';


// let lineUid = 0, lampUid = 0, psUid = 0;

let fetched = {};
let svgPt, svgEl;

// the rotate's of svg elements apperently use opposites
function rotateSvg(cx, cy, x, y, angle) {
  const radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = (cos * (x - cx)) - (sin * (y - cy)) + cx,
    ny = (cos * (y - cy)) + (sin * (x - cx)) + cy;
  return {x: nx, y: ny};
}

function rotate(cx, cy, x, y, angle) {
  const radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
    ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
  return {x: nx, y: ny};
}

function createSelectedVariables(node, objectReducer, variableReducer, uniqueObjectsReducer, objectNames, objects, variables) {
  if (!node) {
    return;
  }

  if (!node.objects) {
    return;
  }

  node.objects.forEach((objectName) => {
    const object = objectReducer[`${node.id}/${objectName}`];
    if (object !== undefined) {

      object.variables.forEach((variableName) => {
        const isSelected = uniqueObjectsReducer[`${objectName}/${variableName}`];
        if (isSelected === true && variables[`${objectName}/${variableName}`] === undefined) {
          if (objects[objectName] === undefined) {
            objectNames.push(objectName);
            objects[objectName] = {variables: []};
          }
          objects[objectName].variables.push(variableName);
          const {value, writeable} = variableReducer[`${node.id}/${objectName}/${variableName}`];
          variables[`${objectName}/${variableName}`] = {editing: false, value, writeable, isCopied: false};
        }
      });

    }

  });
}

function createBuildingConfig(buildingObj, dispatch) {
  const {id : buildingId} = buildingObj;
  const makeUid = (buildingId, obj) => {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      const value = obj[key];
      const newId = `${buildingId}_${key}`;
      value.id = newId;
      obj[newId] = value;
      delete obj[key];
    });
  };

  const makeSingleUid = (prefixKey, id) => {
    return `${prefixKey}_${id}`;
  };

  const makeUidArray = (prefixKey, obj, idArrayName) => {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      const value = obj[key];
      if (value[idArrayName] !== undefined) {
        value[idArrayName] = value[idArrayName].map(id => `${prefixKey}_${id}`);
      }
    });
  };

  const makeUidFk = (buildingId, obj, fkName) => {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      const value = obj[key];
      value[fkName] = `${buildingId}_${value[fkName]}`;
    });
  };

  const addCustomProp = (obj, propName, propDefaultValue) => {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      obj[key][propName] = propDefaultValue;
    });
  };

  let allSubLampIds = [];
  const addSubLampIds = (line, lampsObj) => {
    line.subLampIds = line.lampIds.reduce((accumulator, lampId) => {
      const lamp = lampsObj[lampId];
      if (lamp.lampIds) {
        return accumulator.concat(lamp.lampIds);
      }
      return accumulator;
    }, []);
    allSubLampIds = allSubLampIds.concat(line.subLampIds);
  };

  const getLastId = (obj) => {
    let lastId = 0;
    const keys = Object.keys(obj);
    keys.forEach(key => {
      lastId = Math.max(lastId, obj[key].id);
    });
    return lastId;
  };

  const {building, lamps, lines, plan_img, pses, scenarios, dataportSlug, lampIds, dataport_id, id, extra} = buildingObj;
  building.id = id;
  building.dataportId = dataport_id;
  building.configFetched = true;

  const profiles = {};
  const groups = {};

  const lastLampId = getLastId(lamps);
  const lastLineId = getLastId(lines);
  const lastPsId = getLastId(pses);
  const lastScenarioId = getLastId(scenarios);

  makeUid(buildingId, scenarios);
  makeUidArray(buildingId, scenarios, 'profileIds');
  makeUidArray(buildingId, scenarios, 'groupIds');

  addCustomProp(lamps, 'selected', false);
  addCustomProp(lines, 'selected', false);

  makeUid(buildingId, lamps);
  makeUid(buildingId, lines);
  makeUid(buildingId, pses);

  makeUidArray(buildingId, lines, 'lampIds');
  makeUidArray(buildingId, lamps, 'lampIds');
  makeUidFk(buildingId, lines, 'psId');

  const scenarioIds = Object.keys(scenarios);
  const lineIds = Object.keys(lines);
  const allLampIds = Object.keys(lamps);
  const psIds = Object.keys(pses);

  let extraLampVars = {};
  lineIds.forEach(lineId => {
    const line = lines[lineId];
    addSubLampIds(line, lamps);

    line.lampIds.forEach(lampId => {
      const lamp = lamps[lampId];
      lamp.lineId = line.id;
    });
    const ps = pses[line.psId];
    ps.lineId = line.id;
  });

  allLampIds.forEach(lampId => {
    const lamp = lamps[lampId];
    let luxMax = lamp.luxMax;
    if (lamp.luxMax === undefined) {
      luxMax = 0;
    }

    extraLampVars[`${lampId}/luxMax`] = {
      value: luxMax,
      isEditing: false,
      tValue: luxMax.toString(),
    };

    lamp.tops = lamp.tops.map(id => makeSingleUid(buildingId, id));
    lamp.rights = lamp.rights.map(id => makeSingleUid(buildingId, id));
    lamp.lefts = lamp.lefts.map(id => makeSingleUid(buildingId, id));
    lamp.bots = lamp.bots.map(id => makeSingleUid(buildingId, id));
    if (lamp.lampIds) {
      lamp.lampIds.forEach(subLampId => {
        const subLamp = lamps[subLampId];
        subLamp.parentId = lampId;
      });
    }
  });

  scenarioIds.forEach(scenarioId => {
    const scenario = scenarios[scenarioId];
    let lastGroupId = 0;
    let lastProfileId = 0;

    scenario.profileIds = Object.keys(scenario.profiles).map(profileId => {
      const profile = scenario.profiles[profileId];
      lastProfileId = Math.max(lastProfileId, profileId);
      profile.id = makeSingleUid(scenario.id, profileId);
      profiles[profile.id] = profile;
      return profile.id;
    });

    scenario.groupIds = Object.keys(scenario.groups).map(groupId => {
      const group = scenario.groups[groupId];
      lastGroupId = Math.max(lastGroupId, groupId);
      group.profileId = makeSingleUid(scenario.id, group.profileId);
      group.id = makeSingleUid(scenario.id, groupId);
      groups[group.id] = group;
      return group.id;
    });

    scenario.defaultProfileId = scenario.profileIds[0];
    scenario.lastProfileId = lastProfileId;
    scenario.lastGroupId = lastGroupId;
    scenario.needsSave = false;

    delete scenario.groups;
    delete scenario.profiles;
  });

  makeUidArray(buildingId, groups, 'lampIds');

  building.dataportSlug = dataportSlug;
  if (LOCAL) {
    building.dataportId = 1;
  }
  building.scale = 1;
  building.offsetX = building.width;
  building.offsetY = building.height;
  building.lineIds = lineIds;
  building.subLampIds = allSubLampIds;
  building.scenarioIds = scenarioIds;
  building.lastLineId = lastLineId;
  building.lastLampId = lastLampId;
  building.lastScenarioId = lastScenarioId;
  building.lastPsId = lastPsId;
  building.allLampIds = allLampIds;
  building.lampIds = lampIds.map(lampId => makeSingleUid(building.id, lampId));
  building.psIds = psIds;
  building.activeScenarioId = makeSingleUid(building.id, extra.activeScenarioId);
  building.selectedScenarioId = building.activeScenarioId;

  building.scenarioOptions = scenarioIds.map(id => {
      const scenario = scenarios[id];
      return {value: scenario.id, label: scenario.name};
    });

  plan_img.id = buildingId;

  const {defaultX, defaultY, defaultK, defaultRotation} = plan_img;
  building.zoomX = defaultX;
  building.zoomY = defaultY;
  building.zoomK = defaultK;
  building.rotation = defaultRotation;

  const psMacMap = {};

  psIds.forEach(psId => {
    const ps = pses[psId];
    if (ps.mac) {
      psMacMap[ps.mac] = ps.id;
    }
  });

  const lampMacMap = {};
  allLampIds.forEach(lampId => {
    const lamp = lamps[lampId];
    if (lamp.mac) {
      lampMacMap[lamp.mac] = lamp.id;
    }
  });

  dispatch({
    type: SET_PSES,
    pses,
  });

  dispatch({
    type: SET_PS_MAC_MAP,
    psMacMap,
    key: `${buildingId}`,
  });

  dispatch({
    type: SET_LAMP_MAC_MAP,
    lampMacMap,
  });

  dispatch(initExtraVars(extraLampVars));

  dispatch({
    type: SET_LAMPS,
    lamps,
  });

  dispatch({
    type: SET_LINES,
    lines,
  });

  dispatch({
    type: SET_PROFILES,
    obj: profiles,
  });

  dispatch({
    type: SET_GROUPS,
    obj: groups,
  });

  dispatch({
    type: SET_SCENARIOS,
    scenarios,
  });

  dispatch({
    type: SET_PLAN_IMG,
    planImg: plan_img,
    id: building.id,
  });

  dispatch({
    type: SET_BUILDING,
    building,
  });

  return building;
}

const planMiddleware = store => next => action => {
  const {dispatch} = store;

  const executeFuncTechEvents = (func, dataport) => {
    dispatch(func(dataport, SET_VAR_SUC_URL, SET_VAR_SUC_EVENT));
    dispatch(func(dataport, SET_VAR_FAIL_URL, SET_VAR_FAIL_EVENT));
    dispatch(func(dataport, PING_SUC_URL, PING_SUC_EVENT));
    dispatch(func(dataport, PING_FAIL_URL, PING_FAIL_EVENT));
    dispatch(func(dataport, POLL_SUC_URL, POLL_SUC_EVENT));
    dispatch(func(dataport, POLL_FAIL_URL, POLL_FAIL_EVENT));
    dispatch(func(dataport, PP_ADDED_URL, PP_ADDED_EVENT));
    dispatch(func(dataport, AP_ADDED_URL, AP_ADDED_EVENT));
    dispatch(func(dataport, NODE_ADDED_URL, NODE_ADDED_EVENT));
    dispatch(func(dataport, PP_DECOMMISSIONED_URL, PP_DECOMMISSIONED_EVENT));
  };

  if(action.type === GET_CONFIG.SUCCESS) {
    const {configuration, permissions} = action.response;
    const {dataportSlug} = configuration;
    const building = createBuildingConfig(configuration, dispatch);
    dispatch(setSelectedScenario(building.activeScenarioId));

    dispatch(setUserConfigPermissions(configuration.id, permissions));

    if (dataportSlug) {
      dispatch(execPlanSubscribes(dataportSlug, configuration.id, building.activeScenarioId, building.activeScenarioId));
    }

  }else if (action.type === CREATE_BUILDING.SUCCESS) {
    const {configuration, permissions} = action.response;
    createBuildingConfig(configuration, dispatch);
    dispatch(setUserConfigPermissions(configuration.id, permissions));
    action.id = configuration.id;
    next(action);
    const {prevRoute} = store.getState().routingReducer;
    dispatch(push(prevRoute));

  }else if(action.type === AUTO_CALC_HITZONES) {
    const {lampReducer, buildingReducer, lineReducer, lampTypeReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lampIds, lineIds, width: buildingWidth, height: buildingHeight, allLampIds} = buildingReducer[activeBuildingId];
    let copyLampObj = {};
    let filteredLampIds = [].concat(allLampIds);

    const getMidXYbasedOnType = (lampType, lamp) => {
      let {x, y} = lamp;
      if (y === undefined) {
        y = 0;
      }
      const {depictionType, length} = lampType;
      if (depictionType === DEPICTION_RECT) {
        x += length / 2;
      }
      return {x, y};
    };

    const getExtraXYbasedOnType = (lampType) => {
      let x = 0, y = 0;
      const {depictionType, length} = lampType;
      if (depictionType === DEPICTION_RECT) {
        x += length / 2;
      }
      return {x, y};
    };

    copyLampObj = {};
    lampIds.forEach(lampId => {
      const lamp = lampReducer[lampId];
      const lampType = lampTypeReducer[lamp.typeId];
      const {x : extraX, y : extraY} = getExtraXYbasedOnType(lampType);
      const {x, y} = rotateSvg(0, 0, extraX, extraY, lamp.rotation ? lamp.rotation:0);
      copyLampObj[lampId] = {
        midX: lamp.x + x,
        midY: lamp.y + y,
        rotation: lamp.rotation === undefined ? 0:lamp.rotation,
        lampType,
      };

      if (lampType.relationType === LAMP_TYPE_MASTER && lamp.lampIds) {
        lamp.lampIds.forEach(slaveLampId => {
          const slaveLamp = lampReducer[slaveLampId];
          const slaveLampType = lampTypeReducer[slaveLamp.typeId];
          const slaveLampRotation = slaveLamp.rotation === undefined ? 0:slaveLamp.rotation;
          const {x: extraX, y: extraY} = getExtraXYbasedOnType(slaveLampType);
          const {x: midX, y: midY} = rotateSvg(0, 0, extraX, extraY, slaveLampRotation);
          copyLampObj[slaveLampId] = {
            midX: slaveLamp.x + lamp.x + midX,
            midY: slaveLamp.y + lamp.y + midY,
            rotation: slaveLampRotation,
            lampType: slaveLampType,
          };
        });
      }
    });

    lineIds.forEach((lineId) => {
      const line = lineReducer[lineId];
      line.lampIds.forEach((lampId) => {
        const lamp = lampReducer[lampId];
        const lampType = lampTypeReducer[lamp.typeId];
        if (lampType.relationType === LAMP_TYPE_MASTER && lamp.lampIds.length > 0) {
          lamp.lampIds.forEach(slaveLampId => {
            const slaveLamp = lampReducer[slaveLampId];
            const slaveLampType = lampTypeReducer[slaveLamp.typeId];
            const slaveLampRotation = slaveLamp.rotation === undefined ? 0:slaveLamp.rotation;
            const {x: extraX, y: extraY} = getExtraXYbasedOnType(slaveLampType);
            const masterY = lamp.y === undefined ? 0:lamp.y;
            const {x: slaveX, y: slaveY} = rotateSvg(0, 0, slaveLamp.x + lamp.x, slaveLamp.y + masterY, line.rotation);
            const {x: midX, y: midY} = rotateSvg(0, 0, extraX, extraY, slaveLampRotation + line.rotation);
            copyLampObj[slaveLampId] = {
              midX: line.x + slaveX + midX,
              midY: line.y + slaveY + midY,
              rotation: line.rotation + slaveLampRotation,
              lampType: slaveLampType,
            };

          });
        }else {
          const {x: midX, y : midY} = getMidXYbasedOnType(lampType, lamp);
          const {x, y} = rotateSvg(0, 0, midX, midY, line.rotation);
          copyLampObj[lampId] = {
            midX: x + line.x,
            midY: y + line.y,
            rotation: line.rotation,
            lampType,
          };
        }
      });
    });

      const rotateLeftRightTopBot = (rotation, left, right, top, bot) => {
        rotation = rotation % 360;
        if (rotation === 90 || rotation === 270) {
          return {
            left: top,
            top: left,
            right: bot,
            bot: right,
          };
        }
        return {
          left,
          top,
          right,
          bot,
        };
      };

      // const points = [];
      Object.keys(copyLampObj).forEach(key => {
        const {midX, midY, lampType, rotation} = copyLampObj[key];
        // points.push({x: midX, y: midY});
        const {depictionType, radius, length, width} = lampType;
        let left, right, top, bot;
        if (depictionType === DEPICTION_CLOCK) {
          left = -radius;
          right = radius;
          top = -radius;
          bot = radius;
        }else if(depictionType === DEPICTION_RECT) {
          left = -length / 2;
          right = length / 2;
          top = -width / 2;
          bot = width / 2;
        }

        const result = rotateLeftRightTopBot(rotation, left, right, top, bot);
        // points.push({
        //   x: result.left + midX,
        //   y: midY,
        // });
        // points.push({
        //   x: result.right + midX,
        //   y: midY,
        // });
        // points.push({
        //   x: midX,
        //   y: midY + result.bot,
        // });
        // points.push({
        //   x: midX,
        //   y: midY + result.top,
        // });
        copyLampObj[key] = {
          ...copyLampObj[key],
          bot: midY + result.bot,
          top: midY + result.top,
          left: midX + result.left,
          right: midX + result.right,
          exactX: midX,
          exactY: midY,
        };

      });
    // dispatch(setPoints(points));

    filteredLampIds = allLampIds.filter((lampId) => {
      const lamp = lampReducer[lampId];
      const lampType = lampTypeReducer[lamp.typeId];
      if (lampType.relationType === LAMP_TYPE_MASTER && lamp.lampIds.length > 0) {
        return false;
      }
      return true;
    });

    filteredLampIds.filter((lampId) => lampReducer[lampId].selected).forEach((lampId) => {
      const lamp = copyLampObj[lampId];
      let left = Infinity;
      let right = Infinity;
      let top = Infinity;
      let bot = Infinity;

      let leftLamp, rightLamp, topLamp, botLamp;
      filteredLampIds.filter(otherLampId => otherLampId !== lampId).forEach((otherLampId) => {
        const otherLamp = copyLampObj[otherLampId];

        if (otherLamp.left > lamp.right) {
          const dist = Math.abs(otherLamp.left - lamp.right) + Math.abs(otherLamp.exactY - lamp.exactY);
          if (dist < right) {
            right = dist;
            rightLamp = otherLamp;
          }
        }

        if (otherLamp.right < lamp.left) {
          const dist = Math.abs(otherLamp.right - lamp.left) + Math.abs(otherLamp.exactY - lamp.exactY);
          if (dist < left) {
            left = dist;
            leftLamp = otherLamp;
          }
        }

        if (otherLamp.bot < lamp.top) {
          const dist = Math.abs(otherLamp.bot - lamp.top) + Math.abs(otherLamp.exactX - lamp.exactX);
          if (dist < top) {
            top = dist;
            topLamp = otherLamp;
          }
        }

        if (otherLamp.top > lamp.bot) {
          const dist = Math.abs(otherLamp.top - lamp.bot) + Math.abs(otherLamp.exactX - lamp.exactX);
          if (dist < bot) {
            bot = dist;
            botLamp = otherLamp;
          }
        }

      });

      let closestMinX, closestMaxX, closestMinY, closestMaxY;

      if (leftLamp !== undefined) {
        closestMinX = (lamp.left - leftLamp.right) / 2 + (lamp.exactX - lamp.left);
      }else {
        closestMinX = lamp.exactX;
      }

      if (rightLamp !== undefined) {
        closestMaxX = (rightLamp.left - lamp.right) / 2 + (lamp.right - lamp.exactX);
      }else {
        closestMaxX = buildingWidth - lamp.exactX;
      }

      if (topLamp !== undefined) {
        closestMinY = (lamp.top - topLamp.bot) / 2 + (lamp.exactY - lamp.top);
      }else {
        closestMinY = lamp.exactY;
      }

      if (botLamp !== undefined) {
        closestMaxY = (botLamp.top - lamp.bot) / 2 + (lamp.bot - lamp.exactY);
      }else {
        closestMaxY = buildingHeight - lamp.exactY;
      }

      let lampObj = {};
      const totalRotation = lamp.rotation % 360;

      if (totalRotation === 90) {

        lampObj.left = closestMinY;
        lampObj.right = closestMaxY;
        lampObj.top = closestMaxX;
        lampObj.bottom = closestMinX;
      }else if(totalRotation === 180) {

        lampObj.left = closestMaxX;
        lampObj.right = closestMinX;
        lampObj.top = closestMaxY;
        lampObj.bottom = closestMinY;
      }else if(totalRotation === 270) {

        lampObj.left = closestMaxY;
        lampObj.right = closestMinY;
        lampObj.top = closestMinX;
        lampObj.bottom = closestMaxX;
      }else {
        lampObj.left = closestMinX;
        lampObj.right = closestMaxX;
        lampObj.top = closestMinY;
        lampObj.bottom = closestMaxY;
      }
      dispatch(setLampProps(lampId, lampObj));

    });

  }else if(action.type === CALC_ADJACENCY) {
    const {lampReducer, buildingReducer, lineReducer, lampTypeReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lampIds, lineIds, allLampIds} = buildingReducer[activeBuildingId];
    let copyLampObj = {};
    let filteredLampIds = allLampIds.filter(lampId => {
      const lamp = lampReducer[lampId];
      if (lamp.lampIds && lamp.lampIds.length > 0) {
        return false;
      }
      return lamp.left !== undefined;
    });

    const getMidXYbasedOnType = (lampType, lamp) => {
      let {x, y} = lamp;
      if (y === undefined) {
        y = 0;
      }
      const {depictionType, length} = lampType;
      if (depictionType === DEPICTION_RECT) {
        x += length / 2;
      }
      return {x, y};
    };

    const getExtraXYbasedOnType = (lampType) => {
      let x = 0, y = 0;
      const {depictionType, length} = lampType;
      if (depictionType === DEPICTION_RECT) {
        x += length / 2;
      }
      return {x, y};
    };

    copyLampObj = {};
    lampIds.forEach(lampId => {
      const lamp = lampReducer[lampId];
      const lampType = lampTypeReducer[lamp.typeId];
      const {x : extraX, y : extraY} = getExtraXYbasedOnType(lampType);
      const {x, y} = rotateSvg(0, 0, extraX, extraY, lamp.rotation ? lamp.rotation:0);
      const {left, right, top, bottom} = lamp;
      copyLampObj[lampId] = {
        midX: lamp.x + x,
        midY: lamp.y + y,
        rotation: lamp.rotation === undefined ? 0:lamp.rotation,
        lampType,
        left, right, top, bottom,
      };
      if (lampType.relationType === LAMP_TYPE_MASTER && lamp.lampIds) {
        lamp.lampIds.forEach(slaveLampId => {
          const slaveLamp = lampReducer[slaveLampId];
          const slaveLampType = lampTypeReducer[slaveLamp.typeId];
          const slaveLampRotation = slaveLamp.rotation === undefined ? 0:slaveLamp.rotation;
          const {x: extraX, y: extraY} = getExtraXYbasedOnType(slaveLampType);
          const {x: midX, y: midY} = rotateSvg(0, 0, extraX, extraY, slaveLampRotation);
          const {left, right, bottom, top} = slaveLamp;
          copyLampObj[slaveLampId] = {
            midX: slaveLamp.x + lamp.x + midX,
            midY: slaveLamp.y + lamp.y + midY,
            rotation: slaveLampRotation,
            lampType: slaveLampType,
            left, right, bottom, top,
          };
        });
      }
    });

    lineIds.forEach((lineId) => {
      const line = lineReducer[lineId];
      line.lampIds.forEach((lampId) => {
        const lamp = lampReducer[lampId];
        const lampType = lampTypeReducer[lamp.typeId];
        if (lampType.relationType === LAMP_TYPE_MASTER && lamp.lampIds) {
          lamp.lampIds.forEach(slaveLampId => {
            const slaveLamp = lampReducer[slaveLampId];
            const slaveLampType = lampTypeReducer[slaveLamp.typeId];
            const slaveLampRotation = slaveLamp.rotation === undefined ? 0:slaveLamp.rotation;
            const {x: extraX, y: extraY} = getExtraXYbasedOnType(slaveLampType);
            const masterY = lamp.y === undefined ? 0:lamp.y;
            const {x: slaveX, y: slaveY} = rotateSvg(0, 0, slaveLamp.x + lamp.x, slaveLamp.y + masterY, line.rotation);
            const {x: midX, y: midY} = rotateSvg(0, 0, extraX, extraY, slaveLampRotation + line.rotation);
            const {left, right, bottom, top} = slaveLamp;
            copyLampObj[slaveLampId] = {
              midX: line.x + slaveX + midX,
              midY: line.y + slaveY + midY,
              rotation: line.rotation + slaveLampRotation,
              lampType: slaveLampType,
              left, right, bottom, top,
            };

          });
        }else {
          const {x: midX, y : midY} = getMidXYbasedOnType(lampType, lamp);
          const {x, y} = rotateSvg(0, 0, midX, midY, line.rotation);
          const {left, right, bottom, top} = lamp;
          copyLampObj[lampId] = {
            midX: x + line.x,
            midY: y + line.y,
            rotation: line.rotation,
            lampType,
            left, right, bottom, top,
          };
        }
      });
    });

    filteredLampIds.forEach(lampId => {
      const {left, right, top, bottom, midX, midY, rotation} = copyLampObj[lampId];
      let topN = top;
      let leftN = left;
      let rightN = right;
      let bottomN = bottom;
      const moduluRotated = rotation % 360;
      if (moduluRotated === 270) {

        leftN = top;
        topN = right;
        rightN = bottom;
        bottomN = left;
      }else if(moduluRotated === 180) {

        leftN = right;
        topN = bottom;
        rightN = left;
        bottomN = top;
      }else if(moduluRotated === 90) {

        leftN = bottom;
        topN = left;
        rightN = top;
        bottomN = right;
      }
      copyLampObj[lampId] = {
        id: lampId,
        ...copyLampObj[lampId],
        topLeft: {x: midX - leftN, y: midY - topN},
        topRight: {x: midX + rightN, y: midY - topN},
        bottomLeft: {x: midX - leftN, y: midY + bottomN},
        bottomRight: {x: midX + rightN, y: midY + bottomN},
        bots: {},
        lefts: {},
        rights: {},
        tops: {},
      };

    });

    const getOverlap = (min1, max1, min2, max2) => {
      return Math.max(0, Math.min(max1, max2) - Math.max(min1, min2));
    };

    const leeway = 100;
    const minIntersectLen = 120;

    let newLampsProps = [];

    for (let i = 0; i < filteredLampIds.length; i++) {
      const lampId = filteredLampIds[i];
      let lamp = copyLampObj[lampId];

      for (let j = 0; j < filteredLampIds.length; j++) {
        if (i === j) {
          continue;
        }
        const otherLampId = filteredLampIds[j];
        let otherLamp = copyLampObj[otherLampId];

        const {topLeft, topRight, bottomLeft, bottomRight} = lamp;

        // check if they are on the same line
        const underTopLeftX = topLeft.x - leeway;
        const overTopLeftX = topLeft.x + leeway;
        const underTopLeftY = topLeft.y - leeway;
        const overTopLeftY = topLeft.y + leeway;

        const underTopRightX = topRight.x - leeway;
        const overTopRightX = topRight.x + leeway;

        const underBottomLeftY = bottomLeft.y - leeway;
        const overBottomLeftY = bottomLeft.y + leeway;

        const otherTopLeft = otherLamp.topLeft;
        const otherTopRight = otherLamp.topRight;
        const otherBotLeft = otherLamp.bottomLeft;
        const otherBotRight = otherLamp.bottomRight;

        if (otherTopLeft.y > underBottomLeftY && otherTopLeft.y < overBottomLeftY) {
          const overlap = getOverlap(bottomLeft.x, bottomRight.x, otherTopLeft.x, otherTopRight.x);
          if (overlap > minIntersectLen) {
            otherLamp.tops[lamp.id] = true;
            lamp.bots[otherLamp.id] = true;
          }
        }

        if (otherBotLeft.y > underTopLeftY && otherBotLeft.y < overTopLeftY) {
          const overlap = getOverlap(topLeft.x, topRight.x, otherBotLeft.x, otherBotRight.x);
          if (overlap > minIntersectLen) {
            otherLamp.bots[lamp.id] = true;
            lamp.tops[otherLamp.id] = true;
          }
        }

        if (otherBotRight.x > underTopLeftX && otherBotRight.x < overTopLeftX) {
          const overlap = getOverlap(topLeft.y, bottomLeft.y, otherTopRight.y, otherBotRight.y);
          if (overlap > minIntersectLen) {
            otherLamp.rights[lamp.id] = true;
            lamp.lefts[otherLamp.id] = true;
          }
        }

        if (otherBotLeft.x > underTopRightX && otherBotLeft.x < overTopRightX) {
          const overlap = getOverlap(topRight.y, bottomRight.y, otherTopLeft.y, otherBotLeft.y);
          if (overlap > minIntersectLen) {
            otherLamp.lefts[lamp.id] = true;
            lamp.rights[otherLamp.id] = true;
          }
        }

      }

    }

    for (let i = 0; i < filteredLampIds.length; i++) {
      const lampId = filteredLampIds[i];
      const lamp = copyLampObj[lampId];
      const {rotation} = lamp;
      const moduluRotated = rotation % 360;

      const topsI = Object.keys(lamp.tops);
      const leftsI = Object.keys(lamp.lefts);
      const rightsI = Object.keys(lamp.rights);
      const botsI = Object.keys(lamp.bots);

      let tops = topsI;
      let lefts = leftsI;
      let rights = rightsI;
      let bots = botsI;

      if (moduluRotated === 90) {
        tops = rightsI;
        rights = botsI;
        bots = leftsI;
        lefts = topsI;
      }else if(moduluRotated === 180) {
        tops = botsI;
        rights = leftsI;
        bots = topsI;
        lefts = rightsI;
      }else if(moduluRotated === 270) {
        tops = leftsI;
        rights = topsI;
        bots = rightsI;
        lefts = botsI;
      }

      newLampsProps.push({
        id: lamp.id,
        tops: tops,
        lefts: lefts,
        rights: rights,
        bots: bots,
    });
    }

    dispatch(setLampsProps(newLampsProps));
    return next(action);

  }else if(action.type === SAVE_BUILDING_CONFIG) {
    const { buildingReducer, lineReducer, psReducer,
    lampReducer, planImgReducer} = store.getState();
    const {buildingId} = action;

    const sanitizeLamp = lamp => {
      lamp.tops = lamp.tops.map(revertUid);
      lamp.lefts = lamp.lefts.map(revertUid);
      lamp.rights = lamp.rights.map(revertUid);
      lamp.bots = lamp.bots.map(revertUid);

      delete lamp.groupId;
      delete lamp.selected;
      delete lamp.lineId;
      delete lamp.parentId;
    };

    const building = objectAssign({}, buildingReducer[buildingId]);

    const planImg = objectAssign({}, planImgReducer[buildingId]);
    planImg.opacity = parseFloat(planImg.opacity);
    planImg.scale = parseFloat(planImg.scale);
    planImg.rotation = parseFloat(planImg.rotation);
    delete planImg.id;

    const lines = {};
    const pses = {};
    const lamps = {};

    building.lineIds = building.lineIds.map(lineId => {
      const line = objectAssign({}, lineReducer[lineId]);
      line.id = revertUid(line.id);
      delete line.subLampIds;
      delete line.selected;

      const ps = objectAssign({}, psReducer[line.psId]);
      ps.id = revertUid(ps.id);
      delete ps.lineId;
      line.psId = ps.id;
      pses[ps.id] = ps;

      line.lampIds = line.lampIds.map(lampId => {
        const lamp = objectAssign({}, lampReducer[lampId]);
        lamp.id = revertUid(lamp.id);
        if (lamp.lampIds) {
          lamp.lampIds = lamp.lampIds.map(subLampId => {
            const subLamp = objectAssign({}, lampReducer[subLampId]);
            subLamp.id = revertUid(subLampId);
            sanitizeLamp(subLamp);

            lamps[subLamp.id] = subLamp;
            return subLamp.id;
          });
        }
        sanitizeLamp(lamp);

        lamps[lamp.id] = lamp;
        return lamp.id;
      });

      lines[line.id] = line;
      return line.id;
    });

    const lampIds = building.lampIds.map(lampId => {
      const lamp = objectAssign({}, lampReducer[lampId]);
      lamp.id = revertUid(lamp.id);
      sanitizeLamp(lamp);
      lamps[lamp.id] = lamp;

      if (lamp.lampIds) {
        lamp.lampIds = lamp.lampIds.map(subLampId => {
          const subLamp = objectAssign({}, lampReducer[subLampId]);
          subLamp.id = revertUid(subLampId);
          sanitizeLamp(subLamp);

          lamps[subLamp.id] = subLamp;
          return subLamp.id;
        });
      }

      return lamp.id;
    });

    const id = building.id;
    deleteExtraBuildingProps(building);

    const buildingConfig = {
      id,
      building,
      plan_img: planImg,
      lampIds,
      lamps,
      lines,
      pses,
    };

    dispatch(saveBuildingConfigRemote(buildingConfig));

  }else if(action.type === STOP_SELECT_RECT) {
    const { buildingReducer, lineReducer, lampReducer, planReducer, psReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {zoomX, zoomY, zoomK, width: buildingWidth, height: buildingHeight, rotation: buildingRotation,
      lampIds,
    lineIds} = buildingReducer[activeBuildingId];
    const {selectX, selectY, selectHeight, selectWidth} = planReducer;
    const zoomInverseK = 1 / zoomK;
    const mode = planReducer.mode;

    const rotationXY = rotate(buildingWidth / 2, buildingHeight / 2, (selectX - zoomX) * zoomInverseK, (selectY - zoomY) * zoomInverseK, buildingRotation);
    const rotationWH = rotate(buildingWidth / 2, buildingHeight / 2,
      (selectWidth + selectX - zoomX) * zoomInverseK, (selectHeight + selectY - zoomY) * zoomInverseK,
      buildingRotation);

    let minX, maxX, minY, maxY;

    if (rotationXY.x > rotationWH.x) {
      maxX = rotationXY.x;
      minX = rotationWH.x;
    }else {
      maxX = rotationWH.x;
      minX = rotationXY.x;
    }

    if (rotationXY.y > rotationWH.y) {
      maxY = rotationXY.y;
      minY = rotationWH.y;
    }else {
      maxY = rotationWH.y;
      minY = rotationXY.y;
    }
    let selectedIds = [];
    let uniqueLampIds = {};

    if (mode === SELECT_LAMPS) {
      lineIds.forEach((lineId) => {
        const line = lineReducer[lineId];
        const {rotation} = line;
        const lineX = line.x;
        const lineY = line.y;
        line.lampIds.forEach((lampId) => {
          const lamp = lampReducer[lampId];
          let {x, y} = rotateSvg(0, 0, lamp.x, 0, rotation);
          x += lineX;
          y += lineY;

          if (x > minX && x < maxX && y > minY && y < maxY) {
            uniqueLampIds[lampId] = true;
          }
        });

        line.subLampIds.forEach((subLampId) => {
          const subLamp = lampReducer[subLampId];
          const parentLamp = lampReducer[subLamp.parentId];
          let {x, y} = rotateSvg(0, 0, parentLamp.x + subLamp.x, subLamp.y, rotation);
          x += lineX;
          y += lineY;

          if (x > minX && x < maxX && y > minY && y < maxY) {
            selectedIds.push(subLampId);
            uniqueLampIds[subLamp.parentId] = true;
          }
        });

      });

      lampIds.forEach(lampId => {
        const {x, y, lampIds} = lampReducer[lampId];
        if (x > minX && x < maxX && y > minY && y < maxY) {
          selectedIds.push(lampId);
        }
        if (lampIds) {
          lampIds.forEach(subLampId => {
            const subLamp = lampReducer[subLampId];
            let {x : subX, y : subY} = subLamp;
            subX += x;
            subY += y;
            if (subX > minX && subX < maxX && subY > minY && subY < maxY) {
              selectedIds.push(subLampId);
              uniqueLampIds[lampId] = true;
            }
          });
        }
      });

      selectedIds = selectedIds.concat(Object.keys(uniqueLampIds));

      dispatch(changeSelectLamps(selectedIds, true));

    }else {
      lineIds.forEach((lineId) => {
        const line = lineReducer[lineId];
        const {rotation} = line;
        const lineX = line.x;
        const lineY = line.y;
        const ps = psReducer[line.psId];
        let {x, y} = rotateSvg(0, 0, ps.x, 0, rotation);
        x += lineX;
        y += lineY;

        if (x > minX && x < maxX && y > minY && y < maxY) {
          selectedIds.push(line.psId);
        }

      });

      dispatch(changeSelectPses(selectedIds, true));

    }

    return next(action);
  }else if(action.type === SHOW_LAMP_NODES_SELECTION_DIALOG) {
    const { buildingReducer, lampReducer, objectReducer, nodeReducer, variableReducer, uniqueObjectsReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {allLampIds} = buildingReducer[activeBuildingId];

    const selectedMacs = [];
    let objectNames = [];
    let objects = {};
    let variables = {};
    allLampIds.forEach(lampId => {
      const lamp = lampReducer[lampId];
      if (lamp.selected) {
        if (lamp.mac) {
          selectedMacs.push(lamp.mac);
          const node = nodeReducer[lamp.mac];
          createSelectedVariables(node, objectReducer, variableReducer, uniqueObjectsReducer, objectNames, objects, variables);
        }
      }
    });

    dispatch(setSelectedObjects(objectNames, objects));
    dispatch(setSelectedVars(variables));
    action.selectedLampMacs = selectedMacs;

    return next(action);
  }else if(action.type === SET_SHOW_PS_SELECTION_DIALOG && action.show === true) {
    const { buildingReducer, lineReducer, psReducer, objectReducer, nodeReducer, variableReducer, uniqueObjectsReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lineIds} = buildingReducer[activeBuildingId];

    let selectedMacs = [];
    let objectNames = [];
    let objects = {};
    let variables = {};
    lineIds.forEach((lineId) => {
      const line = lineReducer[lineId];
      const ps = psReducer[line.psId];
      if (ps.selected === true) {
        selectedMacs.push(ps.mac);
        const node = nodeReducer[ps.mac];
        createSelectedVariables(node, objectReducer, variableReducer, uniqueObjectsReducer, objectNames, objects, variables);
      }
    });

    dispatch(setSelectedObjects(objectNames, objects));
    dispatch(setSelectedVars(variables));
    dispatch(setSelectedPsMacs(selectedMacs));

    return next(action);

  }else if(action.type === SET_SHOW_PS_NODES_SELECTION_DIALOG && action.show === true) {
    const { buildingReducer, objectReducer, nodeReducer, variableReducer, apReducer, uniqueObjectsReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {dataportSlug} = buildingReducer[activeBuildingId];

    let selectedMacs = [];
    let objectNames = [];
    let objects = {};
    let variables = {};
    apReducer[`${dataportSlug}/ids`].forEach((id) => {
      const node = nodeReducer[id];
      if (node && node.selected === true) {
        selectedMacs.push(id);
        createSelectedVariables(node, objectReducer, variableReducer, uniqueObjectsReducer, objectNames, objects, variables);
      }
    });

    dispatch(setSelectedObjects(objectNames, objects));
    dispatch(setSelectedVars(variables));
    dispatch(setSelectedPsMacs(selectedMacs));

    return next(action);

    }else if(action.type === SHOW_LAMP_PPS_SELECTION_DIALOG) {
      const { buildingReducer, objectReducer, nodeReducer, variableReducer, ppReducer, uniqueObjectsReducer} = store.getState();
      const {activeBuildingId} = buildingReducer;
      const {dataportSlug} = buildingReducer[activeBuildingId];

      let objectNames = [];
      let objects = {};
      let variables = {};
      const lampMacs = {};
      action.selectedPpIds = ppReducer[`${dataportSlug}/ids`].filter((id) => {
        const {ipui, selected} = ppReducer[id];
        const node = nodeReducer[ipui];
        if (selected === true && node && lampMacs[ipui] === undefined) {
          createSelectedVariables(node, objectReducer, variableReducer, uniqueObjectsReducer, objectNames, objects, variables);
          lampMacs[ipui] = true;
        }
        return selected;
      });

      action.lampMacs = Object.keys(lampMacs);

      dispatch(setSelectedObjects(objectNames, objects));
      dispatch(setSelectedVars(variables));

      return next(action);

  }else if(action.type === CHANGE_SELECT_ALL) {
    const mode = store.getState().planReducer.mode;
    const {selected} = action;
    if (mode === SELECT_PSES) {
      dispatch(changeSelectAllPses(selected));
    }else {
      dispatch(changeSelectAllLamps(selected));
    }

  }else if(action.type === EXEC_PLAN_SUBSCRIBES) {
    const {routingReducer, authReducer, clientReducer} = store.getState();
    const {currentRoute} = routingReducer;
    const {buildingId, dataportSlug, activeScenarioId, selectedScenarioId} = action;
    const {activeClientKey} = clientReducer;
    const {permissions: clientPermissions} = clientReducer[activeClientKey];
    const permissions = authReducer[buildingId];
    const [prefix] = /^\/[^/]+\/[^/]+/.exec(currentRoute);
    if (!isRoute(currentRoute, ROUTE_LIVE) && !hasRoutePermission(prefix, permissions) && !hasRoutePermission(prefix, clientPermissions)) {
      dispatch(setBuildingRoute('/plan/live/'));
      return;
    }

    if (isRoute(currentRoute, ROUTE_LIVE)) {
      const {liveMode} = store.getState().liveReducer;
      dispatch(setLampGroupMapByScenario(activeScenarioId));
      dispatch(getGroupsProfileState(buildingId));
      if (dataportSlug) {
        dispatch(subscribeMovement(dataportSlug, buildingId));
        dispatch(subscribeGroupUpdates(dataportSlug, buildingId));
      }

      dispatch(setLiveMode(liveMode));
    }else if(isRoute(currentRoute, ROUTE_TECHNICAL)) {
      dispatch(setMode(ZOOM));
      if (dataportSlug) {
        executeFuncTechEvents(subscribeDataportEvent, dataportSlug);
        if (fetched[dataportSlug] === undefined) {
          dispatch(getScheduledUpgrade());
          dispatch(getUpgradeStatus());
          fetched[dataportSlug] = true;
          dispatch(getAps(dataportSlug));
          dispatch(getNodes(dataportSlug));
        }else {
          dispatch(recalculateFilters());
        }
      }
    }else if(isRoute(currentRoute, ROUTE_CONFIG)) {
      dispatch(setLampGroupMapByScenario(selectedScenarioId));
    }

    return next(action);
  }else if(action.type === EXEC_PLAN_UNSUBSCRIBES) {

    const {currentRoute} = store.getState().routingReducer;

    const {dataportSlug, buildingId} = action;
    if (isRoute(currentRoute, ROUTE_LIVE)) {
      if (dataportSlug) {
        dispatch(unsubscribeMovement(dataportSlug, buildingId));
        dispatch(unsubscribeGroupUpdates(dataportSlug, buildingId));
      }

      dispatch(clearLiveProps());
    }else if(isRoute(currentRoute, ROUTE_TECHNICAL)) {
      if (dataportSlug) {
        executeFuncTechEvents(unsubscribeDataportEvent, dataportSlug);
      }
    }

    return next(action);

  }else if(action.type === SET_PLAN_SVG_EL) {
    svgPt = action.svgPt;
    svgEl = action.svgEl;

    next(action);

    setTimeout(() => {
      dispatch(calcPlanSizeProps());
    }, 300);

  }else if(action.type === CALC_PLAN_SIZE_PROPS) {
    if (svgEl === undefined) {
      return;
    }
    const clientRect = svgEl.getBoundingClientRect();
    const planHeight = (window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight) - clientRect.top;
    const planWidth = clientRect.width;

    dispatch(setPlanWidthHeight(planWidth, planHeight));

    setTimeout(() => {
      const clientRect = svgEl.getBoundingClientRect();
      svgPt.x = clientRect.left;
      svgPt.y = clientRect.top;
      const leftTopCorner =  svgPt.matrixTransform(svgEl.getScreenCTM().inverse());
      const x = leftTopCorner.x;
      const y = leftTopCorner.y;

      svgPt.x = clientRect.right;
      svgPt.y = clientRect.height + clientRect.top;
      const rightBottomCorner = svgPt.matrixTransform(svgEl.getScreenCTM().inverse());
      let width = rightBottomCorner.x;
      if (x < 0) {
        width -= leftTopCorner.x;
      }
      const height = rightBottomCorner.y;

      dispatch(setZoomRect(x, y, width, height));

      svgPt.x = 1;
      svgPt.y = 1;
      const matrix =  svgPt.matrixTransform(svgEl.getScreenCTM().inverse());
      const x1 = matrix.x;
      const y1 = matrix.y;
      svgPt.x = 0;
      svgPt.y = 0;
      const matrix2 =  svgPt.matrixTransform(svgEl.getScreenCTM().inverse());

      const x2 = matrix2.x;
      const y2 = matrix2.y;

      dispatch(setCoordMap(x1 - x2, y1 - y2, matrix2.x, matrix2.y));
    });

  }else {
    return next(action);
  }

};

export default planMiddleware;



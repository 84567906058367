import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {connect} from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import {roundDateToRetention} from '../utils/dateHelper';

const StartDateEndDateInterval = ({
  setStartDate, setEndDate,
  startDate, endDate,
  retentionObject,
  staff,
  translate,
}) => {

  const {periods, minDate}= retentionObject;

  const roundToRetentionPeriod = (theDate, action) => {
    const newDate = roundDateToRetention(theDate, periods);
    if (newDate) {
      action(newDate);
    }
  };

  const endDateBeforeStartDate = endDate <= startDate;

  let endDateBeforeStartDateErrEl = null;
  if (endDateBeforeStartDate) {
    endDateBeforeStartDateErrEl = (
      <p className="err-info">
        {translate('global_end_date_after_start_date')}.
      </p>
    );
  }
  const now = new Date();

  return (
    <div>
      <div className="date-block">
        <label htmlFor="start-date">{translate('global_start_date')}:</label>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          maxDate={now}
          minDate={staff === true ? undefined:minDate}
          showTimeSelect
          showTimeInput
          timeInputLabel="Time: "
          dateFormat="dd/MM/yyyy HH:mm"
          timeFormat="HH:mm"
          name="start-date"
          onClickOutside={() => roundToRetentionPeriod(startDate, setStartDate)}
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
              boundariesElement: 'viewport'
            }
          }}
        />
      </div>
      <div className="date-block">
        <label htmlFor="end-date">{translate('global_end_date')}:</label>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          maxDate={now}
          minDate={staff === true ? undefined:minDate}
          showTimeSelect
          showTimeInput
          timeInputLabel="Time: "
          dateFormat="dd/MM/yyyy HH:mm"
          timeFormat="HH:mm"
          name="end-date"
          onClickOutside={() => roundToRetentionPeriod(endDate, setEndDate)}
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
              boundariesElement: 'viewport'
            }
          }}
        />
      </div>
      {endDateBeforeStartDateErrEl}
    </div>
  );
};

StartDateEndDateInterval.propTypes = {
  translate: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  retentionObject: PropTypes.object,
  staff: PropTypes.bool,
};

function mapStateToProps({localeReducer, authReducer}) {
  const {staff} = authReducer;
  return {
    translate: getTranslate(localeReducer),
    staff,
  };
}

export default connect(
  mapStateToProps,
  null,
)(StartDateEndDateInterval);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import '../styles/live-legend.scss';
import { getTranslate } from 'react-localize-redux';

const LiveMovementLegend = ({translate}) => {
  return (
    <div className="live-legend">
      <p>
        <span className="block on" /> {translate('global_live_on')}
      </p>
      <p>
        <span className="block stby" /> {translate('global_live_standby')}
      </p>
      <p>
        <span className="block off" /> {translate('global_live_off')}
      </p>
    </div>
  );
};

LiveMovementLegend.propTypes = {
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const translate = getTranslate(state.localeReducer);
  return {
    translate
  };
}

export default connect(
  mapStateToProps,
  null,
)(LiveMovementLegend);

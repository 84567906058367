import {
  GET_REPORT_HISTORIES,
  DOWNLOAD_REPORT_HISTORY,
} from '../constants/reportHistoryActionTypes';
import {TRIVIEW_API} from '../constants/urls';
import {API, GET} from '../constants/customTypes';

export function getReportHistories(clientId) {
  return {
    type: API,
    auth: true,
    payload: {
      method: GET,
      url: TRIVIEW_API.REPORT_HISTORY.GET_CLIENT.replace(':clientId', clientId),
      success: GET_REPORT_HISTORIES.SUCCESS,
      error: GET_REPORT_HISTORIES.ERROR,
      start: GET_REPORT_HISTORIES.START,
    },
    props: {
      clientId,
    },
  };
}

export function downloadReportHistory(clientId, reportHistoryId, filename) {
  return {
    type: API,
    auth: true,
    download: true,
    filename,
    payload: {
      method: GET,
      url: TRIVIEW_API.REPORT_HISTORY.DOWNLOAD.replace(':reportHistoryId', reportHistoryId).replace(':clientId', clientId),
      success: DOWNLOAD_REPORT_HISTORY.SUCCESS,
      error: DOWNLOAD_REPORT_HISTORY.ERROR,
      start: DOWNLOAD_REPORT_HISTORY.START,
    },
  };
}

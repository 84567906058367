import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../components/DialogWrapper';

const ConfirmDialog = ({show, confirmAction, closeAction, text, title}) => {
  if (show === false) {
    return null;
  }

  const body = (
    <div className="row">
      <div className="col-md-12">
        <p>{text}</p>
      </div>
    </div>
  );

  const footer = (
    <span className="btn btn-primary" onClick={() => confirmAction()}>Confirm</span>
  );

  return (
    <span className="cfm-dialog">
      <Dialog title={title} visible={show} body={body} closeLabel="Cancel"
        closeAction={() => closeAction()} footer={footer} />
    </span>
  );
};

ConfirmDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  confirmAction: PropTypes.func.isRequired,
  closeAction: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ConfirmDialog;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/buildingActions';
import BuildingItem from '../containers/BuildingItem';
import ConfirmDialog from '../containers/ConfirmDialog';

const BuildingsOverview = ({buildingIds, deleteBuildingId, actions, clientId}) => {
  const {setBuildingReducerProps, deleteBuilding} = actions;
  if (!buildingIds) {
    return <p>Loading...</p>;
  }

  return (
    <div className="buildings-overview">
      <ConfirmDialog show={deleteBuildingId !== undefined ? true:false} closeAction={() => setBuildingReducerProps({deleteBuildingId: undefined})}
        confirmAction={() => deleteBuilding(clientId, deleteBuildingId)} text="Are you sure you want to delete this building? The whole configuration will be deleted aswell!"
        title="Delete building" />
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {buildingIds.map(buildingId => {
            return <BuildingItem key={buildingId} id={buildingId} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

BuildingsOverview.propTypes = {
  buildingIds: PropTypes.array,
  deleteBuildingId: PropTypes.number,
  actions: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const {deleteBuildingId} = state.buildingReducer;
  return {
    deleteBuildingId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildingsOverview);

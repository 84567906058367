import moment from 'moment';
import {SET_FIRMWARE_VERSIONS, SET_SHOW_UPGRADE_DIALOG, GET_FIRMWARE_FILES,
  SET_FIRMWARE_REDUCER_PROPS, GET_UPGRADE_STATUS, SYNC_FIRMWARE_PS, SYNC_FIRMWARE_DRIVER,
  GET_SCHEDULED_UPGRADE, CANCEL_SCHEDULED_UPGRADE, GET_NODES_UPGRADE_SYNCED,
  RE_SYNC_NODES_UPGRADE_API, UPGRADE_SELECTION,
SET_FIRMWARE_SW_VERSION_FILE} from '../constants/firmwareActionTypes';
import {DONE, ERROR, STARTED, LAMP, UPGRADER_STATUS_CLEARED, DEFAULT_DATE_FORMAT, INITIAL} from '../constants/customTypes';
import objectAssign from 'object-assign';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {
  nodeTypes: [],
  showFirmwareDialog: false,
  psFiles: [],
  lampFiles: [],
  mode: LAMP,
  status: UPGRADER_STATUS_CLEARED,
  stop: false,
  upgraded: 0,
  failed: 0,
  todo: 0,
  startDate: '',
  stopDate: '',
  schedule: false,
  apOnOff: false,
  upgradeIsScheduled: false,
  scheduledStartDate: '',
  scheduledStopDate: '',
  getSyncedStatus: INITIAL,
  reSyncStatus: INITIAL,
  pollAndSync: true,
  updateIds: [],
};

export default function firmwareReducer(state = initialState, action) {

  switch (action.type) {
    case SET_FIRMWARE_VERSIONS:{
      const {nodeTypes, nodeTypeObj, versionObj} = action;
      let newState = objectAssign({}, state, {nodeTypes}, versionObj);
      nodeTypes.forEach((nodeType) => {
        newState[nodeType] = nodeTypeObj[nodeType];
      });

      return newState;
    }
    case SET_SHOW_UPGRADE_DIALOG:{
      const {show, mode} = action;
      return objectAssign({}, state, {showFirmwareDialog: show, mode});
    }
    case GET_FIRMWARE_FILES.SUCCESS:{
      const {response} = action;
      let lampFiles = [];
      let psFiles = [];
      response.forEach((file) => {
        const last3 = file.substr(-3);
        if (last3 === 'fws') {
          lampFiles.push(file);
        }else if(last3 === 'bin') {
          psFiles.push(file);
        }
      });
      return objectAssign({}, state, {psFiles, lampFiles});
    }
    case SET_FIRMWARE_SW_VERSION_FILE:{
      const {swVersion, nodeType, file} = action;
      let newState = objectAssign({}, state);
      const key = `${nodeType}/${swVersion}`;
      newState[key] = file;
      return newState;
    }
    case SET_FIRMWARE_REDUCER_PROPS:{
      return objectAssign({}, state, action.obj);
    }
    case GET_UPGRADE_STATUS.SUCCESS:{
      const {status, stop, upgraded, failed, todo, mode} = action.response;
      return objectAssign({}, state, {status, stop, upgraded, failed, todo, mode});
    }
    case SYNC_FIRMWARE_PS.START:{
      return objectAssign({}, state, {syncFirmwarePsStatus: STARTED});
    }
    case SYNC_FIRMWARE_PS.ERROR:{
      return objectAssign({}, state, {syncFirmwarePsStatus: ERROR});
    }
    case SYNC_FIRMWARE_PS.SUCCESS:{
      return objectAssign({}, state, {syncFirmwarePsStatus: DONE});
    }
    case SYNC_FIRMWARE_DRIVER.START:{
      return objectAssign({}, state, {syncFirmwareDriverStatus: STARTED});
    }
    case SYNC_FIRMWARE_DRIVER.ERROR:{
      return objectAssign({}, state, {syncFirmwareDriverStatus: ERROR});
    }
    case SYNC_FIRMWARE_DRIVER.SUCCESS:{
      return objectAssign({}, state, {syncFirmwareDriverStatus: DONE});
    }
    case GET_SCHEDULED_UPGRADE.SUCCESS:{
      const {scheduled, startDate, stopDate} = action.response;
      if (scheduled === true) {
        return objectAssign({}, state, {
          upgradeIsScheduled: true,
          scheduledStartDate: moment(startDate).format(DEFAULT_DATE_FORMAT),
          scheduledStopDate: moment(stopDate).format(DEFAULT_DATE_FORMAT),
        });
      }else {
        return objectAssign({}, state, {upgradeIsScheduled: false});
      }
    }
    case CANCEL_SCHEDULED_UPGRADE.SUCCESS:{
      return objectAssign({}, state, {upgradeIsScheduled: false});
    }
    case GET_NODES_UPGRADE_SYNCED.START:{
      return objectAssign({}, state, {getSyncedStatus: STARTED});
    }
    case GET_NODES_UPGRADE_SYNCED.ERROR:{
      return objectAssign({}, state, {getSyncedStatus: ERROR});
    }
    case GET_NODES_UPGRADE_SYNCED.SUCCESS:{
      return objectAssign({}, state, {getSyncedStatus: DONE});
    }
    case RE_SYNC_NODES_UPGRADE_API.START:{
      return objectAssign({}, state, {reSyncStatus: STARTED});
    }
    case RE_SYNC_NODES_UPGRADE_API.ERROR:{
      return objectAssign({}, state, {reSyncStatus: ERROR});
    }
    case RE_SYNC_NODES_UPGRADE_API.SUCCESS:{
      return objectAssign({}, state, {reSyncStatus: DONE});
    }
    case UPGRADE_SELECTION:{
      const {updateIds} = action;
      return objectAssign({}, state, {updateIds});
    }


    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import PlanPageWrapper from './PlanPageWrapper';
import Plan from './Plan';
import MovementBar from './MovementBar';

const PlanConfigurationPage = ({planHeight}) => {
  return (
    <>
      <div className="plan-left">
        <Plan className="border-right" showLegend
          interactive={false}
        />
      </div>
      <div className="plan-right-wrapper">
        <div className="plan-right" style={{height: planHeight}}>
          <MovementBar />
        </div>
      </div>
    </>
  );
};

PlanConfigurationPage.propTypes = {
  planHeight: PropTypes.number.isRequired,
};

export default PlanPageWrapper(PlanConfigurationPage, 'page_title_plan_movement');

import {GET_BUILDINGS, SET_SHOW_BUILDINGS_LIST,
  SET_BUILDING_PROPS, SET_BUILDING, SET_BUILDING_ROTATION,
  SAVE_BUILDING_CONFIG_REMOTE,
  SET_BUILDING_REDUCER_PROPS, DELETE_BUILDING, UPDATE_BUILDING,
  SET_BUILDING_ROUTE_SLUG,
SET_ACTIVE_BUILDING} from '../constants/buildingActionTypes';
import objectAssign from 'object-assign';
import {removeDuplicatesArray} from '../utils/helper';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {
  buildingIds: [],
  activeBuildingId: undefined,
  showBuildingsList: false,
  configSaved: false,
  deleteBuildingId: undefined,
};

export default function buildingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BUILDINGS.SUCCESS:{
      const newState = objectAssign({}, state);
      action.response.forEach(({building, id, dataport_id}) => {
        newState[id] = objectAssign({}, state[id], building, {id, dataportId: dataport_id});
      });
      return newState;
    }
    case SET_BUILDING:{
      const {building} = action;
      const newState = objectAssign({}, state);
      newState[building.id] = objectAssign({}, state[building.id], building);
      newState.buildingIds = removeDuplicatesArray(state.buildingIds.concat([building.id]));
      return newState;
    }
    case SET_ACTIVE_BUILDING:
      return objectAssign({}, state, {activeBuildingId: action.id});
    case SET_SHOW_BUILDINGS_LIST:{
      const {show} = action;
      return objectAssign({}, state, {showBuildingsList: show});
    }
    case SET_BUILDING_ROUTE_SLUG:
      return objectAssign({}, state, {routeSlug: action.slug});
    case SET_BUILDING_PROPS:{
      const {id, obj} = action;
      let newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[id], obj);
      return newState;
    }
    case SET_BUILDING_ROTATION:{
      let newState = objectAssign({}, state);
      const {id, rotation} = action;
      newState[id] = objectAssign({}, state[id], {rotation});
      return newState;
    }
    case SAVE_BUILDING_CONFIG_REMOTE.START:{
      return objectAssign({}, state, {configSaved: false});
    }
    case SAVE_BUILDING_CONFIG_REMOTE.SUCCESS:{
      // const {id} = action.props;
      const newState = objectAssign({}, state);
      newState.configSaved = true;
      return newState;
    }
    case SAVE_BUILDING_CONFIG_REMOTE.ERROR:{
      return state;
    }
    case SET_BUILDING_REDUCER_PROPS:{
      const {obj} = action;
      return objectAssign({}, state, obj);
    }
    case DELETE_BUILDING.SUCCESS:{
      const {dataport} = action;
      const {buildingId} = action.props;
      const newState = objectAssign({}, state, {deleteBuildingId: undefined});
      const buildingIds = [].concat(state[dataport]);
      if (state.activeBuildingId === buildingId) {
        newState.activeBuildingId = undefined;
      }
      delete newState[buildingId];
      const index = buildingIds.indexOf(buildingId);
      if (index > -1) {
        buildingIds.splice(index, 1);
      }
      newState[dataport] = buildingIds;
      return newState;
    }
    case UPDATE_BUILDING.SUCCESS:{
      const {building} = action.props;
      const newState = objectAssign({}, state);
      newState[building.id] = objectAssign({}, state[building.id], building);
      return newState;
    }

    default:
      return state;
  }
}

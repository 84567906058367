import {SERIAL_RPC} from '../constants/customTypes';

export function serialRpcAction(url, keys, keyName, replaceSite, resultAction) {
  return {
    type: SERIAL_RPC,
    url,
    keys,
    keyName,
    replaceSite,
    resultAction,
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions/clientActions';
import '../../styles/selected-client.scss';
import { getTranslate } from 'react-localize-redux';

const SelectedClient = ({activeClientName, actions, translate}) => {
  const {setShowSelectClientDialog} = actions;
  return (
    <div className="selected-client">
      <div className="pointer header" onClick={() => setShowSelectClientDialog(true)}>
        {activeClientName ? activeClientName:translate('client_select_client')}{' '}
        <i className="fa fa-caret-square-o-down" aria-hidden="true" />
      </div>
    </div>
  );
};

SelectedClient.propTypes = {
  activeClientName: PropTypes.string,
  actions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  let activeClientName;
  const {activeClientKey} = state.clientReducer;
  const client = state.clientReducer[activeClientKey];
  if (client) {
    activeClientName = client.name;
  }
  const translate = getTranslate(state.localeReducer);
  return {
    activeClientName,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectedClient);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {translateSoftwareVersion} from '../utils/versionHelper';
import { getTranslate } from 'react-localize-redux';

const SoftwareVersion = ({softwareVersion, translate}) => {
  if (softwareVersion !== undefined) {
    return (
      <span>
        {translateSoftwareVersion(softwareVersion)}
      </span>
    );
  }else {
    return <span>{translate('technical_undefined')}</span>;
  }
};

SoftwareVersion.propTypes = {
  softwareVersion: PropTypes.string,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const translate = getTranslate(state.localeReducer);
  return {
    translate
  };
}

export default connect(
  mapStateToProps,
  null,
)(SoftwareVersion);

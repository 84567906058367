import {
  GET_CONFIG_ROLES,
  GET_CLIENT_ROLES,
} from '../constants/roleActionTypes';
import {API, GET} from '../constants/customTypes';
import {TRIVIEW_API} from '../constants/urls';

export function getConfigRoles() {
  return {
    type: API,
    auth: true,
    idProp: 'id',
    payload: {
      method: GET,
      url: TRIVIEW_API.CONFIG_ROLES.GET,
      success: GET_CONFIG_ROLES.SUCCESS,
      start: GET_CONFIG_ROLES.START,
      error: GET_CONFIG_ROLES.ERROR,
    }
  };
}

export function getClientRoles() {
  return {
    type: API,
    auth: true,
    idProp: 'id',
    payload: {
      method: GET,
      url: TRIVIEW_API.CLIENT_ROLES.GET,
      success: GET_CLIENT_ROLES.SUCCESS,
      start: GET_CLIENT_ROLES.START,
      error: GET_CLIENT_ROLES.ERROR,
    }
  };
}

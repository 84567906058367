import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PlanPageWrapper from './PlanPageWrapper';
import * as actions from '../actions/reportsActions';
import FormSelect from '../components/FormSelect';
import {useImmer} from 'use-immer';
import ActionButton from '../components/ActionButton';
import ReportConfig from './ReportConfig';

// const defaultReport = {
// };

const PlanReportPage = ({clientId, report : oldReport, reportId, actions, changeReportStatus, buildingIds}) => {
  const {createReport, updateReport, getReport} = actions;

  const [report, setReport] = useImmer();
  const [validationErrors, setValidationErrors] = useState({
    emailErr: false,
    buildingErr: false,
  });

  const {emailErr, buildingErr} = validationErrors;

  useEffect(() => {
    if (oldReport && oldReport.fetched) {
      setReport(() => oldReport);
    }else if(reportId !== undefined) {
      getReport(clientId, reportId);
    }else {
      setReport(() => ({
        language: 'en',
        frequency: 'MONTHLY',
        email_to: [],
        add_summary: true,
        client_id: clientId,
        reportConfigurations: {},
      }));
    }
  }, [oldReport]);

  if (!report) {
    return null;
  }

  const {language, frequency, email_to, reportConfigurations} = report;

  const shouldEnableDefaultReport = Object.keys(reportConfigurations).length === 0;

  const saveReport = (e) => {
    e.preventDefault();
    if (email_to.length === 0) {
      setValidationErrors({...validationErrors, emailErr: true});
      return;
    }
    if (Object.keys(reportConfigurations).filter(key => reportConfigurations[key].enabled).length === 0) {
      setValidationErrors({...validationErrors, buildingErr: true});
    }else {
      report.id ? updateReport(report):createReport(report);
      setValidationErrors({
        buildingErr: false,
        emailErr: false,
      });
    }
  };

  const addEmail = () => setReport((draft) => {
    draft.email_to.push('');
  });

  const deleteEmail = (i) => setReport((draft) => {
    draft.email_to.splice(i, 1);
  });
  const changeEmail = (i, value) => setReport((draft) => {
    draft.email_to[i] = value;
  });

  const changeReportConfiguration = (buildingId, changes) => setReport((draft) => {
    draft.reportConfigurations[buildingId] = {
      ...draft.reportConfigurations[buildingId],
      ...changes,
    };
  });

  const frequencyOptions = [
    {value: 'MONTHLY', label: 'Monthly'},
    // {value: 'WEEKLY', label: 'Weekly'},
  ];

  let validationErrEl = null;
  if (buildingErr) {
    validationErrEl = (
      <p className="err-info">Atleast one building should be enabled!</p>
    );
  }

  let emailErrEl = null;
  if (emailErr) {
    emailErrEl = (
      <p className="err-info">A report should have atleast one email!</p>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12" style={{marginTop: '10px'}}>
          <form onSubmit={saveReport}>
          <FormSelect label={'Language'} value={language} id="lang"
            onChange={(value) => setReport(() => ({...report, language: value}))}
            options={[{value: 'en', label: 'English'}]} />

          <FormSelect label={'Frequency'} value={frequency} id="frequency"
            onChange={(value) => setReport(() => ({...report, frequency: value}))}
            options={frequencyOptions} />

          <h4>Emails:</h4>
          <table style={{marginBottom: '10px'}} className="table">
            <tbody>
              {email_to.map((email, i) => (
                <tr key={i}>
                  <td><input required type="email" id={`email${i}`} name={`email${i}`} className="form-control" value={email} onChange={(event) => changeEmail(i, event.target.value)} /></td>
                  <td><ActionButton action={() => deleteEmail(i)} icon="fa-remove" btnClass="btn-danger" /></td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <ActionButton action={() => addEmail()} text={'Add email address'} style={{marginBottom: '10px'}} />
          </div>

          <h4>Buildings</h4>
          <table style={{marginBottom: '10px'}} className="table">
            <thead>
              <tr>
                <th/>
                <th>Movement</th>
                <th>Energy</th>
              </tr>
            </thead>
            <tbody>
              {buildingIds.map(buildingId => (
                <ReportConfig key={buildingId}
                  buildingId={buildingId}
                  reportConfiguration={reportConfigurations[buildingId]}
                  shouldEnable={shouldEnableDefaultReport}
                  onChange={(change) => changeReportConfiguration(buildingId, change)}
                />
              ))}
            </tbody>
          </table>

          {validationErrEl}
          {emailErrEl}
          <div>
            <ActionButton isSubmit={true} status={changeReportStatus} text={report.id ? 'Save':'Create'} style={{marginTop: '20px'}}
              action={() => saveReport()} />
          </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const ConnectedPlanReportPage = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PlanReportPage);

PlanReportPage.propTypes = {
  report: PropTypes.object,
  reportId: PropTypes.string,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  changeReportStatus: PropTypes.string.isRequired,
  buildingIds: PropTypes.array.isRequired,
};

function mapStateToProps({clientReducer, reportsReducer}, {match}) {
  const {reportId} = match.params;
  const {activeClientKey} = clientReducer;
  const {buildingIds} = clientReducer[activeClientKey];
  const {changeReportStatus} = reportsReducer;
  return {
    report: reportsReducer[reportId],
    clientId: activeClientKey,
    reportId,
    changeReportStatus,
    buildingIds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default PlanPageWrapper(ConnectedPlanReportPage, 'page_title_plan_reports');

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/planActions';
import * as buildingActions from '../actions/buildingActions';
import {DraggableCore} from 'react-draggable';
import {SELECT_PSES, SELECT_LAMPS} from '../constants/customTypes';

const PlanDrag = ({actions, x, y, width, height, xMap, yMap, mode,
  selectWidth, selectHeight, selectX, selectY, pt, svgNode, buildingActions}) => {
  if (mode !== SELECT_PSES && mode !== SELECT_LAMPS) {
    return null;
  }
  const {zoomZoom} = buildingActions;

  const zoom = (event) => {
    event.preventDefault();

    const eventDelta = -event.deltaY * (event.deltaMode ? 120:1) / 500;
    zoomZoom(event.pageX, event.pageY, eventDelta);

  };
  const {initializeSelectRect, stopSelectRect, dragSelectRect} = actions;
  const onStart = (event, data) => {
    pt.x = data.x;
    pt.y = data.y;
    const transform = pt.matrixTransform(svgNode.getScreenCTM().inverse());
    initializeSelectRect(transform.x, transform.y);
  };

  let rectX = selectX;
  let rectY = selectY;
  let rectWidth = selectWidth;
  let rectHeight = selectHeight;
  // rect cant have negative width or height
  if (selectWidth < 0) {
    rectX += selectWidth;
    rectWidth = -selectWidth;
  }

  if (selectHeight < 0) {
    rectY += selectHeight;
    rectHeight = -selectHeight;
  }

  return (
    <g>
      <DraggableCore onDrag={(event, data) => {
        dragSelectRect(selectWidth + (xMap * data.deltaX), selectHeight + (yMap * data.deltaY));
      }}
        onStart={onStart}
        onStop={() => stopSelectRect()} >
        <rect x={x} y={y} className="select-overlay" width={width} height={height} onWheel={(e) => zoom(e)} />
      </DraggableCore>
      <rect x={rectX} y={rectY} width={rectWidth} height={rectHeight} className="select-rect"
      style={{strokeWidth: 1 * xMap}} />
    </g>
  );
};

PlanDrag.propTypes = {
  actions: PropTypes.object.isRequired,
  xMap: PropTypes.number.isRequired,
  yMap: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  pt: PropTypes.object,
  svgNode: PropTypes.object,
  selectWidth: PropTypes.number,
  selectHeight: PropTypes.number,
  selectX: PropTypes.number,
  selectY: PropTypes.number,
  svgCornerX: PropTypes.number,
  svgCornerY: PropTypes.number,
  buildingActions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {xMap, yMap, selectX, selectY, selectWidth, selectHeight, mode} = state.planReducer;
  return {
    xMap,
    yMap,
    selectX,
    selectY,
    selectWidth,
    selectHeight,
    mode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    buildingActions: bindActionCreators(buildingActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanDrag);

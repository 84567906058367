import {DELETE_PROFILE, SAVE_PROFILE, SET_PROFILE_FILTER,
CREATE_PROFILE} from '../constants/profileActionTypes';
import {setProfileEditing, setProfileFilter, setDeleteProfileShow,
setProfileValidationErrors} from '../actions/profileActions';
import {setEditingGroupProfile} from '../actions/groupActions';
import {setScenarioProps} from '../actions/scenarioActions';
import {PROFILE_FILTER_ALL, PROFILE_FILTER_BUILDING, PROFILE_VALIDATION_ERRORS_OBJ} from '../constants/profileTypes';
import {getColorNumber} from '../utils/colorNumberHelper';
import objectAssign from 'object-assign';

function validateProfile(profile) {
  let errObj = objectAssign({}, PROFILE_VALIDATION_ERRORS_OBJ);
  const onIsNumber = profile.percent_on !== undefined && !isNaN(profile.percent_on);
  const stbyIsNumber = profile.percent_standby !== undefined && !isNaN(profile.percent_standby);

  if (!onIsNumber) {
    errObj.onErr = true;
  }
  if (!stbyIsNumber) {
    errObj.standbyErr = true;
  }

  if (onIsNumber && stbyIsNumber) {

    profile = objectAssign({}, profile, {
      percent_standby: parseInt(profile.percent_standby),
      percent_on: parseInt(profile.percent_on),
    });

    if (profile.percent_standby < 0 || profile.percent_standby > 100) {
      errObj.standbyErr = true;
    }
    if (profile.percent_on < 0 || profile.percent_on > 100) {
      errObj.onErr = true;
    }
    if (profile.percent_standby > profile.percent_on) {
      errObj.standbyErr = true;
    }
  }

  if (profile.timeout_on < 30 || profile.timeout_on > 7200) {
    errObj.timeoutOnErr = true;
  }
  if (profile.timeout_standby < 30 || profile.timeout_standby > 7200) {
    errObj.timeoutStandbyErr = true;
  }

  if (profile.name.length === 0 || profile.name.length > 200) {
    errObj.nameErr = true;
  }
  return {errObj, profile};
}

const profileMiddleware = store => next => action => {
  const {dispatch} = store;
  if(action.type === DELETE_PROFILE) {

    const {buildingReducer, groupReducer, profileReducer, scenarioReducer} = store.getState();
    const {id} = action;
    const {activeBuildingId} = buildingReducer;
    const {selectedScenarioId} = buildingReducer[activeBuildingId];
    const {groupIds, profileIds, defaultProfileId} = scenarioReducer[selectedScenarioId];
    if (profileIds.length < 2) {
      return;
    }

    let stillUsed = false;
    groupIds.forEach(groupId => {
      const group = groupReducer[groupId];
      if (group.profileId === id) {
        stillUsed = true;
      }
    });
    if (stillUsed === false) {
      const filteredProfileIds = profileIds.filter(profileId => profileId !== id);
      const scenarioChanges = {
        needsSave: true,
        profileIds: filteredProfileIds,
      };
      if (defaultProfileId === id) {
        scenarioChanges.defaultProfileId = filteredProfileIds[0];
      }

      dispatch(setScenarioProps(selectedScenarioId, scenarioChanges));

      const {profileFilter} = profileReducer;
      next(action);
      dispatch(setProfileFilter(profileFilter));
    }else {
      dispatch(setDeleteProfileShow(true));
    }
  }else if(action.type === SAVE_PROFILE) {
    const {profileReducer, groupReducer, buildingReducer, scenarioReducer} = store.getState();
    const profile = profileReducer[action.id];
    action.profile = profile;

    const {activeBuildingId} = buildingReducer;
    const {selectedScenarioId} = buildingReducer[activeBuildingId];

    const {errObj, profile: parsedIntProfile} = validateProfile(profile);
    let validationError = false;
    Object.keys(errObj).forEach((key) => {
      if (errObj[key] === true) {
        validationError = true;
      }
    });

    if (validationError === false) {
      action.profile = parsedIntProfile;
      next(action);
      dispatch(setProfileEditing(undefined));
      if (groupReducer.groupEditing === true) {
        dispatch(setEditingGroupProfile(action.id));
      }
      const {profileIds} = scenarioReducer[selectedScenarioId];
      const scenarioChanges = {
        needsSave: true,
      };
      if (profileIds.indexOf(profile.id) === -1) {
        scenarioChanges.profileIds = [profile.id].concat(profileIds);
      }
      dispatch(setScenarioProps(selectedScenarioId, scenarioChanges));

      const {profileFilter} = profileReducer;
      dispatch(setProfileFilter(profileFilter));
    }

    dispatch(setProfileValidationErrors(errObj));

  }else if(action.type === SET_PROFILE_FILTER) {
    const {buildingReducer, groupReducer, scenarioReducer} = store.getState();
    const {profileFilter} = action;
    const {activeBuildingId} = buildingReducer;
    const {selectedScenarioId} = buildingReducer[activeBuildingId];
    const {groupIds, profileIds} = scenarioReducer[selectedScenarioId];

    if (profileFilter === PROFILE_FILTER_ALL) {
      action.filteredProfileIds = profileIds;
    }else if(profileFilter === PROFILE_FILTER_BUILDING) {
      let uniqueObj = {};
      let filteredProfileIds = [];

      groupIds.forEach(groupId => {
        const {profileId} = groupReducer[groupId];
        if (uniqueObj[profileId] === undefined) {
          filteredProfileIds.push(profileId);
          uniqueObj[profileId] = true;
        }
      });
      action.filteredProfileIds = filteredProfileIds;
    }

    return next(action);

  }else if(action.type === CREATE_PROFILE) {
    const {buildingReducer, profileReducer, scenarioReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {selectedScenarioId} = buildingReducer[activeBuildingId];
    const {lastProfileId, defaultProfileId, profileIds} = scenarioReducer[selectedScenarioId];
    const newProfileId = `${selectedScenarioId}_${lastProfileId + 1}`;
    action.id = newProfileId;
    action.defaultProfileId = defaultProfileId;

    const colorNumbers = profileIds.map(profileId => profileReducer[profileId].color_number);

    action.colorNumber = getColorNumber(colorNumbers);

    dispatch(setScenarioProps(selectedScenarioId, {lastProfileId: lastProfileId + 1}));
    next(action);
    dispatch(setProfileEditing(newProfileId));

  }else {
    return next(action);
  }

};

export default profileMiddleware;

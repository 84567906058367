import { CREATE_USER, UPDATE_USER, SAVE_USER } from '../constants/userActionTypes';
import {updateUser, createUser} from '../actions/userActions';
import { push } from 'connected-react-router';

const userMiddleware = store => next => action => {
  const {dispatch} = store;
  if (action.type === UPDATE_USER.SUCCESS || action.type === CREATE_USER.SUCCESS) {
    dispatch(push('/users'));
    next(action);
  }else if(action.type === SAVE_USER) {
    const {user, wasUserDefined} = action;
    if (wasUserDefined) {
      dispatch(updateUser(user));
    }else {
      dispatch(createUser(user));
    }
  }else {
    return next(action);
  }

};

export default userMiddleware;

import moment from 'moment';
import {setFirmwareVersions, getFirmwareFiles, startUpgradeApi, getUpgradeStatus,
getScheduledUpgrade, reSyncNodesUpgradeApi, setShowUpgradeDialog} from '../actions/firmwareActions';
import {setNodesMultiProps} from '../actions/nodeActions';
import {START_UPGRADE, GET_UPGRADE_STATUS, CLEAR_UPGRADE, START_UPGRADE_API,
  RE_SYNC_NODES_UPGRADE,
  UPGRADE_SELECTION,
SYNC_FIRMWARE_DRIVER, SYNC_FIRMWARE_PS} from '../constants/firmwareActionTypes';
import {LAMP, PS, UPGRADER_STATUS_CLEARED, UPGRADER_STATUS_STOPPED, DEFAULT_DATE_FORMAT,
  UPGRADE_ITEMS_LAMP,
  UPGRADE_ITEMS_PS,
  UPGRADE_PLAN_LAMP,
  UPGRADE_PLAN_PS
} from '../constants/customTypes';
import {getUniqueNodeVersions} from '../utils/versionHelper';
import {removeDuplicatesArray} from '../utils/helper';

let getUpgradeStatusTimeoutId;

const firmwareMiddleware = store => next => action => {
  const {dispatch} = store;
  const {type} = action;
  if (type === START_UPGRADE) {
    const {firmwareReducer} = store.getState();
    const {mode, schedule, startDate, stopDate, apOnOff, pollAndSync, updateIds} = firmwareReducer;

    let firmwareMap = {};
    firmwareReducer.nodeTypes.forEach((nodeType) => {
      const versions = firmwareReducer[nodeType];
      versions.forEach((swVersion) => {
        const key = `${nodeType}/${swVersion}`;
        firmwareMap[key] = firmwareReducer[key];
      });
    });

    dispatch(startUpgradeApi({
      macs: updateIds,
      firmwareMap,
      mode,
      pollAndSync,
      schedule,
      startDate: moment(startDate, DEFAULT_DATE_FORMAT),
      stopDate: moment(stopDate, DEFAULT_DATE_FORMAT),
      apOnOff,
    }));

  }else if(type === GET_UPGRADE_STATUS.SUCCESS) {
    clearTimeout(getUpgradeStatusTimeoutId);
    const {status, lamps, mode, pses} = action.response;
    if (status !== UPGRADER_STATUS_STOPPED && status !== UPGRADER_STATUS_CLEARED) {
      setTimeout(() => {
        dispatch(getUpgradeStatus());
      }, 30000);
    }
    if (mode === PS) {
      let ids = [];
      let obj = {};
      pses.forEach((ps) => {
        ids.push(ps.id);
        obj[ps.id] = ps;
      });

      dispatch(setNodesMultiProps(ids, obj));

    }else if(mode === LAMP) {
      let ids = [];
      let obj = {};
      lamps.forEach((lamp) => {
        ids.push(lamp.id);
        obj[lamp.id] = lamp;
      });

      dispatch(setNodesMultiProps(ids, obj));
    }

    return next(action);

  }else if(type === SYNC_FIRMWARE_PS.SUCCESS || type === SYNC_FIRMWARE_DRIVER.SUCCESS) {
    dispatch(getFirmwareFiles());
    return next(action);

  }else if(type === START_UPGRADE_API.SUCCESS) {
    const {schedule} = action.props;
    if (schedule === true) {
      dispatch(getScheduledUpgrade());
    }else {
      dispatch(getUpgradeStatus());
    }
  }else if(type === CLEAR_UPGRADE.SUCCESS) {
    dispatch(getUpgradeStatus());
    return next(action);
  }else if(type === RE_SYNC_NODES_UPGRADE) {
    const {nodeReducer, ppReducer, buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {dataportSlug} = buildingReducer[activeBuildingId];
    const ids = ppReducer[`${dataportSlug}/ids`];
    const filteredIds = ids.filter((id) => {
      const node = nodeReducer[id];
      return node && node.selected;
    });

    dispatch(reSyncNodesUpgradeApi(filteredIds));

  }else if (action.type === UPGRADE_SELECTION) {
    const { lineReducer, lampReducer, buildingReducer, psReducer, nodeReducer,
      apReducer, ppReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lineIds, allLampIds, dataportSlug} = buildingReducer[activeBuildingId];
    const {mode} = action;

    let nodes = [];
    let newMode;
    if (mode === UPGRADE_PLAN_PS) {
      newMode = PS;
      lineIds.forEach((lineId) => {
        const line = lineReducer[lineId];
        const ps = psReducer[line.psId];
        if (ps.selected) {
          const node = nodeReducer[ps.mac];
          if (node) {
            nodes.push(node);
          }
        }
      });
    }else if (mode === UPGRADE_PLAN_LAMP) {
      newMode = LAMP;
      allLampIds.forEach(lampId => {
        const lamp = lampReducer[lampId];
        if (lamp.selected) {
          const node = nodeReducer[lamp.mac];
          if (node) {
            nodes.push(node);
          }
        }
      });
    }else if (mode === UPGRADE_ITEMS_PS) {
      newMode = PS;
      const ids = apReducer[`${dataportSlug}/ids`];
      nodes = ids.map(id => nodeReducer[id]).filter(node => node && node.selected);
    }else if (mode === UPGRADE_ITEMS_LAMP) {
      newMode = LAMP;
      const ids = ppReducer[`${dataportSlug}/ids`];
      const pps = ids.map(id => ppReducer[id]).filter(pp => pp && pp.selected);
      const macs = pps.map(pp => pp.ipui);
      nodes = macs.map(mac => nodeReducer[mac]);
    }

    const {nodeTypes, nodeTypeObj, versionObj} = getUniqueNodeVersions(nodes);
    dispatch(getFirmwareFiles());
    dispatch(setFirmwareVersions(nodeTypes, nodeTypeObj, versionObj));
    dispatch(setShowUpgradeDialog(true, newMode));

    action.updateIds = removeDuplicatesArray(nodes.map(node => node.id));
    return next(action);
  }else {
    return next(action);
  }
};

export default firmwareMiddleware;

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import "react-datepicker/dist/react-datepicker.css";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ActionButton from '../components/ActionButton';
import * as movementActions from '../actions/movementActions';
import * as movementRetentionActions from '../actions/movementRetentionActions';
import { getTranslate } from 'react-localize-redux';
import '../styles/heatmap-bar.scss';
import StartDateEndDateInterval from './StartDateEndDateInterval';

const MovementBar = ({movementActions, activeBuildingId, getMovementStatus, startDate, endDate, translate,
retentionObject, movementRetentionActions}) => {
  const {getMovementRetentionPeriods} = movementRetentionActions;

  useEffect(() => {
    if (retentionObject === undefined) {
      getMovementRetentionPeriods(activeBuildingId);
    }
  }, []);

  if (!retentionObject) {
    return null;
  }

  const {getMovementData, setMovementReducerProps} = movementActions;

  const endDateBeforeStartDate = endDate <= startDate;

  return (
    <div className="heatmap-bar">
      <StartDateEndDateInterval
        retentionObject={retentionObject}
        startDate={startDate} endDate={endDate}
        setStartDate={(date) => setMovementReducerProps({startDate: date})}
        setEndDate={(date) => setMovementReducerProps({endDate: date})}
      />
      <ActionButton action={() => getMovementData(activeBuildingId, startDate, endDate)} disabled={endDateBeforeStartDate}
        status={getMovementStatus}
        text={translate('movement_get')}/>
    </div>
  );
};

MovementBar.propTypes = {
  movementActions: PropTypes.object.isRequired,
  movementRetentionActions: PropTypes.object.isRequired,
  activeBuildingId: PropTypes.number.isRequired,
  translate: PropTypes.func.isRequired,
  getMovementStatus: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  retentionObject: PropTypes.object,
};

function mapStateToProps({localeReducer, buildingReducer, movementReducer, movementRetentionReducer}) {
  const {activeBuildingId} = buildingReducer;
  const {getMovementStatus, startDate, endDate} = movementReducer;
  const retentionObject = movementRetentionReducer[activeBuildingId];
  return {
    translate: getTranslate(localeReducer),
    activeBuildingId,
    getMovementStatus,
    startDate,
    endDate,
    retentionObject,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    movementActions: bindActionCreators(movementActions, dispatch),
    movementRetentionActions: bindActionCreators(movementRetentionActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MovementBar);

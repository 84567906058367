/* eslint-disable import/no-unresolved */
import {dsClientUrl, triviewApiUrl as configTriviewUrl, useHostname} from 'Config';
/* eslint-engage import/no-unresolved */

import {createPrependedEnum} from '../utils/enumHelper';

export const DEEPSTREAM_URL = dsClientUrl;

let triviewApiUrl = configTriviewUrl;

if (LOCAL && useHostname === true) {
  triviewApiUrl = triviewApiUrl.replace('HOSTNAME', location.hostname);
}

let urls = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/user/password/forgot',
  RESET_PASSWORD: '/user/password/reset',
  CHANGE_PASSWORD: '/user/change-password',
  CHANGE_PROFILE: '/user/change-profile',
  RE_AUTH: '/auth/jwt-token-refresh/',
  ME: '/me/',
  CONFIGURATION: {
    GET: '/client/:clientSlug/building/:buildingSlug/configuration',
    PUT: '/configuration',
  },
  DATAPORTS: {
    TECHNICAL: '/dataports/technical',
    INFO: '/dataport/:id/info',
    ALL: '/dataports',
    GET: '/dataports/:slug/',
    PUT: '/dataport',
    POST: '/dataport',
    DELETE: '/dataport/:id',
    REFRESH_INFO: '/dataport/:id/agent/refresh-info',
  },
  DEEPSTREAM: {
    TOKEN: '/deepstream/token',
  },
  USERS: {
    GET: '/users',
    PUT: '/user',
    POST: '/user',
    DELETE: '/user/:userId',
  },
  USER_CLIENT_ROLES: {
    GET: '/user/:userId/client/:clientId/roles',
  },
  USER_BUILDING_ROLES: {
    GET: '/user/:userId/client/:clientId/building-roles',
    PUT: '/user',
    POST: '/user',
  },
  CONFIG_ROLES: {
    GET: '/config-roles',
  },
  CLIENT_ROLES: {
    GET: '/client-roles',
  },
  GROUPS: {
    GET: '/configurations/:id/groups/',
    PUT: '/groups/:id/',
    POST: '/groups/',
  },
  PROFILES: {
    GET: '/clients/:slug/profiles/',
    POST: '/clients/:slug/profiles/',
  },
  CLIENTS: {
    GET: '/clients',
    PUT: '/client',
    POST: '/client',
    DELETE: '/client/:id',
  },
  BUILDINGS: {
    GET: '/client/:slug/buildings',
    PUT: '/building',
    POST: '/building',
    DELETE: '/building/:id',
    UPLOAD: '/configuration/:id/plan/upload/',
  },
  SCENARIOS: {
    PUT: '/configuration/:configId/scenario',
    ACTIVATE: '/configuration/:configId/scenario/:scenarioId/activate',
    DELETE: '/configuration/:configId/scenario/:scenarioId',
  },
  PLAN_IMG: '/plan-img/:filename',
  PING: '/ping/',
  LAMP_TYPES: '/lamp-types',
};

if (CLOUD) {
  urls = {
    ...urls,
    MOVEMENT: {
      GET: '/movement/:configId',
      RETENTION: '/movement/retention/:configId',
    },
    ENERGY: {
      GET: '/energy/:configId',
      SIMULATE: '/energy/simulate/:configId',
      RETENTION: '/energy/retention/:configId',
    },
    REPORTS: {
      GET_CLIENT: '/client/:clientId/reports',
      GET: '/client/:clientId/report/:reportId',
      POST: '/client/:clientId/report',
      PUT: '/client/:clientId/report',
      DELETE: '/client/:clientId/report/:reportId',
    },
    REPORT_HISTORY: {
      GET_CLIENT: '/client/:clientId/report-histories',
      DOWNLOAD: '/client/:clientId/report-history/:reportHistoryId/download',
    },
    DATA_STORAGE_OPTIONS: '/data-storage-options/:configId',
    LAMP_TYPE: {
      PUT: '/lamp-type',
      POST: '/lamp-type',
      DELETE: '/lamp-type/:id',
    },
  };
}

export const FETCH_AUTH_PARAMS = {
  credentials: 'include',
};

export const TRIVIEW_API = createPrependedEnum(urls, triviewApiUrl);

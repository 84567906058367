import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/nodeActions';
import * as apActions from '../actions/apActions';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';
import '../styles/pses-commands.scss';

const PsesCommands = ({nodes, psMacs, actions, translate, aps, apActions}) => {
  const { pingNodes, pollNodes, resetNode} = actions;
  const {setCommissioning, setApEnabled} = apActions;

  return (
      <span className="pses-commands">
        <div className="command-btns">
          <span className="btn-section">
            <ActionButton action={() => psMacs.forEach((mac) => setCommissioning(mac, true))} icon="fa-link" text="Commissioning ON"
              extraClass="btn btn-danger"
            />
            <ActionButton action={() => psMacs.forEach((mac) => setCommissioning(mac, false))} icon="fa-link" text="Commissioning OFF"
            extraClass="btn btn-default" />
          </span>
          <span className="btn-section">
            <ActionButton action={() => psMacs.forEach((mac) => setApEnabled(mac, true))} text="AP's ON"
              extraClass="btn btn-default"
            />
            <ActionButton action={() => psMacs.forEach((mac) => setApEnabled(mac, false))} text="AP's OFF"
              extraClass="btn btn-danger"
            />
          </span>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th />
              <th>
                <ActionButton action={() => pingNodes(psMacs)} icon="fa-podcast" text={translate('technical_ping')} />
              </th>
              <th>
                <ActionButton action={() => pollNodes(psMacs)} icon="fa-refresh" text={translate('technical_poll')} />
              </th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {psMacs.map((mac) => {
              let pingStatus, pollStatus, resetStatus, registrationMode, pingFails, enabled;
              const node = nodes[mac];
              const ap = aps[mac];
              if (ap) {
                registrationMode = ap.registration_mode;
                enabled = ap.enabled;
              }
              if (node) {
                pingStatus = node.pingStatus;
                pollStatus = node.pollStatus;
                resetStatus = node.resetStatus;
                pingFails = node.pingFails;
              }
              let nodePingFails = null;
              if (pingFails > 0) {
                nodePingFails = (
                  <span className="fails">{node.pingFails}</span>
                );
              }
              return (
                <tr key={mac}>
                  <td>{mac}</td>
                  <td>
                    <ActionButton action={() => pingNodes([mac])} status={pingStatus} icon="fa-podcast"
                      text={translate('technical_ping')}/>{nodePingFails}
                  </td>
                  <td>
                    <ActionButton action={() => pollNodes([mac])} status={pollStatus} icon="fa-refresh"
                      text={translate('technical_poll')}/>
                  </td>
                  <td>
                    <ActionButton action={() => resetNode(mac)} status={resetStatus} icon="fa-undo"
                      text={translate('technical_reset_ps')}/>
                  </td>
                  <td>
                    <ActionButton action={() => setCommissioning(mac, !registrationMode)}
                      extraClass={(registrationMode === true ? 'btn-danger':'btn-default')}
                      icon="fa-link"
                      text={translate('technical_commission')}/>
                  </td>
                  <td>
                    <ActionButton action={() => setApEnabled(mac, !enabled)}
                      extraClass={(enabled === true ? 'active':'')}
                      text="Ap Active" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </span>
  );
};

PsesCommands.propTypes = {
  nodes: PropTypes.object,
  aps: PropTypes.object,
  actions: PropTypes.object.isRequired,
  apActions: PropTypes.object.isRequired,
  psMacs: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const translate = getTranslate(state.localeReducer);
  return {
    nodes: state.nodeReducer,
    aps: state.apReducer,
    translate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    apActions: bindActionCreators(apActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PsesCommands);

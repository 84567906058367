import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import {setClientRouteSlug, getClients} from '../actions/clientActions';
import {getLampTypes} from '../actions/lampTypeActions';
import {setBuildingRouteSlug} from '../actions/buildingActions';
import SubNav from '../components/SubNav';
import ModeBar from './ModeBar';
import ClientsDialog from './ClientsDialog';
import '../styles/plan-page.scss';

let initial = true;

const PlanPageWrapper = ({Component, extra, dispatch, clientIds, lampTypesFetched, buildingDefined, planHeight, translate, pageTitle}) => {
  const {params} = extra.match;
  useEffect(() => {
    if (clientIds !== undefined && clientIds.length === 0) {
      dispatch(getClients());
    }

    if (lampTypesFetched === false) {
      dispatch(getLampTypes());
    }
    if (initial === true) {
      initial = false;
      const {clientName, buildingName} = params;
      if (clientName) {
        dispatch(setClientRouteSlug(clientName));
      }

      if (buildingName) {
        dispatch(setBuildingRouteSlug(buildingName));
      }
    }

  }, []);

  let el = null;
  if (buildingDefined === true && lampTypesFetched === true) {
    el = (
      <>
        <ModeBar />
        <div className="plan-page">
          <Component {...extra} planHeight={planHeight} />
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{translate(pageTitle)}</title>
      </Helmet>
      <ClientsDialog />
      <SubNav parentNav="plan" />
      {el}
    </>
  );
};

PlanPageWrapper.propTypes = {
  dispatch: PropTypes.func.isRequired,
  activeClientKey: PropTypes.string,
  clientIds: PropTypes.array.isRequired,
  planHeight: PropTypes.number.isRequired,
  buildingDefined: PropTypes.bool.isRequired,
  lampTypesFetched: PropTypes.bool.isRequired,
  extra: PropTypes.object,
  Component: PropTypes.elementType.isRequired,
  translate: PropTypes.func.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {planReducer, buildingReducer, generalReducer, clientReducer, lampTypeReducer} = state;
  const {planHeight} = planReducer;
  const {clientIds} = clientReducer;
  const {activeBuildingId} = buildingReducer;
  const {browserHeight} = generalReducer;
  const {lampTypesFetched} = lampTypeReducer;

  let buildingDefined = false;

  if (!activeBuildingId) {
    return {
      clientIds,
      planHeight,
      buildingDefined: false,
      lampTypesFetched,
      translate: getTranslate(state.localeReducer),
    };
  }
  let dataport;
  const building = buildingReducer[activeBuildingId];
  if (building !== undefined) {
    buildingDefined = building.configFetched === true;
    dataport = building.dataport;
  }

  return {
    dataport,
    browserHeight,
    planHeight,
    clientIds,
    buildingDefined,
    lampTypesFetched,
    translate: getTranslate(state.localeReducer),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const ConnectedPlanPageWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanPageWrapper);

export default (Component, pageTitle) => {
  // eslint-disable-next-line
  return (props) => {
    return <ConnectedPlanPageWrapper Component={Component} extra={props} pageTitle={pageTitle} />;
  };
};

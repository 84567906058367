import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import EditClientForm from '../containers/EditClientForm';

const EditClientPage = ({match}) => {
  const {clientId} = match.params;
  return (
    <div className="not-found-page">
      <Helmet>
        <title>Edit Client | TriVIEW</title>
      </Helmet>
      <div className="container">
        <h1>Client</h1>
        <EditClientForm id={clientId} />
      </div>
    </div>
  );
};

EditClientPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EditClientPage;

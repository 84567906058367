import {DS_CONNECT, DS_DISCONNECT} from '../constants/dsActionTypes';

export function dsConnect(username, userId, token) {
  return {
    type: DS_CONNECT,
    username,
    userId,
    token,
  };
}

export function dsDisconnect() {
  return {
    type: DS_DISCONNECT,
  };
}

import {CLEAR_LIVE_PROPS, GENERATE_ENERGY_MONITORING_STATS, SET_LIVE_MODE} from '../constants/liveActionTypes';

export function setLiveMode(mode) {
  return {
    type: SET_LIVE_MODE,
    mode,
  };
}

export function clearLiveProps() {
  return {
    type: CLEAR_LIVE_PROPS
  };
}

export function generateEnergyMonitoringStats() {
  return {
    type: GENERATE_ENERGY_MONITORING_STATS
  };
}

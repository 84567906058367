import {SET_UNIQUE_OBJECTS_AND_VARS, SET_UNIQUE_VAR_SELECTED, CHANGE_UNIQUE_SELECTED_ALL, CHANGE_UNIQUE_OBJECT_SELECTED_ALL} from '../constants/uniqueObjectsActionTypes';
// import {removeDuplicatesArray} from '../utils/helper';
import objectAssign from 'object-assign';

const initialState = {
  objectNames: [],
};

export default function selectedObjectsReducer(state = initialState, action) {

  switch (action.type) {
    case SET_UNIQUE_OBJECTS_AND_VARS:{
      const {uObjects} = action;
      const keys = Object.keys(uObjects);
      const newState = objectAssign({}, state);
      const objectNamesObj = {};
      state.objectNames.forEach(objectName => objectNamesObj[objectName] = true);

      keys.forEach(key => {
        const [objectName, variableName] = key.split('/');
        objectNamesObj[objectName] = true;
        const oldVar = newState[key];
        if (oldVar === undefined) {
          const variables = newState[objectName];
          if (variables === undefined) {
            newState[objectName] = [variableName];
          }else {
            newState[objectName] = variables.concat(variableName);
          }
        }

        newState[key] = true;
      });

      newState.objectNames = Object.keys(objectNamesObj);

      return newState;
    }
    case SET_UNIQUE_VAR_SELECTED:{
      const {key, value} = action;
      const obj = {};
      obj[key] = value;
      return objectAssign({}, state, obj);
    }
    case CHANGE_UNIQUE_SELECTED_ALL:{
      const {value} = action;
      const newState = objectAssign({}, state);
      state.objectNames.forEach(objectName => {
        const variables = state[objectName];
        variables.forEach(variableName => {
          newState[`${objectName}/${variableName}`] = value;
        });
      });
      return newState;
    }
    case CHANGE_UNIQUE_OBJECT_SELECTED_ALL:{
      const {value, objectName} = action;
      const newState = objectAssign({}, state);
      const variables = state[objectName];
      variables.forEach(variableName => {
        newState[`${objectName}/${variableName}`] = value;
      });
      return newState;
    }

    default:
      return state;
  }
}

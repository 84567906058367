import {API, GET, PUT, POST, DELETE, DS_RPC,
} from '../constants/customTypes';
import {GET_DATAPORT, UPDATE_DATAPORT, DELETE_DATAPORT, CREATE_DATAPORT, GET_DATAPORTS,
GET_DATAPORTS_TECHNICAL, GET_DATAPORT_INFO, REFRESH_DATAPORT_INFO, UPDATE_DATAPORT_PACKAGE,
EXECUTE_DATAPORT_COMMAND, EXECUTE_DATAPORT_COMMAND_RPC, EXECUTE_DATAPORTS_COMMAND,
UPDATE_DATAPORT_PACKAGE_RPC,
UPDATE_DATAPORTS_PACKAGE,
SET_DATAPORT_PROPS, GET_DATAPORT_COMMAND_OUTPUT,
SET_DATAPORT_REDUCER_PROPS} from '../constants/dataportActionTypes';
import {TRIVIEW_API} from '../constants/urls';

export function getDataport(name) {
  return {
    type: API,
    auth: true,
    payload: {
      url: TRIVIEW_API.DATAPORTS.GET.replace(':slug', name),
      method: GET,
      success: GET_DATAPORT.SUCCESS,
      start: GET_DATAPORT.START,
      error: GET_DATAPORT.ERROR,
    }
  };
}

export function getDataports() {
  return {
    type: API,
    auth: true,
    idProp: 'id',
    payload: {
      url: TRIVIEW_API.DATAPORTS.ALL,
      method: GET,
      success: GET_DATAPORTS.SUCCESS,
      start: GET_DATAPORTS.START,
      error: GET_DATAPORTS.ERROR,
    }
  };
}

export function createDataport(dataport) {
  return {
    type: API,
    auth: true,
    payload: {
      url: TRIVIEW_API.DATAPORTS.POST,
      method: POST,
      body: dataport,
      success: CREATE_DATAPORT.SUCCESS,
      start: CREATE_DATAPORT.START,
      error: CREATE_DATAPORT.ERROR,
    }
  };
}

export function updateDataport(dataport) {
  return {
    type: API,
    auth: true,
    payload: {
      url: TRIVIEW_API.DATAPORTS.PUT,
      method: PUT,
      body: dataport,
      success: UPDATE_DATAPORT.SUCCESS,
      start: UPDATE_DATAPORT.START,
      error: UPDATE_DATAPORT.ERROR,
    }
  };
}

export function deleteDataport(id) {
  return {
    type: API,
    auth: true,
    payload: {
      url: TRIVIEW_API.DATAPORTS.DELETE.replace(':id', id),
      method: DELETE,
      success: DELETE_DATAPORT.SUCCESS,
      start: DELETE_DATAPORT.START,
      error: DELETE_DATAPORT.ERROR,
    },
    props: {
      id,
    },
  };
}

export function setDataportReducerProps(obj) {
  return {
    type: SET_DATAPORT_REDUCER_PROPS,
    obj,
  };
}

export function getDataportsTechnical() {
  return {
    type: API,
    auth: true,
    payload: {
      url: TRIVIEW_API.DATAPORTS.TECHNICAL,
      method: GET,
      success: GET_DATAPORTS_TECHNICAL.SUCCESS,
      start: GET_DATAPORTS_TECHNICAL.START,
      error: GET_DATAPORTS_TECHNICAL.ERROR,
    }
  };
}

export function getDataportInfo(dataportId) {
  return {
    type: API,
    auth: true,
    payload: {
      url: TRIVIEW_API.DATAPORTS.INFO.replace(':id', dataportId),
      method: GET,
      success: GET_DATAPORT_INFO.SUCCESS,
      start: GET_DATAPORT_INFO.START,
      error: GET_DATAPORT_INFO.ERROR,
    }
  };
}

export function refreshDataportInfo(dataportId) {
  return {
    type: API,
    auth: true,
    payload: {
      url: TRIVIEW_API.DATAPORTS.REFRESH_INFO.replace(':id', dataportId),
      method: GET,
      success: REFRESH_DATAPORT_INFO.SUCCESS,
      start: REFRESH_DATAPORT_INFO.START,
      error: REFRESH_DATAPORT_INFO.ERROR,
    }
  };
}

export function updateDataportPackageRpc(dataportId, dataportName, packageName, sessionId) {
  return {
    type: DS_RPC,
    payload: {
      url: `${dataportName.toLowerCase()}/agent/updatePackage`,
      data: {packageName, sessionId},
      success: UPDATE_DATAPORT_PACKAGE_RPC.SUCCESS,
      error: UPDATE_DATAPORT_PACKAGE_RPC.ERROR,
      start: UPDATE_DATAPORT_PACKAGE_RPC.START,
    },
    props: {
      dataportId,
      packageName,
      sessionId,
    },
  };
}

export function updateDataportPackage(dataportId, packageName) {
  return {
    type: UPDATE_DATAPORT_PACKAGE,
    dataportId,
    packageName,
  };
}

export function updateDataportsPackage(packageName) {
  return {
    type: UPDATE_DATAPORTS_PACKAGE,
    packageName,
  };
}

export function execDataportCommandRpc(dataportId, dataportName, cmd, sessionId, emitStd) {
  return {
    type: DS_RPC,
    payload: {
      url: `${dataportName.toLowerCase()}/agent/runCommand`,
      data: {sessionId, cmd, emitStd},
      success: EXECUTE_DATAPORT_COMMAND_RPC.SUCCESS,
      error: EXECUTE_DATAPORT_COMMAND_RPC.ERROR,
      start: EXECUTE_DATAPORT_COMMAND_RPC.START,
    },
    props: {
      sessionId,
      dataportId,
    },
  };
}

export function execDataportCommand(dataportId, cmd) {
  return {
    type: EXECUTE_DATAPORT_COMMAND,
    cmd,
    dataportId,
  };
}

export function execDataportsCommand(cmd) {
  return {
    type: EXECUTE_DATAPORTS_COMMAND,
    cmd,
  };
}

export function setDataportProps(id, obj) {
  return {
    type: SET_DATAPORT_PROPS,
    id,
    obj,
  };
}

export function getDataportCommandOutput(dataportId, dataportName, sessionId) {
  return {
    type: DS_RPC,
    payload: {
      url: `${dataportName.toLowerCase()}/agent/getCommandOutput`,
      data: sessionId,
      success: GET_DATAPORT_COMMAND_OUTPUT.SUCCESS,
      error: GET_DATAPORT_COMMAND_OUTPUT.ERROR,
      start: GET_DATAPORT_COMMAND_OUTPUT.START,
    },
    props: {
      sessionId,
      dataportId,
    },
  };
}

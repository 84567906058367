import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/nodeActions';
import HardwareVersion from '../components/HardwareVersion';
import SoftwareVersion from '../components/SoftwareVersion';
import {LAMP_SELECTION_DIALOG_MODE_PPS, LAMP_SELECTION_DIALOG_MODE_NODES} from '../constants/customTypes';

const LampsInfoItem = ({node, pp, id, lampSelectionDialogMode, parentApIds}) => {
  let nodeType, hwVersion, swVersion;
  let mac = id;
  if (node !== undefined) {
    nodeType = node.node_type;
    hwVersion = node.hardware_version;
    swVersion = node.software_version;
    mac = node.id;
  }

  let extraInfoEl = null;
  if (lampSelectionDialogMode === LAMP_SELECTION_DIALOG_MODE_PPS) {
    if (pp !== undefined) {
      extraInfoEl = (
        <React.Fragment>
          <td>{pp.terminal_id}</td>
          <td>{pp.parentId}</td>
        </React.Fragment>
      );
    }
  }else if(lampSelectionDialogMode === LAMP_SELECTION_DIALOG_MODE_NODES) {
    extraInfoEl = (
      <td>{parentApIds !== undefined ? parentApIds.join(', '):''}</td>
    );
  }

  return (
    <tr>
      <td>{mac}</td>
      <td>{nodeType}</td>
      {extraInfoEl}
      <td><HardwareVersion hardwareVersion={hwVersion} /></td>
      <td><SoftwareVersion softwareVersion={swVersion} /></td>
    </tr>
  );
};

LampsInfoItem.propTypes = {
  id: PropTypes.string.isRequired,
  node: PropTypes.object,
  pp: PropTypes.object,
  parentApIds: PropTypes.array,
  lampSelectionDialogMode: PropTypes.string.isRequired,
};

function mapStateToProps(state, {id}) {
  const {planReducer, nodeReducer, ppReducer} = state;
  const {lampSelectionDialogMode} = planReducer;
  let node, pp, parentApIds;
  if (lampSelectionDialogMode === LAMP_SELECTION_DIALOG_MODE_PPS) {
    pp = ppReducer[id];
    node = nodeReducer[pp.ipui];
  }else if(LAMP_SELECTION_DIALOG_MODE_NODES) {
    node = nodeReducer[id];
    const {idApsMap} = ppReducer;
    parentApIds = idApsMap[id];
  }
  return {
    node,
    pp,
    parentApIds,
    lampSelectionDialogMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LampsInfoItem);

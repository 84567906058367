import {apiAction} from './helper';

export const SET_PLAN_PROPS = 'SET_PLAN_PROPS';
export const SET_IMG_DRAG = 'SET_IMG_DRAG';
export const SAVE_PLAN = apiAction('SAVE_PLAN');
export const SET_SHOW_PLAN_CONTROL = 'SET_SHOW_PLAN_CONTROL';
export const SET_ROTATION = 'SET_ROTATION';
export const SET_COORD_MAP = 'SET_COORD_MAP';
export const INITIALIZE_SELECT_RECT = 'INITIALIZE_SELECT_RECT';
export const STOP_SELECT_RECT = 'STOP_SELECT_RECT';
export const DRAG_SELECT_RECT = 'DRAG_SELECT_RECT';
export const SET_MODE = 'SET_MODE';
export const SET_SHOW_PS_SELECTION_DIALOG = 'SET_SHOW_PS_SELECTION_DIALOG';
export const CHANGE_SELECT_ALL = 'CHANGE_SELECT_ALL';
export const SET_SELECTED_PS_MACS = 'SET_SELECTED_PS_MACS';
export const SET_GROUP_MODE = 'SET_GROUP_MODE';
export const AUTO_CALC_HITZONES = 'AUTO_CALC_HITZONES';
export const SET_SHOW_PS_NODES_SELECTION_DIALOG = 'SET_SHOW_PS_NODES_SELECTION_DIALOG';
export const CALC_ADJACENCY = 'CALC_ADJACENCY';
export const SET_PLAN_SVG_EL = 'SET_PLAN_SVG_EL';
export const SET_ZOOM_RECT = 'SET_ZOOM_RECT';
export const CALC_PLAN_SIZE_PROPS = 'CALC_PLAN_SIZE_PROPS';
export const SET_PLAN_WIDTH_HEIGHT = 'SET_PLAN_WIDTH_HEIGHT';
export const SET_LAMP_CLICK_MODE = 'SET_LAMP_CLICK_MODE';
export const SET_POINTS = 'SET_POINTS';
export const SET_PLAN_DRAG_START = 'SET_PLAN_DRAG_START';
export const SET_PLAN_REDUCER_PROPS = 'SET_PLAN_REDUCER_PROPS';
export const CLOSE_LAMP_SELECTION_DIALOG = 'CLOSE_LAMP_SELECTION_DIALOG';
export const SHOW_LAMP_PPS_SELECTION_DIALOG = 'SHOW_LAMP_PPS_SELECTION_DIALOG';
export const SHOW_LAMP_NODES_SELECTION_DIALOG = 'SHOW_LAMP_NODES_SELECTION_DIALOG';
export const EXEC_PLAN_SUBSCRIBES = 'EXEC_PLAN_SUBSCRIBES';
export const EXEC_PLAN_UNSUBSCRIBES = 'EXEC_PLAN_UNSUBSCRIBES';

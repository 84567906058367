import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import LampZoneDepiction from '../containers/LampZoneDepiction';
import {PROFILE_COLORS, HIGHLIGHT_PROFILE_COLORS, DARKLIGHT_PROFILE_COLORS} from '../constants/colorConstants';
import {LAMP_TYPE_MASTER} from '../constants/lampTypeTypes';
import LampZoneWrapper from '../containers/LampZoneWrapper';

const LampBackgroundZone = ({group, x, y, rotation,
  groupEditing, groupEditingId, profile, lampType, lamp, ...rest}) => {
  if (group === undefined) {
    return null;
  }
  const extra = {
    padding: 60,
    className: 'background-zone',
  };

  if (groupEditing === true) {
    if (group.id === groupEditingId) {
      if (profile !== undefined) {
        extra.color = DARKLIGHT_PROFILE_COLORS[profile.color_number % 8];
      }
    }else {
      extra.color = 'grey';
    }
  }else {
    if (profile !== undefined) {
      if (group.hover === true || profile.hover === true) {
        extra.color = HIGHLIGHT_PROFILE_COLORS[profile.color_number % 8];
      }else {
        extra.color = PROFILE_COLORS[profile.color_number % 8];
      }
    }
  }

  if (lampType.relationType === LAMP_TYPE_MASTER && lamp.lampIds.length > 0) {
    return (
      <g transform={`translate(${x},${y}),rotate(${rotation})`}>
        {lamp.lampIds.map(id => <LampZoneWrapper key={id} id={id} {...extra} />)}
      </g>
    );
  }
  return <LampZoneDepiction {...rest} lamp={lamp} lampType={lampType} {...extra} />;
};

LampBackgroundZone.propTypes = {
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  group: PropTypes.object,
  profile: PropTypes.object,
  groupEditing: PropTypes.bool.isRequired,
  groupEditingId: PropTypes.string,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
};

LampBackgroundZone.defaultProps = {
  y: 0,
  rotation: 0,
};

function mapStateToProps(state, {id}) {
  const {lampReducer, lampTypeReducer, groupReducer, profileReducer} = state;
  const {groupEditing, editingId: groupEditingId, lampGroupMap} = groupReducer;
  const lamp = lampReducer[id];
  const {typeId, x, y, rotation} = lamp;
  const lampType = lampTypeReducer[typeId];
  const groupId = lampGroupMap[id];
  const group = groupReducer[groupId];
  let profile;
  if (group) {
    profile = profileReducer[group.profileId];
  }
  return {
    lamp,
    lampType,
    group,
    profile,
    groupEditing,
    groupEditingId,
    x,
    y,
    rotation,
  };
}

export default connect(
  mapStateToProps,
  {},
)(LampBackgroundZone);

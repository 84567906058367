import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import LampDepiction from '../containers/LampDepiction';
import MasterLamp from '../containers/MasterLamp';
import SlaveLamp from '../containers/SlaveLamp';
import {LAMP_TYPE_MASTER, LAMP_TYPE_SLAVE, LAMP_TYPE_INDEPENDANT} from '../constants/lampTypeTypes';

const Lamp = (props) => {
  const {relationType} = props.lampType;
  if (relationType === LAMP_TYPE_INDEPENDANT) {
    return <LampDepiction {...props} />;
  }else if(relationType === LAMP_TYPE_SLAVE) {
    return <SlaveLamp {...props} />;
  }else if(relationType === LAMP_TYPE_MASTER) {
    return <MasterLamp {...props} />;
  }
  return null;
};

Lamp.propTypes = {
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  extraRotation: PropTypes.number.isRequired,
};

Lamp.defaultProps = {
  extraRotation: 0,
};

function mapStateToProps(state, {id}) {
  const {lampReducer, lampTypeReducer} = state;
  const lamp = lampReducer[id];
  const {typeId} = lamp;
  const lampType = lampTypeReducer[typeId];
  return {
    lamp,
    lampType,
  };
}

export default connect(
  mapStateToProps,
  null,
)(Lamp);

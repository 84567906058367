import {GET_REPORTS, CREATE_REPORT, UPDATE_REPORT, DELETE_REPORT,
  GET_REPORT,
} from '../constants/reportsActionTypes';
import {TRIVIEW_API} from '../constants/urls';
import {API, GET, POST, PUT, DELETE} from '../constants/customTypes';

export function getReports(clientId) {
  return {
    type: API,
    auth: true,
    payload: {
      method: GET,
      url: TRIVIEW_API.REPORTS.GET_CLIENT.replace(':clientId', clientId),
      success: GET_REPORTS.SUCCESS,
      error: GET_REPORTS.ERROR,
      start: GET_REPORTS.START,
    },
    props: {
      clientId,
    },
  };
}

export function getReport(clientId, reportId) {
  return {
    type: API,
    auth: true,
    payload: {
      method: GET,
      url: TRIVIEW_API.REPORTS.GET.replace(':reportId', reportId).replace(':clientId', clientId),
      success: GET_REPORT.SUCCESS,
      error: GET_REPORT.ERROR,
      start: GET_REPORT.START,
    },
  };
}

export function createReport(report) {
  const reportCp = {...report};
  reportCp.reportConfigurations = Object.keys(report.reportConfigurations)
    .map(key => report.reportConfigurations[key])
    .filter(reportConfig => reportConfig.enabled);

  return {
    type: API,
    auth: true,
    payload: {
      method: POST,
      url: TRIVIEW_API.REPORTS.POST.replace(':clientId', report.client_id),
      body: reportCp,
      success: CREATE_REPORT.SUCCESS,
      error: CREATE_REPORT.ERROR,
      start: CREATE_REPORT.START,
    },
  };
}

export function updateReport(report) {
  const reportCp = {...report};
  reportCp.reportConfigurations = Object.keys(report.reportConfigurations).map(key => report.reportConfigurations[key]);

  return {
    type: API,
    auth: true,
    payload: {
      method: PUT,
      url: TRIVIEW_API.REPORTS.PUT.replace(':clientId', report.client_id),
      body: reportCp,
      success: UPDATE_REPORT.SUCCESS,
      error: UPDATE_REPORT.ERROR,
      start: UPDATE_REPORT.START,
    },
  };
}

export function deleteReport(clientId, reportId) {
  return {
    type: API,
    auth: true,
    payload: {
      method: DELETE,
      url: TRIVIEW_API.REPORTS.DELETE.replace(':reportId', reportId).replace(':clientId', clientId),
      success: DELETE_REPORT.SUCCESS,
      error: DELETE_REPORT.ERROR,
      start: DELETE_REPORT.START,
    },
    props: {
      clientId,
      reportId,
    },
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/nodeActions';
import * as apActions from '../actions/apActions';
import {LAMP_SELECTION_DIALOG_MODE_PPS, LAMP_SELECTION_DIALOG_MODE_NODES} from '../constants/customTypes';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const LampsCommandsItem = ({node, pp, id, translate, actions, apActions}) => {
  const {pingNodes, pollNodes, wink} = actions;
  const {decommission, decommissionNode} = apActions;
  let pingFails, winkStatus, pingStatus, pollStatus;
  let mac = id;

  if (node !== undefined) {
    pingFails = node.pingFails;
    pingStatus = node.pingStatus;
    pollStatus = node.pollStatus;
    winkStatus = node.winkStatus;
    mac = node.id;
  }

  let nodePingFails = null;
  if (pingFails > 0) {
    nodePingFails = (
      <span className="fails">{pingFails}</span>
    );
  }

  const decommissionFunc = () => {
    if(pp !== undefined) {
      decommission(pp.parentId, [pp.terminal_id]);
    }else {
      decommissionNode([mac]);
    }
  };

  return (
    <tr>
      <td>{mac}</td>
      <td>
        <ActionButton action={() => wink(mac)} status={winkStatus} icon="fa-lightbulb-o"
          text={translate('technical_wink')}/>
      </td>
      <td>
        <ActionButton action={() => decommissionFunc()} status={undefined} icon="fa-trash"
          text={translate('technical_decommission')}/>
      </td>
      <td>
        <ActionButton action={() => pingNodes([mac])} status={pingStatus} icon="fa-podcast"
          text={translate('technical_ping')}/>
        {nodePingFails}
      </td>
      <td>
        <ActionButton action={() => pollNodes([mac])} status={pollStatus} icon="fa-refresh"
          text={translate('technical_poll')}/>
      </td>
    </tr>
  );
};

LampsCommandsItem.propTypes = {
  id: PropTypes.string.isRequired,
  node: PropTypes.object,
  pp: PropTypes.object,
  translate: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  apActions: PropTypes.object.isRequired,
};

function mapStateToProps(state, {id}) {
  const {planReducer, nodeReducer, ppReducer, localeReducer} = state;
  const {lampSelectionDialogMode} = planReducer;
  let node, pp;
  if (lampSelectionDialogMode === LAMP_SELECTION_DIALOG_MODE_PPS) {
    pp = ppReducer[id];
    node = nodeReducer[pp.ipui];
  }else if(LAMP_SELECTION_DIALOG_MODE_NODES) {
    node = nodeReducer[id];
  }
  const translate = getTranslate(localeReducer);
  return {
    node,
    pp,
    translate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    apActions: bindActionCreators(apActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LampsCommandsItem);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const LineItem = ({line}) => {
  const {x, y} = line;
  return (
    <div className="line-item">
      x: {x}, y: {y}
    </div>
  );
};

LineItem.propTypes = {
  line: PropTypes.object.isRequired,
};

function mapStateToProps(state, {id}) {
  return {
    line: state.lineReducer[id],
  };
}

export default connect(
  mapStateToProps,
  null,
)(LineItem);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function replaceStrAt(string, index, replStr) {
  const len = replStr.length;
  return string.substr(0, index) + replStr + string.substr(index + len);
}

function zeroPad(intVal, paddingLen) {
  let strVal = intVal.toString();
  for (let i = strVal.length; i < paddingLen; i++) {
    strVal = '0' + strVal;
  }
  return strVal;
}
let newCursorPosition = 0;

class DateTimeInput extends Component {
  constructor(props) {
    super(props);
    const {value, onChange} = props;
    if (value.length === 0) {
      onChange(moment().format('DD/MM/YYYY HH:mm:ss'));
    }
    this.onKeyDown = this._onKeyDown.bind(this);
  }
  componentDidUpdate(prevProps) {
    const {inputRef} = this;
    if (prevProps.value !== this.props.value) {
      inputRef.selectionStart = newCursorPosition;
      inputRef.selectionEnd = newCursorPosition;
    }
  }
  _onKeyDown(e) {
    e.stopPropagation();
    const {onChange, value: date} = this.props;
    const {inputRef} = this;
    const cursorPosition = e.target.selectionStart;

    const changeGroup = (operatorFunc) => {
      let newDate;
      if (cursorPosition <= 2) {
        //day
        const day = date.substr(0, 2);
        const month = date.substr(3, 2);
        const year = date.substr(6, 4);
        const daysInMonth = moment(`${month}${year}`, 'MMYYYY').daysInMonth();
        let newDay = operatorFunc(parseInt(day));
        if (newDay === 0) {
          newDay = daysInMonth;
        }else if(newDay > daysInMonth) {
          newDay = 1;
        }
        newDate = replaceStrAt(date, 0, zeroPad(newDay, 2));

      }else if(cursorPosition > 2 && cursorPosition <= 5) {
        // month
        const day = date.substr(0, 2);
        const month = date.substr(3, 2);
        const year = date.substr(6, 4);
        let newMonth = operatorFunc(parseInt(month));
        if (newMonth > 12) {
          newMonth = 1;
        }else if (newMonth < 1) {
          newMonth = 12;
        }
        const paddedMonth = zeroPad(newMonth, 2);
        newDate = replaceStrAt(date, 3, paddedMonth);
        const daysInMonth = moment(`${paddedMonth}${year}`, 'MMYYYY').daysInMonth();
        if (parseInt(day) > daysInMonth) {
          newDate = replaceStrAt(newDate, 0, daysInMonth.toString());
        }

      }else if(cursorPosition > 5 && cursorPosition <= 10) {
        // year
        const day = parseInt(date.substr(0, 2));
        const month = date.substr(3, 2);
        const year = date.substr(6, 4);
        let newYear = operatorFunc(parseInt(year));
        newDate = replaceStrAt(date, 6, newYear.toString());
        const daysInMonth = moment(`${month}${year}`, 'MMYYYY').daysInMonth();
        if (parseInt(day) > daysInMonth) {
          newDate = replaceStrAt(newDate, 0, daysInMonth.toString());
        }

      }else if(cursorPosition > 10 && cursorPosition <= 13) {
        // hours
        const hours = date.substr(11, 2);
        let newHours = operatorFunc(parseInt(hours));
        if (newHours > 23) {
          newHours = 0;
        }else if (newHours < 0) {
          newHours = 23;
        }
        newDate = replaceStrAt(date, 11, zeroPad(newHours, 2));

      }else if(cursorPosition > 13 && cursorPosition <= 16) {
        // minutes
        const minutes = date.substr(14, 2);
        let newMinutes = operatorFunc(parseInt(minutes));
        if (newMinutes >= 60) {
          newMinutes = 0;
        }else if (newMinutes < 0) {
          newMinutes = 59;
        }
        newDate = replaceStrAt(date, 14, zeroPad(newMinutes, 2));

      }else if(cursorPosition > 16) {
        // seconds
        const seconds = date.substr(17);
        let newSeconds = operatorFunc(parseInt(seconds));
        if (newSeconds >= 60) {
          newSeconds = 0;
        }else if (newSeconds < 0) {
          newSeconds = 60;
        }
        newDate = replaceStrAt(date, 17, zeroPad(newSeconds, 2));

      }
      newCursorPosition = cursorPosition;
      if (newDate === date) {
        inputRef.selectionStart = newCursorPosition;
        inputRef.selectionEnd = newCursorPosition;
      }
      onChange(newDate);

    };

    if (e.key === "ArrowDown") {
      e.preventDefault();
      changeGroup(val => val - 1);
    }else if(e.key === "ArrowUp") {
      e.preventDefault();
      changeGroup(val => val + 1);
    }else if(e.key === " ") {
      e.preventDefault();
      newCursorPosition = cursorPosition + 1;

    }else if(e.key === "Backspace") {
      e.preventDefault();
      newCursorPosition = cursorPosition;
      if (cursorPosition !== 0) {
        newCursorPosition = cursorPosition - 1;
      }

      const positionsToZero = [1, 4, 8, 9, 10, 12, 13, 15, 16, 18, 19];
      if (positionsToZero.indexOf(cursorPosition) !== -1) {
        const newDate = replaceStrAt(date, cursorPosition - 1, '0');
        if (newDate === date) {
          inputRef.selectionStart = newCursorPosition;
          inputRef.selectionEnd = newCursorPosition;
        }
        onChange(newDate);

      }else if(cursorPosition === 2 || cursorPosition === 5) {
        const newDate = replaceStrAt(date, cursorPosition - 1, '1');
        if (newDate === date) {
          inputRef.selectionStart = newCursorPosition;
          inputRef.selectionEnd = newCursorPosition;
        }
        onChange(newDate);
      }
      inputRef.selectionStart = newCursorPosition;
      inputRef.selectionEnd = newCursorPosition;

    }else if(!isNaN(e.key)) {
      e.preventDefault();
      const num = parseInt(e.key);
      let newDate;
      newCursorPosition = cursorPosition;
      if (cursorPosition === 0) {
        const month = date.substr(3, 2);
        const year = date.substr(6, 4);
        const daysInMonth = moment(`${month}${year}`, 'MMYYYY').daysInMonth();
        let newDay = e.key + date.substr(1, 2);
        if (parseInt(newDay) > daysInMonth) {
          newDay = '0' + e.key;
          newCursorPosition = 3;
        }else {
          newCursorPosition = 1;
        }
        newDate = replaceStrAt(date, 0, newDay);

      }else if(cursorPosition === 1) {
        const month = date.substr(3, 2);
        const year = date.substr(6, 4);
        const daysInMonth = moment(`${month}${year}`, 'MMYYYY').daysInMonth();
        let newDay = date.substr(0, 1) + e.key;
        if (parseInt(newDay) > daysInMonth) {
          newDay = daysInMonth.toString();
        }
        newCursorPosition = 3;
        newDate = replaceStrAt(date, 0, newDay);

      }else if(cursorPosition === 3) {
        const month2 = date.substr(4, 1);
        if (num <= 1 && parseInt(month2) < 3) {
          newDate = replaceStrAt(date, 3, e.key + month2);
          newCursorPosition = 4;
        }else {
          newDate = replaceStrAt(date, 3, '0' + e.key);
          newCursorPosition = 6;
        }
      }else if(cursorPosition === 4) {
        const month1 = date.substr(3, 1);
        if (month1 === '1' && num > 2) {
          return;
        }else {
          newDate = replaceStrAt(date, 4, e.key);
          newCursorPosition = 6;
        }

      }else if(cursorPosition > 5 && cursorPosition < 10) {
        const year = date.substr(6, 4);
        const newYear = replaceStrAt(year, cursorPosition - 6, e.key);
        if (parseInt(newYear) > (new Date()).getFullYear() || parseInt(newYear) < 2000) {
          return;
        }else {
          newDate = replaceStrAt(date, 6, newYear);
          newCursorPosition = cursorPosition + 1;
        }
        if (newCursorPosition === 10) {
          newCursorPosition++;
        }

      }else if(cursorPosition === 11) {
        const hour2 = date.substr(12, 1);
        if (num > 2 || (num === 2 && parseInt(hour2) > 3)) {
          newDate = replaceStrAt(date, 11, '0' + e.key);
          newCursorPosition = 14;
        }else {
          newDate = replaceStrAt(date, 11, e.key);
          newCursorPosition = 12;
        }
      }else if(cursorPosition === 12) {
        const hour1 = date.substr(11, 1);
        if (parseInt(hour1) < 2 || (hour1 === '2' && num < 4)) {
          newDate = replaceStrAt(date, 12, e.key);
          newCursorPosition = 14;
        }else {
          return;
        }
      }else if(cursorPosition === 14) {
        if (num > 5) {
          newDate = replaceStrAt(date, 14, '0' + e.key);
          newCursorPosition = 17;
        }else {
          newDate = replaceStrAt(date, 14, e.key);
          newCursorPosition = 15;
        }
      }else if(cursorPosition === 15) {
        newDate = replaceStrAt(date, 15, e.key);
        newCursorPosition = 17;
      }else if(cursorPosition === 17) {
        if (num > 5) {
          newDate = replaceStrAt(date, 17, '0' + e.key);
        }else {
          newDate = replaceStrAt(date, 17, e.key);
        }
        newCursorPosition = 18;
      }else if(cursorPosition === 18) {
        newDate = replaceStrAt(date, 18, e.key);
      }

      if (newDate === date) {
        inputRef.selectionStart = newCursorPosition;
        inputRef.selectionEnd = newCursorPosition;
      }
      onChange(newDate);
    }else if(e.key.length === 1) {
      e.preventDefault();
    }
  }
  render() {
    const {value, onChange, ...rest} = this.props;
    return (
      <span>
        <input type="text" value={value} onKeyDown={this.onKeyDown} ref={(ref) => this.inputRef = ref} {...rest}  />
      </span>
    );
  }
}

DateTimeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

DateTimeInput.defaultProps = {
  value: moment().format('DD/MM/YYYY HH:mm:ss'),
};

export default DateTimeInput;

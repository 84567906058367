import {CHANGE_PROP_ALL_APS, GET_APS, SET_APS, DECOMMISSION_NODE, DECOMMISSION_SELECTED} from '../constants/apActionTypes';
import {LAMP_SELECTION_DIALOG_MODE_PPS, LAMP_SELECTION_DIALOG_MODE_NODES} from '../constants/customTypes';
import {decommission} from '../actions/apActions';
import {SET_PPS} from '../constants/ppActionTypes';
import {makeUid} from '../utils/idHelper';

const apMiddleware = store => next => action => {
  const {type} = action;
  const {dispatch} = store;
  if (type === CHANGE_PROP_ALL_APS) {
    const {filterReducer} = store.getState();
    const ids = filterReducer.filteredApIds;
    action.ids = ids;
    return next(action);
  }else if(type === GET_APS.SUCCESS) {
    const {response, props} = action;
    const {dataport} = props;
    const pps = [];
    const map = {};
    const idApsMap = {};
    const aps = response.map((ap) => {
      ap.clientIds = ap.clients.map(client => {
        client.parentId = ap.id;
        client.id = makeUid(ap.id, client.terminal_id);
        pps.push(client);
        if (map[client.ipui] === undefined) {
          map[client.ipui] = [client.id];
        }else {
          map[client.ipui] = map[client.ipui].concat([client.id]);
        }
        if (idApsMap[client.ipui] === undefined) {
          idApsMap[client.ipui] = [ap.id];
        }else {
          idApsMap[client.ipui].push(ap.id);
        }
        return client.id;
      });

      delete ap.clients;
      return ap;
    });


    dispatch({
      type: SET_APS,
      aps,
      dataport,
    });

    dispatch({
      type: SET_PPS,
      pps,
      idApsMap,
      dataport,
    });
    // console.log('map', map);

  }else if(type === DECOMMISSION_NODE) {
    const {ids} = action;
    const {ppReducer} = store.getState();
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {dataportSlug} = buildingReducer[activeBuildingId];

    const key = `${dataportSlug}/ids`;
    const ppIds = ppReducer[key];
    ppIds.forEach(ppId => {
      const {ipui, terminal_id, parentId} = ppReducer[ppId];
      if (ids.indexOf(ipui) > -1) {
        dispatch(decommission(parentId, [terminal_id]));
      }
    });
  }else if(type === DECOMMISSION_SELECTED) {
    const {planReducer, ppReducer, buildingReducer} = store.getState();
    const {lampSelectionDialogMode, selectedLampMacs} = planReducer;

    const {activeBuildingId} = buildingReducer;
    const {dataportSlug} = buildingReducer[activeBuildingId];

    const key = `${dataportSlug}/ids`;
    const ppIds = ppReducer[key];

    const apMap = {};
    if (lampSelectionDialogMode === LAMP_SELECTION_DIALOG_MODE_PPS) {

      ppIds.forEach(ppId => {
        const {parentId, terminal_id, selected} = ppReducer[ppId];
        if (selected) {
          if (apMap[parentId] === undefined) {
            apMap[parentId] = [terminal_id];
          }else {
            apMap[parentId].push(terminal_id);
          }
        }
      });
    }else if (lampSelectionDialogMode === LAMP_SELECTION_DIALOG_MODE_NODES) {
      ppIds.forEach(ppId => {
        const {parentId, terminal_id, ipui} = ppReducer[ppId];
        if (selectedLampMacs.indexOf(ipui) > -1) {
          if (apMap[parentId] === undefined) {
            apMap[parentId] = [terminal_id];
          }else {
            apMap[parentId].push(terminal_id);
          }
        }
      });
    }

    Object.keys(apMap).forEach(apId => {
      dispatch(decommission(apId, apMap[apId]));
    });
  }else {
    return next(action);
  }
};

export default apMiddleware;

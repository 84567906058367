import {SET_SELECTED_OBJECTS} from '../constants/selectedObjectsActionTypes';
import objectAssign from 'object-assign';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {
  objectNames: [],
};

export default function selectedObjectsReducer(state = initialState, action) {

  switch (action.type) {
    case SET_SELECTED_OBJECTS:{
      const {objectNames, objects} = action;
      return objectAssign({}, objects, {objectNames: objectNames});
    }

    default:
      return state;
  }
}

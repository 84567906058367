import {TRIVIEW_API} from '../constants/urls';

export function getPlanImgUrl(s3Key, filename) {
  let url;
  if (LOCAL) {
    url = TRIVIEW_API.PLAN_IMG.replace(':filename', filename);
  }else if(CLOUD) {
    url = s3Key;
  }
  return url;
}

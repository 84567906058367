import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/planImgActions';
import Drag from '../components/Drag';
import {getPlanImgUrl} from '../utils/planImgUrlHelper';

const Image = ({planImg, width, height, actions, imgDrag}) => {
  if (!planImg) {
    return null;
  }
  const {s3Key, filename, x, y, scale, rotation, id, opacity} = planImg;
  if (LOCAL && filename.length === 0) {
    return null;
  }else if(CLOUD && (!s3Key || s3Key.length === 0)) {
    return null;
  }

  const url = getPlanImgUrl(s3Key, filename);
  const {dragPlanImg} = actions;

  let style = {};
  if (!imgDrag) {
    style.pointerEvents = 'none';
  }
  let transform = '';
  if (x !== undefined) {
    transform = `translate(${x}, ${y}) scale(${scale})\
    translate(${width / 2}, ${height / 2}) rotate(${rotation})\
    translate(${-width / 2}, ${-height / 2})`;
  }
  const img = (
    <image xlinkHref={url} x="0" y="0"
    width={width} height={height} opacity={opacity}
    transform={transform} scale={scale} style={style} />
  );

  const dragAction = (deltaX, deltaY) => {
    dragPlanImg(id, deltaX, deltaY);
  };

  if (imgDrag) {
    return (
      <Drag action={dragAction}>
        {img}
      </Drag>
    );
  }

  return img;
};

Image.propTypes = {
  planImg: PropTypes.object,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  imgDrag: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {buildingReducer, planImgReducer} = state;
  const {activeBuildingId} = buildingReducer;
  if (!activeBuildingId) {
    return {};
  }
  const planImg = planImgReducer[activeBuildingId];
  return {
    planImg: planImg,
    imgDrag: planImgReducer.imgDrag,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Image);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import '../styles/display-mode-control.scss';
import { getTranslate } from 'react-localize-redux';
import {hasPermission, PERMISSION_TECHNICAL, PERMISSION_CONFIG,
  PERMISSION_CONFIG_MOVEMENT_DATA, PERMISSION_CONFIG_ENERGY_DATA,
  PERMISSION_CLIENT_REPORTS,
} from '../constants/permissions';
import ModeBtn from './ModeBtn';

const DisplayModeControl = ({ permissions, translate, clientPermissions}) => {
  let technicalBtn = null;
  let drawingBtn = null;
  let configurationBtn = null;
  let firmwareBtn = null;
  let dataportBtn = null;
  let movementBtn = null;
  let energyBtn = null;
  let reportsBtn = null;

  if (hasPermission(permissions, PERMISSION_TECHNICAL)) {
    drawingBtn = (
      <ModeBtn to="/plan/drawing/">
        {translate('displaymode_drawing')}
      </ModeBtn>
    );

    technicalBtn = (
      <ModeBtn to="/plan/technical/">
        {translate('displaymode_technical')}
      </ModeBtn>
    );

    firmwareBtn = (
      <ModeBtn to="/plan/firmware/">
        Firmware
      </ModeBtn>
    );

    dataportBtn = (
      <ModeBtn to="/plan/dataport/">
        Dataport
      </ModeBtn>
    );
  }

  if (hasPermission(permissions, PERMISSION_CONFIG)) {
    configurationBtn = (
      <ModeBtn to="/plan/config/">
        {translate('displaymode_configuration')}
      </ModeBtn>
    );
  }

  if (CLOUD && hasPermission(permissions, PERMISSION_CONFIG_MOVEMENT_DATA)) {
    movementBtn = (
      <ModeBtn to="/plan/movement/">
        {translate('displaymode_movement')}
      </ModeBtn>
    );
  }

  if (CLOUD && hasPermission(permissions, PERMISSION_CONFIG_ENERGY_DATA)) {
    energyBtn = (
      <ModeBtn to="/plan/energy/">
        {translate('displaymode_energy')}
      </ModeBtn>
    );
  }

  if (CLOUD && clientPermissions && hasPermission(clientPermissions, PERMISSION_CLIENT_REPORTS)) {
    reportsBtn = (
      <ModeBtn to="/plan/reports/">
        {translate('displaymode_reports')}
      </ModeBtn>
    );
  }

  return (
    <span className="display-mode-control">
      {drawingBtn}
      {dataportBtn}
      {firmwareBtn}
      {technicalBtn}
      {configurationBtn}
      <ModeBtn to="/plan/live/">
        {translate('displaymode_live')}
      </ModeBtn>
      {movementBtn}
      {energyBtn}
      {reportsBtn}
    </span>
  );
};

DisplayModeControl.propTypes = {
  permissions: PropTypes.array.isRequired,
  clientPermissions: PropTypes.array,
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {buildingReducer, localeReducer, authReducer, clientReducer} = state;
  const {activeBuildingId} = buildingReducer;
  const {activeClientKey} = clientReducer;
  const {permissions : clientPermissions} = clientReducer[activeClientKey];
  const permissions = authReducer[activeBuildingId];
  const translate = getTranslate(localeReducer);
  return {
    permissions: permissions !== undefined ? permissions:[],
    clientPermissions,
    translate,
  };
}

export default connect(
  mapStateToProps,
  null,
)(DisplayModeControl);

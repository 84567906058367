import { GET_CLIENTS, SET_ACTIVE_CLIENT, UPDATE_CLIENT, SET_SELECTED_CLIENT_ID } from '../constants/clientActionTypes';
import {setActiveClient, setClientRouteSlug} from '../actions/clientActions';
import {clearLiveProps} from '../actions/liveActions';
import { replace, push } from 'connected-react-router';
import {getBuildings, setActiveBuilding, setShowBuildingsList} from '../actions/buildingActions';
import {isRoute} from '../utils/helper';
import {ROUTE_REPORT} from '../constants/const';

const clientMiddleware = store => next => action => {
  const {dispatch} = store;
  if (action.type === GET_CLIENTS.SUCCESS) {
    next(action);
    const {clientReducer, routingReducer} = store.getState();
    const {currentRoute} = routingReducer;
    if (currentRoute.substr(0, 5) !== '/plan') {
      return;
    }

    const {routeSlug} = clientReducer;
    if (routeSlug) {
      for (const id of action.ids) {
        if (clientReducer[id].slug === routeSlug) {
          dispatch(setActiveClient(id));
          break;
        }
      }
      dispatch(setClientRouteSlug(undefined));
    }else if(action.ids.length === 1) {
      dispatch(setActiveClient(action.ids[0]));
    }

  }else if (action.type === SET_ACTIVE_CLIENT) {
    const {clientReducer, routingReducer} = store.getState();
    const {activeClientKey} = clientReducer;
    const {slug, buildingIds} = clientReducer[action.id];
    const {currentRoute} = routingReducer;
    dispatch(clearLiveProps());

    if (isRoute(currentRoute, ROUTE_REPORT) && activeClientKey && activeClientKey !== action.id) {
      dispatch(push(`/plan/reports/${slug}`));

    }else {

      const [, routePrefix, routePostFix] = /^(\/[^/]+\/[^/]+)(.*)/.exec(currentRoute);

      if (routePostFix === '') {
        dispatch(replace(`${routePrefix}/${slug}`));
      }
    }

    next(action);
    if (buildingIds !== undefined) {
      if (buildingIds.length === 1) {
        dispatch(setActiveBuilding(buildingIds[0]));
      }else {
        dispatch(setShowBuildingsList(true));
      }
    }else {
      dispatch(getBuildings(slug, true));
    }

  }else if (action.type === UPDATE_CLIENT.SUCCESS) {
    next(action);
    dispatch(push('/clients'));
  }else if (action.type === SET_SELECTED_CLIENT_ID) {
    next(action);
    if (action.id !== null && action.id !== undefined) {
      const {slug} = store.getState().clientReducer[action.id];
      dispatch(getBuildings(slug, false));
    }
  }

  return next(action);

};

export default clientMiddleware;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/planImgActions';
import '../styles/alert.scss';
import { getTranslate } from 'react-localize-redux';

const ImgForm = ({planImg, imgDrag, actions, translate}) => {
  const {setPlanImgProps, setImgDrag} = actions;
  if (planImg === undefined) {
    return null;
  }
  const { scale, rotation, id, opacity } = planImg;
  return (
    <form className="inline-form">
      <fieldset>
        <p>
          <label htmlFor="scale">{translate('technical_scale')}:</label>
          <input type="number" className="form-control" id="scale" placeholder="Scale"
          min="0.1" max="100" step="0.1" value={scale}
          onChange={(e) => setPlanImgProps(id, {scale: e.target.value})} />
        </p>
        <p>
          <label htmlFor="rotation">{translate('technical_rotation')}:</label>
          <input type="number" className="form-control" id="rotation" placeholder="Rotation"
          min="0" max="270" step="90" value={rotation}
          onChange={(e) => setPlanImgProps(id, {rotation: e.target.value})} />
        </p>
        <p>
          <label htmlFor="opacity">{translate('technical_opacity')}:</label>
          <input type="number" className="form-control" id="opacity" placeholder="Rotation"
          min="0" max="1" step="0.1" value={opacity}
          onChange={(e) => setPlanImgProps(id, {opacity: e.target.value})} />
        </p>
      </fieldset>
      <div className="checkbox">
        <label>
          <input type="checkbox" checked={imgDrag}
          onChange={() => setImgDrag(!imgDrag)} /> {translate('technical_image_drag')}
        </label>
      </div>
    </form>
  );
};

ImgForm.propTypes = {
  planImg: PropTypes.object,
  actions: PropTypes.object.isRequired,
  imgDrag: PropTypes.bool,
  planImgSaved: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {buildingReducer, planImgReducer} = state;
  const {activeBuildingId} = buildingReducer;
  const translate = getTranslate(state.localeReducer);
  if (!activeBuildingId) {
    return {translate};
  }
  const planImg = planImgReducer[activeBuildingId];
  const {imgDrag} = planImgReducer;
  return {
    planImg,
    imgDrag,
    translate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImgForm);

import {SET_PP_PROPS, CHANGE_SELECT_ALL_PPS,
SET_MULTI_PP_PROPS} from '../constants/ppActionTypes';

export function setPpProps(id, obj) {
  return {
    type: SET_PP_PROPS,
    id,
    obj,
  };
}

export function changeSelectAllPps(selected) {
  return {
    type: CHANGE_SELECT_ALL_PPS,
    selected,
  };
}

export function setMultiPpProps(ids, obj) {
  return {
    type: SET_MULTI_PP_PROPS,
    ids,
    obj
  };
}


import { DRAG_PLAN_IMG, SET_PLAN_IMG_DEFAULT_VIEW} from '../constants/planImgActionTypes';
import {setPlanImgProps} from '../actions/planImgActions';
import {getDeltaXDeltaYNormalized} from '../utils/helper';

const planImgMiddleware = store => next => action => {
  const {dispatch} = store;
  if(action.type === DRAG_PLAN_IMG) {
    const {planReducer, buildingReducer, planImgReducer} = store.getState();
    const {id, deltaX, deltaY} = action;
    const {xMap, yMap} = planReducer;
    const {activeBuildingId} = buildingReducer;
    const {rotation, zoomK} = buildingReducer[activeBuildingId];
    const planImg = planImgReducer[id];

    const {nDeltaX, nDeltaY} = getDeltaXDeltaYNormalized(deltaX, deltaY, xMap, yMap, zoomK, rotation);

    dispatch(setPlanImgProps(id, {
      x: planImg.x + nDeltaX,
      y: planImg.y + nDeltaY,
    }));

  }else if(action.type === SET_PLAN_IMG_DEFAULT_VIEW) {
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {rotation, zoomX, zoomY, zoomK} = buildingReducer[activeBuildingId];
    action.view = {rotation, zoomX, zoomY, zoomK};
    action.id = activeBuildingId;
    return next(action);
  }else {
    return next(action);
  }

};

export default planImgMiddleware;

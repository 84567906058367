import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../components/Checkbox';
import ActionButton from '../components/ActionButton';
import {FETCH_AUTH_PARAMS, TRIVIEW_API} from '../constants/urls';
import {STARTED, DONE, INITIAL, ERROR} from '../constants/customTypes';
import {fetchJson} from '../utils/fetch';

const DataStorageOptions = ({configId}) => {
  useEffect(() => {
    const func = async () => {
      const res = await fetch(TRIVIEW_API.DATA_STORAGE_OPTIONS.replace(':configId', configId), {
        method: 'GET',
        ...FETCH_AUTH_PARAMS,
      });
      const dataStorageOptions = await res.json();
      setDataStorageOptions(dataStorageOptions);
    };
    func();
  }, []);

  const [dataStorageOptions, setDataStorageOptions] = useState({
    storeMovement: false,
    storePwmSum: false,
  });

  const [saveStatus, setSaveStatus] = useState(INITIAL);

  const {storePwmSum, storeMovement} = dataStorageOptions;
  const changeDataStorageOptions = (obj) => setDataStorageOptions({...dataStorageOptions, ...obj});

  const save = async () => {
    setSaveStatus(STARTED);
    const res = await fetchJson(TRIVIEW_API.DATA_STORAGE_OPTIONS.replace(':configId', configId), {
      method: 'PUT',
      json: dataStorageOptions,
      ...FETCH_AUTH_PARAMS,
    });

    if (res.status >= 300) {
      setSaveStatus(ERROR);
    }else {
      setSaveStatus(DONE);
    }
  };

  return (
    <form onSubmit={(e) => { e.preventDefault(); save();}}>
      <Checkbox
        name="storeMovement"
        id="storeMovement"
        checked={storeMovement}
        onChange={() => changeDataStorageOptions({storeMovement: !storeMovement})}
      >
        Store Movement
      </Checkbox>
      <Checkbox
        name="storePwmSum"
        id="storePwmSum"
        checked={storePwmSum}
        onChange={() => changeDataStorageOptions({storePwmSum: !storePwmSum})}
      >
        Store Pwm Sum
      </Checkbox>
      <ActionButton text="Save" status={saveStatus} isSubmit={true} />
    </form>
  );
};

DataStorageOptions.propTypes = {
  configId: PropTypes.number.isRequired,
};

export default DataStorageOptions;

import {SET_LINES, SET_SHOW_LINE_CONTROL, SET_LINE_PROPS, SELECT_LINE, CREATE_LINE, COPY_LINES,
  DRAG_LINE, CHANGE_SELECT_ALL_LINES, ALIGN_SELECTED_LINES,
  DELETE_LINES, ADD_LAMP_TO_LINE, REMOVE_LAMP_FROM_LINE, SET_LINE_DRAG} from '../constants/lineActionTypes';
import objectAssign from 'object-assign';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {
  lineIds: [],
  showLineControl: false,
  canDragLine: false,
  selectedLineIds: [],
};

export default function lineReducer(state = initialState, action) {

  switch (action.type) {
    case SET_LINES:
      return objectAssign({}, state, action.lines);
    case SET_SHOW_LINE_CONTROL:{
      return objectAssign({}, state, {showLineControl: action.showLineControl});
    }
    case SET_LINE_DRAG:{
      return objectAssign({}, state, {canDragLine: action.canDragLine});
    }
    case SET_LINE_PROPS:{
      let newState = objectAssign({}, state);
      const {id, obj} = action;
      newState[id] = objectAssign({}, state[id], obj);
      return newState;
    }
    case SELECT_LINE:{
      const {selectedLineId} = action;
      let newState = objectAssign({}, state);
      let selectedLineIds = [].concat(state.selectedLineIds);
      let selected;
      if (state[selectedLineId].selected === true) {
        selected = false;
        selectedLineIds.splice(selectedLineIds.indexOf(selectedLineId), 1);
      }else {
        selected = true;
        selectedLineIds.push(selectedLineId);
      }

      newState[selectedLineId] = objectAssign({}, state[selectedLineId], {selected});
      newState.selectedLineIds = selectedLineIds;

      return newState;
    }
    case DELETE_LINES:{
      let newState = objectAssign({}, state);
      const {lineIds} = action;
      let selectedLineIds = [].concat(state.selectedLineIds);
      lineIds.forEach((lineId) => {
        delete newState[lineId];
        selectedLineIds.splice(selectedLineIds.indexOf(lineId), 1);
      });
      newState.selectedLineIds = selectedLineIds;
      return newState;
    }
    case CREATE_LINE:{
      let newState = objectAssign({}, state);
      const {lineId, psId} = action;
      state.selectedLineIds.forEach(lineId => {
        newState[lineId] = objectAssign({}, state[lineId], {selected: false});
      });
      newState.selectedLineIds = [lineId];
      newState[lineId] = {
        id: lineId,
        length: 5000,
        rotation: 0,
        x: 500,
        y: 500,
        showPs: true,
        selected: true,
        lampIds: [],
        subLampIds: [],
        psId,
      };
      return newState;
    }
    case ADD_LAMP_TO_LINE:{
      let newState = objectAssign({}, state);
      const {lampId, lineId} = action;
      let newLamps = [].concat(state[lineId].lampIds);
      newLamps.push(lampId);
      newState[lineId] = objectAssign({}, state[lineId], {
        lampIds: newLamps,
      });
      return newState;
    }
    case REMOVE_LAMP_FROM_LINE:{
      let newState = objectAssign({}, state);
      const {lampId, lineId} = action;
      let newLamps = [].concat(state[lineId].lampIds);
      newLamps.splice(newLamps.indexOf(lampId), 1);
      newState[lineId] = objectAssign({}, state[lineId], {
        lampIds: newLamps,
      });
      return newState;
    }
    case COPY_LINES:{
      let newState = objectAssign({}, state, action.obj);
      state.selectedLineIds.forEach((lineId) => {
        newState[lineId] = objectAssign({}, state[lineId], {selected: false});
      });
      newState.selectedLineIds = action.selectedLineIds;
      return newState;
    }
    case DRAG_LINE:{
      let newState = objectAssign({}, state);
      const {deltaX, deltaY} = action;
      if (state.selectedLineIds.length > 1) {
        state.selectedLineIds.forEach((lineId) => {
          const line = state[lineId];
          newState[lineId] = objectAssign({}, line, {
            x: line.x + deltaX,
            y: line.y + deltaY,
          });
        });
      }else {
        const line = newState[action.lineId];
        newState[action.lineId] = objectAssign({}, line, {
          x: line.x + deltaX,
          y: line.y + deltaY,
        });
      }
      return newState;
    }
    case CHANGE_SELECT_ALL_LINES:{
      const {selected, lineIds} = action;
      let newState = objectAssign({}, state);
      lineIds.forEach((lineId) => {
        newState[lineId] = objectAssign({}, state[lineId], {selected});
      });
      if (selected) {
        newState.selectedLineIds = lineIds;
      }else {
        newState.selectedLineIds = [];
      }
      return newState;
    }
    case ALIGN_SELECTED_LINES:{
      const {avg, prop, selectedLineIds} = action;
      const newState = objectAssign({}, state);
      selectedLineIds.forEach(uid => {
        const obj = {};
        obj[prop] = avg;
        newState[uid] = objectAssign({}, state[uid], obj);
      });
      return newState;
    }


    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/buildingActions';
import '../styles/selected-building.scss';
import { getTranslate } from 'react-localize-redux';

const SelectedBuilding = ({building, actions, activeClientKey, showBuildingsList, translate}) => {
  if (CLOUD && !activeClientKey) {
    return null;
  }

  const {setShowBuildingsList} = actions;
  const onClick = () => {
    setShowBuildingsList(!showBuildingsList);
  };

  return (
    <div className="selected-building">
      <div className="pointer header" onClick={onClick}>
        {building ? building.name: translate('building_select_building')}{' '}
        <i className="fa fa-caret-square-o-down" aria-hidden="true" />
      </div>
    </div>
  );
};

SelectedBuilding.propTypes = {
  actions: PropTypes.object.isRequired,
  activeClientKey: PropTypes.number,
  building: PropTypes.object,
  showBuildingsList: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {clientReducer, buildingReducer} = state;
  const {showBuildingsList} = buildingReducer;
  const {activeClientKey} = clientReducer;
  const building = buildingReducer[buildingReducer.activeBuildingId];
  const translate = getTranslate(state.localeReducer);
  return {
    activeClientKey,
    building,
    showBuildingsList,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectedBuilding);

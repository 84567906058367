import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../actions/firmwareActions';
import {UPGRADER_STATUS_STOPPED, UPGRADER_STATUS_CLEARED} from '../constants/customTypes';
import {bindActionCreators} from 'redux';
import '../styles/upgrade-bar.scss';
import { getTranslate } from 'react-localize-redux';

const ItemsSelectionControl = ({ actions, todo, upgraded, stop, failed, status, translate}) => {

  if (status === UPGRADER_STATUS_CLEARED) {
    return null;
  }

  const {stopUpgrade, clearUpgrade} = actions;
  return (
    <div className="upgrade-bar">
      <span className="upgrade-block">
        {translate('technical_todo')}: <span className="badge">{todo}</span>
      </span>
      <span className="upgrade-block">
        {translate('technical_upgraded')}: <span className="badge">{upgraded}</span>
      </span>
      <span className="upgrade-block">
        {translate('technical_failed')}: <span className="badge">{failed}</span>
      </span>
      <button className="btn btn-danger" onClick={() => stopUpgrade()}
      disabled={stop === true || status === UPGRADER_STATUS_STOPPED}>
        {translate('technical_stop')}
      </button>
      {' '}
      <button className="btn btn-default" onClick={() => clearUpgrade()}
      disabled={status !== UPGRADER_STATUS_STOPPED}>
        {translate('technical_clear')}
      </button>
    </div>
  );
};

/*
TODO change button -> to ActionButtons (add disabled attribute)
<ActionButton action={() => stopUpgrade()}
  extraClass="btn-danger"
  disabled=?
  text={translate('technical_stop')} />

<ActionButton action={() => clearUpgrade()}
  extraClass="btn-default"
  disabled=?
  text={translate('technical_clear')} />
*/

ItemsSelectionControl.propTypes = {
  actions: PropTypes.object.isRequired,
  todo: PropTypes.number.isRequired,
  upgraded: PropTypes.number.isRequired,
  failed: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  stop: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {firmwareReducer} = state;
  const {todo, upgraded, failed, status, stop} = firmwareReducer;
  const translate = getTranslate(state.localeReducer);

  return {
    todo,
    upgraded,
    failed,
    status,
    stop,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemsSelectionControl);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/lampActions';
import Drag from '../components/Drag';
import {DRAG_LEFT, DRAG_RIGHT, DRAG_TOP, DRAG_BOTTOM} from '../constants/customTypes';
import {LAMP_TYPE_MASTER} from '../constants/lampTypeTypes';
import {getLeftRightByType} from '../utils/lampHelper';

const DragBorderZone = ({x, y,
  borderThickness, id, lampType, lampIds, lamp,
  extraRotation, padding,
  rotation, color, className, actions, ...rest}) => {
    const {top, bottom} = lamp;
    if (top === undefined) {
      return null;
    }
    const {left, right} = getLeftRightByType(lamp.left, lamp.right, lampType);
    const halfThickness = borderThickness / 2;
    const {dragHitzone} = actions;
    const transform = `translate(${x}, ${y}) rotate(${rotation})`;
    const totalRotation = extraRotation + rotation;
    let vertClassName = 'ew-resize';
    let horClassName = 'ns-resize';
    if (totalRotation % 180 === 0) {
      vertClassName = 'ns-resize';
      horClassName = 'ew-resize';
    }

    if (lampType.relationType === LAMP_TYPE_MASTER && lampIds.length > 0) {
      return (
        <g transform={transform}>
          {lampIds.map(id => <ConnectedDragBorderZone className={className} key={id} id={id} extraRotation={extraRotation} />)}
        </g>
      );
    }

    return (
      <g className={className} transform={transform} {...rest}>
        <Drag reverse={true} action={(deltaX, deltaY) => dragHitzone(id, DRAG_TOP, deltaX, deltaY)}>
          <rect fill={color} height={borderThickness} stroke="none" className={vertClassName}
            width={left + right + borderThickness} x={-left - halfThickness} y={-top - halfThickness + padding} />
        </Drag>
        <Drag action={(deltaX, deltaY) => dragHitzone(id, DRAG_BOTTOM, deltaX, deltaY)}>
          <rect fill={color} height={borderThickness} stroke="none" className={vertClassName}
            width={left + right + borderThickness} x={-left - halfThickness} y={bottom - halfThickness - padding} />
        </Drag>
        <Drag reverse={true} action={(deltaX, deltaY) => dragHitzone(id, DRAG_LEFT, deltaX, deltaY)}>
          <rect fill={color} height={top + bottom + borderThickness} stroke="none" className={horClassName}
            width={borderThickness} x={-left - halfThickness + padding} y={-top - halfThickness} />
        </Drag>
        <Drag action={(deltaX, deltaY) => dragHitzone(id, DRAG_RIGHT, deltaX, deltaY)}>
          <rect fill={color} height={top + bottom + borderThickness} stroke="none" className={horClassName}
            width={borderThickness} x={right - halfThickness - padding} y={-top - halfThickness} />
        </Drag>
      </g>
    );
};

DragBorderZone.propTypes = {
  id: PropTypes.string.isRequired,
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  padding: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  borderThickness: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  lampIds: PropTypes.array,
  extraRotation: PropTypes.number.isRequired,
};

DragBorderZone.defaultProps = {
  y: 0,
  rotation: 0,
  className: '',
  color: 'black',
  borderThickness: 80,
  extraRotation: 0,
  padding: 100,
};

function mapStateToProps(state, {id}) {
  const {lampReducer, lampTypeReducer} = state;
  const lamp = lampReducer[id];
  const {typeId, x, y, rotation, lampIds} = lamp;
  const lampType = lampTypeReducer[typeId];
  return {
    lamp,
    lampType,
    x,
    y,
    rotation,
    lampIds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

const ConnectedDragBorderZone = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DragBorderZone);

export default ConnectedDragBorderZone;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/authActions';
import { getTranslate } from 'react-localize-redux';
import ActionButton from '../components/ActionButton';

const ForgotPasswordPage = ({actions, translate, forgotPasswordStatus}) => {
  const {forgotPassword} = actions;
  const [email, setEmail] = useState('');
  const submit = (event) => {
    event.preventDefault();
    forgotPassword(email);
  };

  return (
    <div className="forgot-password-page">
      <Helmet>
        <title>{translate('page_title_forgot_password')}</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{paddingTop: '10px'}}>
            <h2>{translate('forgot_password_title')}</h2>
            <form onSubmit={submit}>
              <p>
                <label htmlFor="email">Email:</label>
                <input type="email" value={email} id="email" className="form-control"
                  onChange={(event) => setEmail(event.target.value)} />
              </p>
              <ActionButton
                isSubmit
                extraClass="btn-primary" status={forgotPasswordStatus}
                text={translate('forgot_password_submit')} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

ForgotPasswordPage.propTypes = {
  actions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  forgotPasswordStatus: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {forgotPasswordStatus} = state.authReducer;
  const translate = getTranslate(state.localeReducer);
  return {
    forgotPasswordStatus,
    translate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordPage);

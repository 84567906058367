import {SET_EXTRA_VAR_TEMP_VALUE, INIT_EXTRA_VARS, SAVE_EXTRA_VAR_VALUE, SET_EXTRA_VAR_IS_EDITING,
CREATE_EXTRA_LAMP_VARS} from '../constants/extraVariableActionTypes';

export function setExtraVarTempValue(id, name, value) {
  return {
    type: SET_EXTRA_VAR_TEMP_VALUE,
    id,
    name,
    value,
  };
}

export function initExtraVars(varObj) {
  return {
    type: INIT_EXTRA_VARS,
    varObj,
  };
}

export function saveExtraVarValue(id, name) {
  return {
    type: SAVE_EXTRA_VAR_VALUE,
    id,
    name,
  };
}

export function setExtraVarIsEditing(id, name, isEditing) {
  return {
    type: SET_EXTRA_VAR_IS_EDITING,
    id,
    name,
    isEditing,
  };
}

export function createExtraLampVars(id) {
  return {
    type: CREATE_EXTRA_LAMP_VARS,
    id,
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import PlanPageWrapper from './PlanPageWrapper';
import Plan from './Plan';
import LiveBar from './LiveBar';

const PlanLivePage = () => {
  return (
    <>
      <LiveBar/>
      <div className="container-fluid control-container">
        <div className="col-md-12">
          <Plan showLegend className="border"
            interactive={false}
          />
        </div>
      </div>
    </>
  );
};

PlanLivePage.propTypes = {
  planHeight: PropTypes.number.isRequired,
};

export default PlanPageWrapper(PlanLivePage, 'page_title_plan_live');

import {GET_MOVEMENT_RETENTION_PERIODS} from '../constants/movementRetentionActionTypes';
import {TRIVIEW_API} from '../constants/urls';
import {API, GET} from '../constants/customTypes';

export function getMovementRetentionPeriods(configId) {

  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      url: TRIVIEW_API.MOVEMENT.RETENTION.replace(':configId', configId),
      method: GET,
      success: GET_MOVEMENT_RETENTION_PERIODS.SUCCESS,
      error: GET_MOVEMENT_RETENTION_PERIODS.ERROR,
      start: GET_MOVEMENT_RETENTION_PERIODS.START,
    },
    props: {
      configId,
    },
  };
}

import {SET_ROUTE} from '../constants/routingActionTypes';
import objectAssign from 'object-assign';

const initialState = {
  prevRoute: '',
  currentRoute: '',
};

export default function routingReducer(state = initialState, action) {

  switch (action.type) {
    case SET_ROUTE:{
      const {route} = action;
      return objectAssign({}, state, {
        prevRoute: state.currentRoute,
        currentRoute: route,
      });
    }

    default:
      return state;
  }
}

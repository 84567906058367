import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {bindActionCreators} from 'redux';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as actions from '../actions/dataportActions';
import {TRICOMMAND, TRIAGENT, TRIVIEW_LOCAL} from '../constants/customTypes';
import { Link } from 'react-router-dom';
import SubNav from '../components/SubNav';
import ActionButton from '../components/ActionButton';
import OptionalTableRow from '../components/OptionalTableRow';
import Interface from '../components/Interface';
import CommandOutput from '../components/CommandOutput';
import FormInput from '../components/FormInput';

const DataportTechnicalDetailPage = ({actions, dataport, dataportId, refreshDataportInfoStatus}) => {
  const {getDataportInfo, refreshDataportInfo, execDataportCommand, setDataportProps, updateDataportPackage} = actions;
  useEffect(() => {
    if (dataportId !== undefined) {
      getDataportInfo(dataportId);
    }
  }, []);

  if (dataport === undefined) {
    return null;
  }
  const {name, product, nodejsVersion, ram, cpuClock, packageVersions, interfaces, id, output, command, code, vpn_ip} = dataport;
  let packageRows = null;
  if (packageVersions) {
    const {tricommand, triagent, triviewLocal} = packageVersions;
    packageRows = (
      <React.Fragment>
        <OptionalTableRow label="TriCommand">
          {tricommand}&nbsp;
          <ActionButton text="Update" action={() => updateDataportPackage(id, TRICOMMAND)} />
        </OptionalTableRow>
        <OptionalTableRow label="TriAgent">
          {triagent}&nbsp;
          <ActionButton text="Update" action={() => updateDataportPackage(id, TRIAGENT)} />
        </OptionalTableRow>
        <OptionalTableRow label="TriView local">
          {triviewLocal}&nbsp;
          <ActionButton text="Update" action={() => updateDataportPackage(id, TRIVIEW_LOCAL)} />
        </OptionalTableRow>
      </React.Fragment>
    );
  }

  let interfacesEl = null;

  if (interfaces !== undefined) {
    interfacesEl = interfaces.map(interf => <Interface key={interf.name} {...interf} />);
  }

  return (
    <div className="site-page">
      <Helmet>
        <title>{name} | TriLED Monitor</title>
      </Helmet>
      <SubNav parentNav="monitor" />
      <div className="container site-detail-page">
        <div className="row site-heading">
          <div className="col-md-12">
            <h2>
              <Link to="/monitor">
                <i className="pointer fa back fa-chevron-left" aria-hidden="true"/>
              </Link>
                &nbsp;{name}&nbsp;
                <ActionButton text="Get and save" action={() => refreshDataportInfo(id)} status={refreshDataportInfoStatus} />
            </h2>
          </div>
        </div>
          <div className="row">
            <div className="col-md-12">
              <table className="table">
                <tbody>
                  <OptionalTableRow label="Vpn Ip">{vpn_ip}&nbsp;
                    <CopyToClipboard text={vpn_ip}><span className="btn btn-default"><i className="fa fa-clipboard" aria-hidden="true" /></span></CopyToClipboard>
                  </OptionalTableRow>
                  <OptionalTableRow label="Product" value={product} />
                  <OptionalTableRow label="Ram" value={ram} />
                  <OptionalTableRow label="CpuClock" value={cpuClock} />
                  <OptionalTableRow label="NodeJS version" value={nodejsVersion} />
                  {packageRows}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <CommandOutput output={output} />
            </div>
            <div className="col-md-12">
              <form onSubmit={(e) => {e.preventDefault(); execDataportCommand(id, command);}}>
                <FormInput id="command" type="text" label="Command" value={command}
                  onChange={(val) => setDataportProps(id, {command: val})}
                />
                <input type="submit" className="btn btn-primary" />
                  &nbsp;Code: {code}
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h3>Interfaces</h3>
              {interfacesEl}
            </div>
          </div>
      </div>
    </div>

  );
};

DataportTechnicalDetailPage.propTypes = {
  actions: PropTypes.object.isRequired,
  dataport: PropTypes.object,
  dataportId: PropTypes.string,
  refreshDataportInfoStatus: PropTypes.string.isRequired,
};

function mapStateToProps({dataportReducer}, props) {
  const {dataportId} = props.match.params;
  const {refreshDataportInfoStatus} = dataportReducer;
  const dataport = dataportReducer[dataportId];

  return {
    dataport,
    dataportId,
    refreshDataportInfoStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataportTechnicalDetailPage);

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import '../styles/plan-upload.scss';
import * as actions from '../actions/planImgActions';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const PlanUpload = ({translate, buildingId, actions}) => {
  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg,image/png',
    multiple: false,
    maxSize: 2000000,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const upload = () => {
    if (files.length === 1) {
      const data = new FormData();
      data.append('file', files[0]);
      const {uploadPlanImg} = actions;
      uploadPlanImg(buildingId, data);
    }
  };

  return (
    <section className="plan-upload">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag &apos;n&apos; drop some files here, or click to select files</p>
      </div>
      <aside>
        {files.map((file, i) =>
          <img key={i} src={file.preview} />
        )}
      </aside>
      <ActionButton action={() => upload()}
        extraClass="btn-primary"
        text={translate('technical_upload')} />
    </section>
  );
};

// class PlanUpload extends Component {
//   constructor() {
//     super();
//     this.state = {
//       files: [],
//     };
//     this.onDrop = this.onDrop.bind(this);
//     this.upload = this.upload.bind(this);
//   }
//   onDrop(files) {
//     console.log('files', files);
//     this.setState({
//       files,
//     });
//   }
//   upload() {
//     const {files} = this.state;
//     const {buildingId} = this.props;
//     if (files.length === 1) {
//       let data = new FormData();
//       data.append('file', files[0]);
//       const {uploadPlanImg} = this.props.actions;
//       uploadPlanImg(buildingId, data);
//     }
//   }
//   render() {
//     return (
//       <span>
//         <span className="plan-upload">
//           <Dropzone multiple={false} maxSize={2000000}
//           name="plan-img-upload" accept="image/jpeg,image/png"
//           className="upload-zone" acceptedFiles={this.state.files}
//           onDrop={this.onDrop}>
//           {({getRootProps, getInputProps}) =>  {
//             let el = <p>Drag the image here or click here to open file browser</p>;
//             if (this.state.files.length > 0) {
//               el = this.state.files.map((file, i) => <img key={i} src={file.preview} />);
//             }
//             return (
//               <div {...getRootProps()} className="upload-zone">
//                 <input {...getInputProps()} />
//                 {el}
//               </div>
//             );

//             // return this.state.files.map((file, i) => {
//             //   return (
//             //     <img key={i} src={file.preview} />
//             //   );
//             // });
//           }}
//           </Dropzone>
//         </span>

//         <ActionButton action={() => this.upload()}
//           extraClass="btn-primary"
//           text={this.props.translate('technical_upload')} />
//       </span>
//     );
//   }

// }

PlanUpload.propTypes = {
  buildingId: PropTypes.number,
  actions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    buildingId: state.buildingReducer.activeBuildingId,
    translate: getTranslate(state.localeReducer)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanUpload);

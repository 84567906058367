import {GET_ENERGY_DATA, GET_SIMULATE_ENERGY, CREATE_ENERGY_HEATMAP, SET_ENERGY_REDUCER_PROPS} from '../constants/energyActionTypes';
import objectAssign from 'object-assign';
import {INITIAL} from '../constants/customTypes';
import {statusHandler} from '../utils/reducerHelper';

const weekAgo = new Date();
weekAgo.setDate(weekAgo.getDate() - 7);

const initialState = {
  totalEnergy: 0,
  maxEnergy: 0,
  simulatedEnergyText: undefined,
  actualEnergyText: undefined,
  actualActive: true,
  maxEnergyText: '0 Wh',
  getEnergyStatus: INITIAL,
  getSimulateEnergyStatus: INITIAL,
  startDate: weekAgo,
  endDate: new Date(),
  daylightSaved: -1,
};

const statusHandlerFunc = statusHandler([
  {
    apiAction: GET_ENERGY_DATA,
    statusPropName: 'getEnergyStatus',
  },
  {
    apiAction: GET_SIMULATE_ENERGY,
    statusPropName: 'getSimulateEnergyStatus',
  },
]);

export default function movementReducer(originalState = initialState, action) {
  const state = statusHandlerFunc(originalState, action);
  switch (action.type) {
    case CREATE_ENERGY_HEATMAP:{
      const {totalEnergy, maxEnergy, totalEnergyText, maxEnergyText, isActual} = action;
      const obj = {
        totalEnergy,
        maxEnergy,
        maxEnergyText,
        actualActive: isActual,
      };

      if (isActual) {
        obj.actualEnergyText = totalEnergyText;
      }else {
        obj.simulatedEnergyText = totalEnergyText;
      }
      return objectAssign({}, state, obj);
    }
    case SET_ENERGY_REDUCER_PROPS:{
      const {obj} = action;
      return objectAssign({}, state, obj);
    }
    case GET_ENERGY_DATA.SUCCESS:{
      const {daylightSaved} = action;
      return objectAssign({}, state, {daylightSaved});
    }

    default:
      return state;
  }
}

import React, {memo} from 'react';
import PropTypes from 'prop-types';

const ReactTableHeader = ({column, isSorted, isSortedDesc}) => {
  return (
    // Add the sorting props to control sorting. For this example
    // we can add them into the header props
    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
      {column.render('Header')}
      <span className="sort-ind">
        {isSorted
          ? isSortedDesc
            ? <i className="fa fa-arrow-circle-down" aria-hidden="true"/>
            : <i className="fa fa-arrow-circle-up" aria-hidden="true"/>
          : ''}
      </span>
    </th>
  );
};

ReactTableHeader.propTypes = {
  column: PropTypes.object.isRequired,
  isSorted: PropTypes.bool.isRequired,
  isSortedDesc: PropTypes.bool,
};

export default memo(ReactTableHeader);

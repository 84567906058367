import {SET_UNIQUE_OBJECTS_AND_VARS, SET_UNIQUE_VAR_SELECTED, CHANGE_UNIQUE_SELECTED_ALL,
CHANGE_UNIQUE_OBJECT_SELECTED_ALL} from '../constants/uniqueObjectsActionTypes';

export function setUniqueObjectsAndVars(uObjects) {
  return {
    type: SET_UNIQUE_OBJECTS_AND_VARS,
    uObjects,
  };
}

export function setUniqueVarSelected(key, value) {
  return {
    type: SET_UNIQUE_VAR_SELECTED,
    key,
    value,
  };
}

export function changeUniqueSelectedAll(value) {
  return {
    type: CHANGE_UNIQUE_SELECTED_ALL,
    value,
  };
}

export function changeUniqueObjectSelectedAll(objectName, value) {
  return {
    type: CHANGE_UNIQUE_OBJECT_SELECTED_ALL,
    objectName,
    value,
  };
}

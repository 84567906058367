import {SET_LANGUAGE, INITIALIZE_LANGUAGES} from '../constants/languageActionTypes';

export function setLanguage(language) {
  return {
    type: SET_LANGUAGE,
    language
  };
}

export function initializeLanguages() {
  return {
    type: INITIALIZE_LANGUAGES
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/nodeActions';
import * as apActions from '../actions/apActions';
import ActionButton from '../components/ActionButton';
import LampsCommandsItem from '../containers/LampsCommandsItem';
import { getTranslate } from 'react-localize-redux';

const LampsCommands = ({actions, apActions, translate, lampIds, lampMacs}) => {
  const {serialWink, pingNodes, pollNodes} = actions;
  const {decommissionSelected} = apActions;

  return (
      <span className="lamps-commands">
        <table className="table">
          <thead>
            <tr>
              <th />
              <th>
                <ActionButton action={() => serialWink(lampMacs)} icon="fa-lightbulb-o"
                  text={translate('technical_wink')} />
              </th>
              <th>
                <ActionButton action={() => decommissionSelected()} icon="fa-trash"
                  text={translate('technical_decommission')} />
              </th>
              <th>
                <ActionButton action={() => pingNodes(lampMacs)} icon="fa-podcast"
                  text={translate('technical_ping')} />
              </th>
              <th>
                <ActionButton action={() => pollNodes(lampMacs)} icon="fa-refresh"
                  text={translate('technical_poll')} />
              </th>
            </tr>
          </thead>
          <tbody>
            {lampIds.map((id) => {
              return (
                <LampsCommandsItem key={id} id={id} />
              );
            })}
          </tbody>
        </table>
      </span>
  );
};

LampsCommands.propTypes = {
  actions: PropTypes.object.isRequired,
  apActions: PropTypes.object.isRequired,
  lampIds: PropTypes.array.isRequired,
  lampMacs: PropTypes.array.isRequired,
  lampSelectionDialogMode: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {localeReducer, planReducer} = state;
  const {lampSelectionDialogMode} = planReducer;
  const translate = getTranslate(localeReducer);
  return {
    lampSelectionDialogMode,
    translate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    apActions: bindActionCreators(apActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LampsCommands);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/lampActions';
import '../styles/sub-lamp-item.scss';
import {parseIfInt} from '../utils/helper';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';
import LampTypeOption from '../containers/LampTypeOption';

const SubLampItem = ({actions, lamp, parentLampId, lineId, translate, lampTypeSlaveIds}) => {
  const {setLampType, setLampProps, deleteSubLamp} = actions;
  let className = "sub-lamp-item";
  const {active} = lamp;

  if (active === true) {
    className += ' active';
  }

  return (
    <div className={className}>
      <div>
        <select value={lamp.typeId} onChange={(e) => setLampType(lamp.id, e.target.value)}>
          {lampTypeSlaveIds.map(subLampTypeId => (
            <LampTypeOption key={subLampTypeId} id={subLampTypeId} />
          ))}
        </select>
        &nbsp;
        <ActionButton action={() => deleteSubLamp(lineId, parentLampId, lamp.id)}
          extraClass="btn-danger"
          icon="fa-times"
          title={translate('technical_delete')} />
      </div>
      <div>
        <label htmlFor="rotation">{translate('technical_rotation')}:</label>
        <input type="number" className="form-control" id="rotation" min="0" step="90" max="270" value={lamp.rotation}
          onChange={(e) => setLampProps(lamp.id, {rotation: parseIfInt(e.target.value)})} />
      </div>
      <div>
        <label htmlFor="x">x:</label>
        <input type="number" className="form-control" id="x" step="1" value={lamp.x}
          onChange={(e) => setLampProps(lamp.id, {x: parseIfInt(e.target.value)})} />
      </div>
      <div>
        <label htmlFor="y">y:</label>
        <input type="number" className="form-control" id="y" step="1" value={lamp.y}
          onChange={(e) => setLampProps(lamp.id, {y: parseIfInt(e.target.value)})} />
      </div>
    </div>
  );
};

SubLampItem.propTypes = {
  actions: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  lamp: PropTypes.object,
  parentLampId: PropTypes.string.isRequired,
  lineId: PropTypes.string,
  translate: PropTypes.func.isRequired,
  lampTypeSlaveIds: PropTypes.array.isRequired,
};

function mapStateToProps(state, {id, lineId}) {
  const {lampReducer, lampTypeReducer} = state;
  let lampTypeSlaveIds;
  if (lineId) {
    lampTypeSlaveIds = lampTypeReducer.lampTypeSlaveIds;
  }else {
    lampTypeSlaveIds = lampTypeReducer.lampTypeAloneSlaveIds;
  }
  return {
    lamp: lampReducer[id],
    lampTypeSlaveIds,
    translate: getTranslate(state.localeReducer),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubLampItem);

import {PERMISSION_LIVE, PERMISSION_CONFIG, PERMISSION_CONFIG_MOVEMENT_DATA, PERMISSION_CONFIG_ENERGY_DATA, PERMISSION_TECHNICAL, PERMISSION_CLIENT_REPORTS, hasPermission} from './permissions';

export const PLAN_ROUTE_REGEX = /^\/[^/]+\/[^/]+/;
export const ROUTE_DRAWING = '/plan/drawing';
export const ROUTE_TECHNICAL = '/plan/technical';
export const ROUTE_LIVE = '/plan/live';
export const ROUTE_CONFIG = '/plan/config';
export const ROUTE_MOVEMENT = '/plan/movement';
export const ROUTE_ENERGY = '/plan/energy';
export const ROUTE_REPORTS = '/plan/reports';
export const ROUTE_REPORT = '/plan/report';

export const ROUTE_PERMISSION_MAP = {};
ROUTE_PERMISSION_MAP[ROUTE_LIVE] = PERMISSION_LIVE;
ROUTE_PERMISSION_MAP[ROUTE_CONFIG] = PERMISSION_CONFIG;
ROUTE_PERMISSION_MAP[ROUTE_MOVEMENT] = PERMISSION_CONFIG_MOVEMENT_DATA;
ROUTE_PERMISSION_MAP[ROUTE_ENERGY] = PERMISSION_CONFIG_ENERGY_DATA;
ROUTE_PERMISSION_MAP[ROUTE_REPORTS] = PERMISSION_CLIENT_REPORTS;
ROUTE_PERMISSION_MAP[ROUTE_REPORT] = PERMISSION_CLIENT_REPORTS;

export function hasRoutePermission(route, permissions) {
  const permission = ROUTE_PERMISSION_MAP[route];
  if (!permission) {
    return hasPermission(permissions, PERMISSION_TECHNICAL);
  }
  return hasPermission(permissions, permission);
}

import React from 'react';
import {Helmet} from 'react-helmet';
import DataportsOverview from '../containers/DataportsOverview';

const DataportsPage = () => {
  return (
    <div>
      <Helmet>
        <title>Dataports | TriVIEW</title>
      </Helmet>
      <div className="container">
        <h1>Dataports</h1>
        <DataportsOverview />
      </div>
    </div>
  );
};

export default DataportsPage;

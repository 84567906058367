import immer from 'immer';
import {GET_CONFIG_ROLES, GET_CLIENT_ROLES} from '../constants/roleActionTypes';

const initialState = {
  configRoleIds: [],
  configRoleOptions: [],
  clientRoleIds: [],
  clientRoleOptions: [],
};

export default immer((draft, action) => {

  switch (action.type) {
    case GET_CONFIG_ROLES.SUCCESS:{
      const {ids, obj} = action;
      return {
        ...draft,
        configRoleIds: ids,
        configRoleOptions: ids.map(id => ({value: id, label: obj[id].name})),
        ...obj,
      };
    }
    case GET_CLIENT_ROLES.SUCCESS:{
      const {ids, obj} = action;
      return {
        ...draft,
        clientRoleIds: ids,
        clientRoleOptions: ids.map(id => ({value: id, label: obj[id].name})),
        ...obj,
      };
    }

    default:
      return;
  }
}, initialState);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../actions/planActions';
import {bindActionCreators} from 'redux';
import {EDIT_HITZONE_MODE} from '../constants/customTypes';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const HitzoneControl = ({ actions, groupMode, translate }) => {

  const { setGroupMode, autoCalcHitzones, calcAdjacency} = actions;
  let lampBtnGroup = null;

  return (
    <span className="hitzone-control">
      <ActionButton action={() => setGroupMode(EDIT_HITZONE_MODE)}
        extraClass={"btn-default" + (groupMode === EDIT_HITZONE_MODE ? ' active':'')}
        text={translate('technical_edit_zones')} />
      {' '}
      <ActionButton action={() => autoCalcHitzones()}
        extraClass={"btn-default"}
        text={translate('technical_autocalc_hitzones')} />
      {' '}
      <ActionButton action={() => calcAdjacency()}
        extraClass={"btn-default"}
        text={translate('technical_calc_adjacency')} />
      {' '}
      {lampBtnGroup}
  </span>
  );
};

HitzoneControl.propTypes = {
  actions: PropTypes.object.isRequired,
  groupMode: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    groupMode: state.planReducer.groupMode,
    adjacentLampMode: state.planReducer.adjacentLampMode,
    translate: getTranslate(state.localeReducer)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HitzoneControl);

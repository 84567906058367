export const PING_FAIL_URL = 'pingFail';
export const PING_SUC_URL = 'pingSuc';
export const POLL_FAIL_URL = 'nodePollFail';
export const POLL_SUC_URL = 'nodePollSuc';
export const SET_VAR_SUC_URL = 'setVarSuc';
export const SET_VAR_FAIL_URL = 'setVarFail';
export const PP_ADDED_URL = 'ppAdded';
export const AP_ADDED_URL = 'apAdded';
export const PP_DECOMMISSIONED_URL = 'ppDecommissioned';
export const NODE_ADDED_URL = 'nodeAdded';

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import objectAssign from 'object-assign';
import moment from 'moment';
import * as actions from '../actions/userActions';
import * as clientActions from '../actions/clientActions';
import * as roleActions from '../actions/roleActions';
import FormInput from '../components/FormInput';
import Checkbox from '../components/Checkbox';
import ClientSelect from '../containers/ClientSelect';
import UserBuildingList from '../containers/UserBuildingList';
import UserClientRolesSelect from '../containers/UserClientRolesSelect';

import '../styles/edit-user-page.scss';

const EditUserPage = ({user, configRoleOptions, roleActions, selectedClientId, clientActions, actions, userId,
clientRoleOptions}) => {
  const [passwordError, setPasswordError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [fetchedObj, setFetchedObj] = useState({});

  const {saveUser, setEditUserProp, startEditUser, getUserClientRoles, getUserBuildingRoles} = actions;
  const {setSelectedClientId} = clientActions;

  useEffect(() => {
    const {getConfigRoles, getClientRoles} = roleActions;
    startEditUser(userId);
    if (configRoleOptions.length === 0) {
      getConfigRoles();
      getClientRoles();
    }
    if (selectedClientId && userId !== 'new') {
      getUserBuildingRoles(userId, selectedClientId);
      getUserClientRoles(userId, selectedClientId);
    }

  }, []);

  if (!user) {
    return null;
  }

  const isOldUser = userId && userId !== 'new';

  const save = () => {
    if (LOCAL) {
      if (user.username.length < 4 || /^[0-9a-zA-Z]+$/.test(user.username) === false) {
        setUsernameError(true);
        return;
      }
    }
    if (isOldUser === false || changePassword === true) {
      if (user.password.length < 8) {
        setPasswordError(true);
        return;
      }
    }

    let userCp = user;
    userCp = objectAssign({}, user);
    if (isOldUser === true) {
      userCp.id = oldId;
      if (changePassword === false) {
        delete userCp.password;
      }
      saveUser(userCp, isOldUser);
    }else {
      saveUser(userCp, isOldUser);
    }
  };

  const {username, password, firstname, lastname, email, staff, active, oldId, last_login, clientRoles} = user;
  let passwordLenErrEl = null;
  if (passwordError === true) {
    passwordLenErrEl = (
      <p className="err-info">Password should be 8 or more characters</p>
    );
  }

  let usernameLenErrEl = null;
  if (usernameError === true) {
    usernameLenErrEl = (
      <p className="err-info">Username should be 4 or more characters and contain only letters and numbers</p>
    );
  }

  let usernameEl;
  if (CLOUD) {
    usernameEl = (
      <FormInput id="email" type="email" label="Email" value={email} onChange={(val) => setEditUserProp('email', val)} required />
    );
  }else if (LOCAL) {
    usernameEl = (
      <FormInput id="username" type="text" label="Username" value={username} onChange={(val) => setEditUserProp('username', val)} required />
    );
  }

  let passwordEl = (
    <FormInput id="password" type="password" label="Password" value={password} required
      onChange={(val) => setEditUserProp('password', val)} />
  );
  if (isOldUser) {
    passwordEl = (
      <span>
        <Checkbox checked={changePassword} onChange={() => setChangePassword(!changePassword)}>
          Change password
        </Checkbox>
        {changePassword ? passwordEl:null}
      </span>
    );
  }

  const onClientSelectChange = obj => {
    if (obj) {
      setSelectedClientId(obj.value);
      if (isOldUser && !fetchedObj[obj.value]) {
        getUserBuildingRoles(oldId, obj.value);
        getUserClientRoles(oldId, obj.value);
        const cp = {...fetchedObj};
        cp[obj.value] = true;
        setFetchedObj(cp);
      }
    }
  };

  return (
    <div className="container edit-user-page">
      <h1>{isOldUser ? 'Edit ':'Create '}User</h1>
      <form onSubmit={(e) => { e.preventDefault(); save();}}>
        <FormInput id="firstname" type="text" label="Firstname" value={firstname} onChange={(val) => setEditUserProp('firstname', val)} />
        <FormInput id="lastname" type="text" label="Lastname" value={lastname} onChange={(val) => setEditUserProp('lastname', val)} />
        {usernameEl}
        {usernameLenErrEl}
        <p>Last logged in on: {last_login ? moment(last_login).format('DD/MM/YYYY HH:mm:ss'):'Never'}</p>
        <Checkbox checked={active} onChange={() => setEditUserProp('active', !active)}>
          Active
        </Checkbox>
        <Checkbox checked={staff} onChange={() => setEditUserProp('staff', !staff)}>
          Staff
        </Checkbox>
        {passwordEl}
        {passwordLenErrEl}
        <p>Roles:</p>
        <div className="client-list">
          <label htmlFor="client">Client:</label>
          <ClientSelect value={selectedClientId} name="client" onChange={onClientSelectChange} />
        </div>
        <div className="client-roles">
          <UserClientRolesSelect
            selectedClientId={selectedClientId}
            roleIds={clientRoles[selectedClientId] === undefined ? []:clientRoles[selectedClientId]}
            roleOptions={clientRoleOptions}
            onChange={(roleIds) => setEditUserProp(['clientRoles', selectedClientId], roleIds)}
          />
        </div>
        <div className="user-building-list">
          <UserBuildingList selectedClientId={selectedClientId} />
        </div>
        <input type="submit" className="btn btn-primary" value={isOldUser ? 'Save':'Create'} />
      </form>
    </div>
  );
};

EditUserPage.propTypes = {
  user: PropTypes.object,
  actions: PropTypes.object.isRequired,
  clientActions: PropTypes.object.isRequired,
  roleActions: PropTypes.object.isRequired,
  configRoleOptions: PropTypes.array.isRequired,
  clientRoleOptions: PropTypes.array.isRequired,
  username: PropTypes.string,
  selectedClientId: PropTypes.number,
  userId: PropTypes.string,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  const {userReducer, clientReducer, roleReducer} = state;
  const {configRoleOptions, clientRoleOptions} = roleReducer;
  const {selectedClientId} = clientReducer;
  const {userId} = props.match.params;
  const {editUser} = userReducer;
  return {
    user: editUser,
    selectedClientId,
    configRoleOptions,
    clientRoleOptions,
    userId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    clientActions: bindActionCreators(clientActions, dispatch),
    roleActions: bindActionCreators(roleActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditUserPage);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Dialog from '../components/DialogWrapper';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/nodeActions';
import PsCommands from './PsCommands';
import PsInfo from '../components/PsInfo';
import Variables from './Variables';
import '../styles/ps-dialog.scss';
import { getTranslate } from 'react-localize-redux';

const PsDialog = ({mac, node, actions, translate, ap}) => {
  if (!node && !ap) {
    return null;
  }

  const { setPsDialogMac} = actions;
  let objects = [];
  if (node && node.objects !== undefined) {
    objects = node.objects;
  }

  const body = (
    <div className="row">
      <div className="col-md-12 commands">
        <PsCommands mac={mac} />
      </div>
      <div className="col-md-12">
        <PsInfo node={node} ap={ap} translate={translate} />
      </div>
      <Variables mac={mac} objects={objects} />
    </div>
  );

  return (
    <span className="ps-dialog">
      <Dialog title={`${mac}, ${ap === undefined ? 'No Ap':ap.name}`} visible={true} body={body} closeLabel={translate('global_close')}
        closeAction={() => setPsDialogMac(undefined)} />
    </span>
  );
};

PsDialog.propTypes = {
  mac: PropTypes.string,
  node: PropTypes.object,
  ap: PropTypes.object,
  actions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  let node, ap;
  const mac = state.nodeReducer.psDialogMac;
  if (mac !== undefined) {
    ap = state.apReducer[mac];
    node = state.nodeReducer[mac];
  }

  const translate = getTranslate(state.localeReducer);

  return {
    mac,
    node,
    translate,
    ap,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PsDialog);

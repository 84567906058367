import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import NavLink from '../containers/NavLink';
import TriviewNavLink from '../containers/TriviewNavLink';
import LanguageSelector from '../containers/LanguageSelector';
import '../styles/alerts-list.scss';
import { getTranslate } from 'react-localize-redux';
import {isBeta} from 'Config';

const Nav = ({staff, translate}) => {
  let monitorNavLink = null;
  let clientsNavLink = null;
  let buildingsNavLink = null;
  let usersNavLink = null;
  let lampTypesNavLink = null;
  if (CLOUD) {
    if (staff) {
      monitorNavLink = (<NavLink to="/monitor">{translate('navigation_items_monitor')}</NavLink>);
    }
  }

  if (staff) {
    clientsNavLink = (
      <NavLink to="/clients">Clients</NavLink>
    );
    usersNavLink = (
      <NavLink to="/users">Users</NavLink>
    );
    lampTypesNavLink = (
      <NavLink to="/lamp-types">Lamp Types</NavLink>
    );
    if (CLOUD) {
      buildingsNavLink = (
        <NavLink to="/dataports">Dataports</NavLink>
      );
    }
  }

  let betaEl = null;
  if(isBeta) {
    betaEl = (
      <p className="navbar-text" style={{color: 'red'}}>BETA</p>
    );
  }

  return (
    <nav className="navbar navbar-default">
      <div className="container-fluid">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
            <span className="sr-only">{translate('navigation_togglenavigation')}</span>
            <span className="icon-bar"/>
            <span className="icon-bar"/>
            <span className="icon-bar"/>
          </button>
          {betaEl}
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav">
            {monitorNavLink}
            <TriviewNavLink>{translate('navigation_items_triview')}</TriviewNavLink>
            {clientsNavLink}
            {buildingsNavLink}
            {usersNavLink}
            {lampTypesNavLink}
            <li>
              <a href={'/static/guides/user_guide_en.html'} title={translate('title_user_guide')}
                target="_blank" rel="noopener noreferrer">
                {translate('title_user_guide')}
              </a>
            </li>
          </ul>

          <LanguageSelector/>
        </div>
      </div>
    </nav>
  );
};

Nav.propTypes = {
  staff: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {staff} = state.authReducer;
  const translate = getTranslate(state.localeReducer);
  return {
    staff,
    translate,
  };
}

export default connect(
  mapStateToProps,
  null
)(Nav);

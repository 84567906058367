import {ADD_ALERT} from '../constants/alertActionTypes';
import {removeAlert} from '../actions/alertActions';
import {getUniqueId} from '../utils/idHelper';

const alertMiddleware = store => next => action => {
  const {dispatch} = store;
  if(action.type === ADD_ALERT) {
    const {timeout} = action;
    action.id = getUniqueId();
    if (timeout !== undefined && timeout !== Infinity) {
      setTimeout(() => dispatch(removeAlert(action.id)), timeout);
    }
    return next(action);
  }else {
    return next(action);
  }
};

export default alertMiddleware;

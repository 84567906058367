import {pgDurationToMs} from '../utils/dateHelper';
import {GET_MOVEMENT_RETENTION_PERIODS} from '../constants/movementRetentionActionTypes';
import objectAssign from 'object-assign';
import {INITIAL} from '../constants/customTypes';
// import {statusHandler} from '../utils/reducerHelper';

const initialState = {
  getMovementStatus: INITIAL,
};

// const statusHandlerFunc = statusHandler([
//   {
//     apiAction: GET_MOVEMENT_DATA,
//     statusPropName: 'getMovementStatus',
//   },
// ]);

export default function movementRetentionReducer(originalState = initialState, action) {
  // const state = statusHandlerFunc(originalState, action);
  const state = originalState;
  switch (action.type) {
    case GET_MOVEMENT_RETENTION_PERIODS.SUCCESS:{
      const {configId} = action.props;
      const {response} = action;
      const newState = objectAssign({}, state);
      const obj = {periods: response};
      const now = new Date();
      if (response.length > 0) {
        const lastPeriod = response[response.length - 1];
        if (lastPeriod.user_access === false) {
          obj.minDate = now.getTime() - pgDurationToMs(lastPeriod.older_than);
        }
      }


      newState[configId] = obj;
      return newState;
    }

    default:
      return state;
  }
}

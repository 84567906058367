import React from 'react';
import PropTypes from 'prop-types';

const OptionalTableRow = ({label, value, children}) => {
  if (value === undefined && children === undefined) {
    return null;
  }

  return (
    <tr>
      <th>{label}</th>
      <td>{value !== undefined ? value:children}</td>
    </tr>
  );
};

OptionalTableRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  children: PropTypes.node,
};

export default OptionalTableRow;

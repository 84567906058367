import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import SoftwareVersion from '../components/SoftwareVersion';
import * as actions from '../actions/filterActions';
import {bindActionCreators} from 'redux';
import Checkbox from '../components/Checkbox';

const FilterSwVersion = ({swVersion, actions, value, accessKey}) => {
  const {setFilterNodeTypeVersionValue} = actions;
  return (
    <div className="form-inline">
      <div className="form-group">
        <Checkbox name={accessKey} id={accessKey} checked={value}
          onChange={() => setFilterNodeTypeVersionValue(accessKey, !value)}>
          &nbsp;<SoftwareVersion softwareVersion={swVersion} />
        </Checkbox>
      </div>
    </div>
  );
};

FilterSwVersion.propTypes = {
  nodeType: PropTypes.string.isRequired,
  swVersion: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  value: PropTypes.bool.isRequired,
  accessKey: PropTypes.string.isRequired,
};

function mapStateToProps({filterReducer}, {nodeType, swVersion, dataportSlug}) {
  const key = `${dataportSlug}/${nodeType}/${swVersion}`;
  return {
    accessKey: key,
    value: filterReducer[key],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterSwVersion);

import immer from 'immer';
import {GET_REPORTS, CREATE_REPORT, UPDATE_REPORT, DELETE_REPORT,
  GET_REPORT,
} from '../constants/reportsActionTypes';
import {statusHandler} from '../utils/reducerHelper';
import {INITIAL} from '../constants/customTypes';
import {clientKey} from '../utils/idHelper';

const statusHandlerFunc = statusHandler([
  {
    apiAction: CREATE_REPORT,
    statusPropName: 'changeReportStatus',
  },
  {
    apiAction: UPDATE_REPORT,
    statusPropName: 'changeReportStatus',
  },
], true);

const initialState = {
  changeReportStatus: INITIAL,
};


const reducer = immer((draft, action) => {

  statusHandlerFunc(draft, action);

  switch (action.type) {
    case GET_REPORTS.SUCCESS:{
      const {response, props} = action;
      const {clientId} = props;
      draft[clientKey(clientId)] = response.map(report => {
        draft[report.id] = {...draft[report.id], ...report};
        return report.id;
      });
      return;
    }
    case GET_REPORT.SUCCESS:{
      const {response} = action;
      const obj = {};
      response.reportConfigurations.forEach(reportConfig => {
        obj[reportConfig.configuration_id] = {...reportConfig};
      });

      draft[response.id] = {
        ...response,
        reportConfigurations: obj,
        fetched: true,
      };
      return;
    }
    case CREATE_REPORT.SUCCESS:{
      const {response} = action;
      const {client_id} = response;
      const obj = {};
      response.reportConfigurations.forEach(reportConfig => {
        obj[reportConfig.configuration_id] = reportConfig;
      });

      draft[clientKey(client_id)].push(response.id);
      draft[response.id] = {...response, fetched: true, reportConfigurations: obj};
      return;
    }
    case UPDATE_REPORT.SUCCESS:{
      const {response} = action;
      const {id} = response;
      const obj = {};
      response.reportConfigurations.forEach(reportConfig => {
        obj[reportConfig.configuration_id] = reportConfig;
      });

      draft[id] = {...draft[id], ...response, reportConfigurations: obj};
      return;
    }
    case DELETE_REPORT.SUCCESS:{
      const {clientId, reportId} = action.props;
      delete draft[reportId];
      const key = clientKey(clientId);
      draft[key].splice(draft[key].indexOf(reportId), 1);
      return;
    }

    default:
      return;
  }
}, initialState);

export default reducer;

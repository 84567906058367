import { UPDATE_LAMP_TYPE, CREATE_LAMP_TYPE } from '../constants/lampTypeActionTypes';
import { push } from 'connected-react-router';

const lampTypeMiddleware = store => next => action => {
  const {dispatch} = store;
  if (action.type === UPDATE_LAMP_TYPE.SUCCESS || action.type === CREATE_LAMP_TYPE.SUCCESS) {
    dispatch(push('/lamp-types'));
    next(action);
  }else {
    return next(action);
  }

};

export default lampTypeMiddleware;

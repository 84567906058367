import {SET_SELECTED_VARS, SET_SELECTED_VAR_PROPS, SET_SELECTED_COPY_VAR} from '../constants/selectedVariablesActionTypes';
import objectAssign from 'object-assign';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {
  copyVarPath: undefined,
};

export default function selectedVariablesReducer(state = initialState, action) {

  switch (action.type) {
    case SET_SELECTED_VARS:{
      const {variables} = action;
      return objectAssign({}, variables);
    }
    case SET_SELECTED_VAR_PROPS:{
      const {objectName, variableName, obj} = action;
      let newState = objectAssign({}, state);
      const key = `${objectName}/${variableName}`;
      newState[key] = objectAssign({}, newState[key], obj);
      return newState;
    }
    case SET_SELECTED_COPY_VAR:{
      const {path, isCopied} = action;
      let newState = objectAssign({}, state);
      if (isCopied === true) {
        newState.copyVarPath = path;
      }else {
        newState.copyVarPath = undefined;
      }

      newState[path] = objectAssign({}, state[path], {isCopied});
      return newState;
    }

    default:
      return state;
  }
}

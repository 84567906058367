import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import LampBorderZone from './LampBorderZone';

const LineBorderZone = ({line,}) => {
  const {x, y, lampIds, rotation} = line;
  const transform = `translate(${x},${y}),rotate(${rotation})`;

  return (
    <g transform={transform}>
      {
        lampIds.map((id) => {
          return (
            <LampBorderZone id={id} key={id} />
          );
        })
      }
    </g>
  );
};

LineBorderZone.propTypes = {
  line: PropTypes.object,
  id: PropTypes.string.isRequired,
};

function mapStateToProps(state, props) {

  return {
    line: state.lineReducer[props.id],
  };
}

export default connect(
  mapStateToProps,
  null,
)(LineBorderZone);

import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import '../styles/command-output.scss';

let forceScrollToBot = true;
let ignoreScrollEvent = false;

const CommandOutput = ({output}) => {
  let outputEl = null;
  if (output !== undefined) {
    outputEl = output.map(({kind, data}, index) => {
      return (
        <span className={kind === 'ERR' ? 'err':''} key={index}>{data}</span>
      );
    });
  }

  const onScroll = (e) => {
    if (ignoreScrollEvent === false) {
      forceScrollToBot = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    }else {
      ignoreScrollEvent = false;
    }
  };

  const containerRef = useRef(null);
  useEffect(() => {
    if (forceScrollToBot) {
      ignoreScrollEvent = containerRef.current.scrollTop !== containerRef.current.scrollHeight;
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [output]);

  return (
    <div className="command-output" ref={containerRef} style={{whiteSpace: 'pre-line'}} onScroll={onScroll}>
      {outputEl}
    </div>
  );
};

CommandOutput.propTypes = {
  output: PropTypes.array,
};


export default CommandOutput;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';

const NavLink = ({to, pathName, children, ...props}) => {
  const regex = new RegExp('^' + to);
  return (
    <li className={regex.test(pathName) ? 'active':''}>
      <Link to={to} {...props}>
          {children}
      </Link>
    </li>
  );
};

NavLink.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  pathName: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    pathName: state.router.location.pathname,
  };
}

export default connect(
  mapStateToProps,
  {},
)(NavLink);

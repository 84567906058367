import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/userActions';
import UserItem from '../containers/UserItem';
import ConfirmDialog from '../containers/ConfirmDialog';

class UsersOverview extends Component {
  componentDidMount() {
    const {actions, usersLoaded} = this.props;
    const {getUsers} = actions;
    if (usersLoaded === false) {
      getUsers();
    }
  }
  render() {
    const {userIds, deleteUserId, actions} = this.props;
    const {setUserReducerProps, deleteUserById} = actions;
    if (!userIds) {
      return <p>Error no config found</p>;
    }
    let identifier = '';
    if (CLOUD) {
      identifier = 'Email';
    }else {
      identifier = 'Username';
    }

    return (
      <div className="users-overview">
        <ConfirmDialog show={deleteUserId !== undefined ? true:false} closeAction={() => setUserReducerProps({deleteUserId: undefined})}
          confirmAction={() => deleteUserById(deleteUserId)} text="Are you sure you want to delete this user?" title="Delete user" />
        <table className="table">
          <thead>
            <tr>
              <th>Lastname</th>
              <th>Firstname</th>
              <th>{identifier}</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {userIds.map(userId => {
              return <UserItem key={userId} id={userId} />;
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

UsersOverview.propTypes = {
  userIds: PropTypes.array,
  deleteUserId: PropTypes.number,
  actions: PropTypes.object.isRequired,
  usersLoaded: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {userIds, deleteUserId, usersLoaded} = state.userReducer;
  return {
    userIds,
    deleteUserId,
    usersLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersOverview);

import React from 'react';
import PropTypes from 'prop-types';
import OptionalTableRow from './OptionalTableRow';

const Interface = ({mac, address, name, netmask}) => {

  return (
    <table className="table">
      <tbody>
        <OptionalTableRow label="Name" value={name} />
        <OptionalTableRow label="Address" value={address} />
        <OptionalTableRow label="Netmask" value={netmask} />
        <OptionalTableRow label="Mac" value={mac} />
      </tbody>
    </table>
  );
};

Interface.propTypes = {
  name: PropTypes.string.isRequired,
  mac: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  netmask: PropTypes.string.isRequired,
};

export default Interface;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/extraVariableActions';

const ExtraLampVar = ({value, name, actions, isEditing, id, tValue}) => {
  const {setExtraVarTempValue, saveExtraVarValue, setExtraVarIsEditing} = actions;
  const submitFunc = () => saveExtraVarValue(id, name);
  if (isEditing === true) {
    return (
      <form className="variable" onSubmit={submitFunc}>
        {name}:&nbsp;
        <input type="number" value={tValue} onChange={(e) => setExtraVarTempValue(id, name, e.target.value)} className="form-control" />
        <input type="submit" className="hidden" />
        <i className="pointer fa fa-times" aria-hidden="true"
          onClick={() => setExtraVarIsEditing(id, name, false)} />
        &nbsp;
        <i className="pointer fa fa-floppy-o" aria-hidden="true"
          onClick={() => submitFunc()}/>
      </form>
    );
  }

  return (
    <span className="variable">
      {name}: {value}&nbsp;
      <i className="fa pointer fa-pencil" aria-hidden="true"
        onClick={() => setExtraVarIsEditing(id, name, true)}/>
    </span>
  );
};

ExtraLampVar.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  tValue: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

function mapStateToProps(state, props) {
  const {value, tValue, isEditing} = state.extraVariableReducer[`${props.id}/${props.name}`];
  return {
    value,
    isEditing,
    tValue,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExtraLampVar);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ProfileGraph from '../components/ProfileGraph';
import * as actions from '../actions/profileActions';
import {bindActionCreators} from 'redux';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import '../styles/profile-edit.scss';
import moment from 'moment';
import {STANDARD_FLOW, BASELINE_FLOW, BEHAVIOUR_STANDARD, BEHAVIOUR_DAYLIGHT} from '../constants/profileTypes';
import {capitalizeFirstLetter} from '../utils/helper';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';
import Checkbox, {LABEL_POSITION_BEFORE} from '../components/Checkbox';

const ProfileEdit = ({profile, actions, browserWidth, canBaseline, nameErr, timeoutOnErr, timeoutStandbyErr,
      onErr, standbyErr, translate, canDaylight}) => {
  const {setProfileProps, saveProfile, cancelEditProfile} = actions;
  let timeoutStandbyEl = null;
  const iconClick = () => {
    let nextFlow = STANDARD_FLOW;
    if (profile.flow === STANDARD_FLOW && canBaseline === true) {
      nextFlow = BASELINE_FLOW;
    }
    setProfileProps(profile.id, {flow: nextFlow});
  };

  let standbyErrEl = null;
  let onErrEl = null;
  let nameErrEl = null;
  let timeoutOnErrEl = null;
  let timeoutStandbyErrEl = null;
  let daylightCheckbox = null;

  if (nameErr === true) {
    nameErrEl = (
      <p className="err-info">{translate('profile_error_nameemptyortoolong')}</p>
    );
  }
  if (onErr === true) {
    onErrEl = (
      <p className="err-info">{translate('profile_error_onpercentbetween')}</p>
    );
  }
  if (standbyErr === true) {
    standbyErrEl = (
      <p className="err-info">{translate('profile_error_standbypercentbetween')}</p>
    );
  }
  if (timeoutOnErr === true) {
    timeoutOnErrEl = (
      <p className="err-info">{translate('profile_error_standbytimeoutduration')}</p>
    );
  }
  if (timeoutStandbyErr === true) {
    timeoutStandbyErrEl = (
      <p className="err-info">{translate('profile_error_offtimeoutduration')}</p>
    );
  }

  if (profile.flow === STANDARD_FLOW) {
    timeoutStandbyEl = (
      <div className="form-edit">
        <label htmlFor="timeout_off">{translate('profile_setting_timeoutoff')}:</label>
        <TimePicker name="timeout_off" id="timeout_off" value={moment().hours(0).minutes(0).seconds(profile.timeout_standby)} className={timeoutStandbyErr ? 'error':''}
          onChange={(value) => setProfileProps(profile.id, {timeout_standby: value.seconds() + value.minutes() * 60 + value.hours() * 3600})}/>
        {timeoutStandbyErrEl}
      </div>
    );
  }

  if (canDaylight === true) {
    daylightCheckbox = (
      <Checkbox checked={profile.behaviour === BEHAVIOUR_DAYLIGHT} className="daylight-checkbox"
        labelPosition={LABEL_POSITION_BEFORE}
        onChange={() => setProfileProps(profile.id, {behaviour: profile.behaviour === BEHAVIOUR_DAYLIGHT ? BEHAVIOUR_STANDARD:BEHAVIOUR_DAYLIGHT})}>
        {translate('profile_daylight_compensation')}
      </Checkbox>
    );
  }

  return (
    <div className="profile-edit">
      <h3 className="name">{profile.name}</h3>
      <ProfileGraph profile={profile} browserWidth={browserWidth} />
      <div className="flow hideoverflow">
        <div className="inner">
          <i className="fa fa-caret-left left-icon pointer icon" aria-hidden="true"
            onClick={() => iconClick()} />
            {capitalizeFirstLetter(profile.flow)}
          <i className="fa fa-caret-right right-icon pointer icon" aria-hidden="true"
            onClick={() => iconClick()} />
        </div>
      </div>
      <form onSubmit={(e) => {e.preventDefault(); saveProfile(profile.id);}}>
        <div className="form-edit">
          <label htmlFor="name">{translate('profile_setting_name')}:</label>
          <input type="text" className={"form-control" + (nameErr ? ' form-control-error':'')} id="name"
            value={profile.name} onChange={(e) => setProfileProps(profile.id, {name: e.target.value})} />
          {nameErrEl}
        </div>
        <div className="form-edit">
          <label htmlFor="percent_on">{translate('profile_setting_onpercent')}:</label>
          <input type="number" min="0" max="100" className={"form-control" + (onErr ? ' form-control-error':'')} id="percent_on"
            value={profile.percent_on} onChange={(e) => setProfileProps(profile.id, {percent_on: e.target.value})} />
          {onErrEl}
        </div>
        <div className="form-edit">
          <label htmlFor="percent_stby">{translate('profile_setting_standbypercent')}:</label>
          <input type="number" min="0" max="100" className={"form-control" + (standbyErr ? ' form-control-error':'')} id="percent_stby"
            value={profile.percent_standby} onChange={(e) => setProfileProps(profile.id, {percent_standby: e.target.value})} />
          {standbyErrEl}
        </div>
        <div className="form-edit">
          <label htmlFor="timeout_stdby">{translate('profile_setting_timeoutstandby')}:</label>
          <TimePicker name="timeout_stdby" id="timeout_stdby" value={moment().hours(0).minutes(0).seconds(profile.timeout_on)} className={timeoutOnErr ? 'error':''}
            onChange={(value) => setProfileProps(profile.id, {timeout_on: value.seconds() + value.minutes() * 60 + value.hours() * 3600})}/>
          {timeoutOnErrEl}
        </div>
        {timeoutStandbyEl}
        {daylightCheckbox}
        <input type="submit" className="hidden" />
      </form>
      <div className="hideoverflow">
        <div className="outer">
          <div className="inner">
            <ActionButton action={() => saveProfile(profile.id)}
              extraClass="btn-primary" icon="fa-floppy-o"
              text={translate('global_save')} />
            {' '}
            <ActionButton action={() => cancelEditProfile(profile.id)}
              extraClass="btn-danger" icon="fa-ban"
              text={translate('global_cancel')} />
          </div>
        </div>
      </div>
    </div>
  );

};

ProfileEdit.propTypes = {
  profile: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  browserWidth: PropTypes.number.isRequired,
  canBaseline: PropTypes.bool.isRequired,
  canDaylight: PropTypes.bool.isRequired,
  onErr: PropTypes.bool.isRequired,
  standbyErr: PropTypes.bool.isRequired,
  timeoutOnErr: PropTypes.bool.isRequired,
  timeoutStandbyErr: PropTypes.bool.isRequired,
  nameErr: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {profileReducer, generalReducer, dataportReducer, buildingReducer} = state;
  const {activeBuildingId} = buildingReducer;
  const {dataport: dataportName} = buildingReducer[activeBuildingId];
  let canBaseline = false;
  let canDaylight = false;
  if (LOCAL) {
    // const {localDataportReducer} = state;
    // canBaseline = localDataportReducer.canBaseline;
    // canDaylight = localDataportReducer.canDaylight;
    canBaseline = true;
    canDaylight = true;
  }else if(CLOUD) {
    canBaseline = true;
    canDaylight = true;
    // const dataport = dataportReducer[dataportName];
    // canBaseline = dataport.canBaseline;
    // canDaylight = dataport.canDaylight;
  }
  const {profileEditingId, onErr, standbyErr, timeoutOnErr, timeoutStandbyErr, nameErr} = profileReducer;
  const {browserWidth} = generalReducer;
  const profile = profileReducer[profileEditingId];
  const translate = getTranslate(state.localeReducer);
  return {
    onErr,
    standbyErr,
    timeoutOnErr,
    timeoutStandbyErr,
    nameErr,
    browserWidth,
    profile,
    canBaseline,
    translate,
    canDaylight,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileEdit);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../actions/profileActions';
import {bindActionCreators} from 'redux';
import {PROFILE_FILTER_ALL, PROFILE_FILTER_BUILDING} from '../constants/profileTypes';
import '../styles/profile-control.scss';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const ProfileControl = ({ actions, profileFilter, translate }) => {

  const { setProfileFilter, createProfile } = actions;
  return (
    <div className="profile-control">
    <span className="btn-group">
      <ActionButton action={() => setProfileFilter(PROFILE_FILTER_ALL)}
        extraClass={"btn-default " + (profileFilter === PROFILE_FILTER_ALL ? 'active':'')}
        text={translate('global_all')} />
      <ActionButton action={() => setProfileFilter(PROFILE_FILTER_BUILDING)}
        extraClass={"btn-default " + (profileFilter === PROFILE_FILTER_BUILDING ? 'active':'')}
        text={translate('global_active')} />
    </span>
    {' '}
    <ActionButton action={() => createProfile()}
      extraClass="btn-primary"
      text={translate('profile_create')} />
  </div>
  );
};

ProfileControl.propTypes = {
  actions: PropTypes.object.isRequired,
  profileFilter: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {profileReducer} = state;
  const {profileFilter} = profileReducer;
  const translate = getTranslate(state.localeReducer);
  return {
    mode: state.planReducer.mode,
    profileFilter,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileControl);

import {SET_FIRMWARE_VERSIONS, SET_SHOW_UPGRADE_DIALOG, GET_FIRMWARE_FILES,
  SET_FIRMWARE_SW_VERSION_FILE, START_UPGRADE, START_UPGRADE_API, SET_FIRMWARE_REDUCER_PROPS,
  STOP_UPGRADE, CLEAR_UPGRADE, SYNC_FIRMWARE_PS, SYNC_FIRMWARE_DRIVER,
  GET_SCHEDULED_UPGRADE, CANCEL_SCHEDULED_UPGRADE, GET_NODES_UPGRADE_SYNCED,
  RE_SYNC_NODES_UPGRADE, RE_SYNC_NODES_UPGRADE_API, UPGRADE_SELECTION,
GET_UPGRADE_STATUS} from '../constants/firmwareActionTypes';
import {DS_RPC} from '../constants/customTypes';

export function setFirmwareReducerProps(obj) {
  return {
    type: SET_FIRMWARE_REDUCER_PROPS,
    obj,
  };
}

export function setFirmwareVersions(nodeTypes, nodeTypeObj, versionObj) {
  return {
    type: SET_FIRMWARE_VERSIONS,
    nodeTypes,
    nodeTypeObj,
    versionObj,
  };
}

export function setShowUpgradeDialog(show, mode) {
  return {
    type: SET_SHOW_UPGRADE_DIALOG,
    show,
    mode,
  };
}

export function getFirmwareFiles() {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: `:site/firmwares`,
      success: GET_FIRMWARE_FILES.SUCCESS,
      error: GET_FIRMWARE_FILES.ERROR,
      start: GET_FIRMWARE_FILES.START,
    },
  };
}

export function syncFirmwareDriver() {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: `:site/firmware/driver/sync`,
      success: SYNC_FIRMWARE_DRIVER.SUCCESS,
      error: SYNC_FIRMWARE_DRIVER.ERROR,
      start: SYNC_FIRMWARE_DRIVER.START,
    },
  };
}

export function syncFirmwarePs() {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: `:site/firmware/ps/sync`,
      success: SYNC_FIRMWARE_PS.SUCCESS,
      error: SYNC_FIRMWARE_PS.ERROR,
      start: SYNC_FIRMWARE_PS.START,
    },
  };
}

export function setFirmwareSwVersionFile(nodeType, swVersion, file) {
  return {
    type: SET_FIRMWARE_SW_VERSION_FILE,
    nodeType,
    swVersion,
    file,
  };
}

export function startUpgradeApi(data) {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      data,
      url: ':site/upgrader/start',
      success: START_UPGRADE_API.SUCCESS,
      error: START_UPGRADE_API.ERROR,
      start: START_UPGRADE_API.START,
    },
    props: {
      schedule: data.schedule,
    },
  };
}

export function startUpgrade() {
  return {
    type: START_UPGRADE,
  };
}

export function getUpgradeStatus() {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: ':site/upgrader/status',
      success: GET_UPGRADE_STATUS.SUCCESS,
      error: GET_UPGRADE_STATUS.ERROR,
      start: GET_UPGRADE_STATUS.START,
    },
  };
}

export function stopUpgrade() {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: ':site/upgrader/stop',
      success: STOP_UPGRADE.SUCCESS,
      error: STOP_UPGRADE.ERROR,
      start: STOP_UPGRADE.START,
    },
  };
}


export function clearUpgrade() {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: ':site/upgrader/clear',
      success: CLEAR_UPGRADE.SUCCESS,
      error: CLEAR_UPGRADE.ERROR,
      start: CLEAR_UPGRADE.START,
    },
  };
}

export function getScheduledUpgrade() {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: ':site/upgrader/scheduled',
      success: GET_SCHEDULED_UPGRADE.SUCCESS,
      error: GET_SCHEDULED_UPGRADE.ERROR,
      start: GET_SCHEDULED_UPGRADE.START,
    },
  };
}

export function cancelScheduledUpgrade() {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: ':site/upgrader/scheduled/cancel',
      success: CANCEL_SCHEDULED_UPGRADE.SUCCESS,
      error: CANCEL_SCHEDULED_UPGRADE.ERROR,
      start: CANCEL_SCHEDULED_UPGRADE.START,
    },
  };
}

export function getNodesUpgradeSynced() {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: ':site/upgrader/synced',
      success: GET_NODES_UPGRADE_SYNCED.SUCCESS,
      error: GET_NODES_UPGRADE_SYNCED.ERROR,
      start: GET_NODES_UPGRADE_SYNCED.START,
    },
  };
}

export function reSyncNodesUpgrade() {
  return {
    type: RE_SYNC_NODES_UPGRADE,
  };
}

export function reSyncNodesUpgradeApi(selectedMacs) {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: ':site/upgrader/resync',
      success: RE_SYNC_NODES_UPGRADE_API.SUCCESS,
      error: RE_SYNC_NODES_UPGRADE_API.ERROR,
      start: RE_SYNC_NODES_UPGRADE_API.START,
      data: selectedMacs,
    },
  };
}

export function upgradeSelection(mode) {
  return {
    type: UPGRADE_SELECTION,
    mode,
  };
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import C3Chart from 'react-c3js';
import {getColorPattern, GREEN_TO_RED} from '../constants/C3GaugeColorPatterns';
import '../styles/c3.css';

const DEFAULT_HEIGHT = 180;
const DEFAULT_INTERACTION_ENABLED = true;

class C3Gauge extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let data;
    let color;
    let size;
    const colorPattern = this.props.colorPattern;

    // interaction
    const interaction = {
      enabled : this.props.enableInteraction
    };

    const legend = {
      show: false
    };

    // data ; color ; size
    if (this.props.data) {
      data = {
        columns: [
          ['data', this.props.data]
        ],
        type: 'gauge',
      };
      color = {
        pattern: getColorPattern(colorPattern),
        threshold: {
          values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
        }
      };
      size = {
        height: this.props.height
      };
    }

    if (data) {
      return (
        <div>
          <C3Chart data={data} color={color} size={size} interaction={interaction} legend={legend} />
        </div>);
    }

    return (null);
  }
}

C3Gauge.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  enableInteraction : PropTypes.bool.isRequired,
  colorPattern: PropTypes.number.isRequired
};

C3Gauge.defaultProps = {
  enableInteraction : DEFAULT_INTERACTION_ENABLED,
  height: DEFAULT_HEIGHT,
  colorPattern: GREEN_TO_RED
};


export default C3Gauge;

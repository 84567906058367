import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import PsItem from './PsItem';
import '../styles/ps-list.scss';

const PsList = ({psIds, activeBuildingId}) => {
  if (activeBuildingId === undefined) {
    return null;
  }
  return (
    <div className="ps-list">
      {psIds.map((id) => {
        return <PsItem key={id} id={id} />;
      })}
    </div>
  );
};

PsList.propTypes = {
  psIds: PropTypes.array.isRequired,
  activeBuildingId: PropTypes.number,
};

function mapStateToProps(state) {
  const {buildingReducer, filterReducer} = state;
  const {activeBuildingId} = buildingReducer;
  let psIds;
  if (activeBuildingId !== undefined) {
    psIds = filterReducer.filteredApIds;
  }
  if (psIds === undefined) {
    psIds = [];
  }
  return {
    psIds: psIds,
    activeBuildingId,
  };
}

export default connect(
  mapStateToProps,
  null
)(PsList);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setVariablesValueApi} from '../actions/nodeActions';
import * as actions from '../actions/selectedVariablesActions';

const EditAllVariables = ({objectName, variableName, variable, macs, copyVarPath, dispatch, actions, path}) => {
  let editing = null;
  let copyEl = null;
  const {setSelectedVarProps, setSelectedCopyVar, copySelectedVars} = actions;
  const saveFunc = () => {
    setSelectedVarProps(objectName, variableName, {editing: false});
    dispatch(setVariablesValueApi(macs, objectName, variableName, variable.value));
  };
  if (variable.editing === true) {
    editing = (
      <form onSubmit={(e) => { e.preventDefault(); saveFunc();}}>
        <input type="text" className="form-control" value={variable.value} autoFocus
          onChange={(e) => dispatch(setSelectedVarProps(objectName, variableName, {value: e.target.value}))}/>
      </form>
    );
  }else {

    if (variable.writeable === true && copyVarPath && variable.isCopied === false) {
      copyEl = <i className={`fa pointer fa-bullseye`} onClick={() => copySelectedVars(copyVarPath, path)} aria-hidden="true" />;
    }else if (copyVarPath === undefined || variable.isCopied === true) {
      copyEl = <i className="fa pointer fa-clone" onClick={() => setSelectedCopyVar(path, !variable.isCopied)} aria-hidden="true" />;
    }

  }
  let editPencil = null;
  if (variable.writeable === true) {
    editPencil = (
      <i className={"fa pointer " + (variable.editing === true ? 'fa-floppy-o':'fa-pencil')}
        onClick={() => {
          if (variable.editing === true) {
            saveFunc();
          }else {
            dispatch(setSelectedVarProps(objectName, variableName, {editing: true}));
          }
        }}/>
    );
  }
  return (
    <span>
      {variableName}
      {editPencil}
      {editing}
      {copyEl}
    </span>
  );
};

EditAllVariables.propTypes = {
  objectName: PropTypes.string.isRequired,
  variableName: PropTypes.string.isRequired,
  variable: PropTypes.object,
  path: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  macs: PropTypes.array.isRequired,
  copyVarPath: PropTypes.string,
};

function mapStateToProps(state, {objectName, variableName}) {
  const variable = state.selectedVariablesReducer[`${objectName}/${variableName}`];
  const path = `${objectName}/${variableName}`;
  const {copyVarPath} = state.selectedVariablesReducer;
  return {
    variable,
    path,
    copyVarPath,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAllVariables);

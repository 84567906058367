import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ProfileItem from './ProfileItem';
import '../styles/profile-list.scss';

const ProfileList = ({profileIds, editingGroupProfileId}) => {
  if (profileIds === undefined) {
    return null;
  }
  let groupProfileEl = null;
  if (editingGroupProfileId !== undefined) {
    groupProfileEl = (
      <ProfileItem id={editingGroupProfileId} />
    );
  }
  return (
    <div className="profile-list">
      {groupProfileEl}
      {
        profileIds.map(profileId => {
          if (profileId === editingGroupProfileId) {
            return null;
          }

         return (
           <ProfileItem key={profileId} id={profileId} />
         );
        })
      }
    </div>
  );

};

ProfileList.propTypes = {
  profileIds: PropTypes.array,
  editingGroupProfileId: PropTypes.string,
};

function mapStateToProps(state) {
  const {profileReducer, groupReducer } = state;

  const profileIds = profileReducer.filteredProfileIds;
  let editingGroupProfileId;
  const {editingId} = groupReducer;
  if (editingId !== undefined) {
    editingGroupProfileId = groupReducer[editingId].profileId;
  }
  return {
    editingGroupProfileId,
    profileIds,
  };
}

export default connect(
  mapStateToProps,
  null
)(ProfileList);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../actions/planActions';
import * as firmwareActions from '../actions/firmwareActions';
import {bindActionCreators} from 'redux';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';
import {SELECT_PSES, UPGRADE_PLAN_PS, UPGRADE_PLAN_LAMP} from '../constants/customTypes';

const SelectionControl = ({ actions, translate, firmwareActions, mode }) => {

  const {upgradeSelection} = firmwareActions;
  const {changeSelectAll, showLampNodesSelectionDialog, setShowPsSelectionDialog} = actions;
  return (
    <span className="selection-control">
      <ActionButton action={() => changeSelectAll(true)}
        extraClass="btn-default"
        text={translate('technical_select_all')} />&nbsp;
      <ActionButton action={() => changeSelectAll(false)}
        extraClass="btn-default"
        text={translate('technical_deselect_all')} />&nbsp;
      <ActionButton action={() => mode === SELECT_PSES ? setShowPsSelectionDialog(true):showLampNodesSelectionDialog(true)}
        extraClass="btn-default"
        text={translate('technical_open_selection')} />&nbsp;
      <ActionButton action={() => upgradeSelection(mode === SELECT_PSES ? UPGRADE_PLAN_PS:UPGRADE_PLAN_LAMP)}
        extraClass="btn-default"
        text="Upgrade" />
    </span>
  );
};

SelectionControl.propTypes = {
  actions: PropTypes.object.isRequired,
  firmwareActions: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    mode: state.planReducer.mode,
    translate: getTranslate(state.localeReducer)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    firmwareActions: bindActionCreators(firmwareActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectionControl);

import {GET_MOVEMENT_DATA, SET_MOVEMENT_REDUCER_PROPS} from '../constants/movementActionTypes';
import objectAssign from 'object-assign';
import {INITIAL} from '../constants/customTypes';
import {statusHandler} from '../utils/reducerHelper';

const weekAgo = new Date();
weekAgo.setDate(weekAgo.getDate() - 7);

const initialState = {
  maxMovements: 0,
  getMovementStatus: INITIAL,
  startDate: weekAgo,
  endDate: new Date(),
};

const statusHandlerFunc = statusHandler([
  {
    apiAction: GET_MOVEMENT_DATA,
    statusPropName: 'getMovementStatus',
  },
]);

export default function movementReducer(originalState = initialState, action) {
  const state = statusHandlerFunc(originalState, action);
  switch (action.type) {
    case GET_MOVEMENT_DATA.SUCCESS:{
      return objectAssign({}, state, {maxMovements: action.maxMovements});
    }
    case SET_MOVEMENT_REDUCER_PROPS:{
      const {obj} = action;
      return objectAssign({}, state, obj);
    }

    default:
      return state;
  }
}

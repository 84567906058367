export const REACHABLE_BOTH = 'REACHABLE_BOTH';
export const REACHABLE = 'REACHABLE';
export const UNREACHABLE = 'UNREACHABLE';

export const LINKED_ALL = 'LINKED_ALL';
export const LINKED = 'LINKED';
export const NOT_LINKED = 'NOT_LINKED';

export const CONFIGURED_ALL = 'CONFIGURED_ALL';
export const CONFIGURED = 'CONFIGURED';
export const NOT_CONFIGURED = 'NOT_CONFIGURED';

export const SYNCED_ALL = 'SYNCED_ALL';
export const SYNCED = 'SYNCED';
export const NOT_SYNCED = 'NOT_SYNCED';

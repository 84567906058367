import {GET_MOVEMENT_RETENTION_PERIODS} from '../constants/movementRetentionActionTypes';
import {GET_ENERGY_RETENTION_PERIODS} from '../constants/energyRetentionActionTypes';
import {setEnergyReducerProps} from '../actions/energyActions';
import {setMovementReducerProps} from '../actions/movementActions';
import {roundDateToRetention} from '../utils/dateHelper';

export default store => next => action => {
  const {dispatch} = store;
  if(action.type === GET_MOVEMENT_RETENTION_PERIODS.SUCCESS) {
    next(action);
    const {buildingReducer, movementRetentionReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {periods} = movementRetentionReducer[activeBuildingId];

    const weekAgo = new Date();
    weekAgo.setDate(weekAgo.getDate() - 7);
    const roundedStart = roundDateToRetention(weekAgo, periods, Math.ceil);
    const startDate = roundedStart ? roundedStart:weekAgo;
    const now = new Date();

    const roundedEnd = roundDateToRetention(now, periods);
    const endDate = roundedEnd ? roundedEnd:now;
    dispatch(setMovementReducerProps({startDate, endDate}));
  }else if(action.type === GET_ENERGY_RETENTION_PERIODS.SUCCESS) {
    next(action);
    const {energyRetentionReducer, buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {periods, energyCaptureStartDate} = energyRetentionReducer[activeBuildingId];

    const weekAgo = new Date();
    weekAgo.setDate(weekAgo.getDate() - 7);
    const roundedStart = roundDateToRetention(weekAgo, periods, Math.ceil);
    let startDate = roundedStart ? roundedStart:weekAgo;
    if (startDate < energyCaptureStartDate) {
      startDate = energyCaptureStartDate;
    }
    const now = new Date();

    const roundedEnd = roundDateToRetention(now, periods);
    const endDate = roundedEnd ? roundedEnd:now;

    dispatch(setEnergyReducerProps({startDate, endDate}));
  }else {
    return next(action);
  }
};

import { initialize, addTranslationForLanguage, setActiveLanguage } from 'react-localize-redux';
import { SET_LANGUAGE, INITIALIZE_LANGUAGES } from '../constants/languageActionTypes';
import { LANGUAGES, ENGLISH } from '../constants/languageTypes';

const languageMiddleware = store => next => action => {
  const {dispatch} = store;

  if (action.type === SET_LANGUAGE) {
    dispatch(setActiveLanguage(action.language));
  } else if (action.type === INITIALIZE_LANGUAGES) {
    // supported languages
    const languages = LANGUAGES;
    dispatch(initialize(languages, { defaultLanguage: ENGLISH }));

    // language files
    LANGUAGES.forEach(function (language) {
      const langFile = require('../assets/' + language + '.trimonitor.json');
      dispatch(addTranslationForLanguage(langFile, language));
    });
  } else {
    return next(action);
  }
};

export default languageMiddleware;

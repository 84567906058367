import React, {memo} from 'react';
import PropTypes from 'prop-types';

const ReactTablePagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  pageIndex,
}) => {
  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        <li className={`pointer ${canPreviousPage ? '':'disabled'}`}
          onClick={() => previousPage()}
        >
          <span className="fa fa-angle-left" aria-hidden="true"/>
        </li>
        {Array(pageCount).fill().map((_, i) => (
          <li
            onClick={() => gotoPage(i)}
            className={`pointer ${i === pageIndex ? 'active':''}`} key={i}
          >
            <span>{i + 1}</span>
          </li>
        ))}
        <li className={`pointer ${canNextPage ? '':'disabled'}`}
          onClick={() => nextPage()}
        >
          <span className="fa fa-angle-right" aria-hidden="true"/>
        </li>
      </ul>
    </nav>
  );
};

ReactTablePagination.propTypes = {
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
};

export default memo(ReactTablePagination);

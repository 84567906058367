import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import '../styles/plan-img.scss';
import {getPlanImgUrl} from '../utils/planImgUrlHelper';

const PlanImg = ({ planImg }) => {
  if (!planImg) {
    return null;
  }
  const {s3Key, filename, defaultRotation, rotation} = planImg;

  if (LOCAL && filename.length === 0) {
    return null;
  }else if(CLOUD && (!s3Key || s3Key.length === 0)) {
    return null;
  }

  const url = getPlanImgUrl(s3Key, filename);

  const totalRotation = (defaultRotation + rotation) % 360;

  return (
    <div className={`plan-img transform-${totalRotation}`}>
      <img src={url} />
    </div>
  );
};

PlanImg.propTypes = {
  planImg: PropTypes.object,
  id: PropTypes.number,
};

function mapStateToProps(state, props) {
  const planImg = state.planImgReducer[props.id];
  return {
    planImg,
  };
}

export default connect(
  mapStateToProps,
  null,
)(PlanImg);

/* eslint-disable import/default */

import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import Root from './components/Root';
require('es6-promise').polyfill();
import 'isomorphic-fetch';
import {setBrowserDimensions} from './actions/generalActions';
import {initializeLanguages} from './actions/languageActions';
import {getMe} from './actions/authActions';
import './utils/chromePreventDefaultFix';

if (LOCAL) {
  require('bootstrap');
  require('bootstrap/dist/css/bootstrap.min.css');
  require('font-awesome/css/font-awesome.min.css');
}

require('./styles/styles.scss');
require('./favicon.png');

import configureStore, {history} from './store/configureStore';

const store = configureStore();

if (LOCAL) {
  // store.dispatch(dsConnect());
}else if(CLOUD) {
  // store.dispatch(dsConnect());
}
store.dispatch(getMe());

function getWidth() {
  return window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;
}

function getHeight() {
  return window.innerHeight
  || document.documentElement.clientHeight
  || document.body.clientHeight;
}

store.dispatch(setBrowserDimensions(getWidth(), getHeight()));

window.addEventListener('resize', function () {
  store.dispatch(setBrowserDimensions(getWidth(), getHeight()));
});

store.dispatch(initializeLanguages());

render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('app')
    );
  });
}

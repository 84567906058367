import objectAssign from 'object-assign';
import {DONE, ERROR, STARTED} from '../constants/customTypes';

export function statusHandler(handles, isImmer = false) {
  return (state, action) => {
    const {type} = action;
    let returnState = state;
    handles.forEach(({apiAction, statusPropName}) => {
      if (apiAction.START === type) {
        if (isImmer) {
          state[statusPropName] = STARTED;
        }else {
          returnState = objectAssign({}, state, {[statusPropName]: STARTED});
        }
      }else if(apiAction.ERROR === type) {
        if (isImmer) {
          state[statusPropName] = ERROR;
        }else {
          returnState = objectAssign({}, state, {[statusPropName]: ERROR});
        }
      }else if(apiAction.SUCCESS === type) {
        if (isImmer) {
          state[statusPropName] = DONE;
        }else {
          returnState = objectAssign({}, state, {[statusPropName]: DONE});
        }
      }
    });
    return returnState;
  };
}

export function statusHandlerId(handles) {
  return (state, action) => {
    let returnState = state;
    const {type, props} = action;

    handles.forEach(({apiAction, statusPropName}) => {
      if (apiAction.START === type) {
        const {id} = props;
        const newState = objectAssign({}, state);
        newState[id] = objectAssign({}, state[id], {[statusPropName]: STARTED});
        returnState = newState;
      }else if(apiAction.ERROR === type) {
        const {id} = props;
        const newState = objectAssign({}, state);
        newState[id] = objectAssign({}, state[id], {[statusPropName]: ERROR});
        returnState = newState;
      }else if(apiAction.SUCCESS === type) {
        const {id} = props;
        const newState = objectAssign({}, state);
        newState[id] = objectAssign({}, state[id], {[statusPropName]: DONE});
        returnState = newState;
      }
    });
    return returnState;
  };
}

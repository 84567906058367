import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/dataportActions';
import DataportsTechnicalRow from './DataportsTechnicalRow';

const DataportsTechnicalTable = ({dataportIds, technicalDataportsFetched, actions}) => {
  const {getDataportsTechnical} = actions;
  useEffect(() => {
    if (technicalDataportsFetched === false) {
      getDataportsTechnical();
    }
  }, []);

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Selected</th>
          <th>Name</th>
          <th>TriCommand</th>
          <th>TriAgent</th>
          <th>TriView</th>
          <th>Online</th>
          <th>Vpn IP</th>
        </tr>
      </thead>
      <tbody>
        {dataportIds.map(dataportId => <DataportsTechnicalRow key={dataportId} id={dataportId}/>)}
      </tbody>
    </table>
  );
};

DataportsTechnicalTable.propTypes = {
  dataportIds: PropTypes.array.isRequired,
  technicalDataportsFetched: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {dataportIds, technicalDataportsFetched} = state.dataportReducer;
  return {
    dataportIds,
    technicalDataportsFetched,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataportsTechnicalTable);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';
import * as actions from '../actions/authActions';
import {Link} from 'react-router-dom';

const LogoutControl = ({ actions, firstname, lastname, translate, loggedIn }) => {
  if (loggedIn === false) {
    return null;
  }

  const { logout } = actions;
  if (CLOUD) {
    return (
      <span className="logout-control">
        <span className="dropdown pointer">
          <span className="dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            {`${firstname} ${lastname} `}
            <span className="caret" />
          </span>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
            <li><Link to="/user/change-profile">{translate('profile_change')}</Link></li>
            <li><Link to="/user/change-password">{translate('password_change')}</Link></li>
          </ul>
        </span>
        &nbsp;
        <ActionButton action={() => logout()}
          extraClass="btn-primary"
          text={translate('global_logout')} />
      </span>
    );
  }else {
    return (
      <span className="logout-control">
        {`${firstname} ${lastname} `}
        &nbsp;
        <ActionButton action={() => logout()}
          extraClass="btn-primary"
          text={translate('global_logout')} />
      </span>
    );
  }
};

LogoutControl.propTypes = {
  actions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
};

function mapStateToProps(state) {
  const {firstname, lastname, username, loggedIn} = state.authReducer;
  const translate = getTranslate(state.localeReducer);
  return {
    firstname,
    lastname,
    username,
    translate,
    loggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutControl);

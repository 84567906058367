import { UPDATE_REPORT, CREATE_REPORT, GET_REPORT} from '../constants/reportsActionTypes';
import {setBuildingRoute} from '../actions/buildingActions';

const reportsMiddleware = store => next => action => {
  const {dispatch} = store;
  if (action.type === UPDATE_REPORT.SUCCESS || action.type === CREATE_REPORT.SUCCESS) {
    next(action);
    setTimeout(() => {
      dispatch(setBuildingRoute('/plan/reports/'));
    }, 300);
  // }else if(action.type === GET_REPORT.SUCCESS) {
  //   const {response} = action;
  //   const {buildingReducer} = action;

  }else {
    return next(action);
  }
};

export default reportsMiddleware;

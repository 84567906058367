import {apiAction} from './helper';

export const SET_USER_CONFIG_PERMISSIONS = 'SET_USER_CONFIG_PERMISSIONS';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_USER_IDENTIFIER = 'SET_USER_IDENTIFIER';
export const LOGIN = apiAction('LOGIN');
export const RE_AUTH = apiAction('RE_AUTH');
export const SET_PREV_ROUTE = 'SET_PREV_ROUTE';
export const SET_USER_PROPS = 'SET_USER_PROPS';
export const GET_ME = apiAction('GET_ME');
export const LOGOUT = apiAction('LOGOUT');
export const GET_DEEPSTREAM_TOKEN = apiAction('GET_DEEPSTREAM_TOKEN');
export const FORGOT_PASSWORD = apiAction('FORGOT_PASSWORD');
export const RESET_PASSWORD = apiAction('RESET_PASSWORD');
export const CHANGE_PASSWORD = apiAction('CHANGE_PASSWORD');
export const CHANGE_PROFILE = apiAction('CHANGE_PROFILE');

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as apActions from '../actions/apActions';
import * as ppActions from '../actions/ppActions';
import * as firmwareActions from '../actions/firmwareActions';
import * as filterActions from '../actions/filterActions';
import * as actions from '../actions/planActions';
import {UPGRADE_ITEMS_PS, UPGRADE_ITEMS_LAMP} from '../constants/customTypes';
import {bindActionCreators} from 'redux';
import '../styles/items-selection-control.scss';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const ItemsSelectionControl = ({ apActions, ppActions, actions, lampCount, psCount, firmwareActions, translate,
filterActions}) => {

  const {changePropAllAps, changePropNodeAllAps} = apActions;
  const {changeSelectAllPps} = ppActions;
  const {setShowPsNodesSelectionDialog, showLampPpsSelectionDialog} = actions;
  const {upgradeSelection} = firmwareActions;
  const {setFilterReducerProps} = filterActions;
  return (
    <span className="items-selection-control">
      <div>
        <ActionButton action={() => setFilterReducerProps({showFilterDialog: true})}
          extraClass="btn btn-default"
          text="Filters" />
        <span className="btn btn-group">
          <ActionButton action={() => changePropAllAps({showLamps: true})}
            extraClass="btn-default" icon="fa-plus"
            text="Expand" />
          <ActionButton action={() => changePropAllAps({showLamps: false})}
            extraClass="btn-default" icon="fa-minus"
            text="Collapse" />
        </span>
      </div>
      <div>
        <i className="fa fa-plug" aria-hidden="true" />
        <span className="badge">{psCount}</span>
        <span className="btn btn-group">
          <ActionButton action={() => changePropNodeAllAps({selected: true})}
            extraClass="btn-default"
            text={translate('technical_select_all')} />
          <ActionButton action={() => changePropNodeAllAps({selected: false})}
            extraClass="btn-default"
            text={translate('technical_deselect_all')} />
          <ActionButton action={() => setShowPsNodesSelectionDialog(true)}
            extraClass="btn-default"
            text={translate('technical_open')} />
          <ActionButton action={() => upgradeSelection(UPGRADE_ITEMS_PS)}
            extraClass="btn-default"
            text={translate('technical_upgrade')} />
        </span>
      </div>
      <div>
        <i className="fa fa-lightbulb-o" aria-hidden="true" />
        <span className="badge">{lampCount}</span>
        <span className="btn btn-group">
          <ActionButton action={() => changeSelectAllPps(true)}
            extraClass="btn-default"
            text={translate('technical_select_all')} />
          <ActionButton action={() => changeSelectAllPps(false)}
            extraClass="btn-default"
            text={translate('technical_deselect_all')} />
          <ActionButton action={() => showLampPpsSelectionDialog()}
            extraClass="btn-default"
            text={translate('technical_open')} />
          <ActionButton action={() => upgradeSelection(UPGRADE_ITEMS_LAMP)}
            extraClass="btn-default"
            text={translate('technical_upgrade')} />
        </span>
      </div>
    </span>
  );
};

ItemsSelectionControl.propTypes = {
  actions: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  apActions: PropTypes.object.isRequired,
  ppActions: PropTypes.object.isRequired,
  psCount: PropTypes.number.isRequired,
  lampCount: PropTypes.number.isRequired,
  firmwareActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const { planReducer, filterReducer} = state;
  const {filteredApIds, ppCount} = filterReducer;

  const translate = getTranslate(state.localeReducer);

  return {
    mode: planReducer.mode,
    psCount: filteredApIds.length,
    lampCount: ppCount,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    apActions: bindActionCreators(apActions, dispatch),
    ppActions: bindActionCreators(ppActions, dispatch),
    firmwareActions: bindActionCreators(firmwareActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemsSelectionControl);

import React from 'react';
import PropTypes from 'prop-types';
import LampItem from '../containers/LampItem';

const LampList = ({ lamps }) => {

  return (
    <div className="lamp-list">
      { lamps.map((id) => {
        return <LampItem key={id} id={id} />;
      })}
    </div>
  );
};

LampList.propTypes = {
  lamps: PropTypes.array.isRequired,
};


export default LampList;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/buildingActions';
import '../styles/config-form.scss';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const SaveConfig = ({activeBuildingId, actions, configSaved, translate}) => {
  const {saveBuildingConfig} = actions;
  if (activeBuildingId === undefined) {
    return null;
  }
  return (
    <span className="save-config">
      <ActionButton action={() => saveBuildingConfig(activeBuildingId)}
        extraClass={(configSaved ? 'btn-success':'btn-primary')}
        text={translate('technical_save_config')} />
    </span>
  );
};

SaveConfig.propTypes = {
  activeBuildingId: PropTypes.number,
  actions: PropTypes.object.isRequired,
  configSaved: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {buildingReducer} = state;
  const {activeBuildingId, configSaved} = buildingReducer;
  const translate = getTranslate(state.localeReducer);

  return {
    configSaved,
    translate,
    activeBuildingId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveConfig);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import '../styles/alerts-list.scss';
import Alert from './Alert';

const AlertsList = ({alertIds}) => {

  return (
    <div className="alerts-list">
      {alertIds.map((id) => {
        return <Alert key={id} id={id} />;
      })}
    </div>
  );
};

AlertsList.propTypes = {
  alertIds: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    alertIds: state.alertReducer.alertIds,
  };
}

export default connect(
  mapStateToProps,
  null
)(AlertsList);

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Checkbox from '../components/Checkbox';

const ReportConfig = ({building, reportConfiguration, onChange, buildingId, shouldEnable}) => {
  const {name} = building;

  useEffect(() => {
    if (!reportConfiguration) {
      onChange({
        configuration_id: buildingId,
        movement_heatmap: true,
        energy_heatmap: true,
        enabled: shouldEnable,
      });
    }
  }, []);

  if (!reportConfiguration) {
    return null;
  }
  const {movement_heatmap, energy_heatmap, enabled} = reportConfiguration;

  return (
    <tr>
      <th>
        <Checkbox checked={enabled} onChange={() => onChange({enabled: !enabled})}>
          {name}
        </Checkbox>
      </th>
      <td>
        <Checkbox checked={movement_heatmap} noMargin onChange={() => onChange({movement_heatmap: !movement_heatmap})} />
      </td>
      <td>
        <Checkbox checked={energy_heatmap} noMargin onChange={() => onChange({energy_heatmap: !energy_heatmap})} />
      </td>
    </tr>
  );
};

ReportConfig.propTypes = {
  buildingId: PropTypes.number.isRequired,
  building: PropTypes.object.isRequired,
  reportConfiguration: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  shouldEnable: PropTypes.bool.isRequired,
};

function mapStateToProps({buildingReducer}, {buildingId}) {
  return {
    building: buildingReducer[buildingId],
  };
}

export default connect(
  mapStateToProps,
  null,
)(ReportConfig);

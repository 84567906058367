import objectAssign from 'object-assign';
import {SET_PP_PROPS, SET_MULTI_PP_PROPS, SET_PPS} from '../constants/ppActionTypes';
import {PP_ADDED_EVENT, PP_DECOMMISSIONED_EVENT} from '../constants/siteActionTypes';
import {makeUid} from '../utils/idHelper';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {
  linking: false,
  linkingId: undefined,
  idApsMap: {},
};

export default function ppReducer(state = initialState, action) {

  switch (action.type) {
    case SET_PPS:{
      const {pps, dataport, idApsMap} = action;
      const newState = objectAssign({}, state, {idApsMap});
      const ppIds = pps.map(pp => {
        newState[pp.id] = objectAssign({selected: false}, state[pp.id], pp);
        return pp.id;
      });

      newState[`${dataport}/ids`] = ppIds;
      return newState;
    }
    case SET_PP_PROPS:{
      let newState = objectAssign({}, state);
      newState[action.id] = objectAssign({}, newState[action.id], action.obj);
      return newState;
    }
    case SET_MULTI_PP_PROPS:{
      const {ids, obj} = action;
      let newState = objectAssign({}, state);
      ids.forEach((id) => {
        newState[id] = objectAssign({}, state[id], obj);
      });
      return newState;
    }
    // case CHANGE_SELECT_ALL_PPS:{
    //   const {ids, selected} = action;
    //   let newState = objectAssign({}, state);
    //   ids.forEach((id) => {
    //     newState[id] = objectAssign({}, state[id], {selected});
    //   });
    //   return newState;
    // }
    case PP_ADDED_EVENT:{
      const {terminalId, id : ipui, apId} = action.data;
      const idApsMap = objectAssign({}, state.idApsMap);
      if (idApsMap[ipui] === undefined) {
        idApsMap[ipui] = [apId];
      }else {
        idApsMap[ipui].push(apId);
      }
      const newState = objectAssign({}, state, {idApsMap});
      const id = makeUid(apId, terminalId);
      newState[id] = {
        id,
        terminal_id: terminalId,
        ipui,
        parentId: apId,
        name: 'no name',
      };
      const key = `${action.props.dataport}/ids`;
      if (state[key] === undefined) {
        newState[key] = [id];
      }else if(state[key].indexOf(id) === -1) {
        newState[key] = state[key].concat([id]);
      }
      return newState;
    }
    case PP_DECOMMISSIONED_EVENT:{
      const {apId, terminalId, id : ipui} = action.data;
      const id = makeUid(apId, terminalId);
      const idApsMap = objectAssign({}, state.idApsMap);

      if (idApsMap[ipui] !== undefined) {
        idApsMap[ipui].splice(idApsMap[ipui].indexOf(ipui), 1);
      }

      let newState = objectAssign({}, state, {idApsMap});
      delete newState[id];
      const key = `${action.props.dataport}/ids`;
      let pps = [].concat(state[key]);
      pps.splice(pps.indexOf(id), 1);
      newState[key] = pps;
      return newState;
    }

    default:
      return state;
  }
}

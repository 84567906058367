import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';
import '../styles/dialog.scss';

const DialogWrapper = (props) => {
  return (
    <div className={"dialog-wrapper " + (props.visible ? '':'hidden')}>
      <Dialog {...props} />
    </div>
  );
};

DialogWrapper.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default DialogWrapper;

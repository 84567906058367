import { SET_COMMISSIONING, SET_AP_ENABLED, CHANGE_PROP_ALL_APS,
  SET_AP_PROPS, SET_MULTI_AP_PROPS,
  SET_APS,
} from '../constants/apActionTypes';
import objectAssign from 'object-assign';
import {AP_ADDED_EVENT, PP_ADDED_EVENT, PP_DECOMMISSIONED_EVENT} from '../constants/siteActionTypes';
import {makeUid} from '../utils/idHelper';
// import {DONE, ERROR, STARTED} from '../constants/customTypes';

const initialState = {
  linking: false,
  linkingId: undefined,
};

export default function apReducer(state = initialState, action) {

  switch (action.type) {
    case SET_APS:{
      const {aps, dataport} = action;
      const newState = objectAssign({}, state);
      let apIds = aps.map(ap => {
        newState[ap.id] = objectAssign({}, state[ap.id], ap);
        return ap.id;
      });

      apIds = apIds.sort((a, b) => {
        if (newState[a].name.length > 0) {
          return newState[a].name.localeCompare(newState[b].name);
        }
        return newState[a].id.localeCompare(newState[b].id);
      });

      newState[`${dataport}/ids`] = apIds;
      return newState;
    }
    case SET_AP_PROPS:{
      let newState = objectAssign({}, state);
      newState[action.id] = objectAssign({}, newState[action.id], action.obj);
      return newState;
    }
    case SET_COMMISSIONING.SUCCESS:{
      const {id, commissioning} = action.props;
      let newState = objectAssign({}, state);
      newState[id] = objectAssign({}, newState[id], {registration_mode: commissioning});
      return newState;
    }
    // case CHANGE_SELECT_APS:{
    //   const {ids, selected} = action;
    //   let newState = objectAssign({}, state);
    //   ids.forEach((id) => {
    //     newState[id] = objectAssign({}, state[id], {selected});
    //   });
    //   return newState;
    // }
    // case CHANGE_SELECT_ALL_APS:{
    //   const {ids, selected} = action;
    //   let newState = objectAssign({}, state);
    //   ids.forEach((id) => {
    //     newState[id] = objectAssign({}, state[id], {selected});
    //   });
    //   return newState;
    // }
    case SET_MULTI_AP_PROPS:{
      const {ids, obj} = action;
      let newState = objectAssign({}, state);
      ids.forEach((id) => {
        newState[id] = objectAssign({}, state[id], obj[id]);
      });
      return newState;
    }
    case AP_ADDED_EVENT:{
      const {dataport} = action.props;
      const {ap} = action.data;
      ap.clientIds = [];
      let newState = objectAssign({}, state);
      newState[ap.id] = ap;
      const key = `${dataport}/ids`;
      if (state[key] === undefined) {
        newState[key] = [ap.id];
      }else if(state[key].indexOf(ap.id) === -1) {
        newState[key] = state[key].concat([ap.id]);
      }
      return newState;
    }
    case PP_ADDED_EVENT:{
      const {terminalId, apId} = action.data;
      const id = makeUid(apId, terminalId);

      let newState = objectAssign({}, state);
      if (state[apId].clientIds.indexOf(id) === -1) {
        const clientIds = state[apId].clientIds.concat([id]);
        newState[apId] = objectAssign({}, state[apId], {clientIds});
      }
      return newState;
    }
    case PP_DECOMMISSIONED_EVENT:{
      const {terminalId, apId} = action.data;
      const id = makeUid(apId, terminalId);
      let newState = objectAssign({}, state);
      let clientIds = [].concat(state[apId].clientIds);
      clientIds.splice(clientIds.indexOf(id), 1);
      newState[apId] = objectAssign({}, state[apId], {clientIds});
      return newState;
    }
    case SET_AP_ENABLED.SUCCESS:{
      const {id, value} = action.props;
      let newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[id], {enabled: value});
      return newState;
    }
    case CHANGE_PROP_ALL_APS:{
      const {ids, obj} = action;
      let newState = objectAssign({}, state);
      ids.forEach(id => {
        newState[id] = objectAssign({}, state[id], obj);
      });
      return newState;
    }

    default:
      return state;
  }
}

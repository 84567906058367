import React from 'react';
import PropTypes from 'prop-types';
import HardwareVersion from './HardwareVersion';
import SoftwareVersion from './SoftwareVersion';

const LampInfo = ({node, translate, pp, parentApIds}) => {
  let nodeType, hwVersion, swVersion, terminalId;
  if (node !== undefined) {
    nodeType = node.node_type;
    hwVersion = node.hardware_version;
    swVersion = node.software_version;
  }

  let extraInfoRow = null;
  if (pp !== undefined) {
    terminalId = pp.terminal_id;
    extraInfoRow = (
      <tr>
        <th>{translate('technical_terminalid')}:</th>
        <td>{terminalId}</td>
      </tr>
    );
  }else if(parentApIds !== undefined) {
    extraInfoRow = (
      <tr>
        <th>Aps:</th>
        <td>{parentApIds.join(', ')}</td>
      </tr>
    );
  }


  return (
    <span className="extra-node-info">
      <table className="table">
        <tbody>
          <tr>
            <th>{translate('technical_nodetype')}:</th>
            <td>{nodeType}</td>
          </tr>
          {extraInfoRow}
          <tr>
            <th>{translate('technical_hardwareversion')}:</th>
            <td><HardwareVersion hardwareVersion={hwVersion} /></td>
          </tr>
          <tr>
            <th>{translate('technical_softwareversion')}:</th>
            <td><SoftwareVersion softwareVersion={swVersion} /></td>
          </tr>
        </tbody>
      </table>
    </span>
  );
};

LampInfo.propTypes = {
  node: PropTypes.object,
  pp: PropTypes.object,
  translate: PropTypes.func.isRequired,
  parentApIds: PropTypes.array,
};

export default LampInfo;

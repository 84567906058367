import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {translateHardwareVersion} from '../utils/versionHelper';
import { getTranslate } from 'react-localize-redux';

const HardwareVersion = ({hardwareVersion, translate}) => {
  if (hardwareVersion !== undefined) {
    return (
      <span>
        {translateHardwareVersion(hardwareVersion)}
      </span>
    );
  }else {
    return <span>{translate('technical_undefined')}</span>;
  }
};

HardwareVersion.propTypes = {
  hardwareVersion: PropTypes.string,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const translate = getTranslate(state.localeReducer);
  return {
    translate
  };
}

export default connect(
  mapStateToProps,
  null,
)(HardwareVersion);

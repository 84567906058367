import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/buildingActions';
import Drag from '../components/Drag';
import '../styles/minimap.scss';

const factor = 6;

class Minimap extends Component {
  constructor() {
    super();
    this.createPoint = this.createPoint.bind(this);
    this.state = {};
  }
  componentDidMount() {
    this.createPoint();
  }
  createPoint() {
    const pt = this.svgRef.createSVGPoint();
    this.setState({
      svgPoint: pt,
      childrenNode: this.childrenNode,
    });
  }
  render() {
    const {children, width, height, svgHeight, svgWidth, planHeight, xMap, yMap,
      planWidth, planX, planY, zoomX, zoomY, zoomK, actions, activeBuildingId,
    } = this.props;
    const {svgPoint, childrenNode} = this.state;
    const halfWidth = width / 2;
    const halfHeight = height / 2;
    const {setBuildingProps} = actions;
    const inverseZoom = 1 / zoomK;
    const drag = (dX, dY) => {
      setBuildingProps(activeBuildingId, {
        zoomX: zoomX - (dX * factor * zoomK * xMap),
        zoomY: zoomY - (dY * factor * zoomK * yMap),
      });
    };

    const click = (e) => {
      svgPoint.x = e.clientX;
      svgPoint.y = e.clientY;
      const matrix =  svgPoint.matrixTransform(childrenNode.getScreenCTM().inverse());
      setBuildingProps(activeBuildingId, {
        zoomX: ((-matrix.x * zoomK) + planX + (planWidth / 2)),
        zoomY: ((-matrix.y * zoomK) + planY + (planHeight / 2)),
      });
    };

    return (
      <svg className="minimap" viewBox={`0 0 ${width} ${height}`} style={{height: svgHeight, width: svgWidth}}
        ref={(input) => this.svgRef = input}>
        <g transform={`scale(0.9) translate(${planWidth * 0.05}, ${planHeight * 0.05})`} ref={(el) => this.childrenNode = el}>
          {children}
        </g>
        <rect className="minimap-pointer-catcher" x={planX} y={planY} width={planWidth} height={planHeight}
          transform={`translate(${halfWidth}, ${halfHeight}) scale(1.1) translate(-${halfWidth}, -${halfHeight})`}
          onClick={click}/>

        <g transform={`scale(0.9)translate(${planWidth * 0.05}, ${planHeight * 0.05})`}>
          <Drag action={drag}>
            <rect className="minimap-rect" x={planX} y={planY} width={planWidth} height={planHeight}
              transform={`scale(${inverseZoom}) translate(${-zoomX}, ${-zoomY})`} />
          </Drag>
        </g>

      </svg>
    );
  }
}

Minimap.propTypes = {
  actions: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  svgHeight: PropTypes.number.isRequired,
  svgWidth: PropTypes.number.isRequired,
  planX: PropTypes.number.isRequired,
  planY: PropTypes.number.isRequired,
  planWidth: PropTypes.number.isRequired,
  planHeight: PropTypes.number.isRequired,
  zoomX: PropTypes.number.isRequired,
  zoomY: PropTypes.number.isRequired,
  zoomK: PropTypes.number.isRequired,
  xMap: PropTypes.number.isRequired,
  yMap: PropTypes.number.isRequired,
  activeBuildingId: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  svgCoordOffsetX: PropTypes.number.isRequired,
  svgCoordOffsetY: PropTypes.number.isRequired,
};

function mapStateToProps(state, props) {
  const {planReducer, buildingReducer} = state;
  const {activeBuildingId} = buildingReducer;
  const svgHeight = props.svgHeight / factor;
  const svgWidth = props.svgWidth / factor;

  const {xMap, yMap, svgCoordOffsetX, svgCoordOffsetY} = planReducer;
  const {zoomX, zoomY, zoomK, rotation} = buildingReducer[activeBuildingId];
  return {
    svgHeight,
    svgWidth,
    zoomX,
    zoomY,
    zoomK,
    activeBuildingId,
    rotation,
    xMap,
    yMap,
    svgCoordOffsetX,
    svgCoordOffsetY,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Minimap);

import {DS_CONNECT_SUCCESS, DS_CONNECT_ERROR, DS_DISCONNECT} from '../constants/dsActionTypes';
import objectAssign from 'object-assign';
import initialState from './dsInitialState';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function dsReducer(state = initialState, action) {

  switch (action.type) {
    case DS_CONNECT_SUCCESS:
      return objectAssign({}, state, {connected: true});
    case DS_CONNECT_ERROR:
      return objectAssign({}, state, {error: action.error, connected: false});
    case DS_DISCONNECT:
      return objectAssign({}, state, {connected: false});
    default:
      return state;
  }
}

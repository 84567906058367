import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userReducer from './userReducer';
import dsReducer from './dsReducer';
import alertReducer from './alertReducer';
import lampReducer from './lampReducer';
import lineReducer from './lineReducer';
import psReducer from './psReducer';
import clientReducer from './clientReducer';
import buildingReducer from './buildingReducer';
import planReducer from './planReducer';
import objectReducer from './objectReducer';
import variableReducer from './variableReducer';
import nodeReducer from './nodeReducer';
import selectedObjectsReducer from './selectedObjectsReducer';
import selectedVariablesReducer from './selectedVariablesReducer';
import groupReducer from './groupReducer';
import profileReducer from './profileReducer';
import generalReducer from './generalReducer';
import planImgReducer from './planImgReducer';
import firmwareReducer from './firmwareReducer';
import liveReducer from './liveReducer';
import apReducer from './apReducer';
import ppReducer from './ppReducer';
import filterReducer from './filterReducer';
import extraVariableReducer from './extraVariableReducer';
import serviceReducer from './serviceReducer';
import lampTypeReducer from './lampTypeReducer';
import authReducer from './authReducer';
import siteReducer from './siteReducer';
import routingReducer from './routingReducer';
import roleReducer from './roleReducer';
import scenarioReducer from './scenarioReducer';
import uniqueObjectsReducer from './uniqueObjectsReducer';
import {localeReducer} from 'react-localize-redux';

const reducers = {
  userReducer,
  dsReducer,
  alertReducer,
  psReducer,
  lampReducer,
  lineReducer,
  clientReducer,
  buildingReducer,
  planReducer,
  objectReducer,
  variableReducer,
  nodeReducer,
  selectedObjectsReducer,
  selectedVariablesReducer,
  groupReducer,
  profileReducer,
  generalReducer,
  planImgReducer,
  firmwareReducer,
  liveReducer,
  extraVariableReducer,
  localeReducer,
  apReducer,
  ppReducer,
  filterReducer,
  serviceReducer,
  lampTypeReducer,
  siteReducer,
  authReducer,
  routingReducer,
  roleReducer,
  scenarioReducer,
  uniqueObjectsReducer,
};

if (CLOUD) {
  reducers.dataportReducer = require('./dataportReducer').default;
  reducers.movementReducer = require('./movementReducer').default;
  reducers.energyReducer = require('./energyReducer').default;
  reducers.movementRetentionReducer = require('./movementRetentionReducer').default;
  reducers.energyRetentionReducer = require('./energyRetentionReducer').default;
  reducers.reportsReducer = require('./reportsReducer').default;
  reducers.reportHistoryReducer = require('./reportHistoryReducer').default;
}else if(LOCAL) {
  reducers.dataportReducer = require('./localDataportReducer').default;
}

const rootReducer = history => (state, action) => {
  if (action.type === 'RESET_REDUX') {
    const { router, localeReducer } = state;
    state = { router, localeReducer } ;
  }

  reducers.router = connectRouter(history);
  const appReducer = combineReducers(reducers);

  return appReducer(state, action);
};

// const rootReducer = history => combineReducers({
//   router: connectRouter(history),
//   ...reducers,
// });

export default rootReducer;

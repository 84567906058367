import objectAssign from 'object-assign';
import {SET_LAMP_DIALOG_MAC, SET_PS_DIALOG_MAC, GET_NODES,
   POLL_NODES, SET_NODE_PROPS, SET_NODES_MULTI_PROPS,
  SET_NODES_PROPS, SET_LINKING, WINK, RESET_NODE,
  FORCE_READ_CONFIG, SAVE_SMALI_OBJECTS,
  PING_NODES, CHANGE_SELECT_NODES, SET_LINKING_SUCCESS,
} from '../constants/nodeActionTypes';
import {GET_NODES_UPGRADE_SYNCED} from '../constants/firmwareActionTypes';
import {DONE, ERROR, STARTED, INITIAL} from '../constants/customTypes';
import {PING_SUC_EVENT, PING_FAIL_EVENT, NODE_ADDED_EVENT, PP_DECOMMISSIONED_EVENT,
  POLL_SUC_EVENT, POLL_FAIL_EVENT,
} from '../constants/siteActionTypes';
import {statusHandlerId, statusHandler} from '../utils/reducerHelper';

const initialState = {
  linking: false,
  linkingId: undefined,
  psDialogMac: undefined,
  lampDialogMac: undefined,
  ppDialogId: undefined,
  saveSmaliStatus: INITIAL,
};

const statusHandleIdFunc = statusHandlerId([
  {
    apiAction: FORCE_READ_CONFIG,
    statusPropName: 'forceReadConfigStatus',
  },
  {
    apiAction: WINK,
    statusPropName: 'winkStatus',
  },
  {
    apiAction: RESET_NODE,
    statusPropName: 'resetStatus',
  },
]);

const statusHandleFunc = statusHandler([
  {
    apiAction: SAVE_SMALI_OBJECTS,
    statusPropName: 'saveSmaliStatus',
  },
]);

export default function nodeReducer(preState = initialState, action) {
  const statusHandlerIdState = statusHandleIdFunc(preState, action);
  const state = statusHandleFunc(statusHandlerIdState, action);

  switch (action.type) {
    case SET_LAMP_DIALOG_MAC:{
      const {mac, ppId} = action;
      return objectAssign({}, state, {
        lampDialogMac: mac,
        ppDialogId: ppId,
      });
    }
    case SET_PS_DIALOG_MAC:{
      return objectAssign({}, state, {psDialogMac: action.mac});
    }
    case GET_NODES.SUCCESS:{
      const {response} = action;
      // const {dataport} = props;

      let newState = objectAssign({}, state);
      response.forEach((node) => {
        newState[node.id] = objectAssign({}, state[node.id], node);
      });

      // newState[`${dataport}/ids`] = lampIds;
      return newState;
    }
    case PING_NODES.START:{
      const {macs} = action.props;
      let newState = objectAssign({}, state);
      macs.forEach((mac) => {
        newState[mac] = objectAssign({}, state[mac], {
          pingStatus: STARTED,
          pingFails: 0,
        });
      });
      return newState;

    }
    case POLL_NODES.START:{
      const {ids} = action.props;
      let newState = objectAssign({}, state);
      ids.forEach((id) => {
        newState[id] = objectAssign({}, state[id], {
          pollStatus: STARTED,
        });
      });
      return newState;
    }
    // case PING_NODE.SUCCESS:{
    //   const {id} = action.props;
    //   let newState = objectAssign({}, state);
    //   newState[id] = objectAssign({}, newState[id], {pingStatus: DONE});
    //   return newState;
    // }
    // case PING_NODE.START:{
    //   const {id} = action.props;
    //   let newState = objectAssign({}, state);
    //   newState[id] = objectAssign({}, newState[id], {pingStatus: STARTED});
    //   return newState;
    // }
    // case PING_NODE.ERROR:{
    //   const {id} = action.props;
    //   let newState = objectAssign({}, state);
    //   newState[id] = objectAssign({}, newState[id], {pingStatus: ERROR});
    //   return newState;
    // }
    case SET_NODES_PROPS:{
      const {ids, obj} = action;
      let newState = objectAssign({}, state);
      ids.forEach((id) => {
        newState[id] = objectAssign({}, state[id], obj);
      });
      return newState;
    }
    case SET_NODES_MULTI_PROPS:{
      const {ids, obj} = action;
      let newState = objectAssign({}, state);
      ids.forEach((id) => {
        newState[id] = objectAssign({}, state[id], obj[id]);
      });
      return newState;
    }
    case SET_NODE_PROPS:{
      const {obj, id} = action;
      let newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[id], obj);
      return newState;
    }
    case SET_LINKING:{
      const {linking, linkingId} = action;
      let newState = objectAssign({}, state, {linking, linkingId});
      if (state.linkingId !== undefined) {
        newState[state.linkingId] = objectAssign({}, state[state.linkingId], {linking: false});
      }
      newState[linkingId] = objectAssign({}, newState[linkingId], {linking});
      return newState;
    }
    case SET_LINKING_SUCCESS:{
      const {linkingId} = state;
      let newState = objectAssign({}, state, {linkingId: undefined, linking: false});
      newState[linkingId] = objectAssign({}, newState[linkingId], {linking: false});
      return newState;
    }
    case PING_SUC_EVENT:{
      const {mac} = action.data;
      let newState = objectAssign({}, state);
      newState[mac] = objectAssign({}, state[mac], {pingStatus: DONE});
      return newState;
    }
    case PING_FAIL_EVENT:{
      const {mac} = action.data;
      let newState = objectAssign({}, state);
      newState[mac] = objectAssign({}, state[mac], {pingFails: state[mac].pingFails + 1});
      return newState;
    }
    case POLL_SUC_EVENT:{
      const {node} = action.data;
      const {id} = node;
      let newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[id], node, {pollStatus: DONE});
      return newState;
    }
    case POLL_FAIL_EVENT:{
      const {id} = action.data;
      let newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[id], {pollStatus: ERROR});
      return newState;
    }
    case CHANGE_SELECT_NODES:{
      const {ids, selected} = action;
      let newState = objectAssign({}, state);
      ids.forEach((id) => {
        newState[id] = objectAssign({}, state[id], {selected});
      });
      return newState;
    }
    case NODE_ADDED_EVENT:{
      const {node} = action.data;
      let newState = objectAssign({}, state);
      newState[node.id] = objectAssign({}, state[node.id], node);
      return newState;
    }
    case PP_DECOMMISSIONED_EVENT:{
      const {id} = action.data;
      let newState = objectAssign({}, state);
      delete newState[id];
      return newState;
    }
    case GET_NODES_UPGRADE_SYNCED.SUCCESS:{
      let newState = objectAssign({}, state);
      const {response} = action;
      const keys = Object.keys(response);
      keys.forEach((key) => {
        const isSynced = response[key];
        newState[key] = objectAssign({}, state[key], {isSynced});
      });
      return newState;
    }

    default:
      return state;
  }
}

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import NotFoundPage from './components/NotFoundPage';
import LoginPage from './containers/LoginPage';
import ClientsPage from './components/ClientsPage';
import EditClientPage from './components/EditClientPage';
import EditBuildingPage from './containers/EditBuildingPage';
import UsersPage from './components/UsersPage';
import EditUserPage from './containers/EditUserPage';
import LampTypesPage from './components/LampTypesPage';
import EditLampTypePage from './components/EditLampTypePage';
import DataportsPage from './components/DataportsPage';
import EditDataportPage from './components/EditDataportPage';
import DataportsTechnicalPage from './components/DataportsTechnicalPage';
import DataportTechnicalDetailPage from './containers/DataportTechnicalDetailPage';
import DataportCommandOutputPage from './containers/DataportCommandOutputPage';
import DataportsCommandPage from './components/DataportsCommandPage';
import ForgotPasswordPage from './containers/ForgotPasswordPage';
import ResetPasswordPage from './containers/ResetPasswordPage';
import ChangePasswordPage from './containers/ChangePasswordPage';
import ChangeProfilePage from './containers/ChangeProfilePage';
import PlanDrawingPage from './containers/PlanDrawingPage';
import PlanDataportPage from './containers/PlanDataportPage';
import PlanFirmwarePage from './containers/PlanFirmwarePage';
import PlanTechnicalPage from './containers/PlanTechnicalPage';
import PlanConfigurationPage from './containers/PlanConfigurationPage';
import PlanLivePage from './containers/PlanLivePage';
import PlanMovementPage from './containers/PlanMovementPage';
import PlanEnergyPage from './containers/PlanEnergyPage';
import PlanReportsPage from './containers/PlanReportsPage';
import PlanReportPage from './containers/PlanReportPage';
import {
  ROUTE_DRAWING,
  ROUTE_TECHNICAL,
  ROUTE_CONFIG,
  ROUTE_LIVE,
  ROUTE_MOVEMENT,
  ROUTE_ENERGY,
  ROUTE_REPORTS,
  ROUTE_REPORT,
} from './constants/const';

const Routes = () => (
  <Switch>
    <Redirect exact from="/" to="/plan" />
    <Route path="/login" component={LoginPage} />
    <Route path="/user/forgot-password" component={ForgotPasswordPage} />
    <Route path="/user/password-reset/:userId/:token" component={ResetPasswordPage} />
    <Route path="/user/change-password" component={ChangePasswordPage} />
    <Route path="/user/change-profile" component={ChangeProfilePage} />

    <Route path={`${ROUTE_DRAWING}/:clientName?/:buildingName?`} component={PlanDrawingPage} />
    <Route path="/plan/dataport/:clientName?/:buildingName?" component={PlanDataportPage} />
    <Route path="/plan/firmware/:clientName?/:buildingName?" component={PlanFirmwarePage} />
    <Route path={`${ROUTE_TECHNICAL}/:clientName?/:buildingName?`} component={PlanTechnicalPage} />
    <Route path={`${ROUTE_CONFIG}/:clientName?/:buildingName?`} component={PlanConfigurationPage} />
    <Route path={`${ROUTE_LIVE}/:clientName?/:buildingName?`} component={PlanLivePage} />
    <Route path={`${ROUTE_MOVEMENT}/:clientName?/:buildingName?`} component={PlanMovementPage} />
    <Route path={`${ROUTE_ENERGY}/:clientName?/:buildingName?`} component={PlanEnergyPage} />
    <Route path={`${ROUTE_REPORT}/:clientName?/:buildingName?/:reportId?`} component={PlanReportPage} />
    <Route path={`${ROUTE_REPORTS}/:clientName?/:buildingName?`} component={PlanReportsPage} />

    <Redirect exact from="/plan/:clientName?/:buildingName?" to="/plan/config/:clientName?/:buildingName?" />

    <Route path="/clients/:clientId" component={EditClientPage} />
    <Route path="/clients" component={ClientsPage} />

    <Route path="/monitor/dataports/command" component={DataportsCommandPage} />
    <Route path="/monitor/command/output/:dataportId" component={DataportCommandOutputPage} />
    <Route path="/monitor/:dataportId" component={DataportTechnicalDetailPage} />
    <Route path="/monitor" component={DataportsTechnicalPage} />

    <Route path="/dataports/:dataportId" component={EditDataportPage} />
    <Route path="/dataports" component={DataportsPage} />
    <Route path="/building/new/:clientId" component={EditBuildingPage} />
    <Route path="/building/:buildingId" component={EditBuildingPage} />
    <Route path="/building" component={EditBuildingPage} />
    <Route path="/user/:userId" component={EditUserPage} />
    <Route path="/users" component={UsersPage} />
    <Route path="/lamp-type/:lampTypeId" component={EditLampTypePage} />
    <Route path="/lamp-types" component={LampTypesPage} />
    <Route component={NotFoundPage}/>
  </Switch>
);

export default Routes;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/dataportActions';

class DataportSelect extends Component {
  componentDidMount() {
    const {dataportOptions, actions} = this.props;
    const {getDataports} = actions;
    if (dataportOptions.length === 0) {
      getDataports();
    }
  }
  render() {
    const {dataportOptions, name, value, onChange} = this.props;
    if (dataportOptions.length === 0) {
      return <p>Loading...</p>;
    }

    return (
      <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={null}
        isClearable={true}
        isSearchable={true}
        onChange={onChange}
        name={name}
        value={dataportOptions.find(option => option.value === value)}
        options={dataportOptions}
      />
    );
  }
}

DataportSelect.propTypes = {
  actions: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  dataportOptions: PropTypes.array.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {dataportOptions} = state.dataportReducer;
  return {
    dataportOptions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataportSelect);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import SoftwareVersion from '../components/SoftwareVersion';
import {PS, LAMP} from '../constants/customTypes';
import * as actions from '../actions/firmwareActions';
import {bindActionCreators} from 'redux';
import { getTranslate } from 'react-localize-redux';

const UpgradeSwVersion = ({swVersion, firmwareFile, files, actions, nodeType, translate}) => {
  const {setFirmwareSwVersionFile} = actions;
  return (
    <div className="form-inline upgrade-sw-version">
      <div className="form-group">
        <label htmlFor="firmware">
        - <SoftwareVersion softwareVersion={swVersion} />
        &nbsp;
        </label>
        <select className="form-control" value={firmwareFile}
          onChange={(e) => setFirmwareSwVersionFile(nodeType, swVersion, e.target.value)}>
          <option value="">{translate('technical_dont_upgrade')}</option>
          {files.map((file) => {
            return <option key={file} value={file}>{file}</option>;
          })}
        </select>
      </div>
    </div>
  );
};

UpgradeSwVersion.propTypes = {
  nodeType: PropTypes.string.isRequired,
  swVersion: PropTypes.string.isRequired,
  firmwareFile: PropTypes.string,
  files: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps({firmwareReducer, localeReducer}, {nodeType, swVersion}) {
  let files = [];
  const {mode} = firmwareReducer;
  if (mode === LAMP) {
    files = firmwareReducer.lampFiles;
  }else if(mode === PS) {
    files = firmwareReducer.psFiles;
  }

  return {
    firmwareFile: firmwareReducer[`${nodeType}/${swVersion}`],
    files,
    translate: getTranslate(localeReducer)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpgradeSwVersion);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/clientActions';

class ClientSelect extends Component {
  componentDidMount() {
    const {actions, clientOptions} = this.props;
    const {getClients} = actions;
    if (clientOptions.length === 0) {
      getClients();
    }
  }
  render() {
    const {clientOptions, name, value, onChange} = this.props;
    if (clientOptions.length === 0) {
      return <p>Loading...</p>;
    }

    return (
      <Select
        className="basic-single"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        onChange={onChange}
        name={name}
        value={clientOptions.find(option => option.value === value)}
        options={clientOptions}
      />
    );
  }
}

ClientSelect.propTypes = {
  actions: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  clientOptions: PropTypes.array.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {clientOptions} = state.clientReducer;
  return {
    clientOptions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientSelect);

import React from 'react';
import PropTypes from 'prop-types';
import {STARTED, DONE, INITIAL, ERROR} from '../constants/customTypes';

const ActionButton = ({status, icon, text, action, extraClass, title, disabled, isSubmit, btnClass, style}) => {
  let fa = null;

  if (icon && icon.indexOf('slash') > -1) {
    fa = (
      <i className={`fa ${icon}`} aria-hidden="true"><strong/></i>
    );
  } else if (icon) {
    fa = (
      <i className={`fa ${icon}`} aria-hidden="true" />
    );
  }

  let className = 'btn';
  if (extraClass) {
    className += ' ' + extraClass;
  }

  if (btnClass) {
    className += ` ${btnClass}`;
  }else {
    if (status === undefined && extraClass === undefined) {
      className += ' btn-primary';
    } else if (status === INITIAL) {
      className += ' btn-primary';
    } else if (status === STARTED) {
      className += ' btn-warning';
    } else if (status === DONE) {
      className += ' btn-success';
    } else if (status === ERROR) {
      className += ' btn-danger';
    } else {
      className += ' btn-default';
    }
  }

  if (disabled === true) {
    className += ' disabled';
  }

  let textEl = null;

  if (text) {
    textEl = (
      <span>
          {icon ? ' ' : ''}{text}
      </span>
    );
  }

  const onClick = () => {
    if (disabled === false) {
      action();
    }
  };

  if (isSubmit) {
    return (
      <button type="submit" className={className}>
        {fa}{textEl}
      </button>
    );
  }

  return (
    <span className={className} onClick={() => onClick()} style={style} title={title}>
      {fa}{textEl}
    </span>
  );
};

ActionButton.propTypes = {
  action: PropTypes.func,
  status: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  extraClass: PropTypes.string,
  btnClass: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  isSubmit: PropTypes.bool.isRequired,
  style: PropTypes.object,
};

ActionButton.defaultProps = {
  disabled: false,
  isSubmit: false,
};

export default ActionButton;

import immer from 'immer';
import { GET_USERS, CREATE_USER, UPDATE_USER,
  SET_USER_REDUCER_PROPS,
  DELETE_USER_BY_ID,
  GET_USER_CLIENT_ROLES,
  SET_EDIT_USER_PROP,
  START_EDIT_USER,
  GET_USER_BUILDING_ROLES,
} from '../constants/userActionTypes';
import {setDeep} from '../utils/helper';

const EDIT_USER_ID = 'editUser';
const defaultUser = {
  firstname: '',
  lastname: '',
  password: '',
  active: true,
  staff: false,
  id: EDIT_USER_ID,
};

if (CLOUD) {
  defaultUser.email = '';
}else if(LOCAL) {
  defaultUser.username = '';
}

const initialState = {
  userIds: [],
  usersLoaded: false,
};

export default immer((draft, action) => {
  switch (action.type) {
    case GET_USERS.SUCCESS:{
      const {ids, obj} = action;
      return {
        ...draft,
        userIds: ids,
        usersLoaded: true,
        ...obj,
      };
    }
    case CREATE_USER.SUCCESS:{
      const user = action.response;
      draft[user.id] = user;
      draft.userIds = draft.userIds.concat([user.id]);
      return;
    }
    case UPDATE_USER.SUCCESS:{
      const user = action.response;
      draft[user.id] = {...draft[user.id], ...user};
      return;
    }
    case DELETE_USER_BY_ID.SUCCESS:{
      const {id} = action.props;
      delete draft[id];

      const index = draft.userIds.indexOf(id);
      draft.userIds.splice(index, 1);

      draft.deleteUserId = undefined;
      return;
    }
    case SET_USER_REDUCER_PROPS:{
      const {obj} = action;
      return {...draft, ...obj};
    }
    case GET_USER_CLIENT_ROLES.SUCCESS:{
      const {props, response} = action;
      const {clientId} = props;
      draft.editUser.clientRoles[clientId] = response;
      return;
    }
    case GET_USER_BUILDING_ROLES.SUCCESS:{
      const {props, response} = action;
      const {clientId} = props;
      let obj = {};
      response.forEach(({id, configurationId}) => {
        let arr = [];
        if (obj[configurationId] !== undefined) {
          arr = arr.concat(obj[configurationId]);
        }
        obj[configurationId] = arr.concat([id]);
      });
      draft.editUser.clientBuildingRoles[clientId] = obj;
      return;
    }
    case START_EDIT_USER:{
      const {userId} = action;
      if (userId && userId !== 'new') {
        draft.editUser = {
          ...draft[userId],
          password: '',
          id: EDIT_USER_ID,
          oldId: userId,
        };
      }else {
        draft.editUser = {
          ...defaultUser,
        };
      }
      draft.editUser.clientRoles = {};
      draft.editUser.clientBuildingRoles = {};
      return;
    }
    case SET_EDIT_USER_PROP:{
      const {propName, value} = action;
      setDeep(draft.editUser, propName, value);
      return;
    }

    default:
      return;
  }
}, initialState);

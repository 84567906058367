import {apiAction} from './helper';

export const GET_BUILDINGS = apiAction('GET_BUILDINGS');
export const GET_BUILDINGS_NORMAL = 'GET_BUILDINGS_NORMAL';
export const SET_ACTIVE_BUILDING = 'SET_ACTIVE_BUILDING';
export const SET_SHOW_BUILDINGS_LIST = 'SET_SHOW_BUILDINGS_LIST';
export const SET_BUILDING_PROPS = 'SET_BUILDING_PROPS';
export const SET_BUILDING = 'SET_BUILDING';
export const SET_BUILDING_ROTATION = 'SET_BUILDING_ROTATION';
export const DRAG_ZOOM = 'DRAG_ZOOM';
export const ZOOM_ZOOM = 'ZOOM_ZOOM';
export const SAVE_BUILDING_CONFIG = 'SAVE_BUILDING_CONFIG';
export const SAVE_BUILDING_CONFIG_REMOTE = apiAction('SAVE_BUILDING_CONFIG_REMOTE');
export const SET_BUILDING_REDUCER_PROPS = 'SET_BUILDING_REDUCER_PROPS';
export const CREATE_BUILDING = apiAction('CREATE_BUILDING');
export const UPDATE_BUILDING = apiAction('UPDATE_BUILDING');
export const DELETE_BUILDING = apiAction('DELETE_BUILDING');
export const GET_CONFIG = apiAction('GET_CONFIG');
export const SET_BUILDING_ROUTE_SLUG = 'SET_BUILDING_ROUTE_SLUG';
export const SET_BUILDING_ROUTE = 'SET_BUILDING_ROUTE';

import {SET_GROUPS, GET_GROUPS_PROFILE_STATE, SET_GROUP_PROPS, SET_GROUP_EDITING, SET_GROUP_LAMPS,
SET_DOUBLE_GROUP_LAMPS, SET_EDITING_GROUP_PROFILE, CANCEL_EDIT_GROUP, SET_LAMP_GROUP_MAP,
SET_LAMP_GROUP_MAP_ENTRY,
SAVE_GROUP, CREATE_GROUP, DELETE_GROUP, GROUP_LAMPS_UNDO, GROUP_LAMPS_REDO} from '../constants/groupActionTypes';
import objectAssign from 'object-assign';
import {GROUP_UPDATE_EVENT} from '../constants/siteActionTypes';
import {makeUid} from '../utils/idHelper';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.

const initialState = {
  groupEditing: false,
  editingId: undefined,
  past: [],
  future: [],
  lampMapPast: [],
  lampMapFuture: [],
  groupsChanged: {},
  lampGroupMap: {},
};

export default function groupReducer(state = initialState, action) {

  switch (action.type) {
    case SET_GROUPS:{
      const {obj} = action;
      return objectAssign({}, state, obj);
    }
    case SET_GROUP_PROPS:{
      let newState = objectAssign({}, state);
      const {key, obj} = action;
      newState[key] = objectAssign({}, state[key], obj);
      return newState;
    }
    case GROUP_UPDATE_EVENT:{
      let newState = objectAssign({}, state);
      const {scenarioId} = action;
      const {group, profileState} = action.data;
      const groupKey = `${scenarioId}_${group}`;
      newState[groupKey] = objectAssign({}, state[groupKey], {profileState});
      return newState;
    }
    case GET_GROUPS_PROFILE_STATE.SUCCESS:{
      let newState = objectAssign({}, state);
      const {response, activeScenarioId} = action;
      Object.keys(response).forEach((key) => {
        const groupKey = makeUid(activeScenarioId, key);
        newState[groupKey] = objectAssign({}, state[groupKey], {profileState: response[key]});
      });
      return newState;
    }
    case SET_GROUP_EDITING:{
      const {key, groupEditing} = action;
      let newState = objectAssign({}, state, {groupEditing});
      if (groupEditing === true) {
        newState.beforeEditProfileId = state[key].profileId;
        newState.editingId = key;
        newState.past = [];
        newState.future = [];
        newState.groupsChanged = {};
      }else {
        newState.editingId = undefined;
        newState[state.editingId] = objectAssign({}, state[state.editingId], {editing: false});
      }
      return newState;
    }
    case SET_GROUP_LAMPS:{
      const {id, lampIds} = action;
      let newState = objectAssign({}, state);
      newState.past = [...state.past, {lampIds: newState[id].lampIds}];
      newState[id] = objectAssign({}, state[id], {lampIds});
      return newState;
    }
    case SAVE_GROUP:{
      const {id} = action;
      const newState = objectAssign({}, state, {groupEditing: false, editingId: undefined});
      newState[id] = objectAssign({}, state[id], {
        updated: new Date().toISOString(),
      });

      newState.past = [];
      newState.future = [];
      newState.lampMapFuture = [];
      newState.lampMapPast = [];
      return newState;
    }
    case CREATE_GROUP:{
      const {profileId, lampIds, id} = action;
      let newState = objectAssign({}, state, {
        groupEditing: true,
        editingId: id,
        future: [],
        groupsChanged: {},
        past: [],
      });
      if (lampIds.length > 0) {
        newState.past = [{lampIds: []}];
      }

      newState[id] = {
        id,
        profileId,
        lampIds: lampIds,
        created: new Date().toISOString(),
      };
      return newState;
    }
    case GROUP_LAMPS_UNDO:{
      const {past, future, editingId, lampMapPast, lampMapFuture, lampGroupMap } = state;
      let present;
      if (state.past.length > 0) {
        let newState = objectAssign({}, state);
        const previous = past[past.length - 1];
        const newPast = past.slice(0, past.length - 1);
        newState.past = newPast;
        present = {lampIds: state[editingId].lampIds};
        newState[editingId] = objectAssign({}, state[editingId], {lampIds: previous.lampIds});
        if (previous.oldId !== undefined) {
          let groupsChanged = objectAssign({}, state.groupsChanged);
          groupsChanged[previous.oldId]--;
          newState.groupsChanged = groupsChanged;
          newState[previous.oldId] = objectAssign({}, state[previous.oldId], {lampIds: previous.oldLampIds});
          present.oldLampIds = previous.oldLampIds;
          present.oldId = previous.oldId;
        }
        newState.future = [present, ...future];

        const newLampPast = [].concat(lampMapPast);
        const nowLamp = newLampPast.pop();

        const presentLamp = {};
        const lampId = Object.keys(nowLamp)[0];
        presentLamp[lampId] = lampGroupMap[lampId];

        newState.lampGroupMap = objectAssign({}, lampGroupMap, nowLamp);
        newState.lampMapPast = newLampPast;
        newState.lampMapFuture = [presentLamp, ...lampMapFuture];
        return newState;
      }else {
        return state;
      }
    }
    case GROUP_LAMPS_REDO:{
      const {past, future, editingId, lampMapPast, lampMapFuture, lampGroupMap} = state;
      let present;
      if (state.future.length > 0) {
        let newState = objectAssign({}, state);
        const next = future[0];
        const newFuture = future.slice(1);

        newState.future = newFuture;
        present = {lampIds: state[editingId].lampIds};
        present.oldGroupId = next.oldId;
        present.oldLampIds = next.oldLampIds;

        newState[editingId] = objectAssign({}, state[editingId], {lampIds: next.lampIds});
        if (next.oldId !== undefined) {
          let groupsChanged = objectAssign({}, state.groupsChanged);
          groupsChanged[next.oldId]++;
          newState[next.oldId] = objectAssign({}, state[next.oldId], {lampIds: next.oldLampIds});
          newState.groupsChanged = groupsChanged;
        }
        newState.past = [...past, present];

        const newLampFuture = [].concat(lampMapFuture);
        const futureLamp = newLampFuture.shift();

        const presentLamp = {};
        const lampId = Object.keys(futureLamp)[0];
        presentLamp[lampId] = lampGroupMap[lampId];

        newState.lampGroupMap = objectAssign({}, lampGroupMap, futureLamp);
        newState.lampMapFuture = newLampFuture;
        newState.lampMapPast = [...lampMapPast, presentLamp];
        return newState;
      }else {
        return state;
      }
    }
    case SET_DOUBLE_GROUP_LAMPS:{
      const {editingGroupId, editingLampIds, oldGroupId, oldLampIds} = action;
      let newState = objectAssign({}, state);
      let groupsChanged = objectAssign({}, state.groupsChanged);
      if (state.groupsChanged[oldGroupId] === undefined) {
        groupsChanged[oldGroupId] = 1;
      }else {
        groupsChanged[oldGroupId]++;
      }
      newState.groupsChanged = groupsChanged;
      newState.past = [...state.past, {lampIds: state[editingGroupId].lampIds, oldId: oldGroupId, oldLampIds: state[oldGroupId].lampIds}];
      newState[editingGroupId] = objectAssign({}, state[editingGroupId], {lampIds: editingLampIds});
      newState[oldGroupId] = objectAssign({}, state[oldGroupId], {lampIds: oldLampIds});
      return newState;
    }
    case SET_EDITING_GROUP_PROFILE:{
      const {profileId} = action;
      let newState = objectAssign({}, state);
      newState[state.editingId] = objectAssign({}, newState[state.editingId], {profileId});
      return newState;
    }
    case CANCEL_EDIT_GROUP:{
      let newState = objectAssign({}, state);
      newState[state.editingId] = objectAssign({}, newState[state.editingId], {
        profileId: state.beforeEditProfileId
      });

      newState.lampMapFuture = [];
      newState.future = [];
      return newState;
    }
    case DELETE_GROUP:{
      const {id} = action;
      const {editingId} = state;
      const newState = objectAssign({}, state);
      if (editingId === id) {
        newState.editingId = undefined;
        newState.groupEditing = false;
      }
      delete newState[id];
      return newState;
    }
    case SET_LAMP_GROUP_MAP:{
      const {lampGroupMap} = action;
      return objectAssign({}, state, {lampGroupMap, past: [], future: [], groupsChanged: {}, lampMapPast: [], lampMapFuture: []});
    }
    case SET_LAMP_GROUP_MAP_ENTRY:{
      const {lampId, groupId} = action;
      const lampGroupMap = objectAssign({}, state.lampGroupMap);

      const pastObj = {};
      pastObj[lampId] = state.lampGroupMap[lampId];

      lampGroupMap[lampId] = groupId;
      const lampMapPast = state.lampMapPast.concat([pastObj]);
      return objectAssign({}, state, {
        lampMapPast,
        lampGroupMap,
      });
    }

    default:
      return state;
  }
}

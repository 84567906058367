import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {UPGRADER_STATUS_CLEARED} from '../constants/customTypes';
import '../styles/upgrade-firmware-labels.scss';
import { getTranslate } from 'react-localize-redux';

const UpgradeFirmwareLabels = ({status, syncing, upgraded, progress, fails, translate}) => {
  if (status === UPGRADER_STATUS_CLEARED || fails === undefined) {
    return null;
  }
  let upgradedEl = null;
  let syncingEl = null;
  let progressEl = null;
  if (upgraded === true) {
    upgradedEl = (
      <span className="label label-success">
        {translate('technical_upgraded')}
      </span>
    );
  }

  if (syncing === true) {
    syncingEl = (
      <span className="label label-info">
        {translate('technical_syncing')}
      </span>
    );
  }

  if (progress > 0 && progress !== 100) {
    progressEl = (
      <span className="label label-info">
        {progress}%
      </span>
    );
  }

  return (
    <span className="upgrade-firmware-labels">
      {upgradedEl}
      {syncingEl}
      {progressEl}
      <span className="label label-danger">
        {translate('technical_fails')}: {fails}
      </span>
    </span>
  );
};

UpgradeFirmwareLabels.propTypes = {
  hardwareVersion: PropTypes.string,
  status: PropTypes.string,
  syncing: PropTypes.bool,
  upgraded: PropTypes.bool,
  progress: PropTypes.number,
  fails: PropTypes.number,
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const translate = getTranslate(state.localeReducer);
  return {
    translate
  };
}

export default connect(
  mapStateToProps,
  null,
)(UpgradeFirmwareLabels);

const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const defaultDuration = {seconds: 0, minutes: 0, hours: 0, days: 0, weeks: 0};

export function pgDurationToMs(duration) {
  const filledDuration = {...defaultDuration, ...duration};
  const {seconds, minutes, hours, days, weeks} = filledDuration;

  // do nothing with years and months
  return seconds * second + minutes * minute + hours * hour + days * day + weeks * week;
}

function roundDateToDuration(date, duration, method) {
  // time in MS from 1970-01-01 UTC to UTC Monday 2000-01-03 same as Timescale's time_bucket function
  const origin = 946857600000;

  const coeff = pgDurationToMs(duration);
  const timeZoneOffset = date.getTimezoneOffset() * minute;
  const rest = method((date.getTime() - timeZoneOffset - origin) / coeff);
  const newMs = rest * coeff + timeZoneOffset + origin;
  return new Date(newMs);
}

export function roundDateToRetention(theDate, retentionPeriods, method = Math.floor) {
  let newDate = theDate;
  const len = retentionPeriods.length - 1;
  for (let i = len; i >= 0; i--) {
    const retentionPeriod = retentionPeriods[i];
    const now = new Date();
    if (theDate.getTime() < (now.getTime() - pgDurationToMs(retentionPeriod.older_than)) && retentionPeriod.aggr_interval !== null) {
      newDate = roundDateToDuration(theDate, retentionPeriod.aggr_interval, method);
      return newDate;
    }
  }
}

import { SET_ACTIVE_BUILDING, DRAG_ZOOM, ZOOM_ZOOM, GET_BUILDINGS, UPDATE_BUILDING, SET_BUILDING_ROUTE } from '../constants/buildingActionTypes';
import {UPLOAD_PLAN_IMG} from '../constants/planImgActionTypes';
import {setActiveBuilding, setBuildingProps, setShowBuildingsList, getConfig, setBuildingRouteSlug} from '../actions/buildingActions';
import {setSelectedScenario} from '../actions/scenarioActions';
import {clearLiveProps} from '../actions/liveActions';
import { replace, push } from 'connected-react-router';
import { execPlanSubscribes, execPlanUnsubscribes} from '../actions/planActions';
import {isRoute} from '../utils/helper';
import {ROUTE_TECHNICAL} from '../constants/const';

const buildingMiddleware = store => next => action => {
  const {dispatch} = store;
  if(action.type === GET_BUILDINGS.SUCCESS) {
    const {buildingReducer, clientReducer} = store.getState();
    const {routeSlug} = buildingReducer;
    const {clientIds} = clientReducer;
    for (const clientId of clientIds) {
      const client = clientReducer[clientId];
      if (client.slug === action.props.slug) {
        action.clientId = clientId;
        break;
      }
    }
    next(action);
    const {forwardAfter} = action.props;
    if (forwardAfter === false) {
      return;
    }

    if(routeSlug !== undefined) {
      let theId;
      action.response.forEach(({id, building}) => {
        if (building.slug === routeSlug) {
          theId = id;
        }
      });
      dispatch(setActiveBuilding(theId));
      dispatch(setBuildingRouteSlug(undefined));
    }else if (action.response.length === 1) {
      dispatch(setActiveBuilding(action.response[0].id));
      dispatch(setBuildingRouteSlug(undefined));
    }else {
      dispatch(setShowBuildingsList(true));
    }
  }else if(action.type === SET_ACTIVE_BUILDING) {
    const state = store.getState();
    const {clientReducer, buildingReducer, routingReducer} = state;
    const {activeBuildingId} = buildingReducer;
    const {slug, configFetched, selectedScenarioId, activeScenarioId} = buildingReducer[action.id];
    const {activeClientKey} = clientReducer;
    const {slug: clientSlug} = clientReducer[activeClientKey];

    const {currentRoute} = routingReducer;

    let sameDataportSlug = false;
    if (activeBuildingId !== undefined) {

      const {dataportSlug} = buildingReducer[activeBuildingId];
      const {dataportSlug : nextDpSlug} = buildingReducer[action.id];
      sameDataportSlug = dataportSlug === nextDpSlug;
    }

    dispatch(clearLiveProps());
    next(action);

    const [routePrefix] = /^(\/[^/]+\/[^/]+)/.exec(currentRoute);
    const routePostfixResult = /^(?:\/[^/]+){4}(.*)/.exec(currentRoute);
    if (routePostfixResult) {
      const [, routePostfix] = routePostfixResult;
      dispatch(replace(`${routePrefix}/${clientSlug}/${slug}${routePostfix}`));
    }else {
      dispatch(replace(`${routePrefix}/${clientSlug}/${slug}`));
    }

    if (configFetched !== true) {
      if (activeBuildingId !== undefined) {
        const {dataportSlug} = buildingReducer[activeBuildingId];
        dispatch(execPlanUnsubscribes(dataportSlug, activeBuildingId));
      }
      dispatch(getConfig(clientSlug, slug, action.id));
    }else {
      dispatch(setSelectedScenario(selectedScenarioId));
      if (!(isRoute(currentRoute, ROUTE_TECHNICAL) && sameDataportSlug)) {
        if (activeBuildingId !== undefined) {
          const {dataportSlug} = buildingReducer[activeBuildingId];
          dispatch(execPlanUnsubscribes(dataportSlug, activeBuildingId));
        }
      }
      dispatch(execPlanSubscribes(slug, action.id, activeScenarioId, selectedScenarioId));
    }

  }else if (action.type === SET_BUILDING_ROUTE) {
    const {route, postFix} = action;
    const {clientReducer, buildingReducer} = store.getState();
    const {activeClientKey} = clientReducer;
    if (!activeClientKey) {
      return dispatch(push(route.slice(0, -1)));
    }
    const {slug : clientSlug} = clientReducer[activeClientKey];

    const {activeBuildingId} = buildingReducer;
    const {slug : buildingSlug, activeScenarioId, dataportSlug, selectedScenarioId} = buildingReducer[activeBuildingId];
    dispatch(execPlanUnsubscribes(dataportSlug, activeBuildingId));
    dispatch(push(`${route}${clientSlug}/${buildingSlug}${postFix}`));
    dispatch(execPlanSubscribes(dataportSlug, activeBuildingId, activeScenarioId, selectedScenarioId));

  }else if (action.type === UPLOAD_PLAN_IMG) {
    const {buildingId} = action.props;
    next(action);
    dispatch(setBuildingProps(buildingId, {plan_img: action.response.id}));
  }else if(action.type === DRAG_ZOOM) {
    const {planReducer, buildingReducer} = store.getState();
    const {deltaX, deltaY} = action;
    const {activeBuildingId} = buildingReducer;
    const {xMap, yMap} = planReducer;
    const {zoomX, zoomY} = buildingReducer[activeBuildingId];

    dispatch(setBuildingProps(activeBuildingId, {
      zoomX: zoomX + (deltaX * xMap),
      zoomY: zoomY + (deltaY * yMap),
    }));

  }else if(action.type === ZOOM_ZOOM) {
    const {buildingReducer, planReducer} = store.getState();
    const {pageX, pageY, eventDelta} = action;
    const {activeBuildingId} = buildingReducer;
    const {zoomX, zoomY, zoomK} = buildingReducer[activeBuildingId];
    const {xMap, yMap, svgCoordOffsetX, svgCoordOffsetY} = planReducer;

    const zoomPointX = (pageX * xMap + svgCoordOffsetX - zoomX) / zoomK;
    const zoomPointY = (pageY * yMap + svgCoordOffsetY - zoomY) / zoomK;

    let scaleChange = zoomK * eventDelta;
    if (zoomK + scaleChange < 0.5) {
      scaleChange = -(zoomK - 0.5);
    }

    const offsetX = zoomX - (zoomPointX * scaleChange);
    const offsetY = zoomY - (zoomPointY * scaleChange);

    dispatch(setBuildingProps(activeBuildingId, {
      zoomX: offsetX,
      zoomY: offsetY,
      zoomK: zoomK + scaleChange,
    }));
  }else if(action.type === UPDATE_BUILDING.SUCCESS) {
    const {prevRoute} = store.getState().routingReducer;
    next(action);
    dispatch(push(prevRoute));
  }else {
    return next(action);
  }

};

export default buildingMiddleware;

import React from 'react';
import PropTypes from 'prop-types';
import PlanPageWrapper from './PlanPageWrapper';
import Plan from './Plan';
import ImgControl from '../components/ImgControl';
import SaveConfig from './SaveConfig';
import StandaloneLampControl from './StandaloneLampControl';
import LineControl from './LineControl';

const PlanDrawingPage = ({planHeight}) => {
  return (
    <div className="container-fluid control-container">
      <div className="col-md-3 left-el" style={{height: planHeight, marginTop: 5}}>
        <SaveConfig />
        <ImgControl />
        <StandaloneLampControl />
        <LineControl />
      </div>
      <div className="col-md-9">
        <Plan showGrid={true} className="border" />
      </div>
    </div>
  );
};

PlanDrawingPage.propTypes = {
  planHeight: PropTypes.number.isRequired,
};

export default PlanPageWrapper(PlanDrawingPage, 'page_title_plan_drawing');

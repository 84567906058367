import React from 'react';
import PropTypes from 'prop-types';
import SvgText from '../components/SvgText';

const CustomSvgText = ({customText, rotation}) => {
  if (!customText) {
    return null;
  }
  const fontSize = 1500 / customText.length;
  let x = 0;
  let y = -200;
  switch(rotation) {
    case 180:{
      x = -1500;
      break;
    }
    case 270:{
      x = 200;
      y = -600;
      break;
    }
    case 90:{
      x = 200;
      y = 1000;
      break;
    }
  }

  return (
    <g className="lamp-svg-text">
      <SvgText rotation={rotation} offsetX={x} offsetY={y} text={customText}
      fontSize={fontSize} />
    </g>
  );
};

CustomSvgText.propTypes = {
  rotation: PropTypes.number.isRequired,
  customText: PropTypes.string,
};

export default CustomSvgText;

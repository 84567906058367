import {SET_SELECTED_VARS, SET_SELECTED_VAR_PROPS, SET_SELECTED_COPY_VAR, COPY_SELECTED_VARS} from '../constants/selectedVariablesActionTypes';

export function setSelectedVars(variables) {
  return {
    type: SET_SELECTED_VARS,
    variables,
  };
}

export function setSelectedVarProps(objectName, variableName, obj) {
  return {
    type: SET_SELECTED_VAR_PROPS,
    objectName,
    variableName,
    obj,
  };
}

export function setSelectedCopyVar(path, isCopied) {
  return {
    type: SET_SELECTED_COPY_VAR,
    path,
    isCopied,
  };
}

export function copySelectedVars(originPath, destPath) {
  return {
    type: COPY_SELECTED_VARS,
    originPath,
    destPath,
  };
}

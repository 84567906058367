import React from 'react';
import PropTypes from 'prop-types';
import PlanPageWrapper from './PlanPageWrapper';
import PlanRightContainer from './PlanRightContainer';
import Plan from './Plan';

const PlanConfigurationPage = ({planHeight}) => {
  return (
    <>
      <div className="plan-left">
        <Plan className="border-right" />
      </div>
      <div className="plan-right-wrapper">
        <div className="plan-right" style={{height: planHeight}}>
          <PlanRightContainer />
        </div>
      </div>
    </>
  );
};

PlanConfigurationPage.propTypes = {
  planHeight: PropTypes.number.isRequired,
};

export default PlanPageWrapper(PlanConfigurationPage, 'page_title_plan_configuration');

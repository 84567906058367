import tinygradient from 'tinygradient';
import {GET_ENERGY_DATA, GET_SIMULATE_ENERGY, CREATE_ENERGY_HEATMAP} from '../constants/energyActionTypes';
import {createEnergyHeatmap} from '../actions/energyActions';
import {setLampsProps} from '../actions/lampActions';
import {makeUid} from '../utils/idHelper';
import {roundFloat, floatToCommaString} from '../utils/helper';

export default store => next => action => {
  const {dispatch} = store;
  if(action.type === GET_SIMULATE_ENERGY.SUCCESS) {
    dispatch(createEnergyHeatmap(action.response, false));
    next(action);
  }else if(action.type === GET_ENERGY_DATA.SUCCESS) {
    const {daylightEnergy, movementEnergy, baseEnergy} = action.response;
    if (daylightEnergy === undefined) {
      dispatch(createEnergyHeatmap(movementEnergy, true));
      action.daylightSaved = -1;
      return next(action);
    }

    dispatch(createEnergyHeatmap(daylightEnergy, true));

    const {totalEnergy} = store.getState().energyReducer;
    const keys = Object.keys(movementEnergy);
    let totalMovEnergy = 0;
    keys.forEach(key => {
      totalMovEnergy += movementEnergy[key];
    });

    const baseKeys = Object.keys(baseEnergy);
    let totalBaseEnergy = 0;
    baseKeys.forEach(key => {
      totalBaseEnergy += baseEnergy[key];
    });

    action.daylightSaved = roundFloat((1 - (totalEnergy - totalBaseEnergy) / (totalMovEnergy - totalBaseEnergy)) * 100, 1);

    next(action);
  }else if(action.type === CREATE_ENERGY_HEATMAP) {
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {allLampIds} = buildingReducer[activeBuildingId];
    const {data} = action;
    const lampIds = Object.keys(data);
    let maxEnergy = 0;
    lampIds.forEach(lampId => {
      const energy = data[lampId];
      maxEnergy = Math.max(maxEnergy, energy);
    });

    let totalEnergy = 0;

    const obj = {};
    allLampIds.forEach(lampId => {
      obj[lampId] = {
        id: lampId,
        energy: 0,
        energyColor: 'white',
      };
    });
    const gradient = tinygradient([
      'white',
      'yellow',
      'red',
    ]);
    const colorRange = gradient.rgb(255);

    lampIds.forEach(lampId => {
      const lampUid = makeUid(activeBuildingId, lampId);
      const energy = data[lampId];
      totalEnergy += energy;
      let index = 0;
      if (maxEnergy > 0) {
        index = Math.round(energy / maxEnergy * (colorRange.length - 1));
      }
      obj[lampUid] = {
        id: lampUid,
        energy: roundFloat(energy, 3),
        energyColor: colorRange[index].toHexString(),
      };
    });

    const lamps = Object.keys(obj).map(key => obj[key]);
    dispatch(setLampsProps(lamps));

    action.totalEnergy = totalEnergy;
    if (totalEnergy > 1000) {
      const roundedEnergy = roundFloat(totalEnergy / 1000, 1);
      action.totalEnergyText = `${floatToCommaString(roundedEnergy)} kWh`;
    }else {
      const roundedEnergy = roundFloat(totalEnergy, 1);
      action.totalEnergyText = `${floatToCommaString(roundedEnergy)} Wh`;
    }

    action.maxEnergy = maxEnergy;
    const roundedMaxEnergy = roundFloat(maxEnergy, 1);
    action.maxEnergyText = `${floatToCommaString(roundedMaxEnergy)} Wh`;
    next(action);
  }else {
    return next(action);
  }
};

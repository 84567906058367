import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/lineActions';
import '../styles/selected-line-control.scss';
import SelectedLineControl from './SelectedLineControl';
import LineItem from './LineItem';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const LinesList = ({selectedLineIds, actions, translate}) => {
  const {changeSelectAllLines, alignSelectedLines} = actions;
  let renderEl = null;
  if (selectedLineIds.length === 1) {
    renderEl = <SelectedLineControl id={selectedLineIds[0]} />;
  }else if(selectedLineIds.length > 1) {
    renderEl = (
      <span>
        <div className="inline">
          <div>
          <ActionButton action={() => alignSelectedLines(false)}
            extraClass="btn-primary"
            text="Align lines" />
          </div>
          <div>
            <ActionButton action={() => changeSelectAllLines(false)}
              extraClass="btn-default"
              text={translate('technical_deselect_all')} />
          </div>
        </div>
        {selectedLineIds.map((id) => {
          return <LineItem id={id} key={id} />;
        })}
      </span>
    );
  }

  return (
    <div className="row lines-list">
      <div className="col-md-12">
        {renderEl}
      </div>
    </div>
  );
};

LinesList.propTypes = {
  actions: PropTypes.object.isRequired,
  selectedLineIds: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {selectedLineIds} = state.lineReducer;
  const translate = getTranslate(state.localeReducer);
  return {
    selectedLineIds,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LinesList);

import React, {memo} from 'react';
import PropTypes from 'prop-types';

const ReactTableCell = ({cell}) => {
  return (
    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
  );
};

ReactTableCell.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default memo(ReactTableCell);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/psActions';
import Drag from '../components/Drag';
import {registerLocateCb, unregisterLocateCb} from '../middleware/psMiddleware';
//import SvgText from '../components/SvgText';
import {isRoute} from '../utils/helper';
import {ROUTE_DRAWING, ROUTE_TECHNICAL} from '../constants/const';

class Ps extends Component {
  componentDidMount() {
    const {locateAnimation} = this;
    const {ps} = this.props;
    registerLocateCb(ps.id, () => {
      locateAnimation.beginElement();
    });
  }

  componentWillUnmount() {
    const {ps} = this.props;
    unregisterLocateCb(ps.id);
  }

  render() {
    const {ps, actions, canDragPs, power, currentRoute, id, node} = this.props;
    if (!isRoute(currentRoute, ROUTE_DRAWING) && !isRoute(currentRoute, ROUTE_TECHNICAL)) {
      return null;
    }

    const {x, selected} = ps;
    const {setPsProps, psClick, dragPs} = actions;
    let className = 'ps';
    //let text;
    if (selected === true) {
      className += ' selected';
    }else if (node) {
      //text = node.name;
      if (node.active) {
        className += ' active';
      }else if(power !== undefined) {
        if (power === 1) {
          className += ' on';
        }else {
          className += ' off';
        }
      }
    }

    const onClick = () => {
      if (isRoute(currentRoute, ROUTE_TECHNICAL)) {
        psClick(id);
      }
    };

    const rect = (
      <g className={className} transform={`translate(${x}, 0)`}
      onClick={onClick}
      onMouseOver={() => setPsProps(id, {active: true})}
      onMouseOut={() => setPsProps(id, {active: false})}>
      <rect width="350" height="350" y="-175"/>
      <circle cx="175" cy="0" r="0" className="locate-circle">
        <animate attributeName="r" ref={(el) => this.locateAnimation = el} begin="indefinite" dur="4000ms" repeatCount="1" from="5000" to="50"/>
      </circle>
        {/*<SvgText rotation={rotation} text={text} offsetY={-500}
        offsetX={0} />*/}
      </g>
    );

    const dragAction = (deltaX, deltaY) => {
      dragPs(id, deltaX, deltaY);
    };

    if (canDragPs) {
      return (
        <Drag action={dragAction}>
          {rect}
        </Drag>
      );
    }

    return rect;
  }
}

Ps.propTypes = {
  ps: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  node: PropTypes.object,
  canDragPs: PropTypes.bool.isRequired,
  power: PropTypes.number,
  currentRoute: PropTypes.string.isRequired,
};

function mapStateToProps(state, props) {
  const ps = state.psReducer[props.id];
  const {canDragPs} = state.psReducer;
  const node = state.nodeReducer[ps.mac];
  const powerVar = state.variableReducer[`${ps.mac}/Power/nviOutput`];
  const power = powerVar === undefined ? undefined:powerVar.value;
  const {currentRoute} = state.routingReducer;
  return {
    ps,
    node,
    canDragPs,
    power,
    currentRoute,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Ps);

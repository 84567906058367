import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/lineActions';
import '../styles/selected-line-control.scss';
import LineLampItem from './LineLampItem';
import {parseIfInt} from '../utils/helper';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const SelectedLineControl = ({actions, selectedLine, translate}) => {
  const {setLineProps, selectLine, addLampToLine, spreadLineLampsEvenly} = actions;

  return (
    <span className="selected-line-control">
      <div className="checkbox">
        <label>
          <input type="checkbox" checked={selectedLine.showPs}
          onChange={() => setLineProps(selectedLine.id, {showPs: !selectedLine.showPs})} />
        {translate('technical_show_ps')}
        </label>
      </div>
      <ActionButton action={() => selectLine(selectedLine.id)}
        extraClass="btn-default"
        text={translate('technical_deselect')} />
      <div className="form-half">
        <label htmlFor="x">x:</label>
        <input type="number" className="form-control" id="x" step="1" value={selectedLine.x}
          onChange={(e) => setLineProps(selectedLine.id, {x: parseIfInt(e.target.value)})} />
      </div>
      <div className="form-half">
        <label htmlFor="y">y:</label>
        <input type="number" className="form-control" id="y" step="1" value={selectedLine.y}
          onChange={(e) => setLineProps(selectedLine.id, {y: parseIfInt(e.target.value)})} />
      </div>
      <div className="form-half">
        <label htmlFor="length">{translate('technical_length')}:</label>
        <input type="number" className="form-control" id="length" min="1" step="1" value={selectedLine.length}
          onChange={(e) => setLineProps(selectedLine.id, {length: parseIfInt(e.target.value)})} />
      </div>
      <div className="form-half">
        <label htmlFor="rotation">{translate('technical_rotation')}:</label>
        <input type="number" className="form-control" id="rotation" min="0" step="90" max="270" value={selectedLine.rotation}
          onChange={(e) =>setLineProps(selectedLine.id, {rotation: parseIfInt(e.target.value)})} />
      </div>
      {selectedLine.lampIds.map((id) => {
        return <LineLampItem key={id} id={id} lineId={selectedLine.id} />;
      })}
      <div>
        <ActionButton action={() => addLampToLine(selectedLine.id)}
          extraClass="btn-primary"
          text={translate('technical_add_lamp')} />
        &nbsp;
        <ActionButton action={() => spreadLineLampsEvenly(selectedLine.id)}
          extraClass="btn-primary"
          text={translate('technical_spread_lamps')} />
      </div>
    </span>
  );
};

SelectedLineControl.propTypes = {
  actions: PropTypes.object.isRequired,
  selectedLine: PropTypes.object,
  id: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state, {id}) {
  const {lineReducer} = state;
  const translate = getTranslate(state.localeReducer);
  return {
    selectedLine: lineReducer[id],
    translate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectedLineControl);

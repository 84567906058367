import deepstream, {CONSTANTS} from 'deepstream.io-client-js';
import { DS_CONNECT, DS_CONNECT_ERROR, DS_DISCONNECT,
    DS_CONNECT_SUCCESS} from '../constants/dsActionTypes';
import {DS_RECORD_GET, DS_RECORD_SET, DS_LIST_GET, DS_RPC,
    DS_EVENT_SUB, DS_EVENT_UNSUB, MOVEMENT_EVENT} from '../constants/customTypes';
import {DEEPSTREAM_URL} from '../constants/urls';
import { apiLogger } from '../utils/logger';
import {makeUid} from '../utils/idHelper';
import {isObject} from '../utils/helper';
import {addAlert} from '../actions/alertActions';

let movementEvents = {};

const createNewDs = () => {
  const ds = deepstream(DEEPSTREAM_URL, {
    rpcAckTimeout: 9000,
    rpcResponseTimeout: 180000,
  });
  ds.on('error', (err, something) => {
    apiLogger('ds error', err, something);
  });
  return ds;
};

export const commMiddleware = store => next => {

  let client;
  let records = {};
  let subs = {};
  let queued = [];
  return action => {
    const {dispatch} = store;

    function execAction(action) {
      const {payload} = action;
      if (action.type === DS_RECORD_GET && records[payload.url] === undefined) {
        records[payload.url] = client.record.getRecord(payload.url);
        records[payload.url].subscribe((data) => {
          apiLogger('data', data);
          dispatch({
            type: payload.dataAction,
            data,
            props: action.props,
          });
        }, true);
        return;
      }else if(action.type === DS_LIST_GET && records[payload.url] === undefined) {
        records[payload.url] = client.record.getList(payload.url);
        records[payload.url].whenReady(() => {
          const entries = records[payload.url].getEntries();
          dispatch({
            type: payload.getEntriesAction,
            entries,
            props: action.props,
          });
        });
        records[payload.url].on('entry-added', (entry) => {
          dispatch({
            type: payload.entryAddedAction,
            entry,
            props: action.props,
          });
        });
        return;
      }else if(action.type === DS_RPC) {
        let dataport;
        if (action.replaceSite) {
          const {buildingReducer} = store.getState();
          const {activeBuildingId} = buildingReducer;
          const {dataportSlug} = buildingReducer[activeBuildingId];
          payload.url = payload.url.replace(':site', dataportSlug);
        }
        dispatch({
          type: payload.start,
          props: action.props,
          dataport,
        });
        client.rpc.make(payload.url, payload.data, (err, response) => {
          if (err) {
            apiLogger('rpc err', err);
            if ((err === CONSTANTS.EVENT.NO_RPC_PROVIDER || err === CONSTANTS.EVENT.RESPONSE_TIMEOUT) && action.showErrors === true) {
              dispatch(addAlert('Could not reach the TriLED controller device. Contact TriLED if this problem persists.'));
            }
            if (action.showErrors === true && isObject(err) && err.message) {
              dispatch(addAlert(err.message));
            }
            dispatch({
              type: payload.error,
              err,
              props: action.props,
              dataport,
            });
          }else {
            dispatch({
              type: payload.success,
              response,
              props: action.props,
              dataport,
            });
          }
          return;

        });

      }else if(action.type === DS_RECORD_SET) {
        if (records[payload.url] === undefined) {
          records[payload.url] = client.record.getRecord(payload.url);
        }
        records[payload.url].set(payload.prop, payload.value);
        return;
      }else if(action.type === DS_EVENT_SUB) {
        if (action.replaceSite) {
          const {buildingReducer} = store.getState();
          const {activeBuildingId} = buildingReducer;
          const {dataportSlug} = buildingReducer[activeBuildingId];
          payload.url = payload.url.replace(':site', dataportSlug);
        }

        if (subs[payload.url] === undefined) {

          if (payload.type === MOVEMENT_EVENT) {
            client.event.subscribe(payload.url, (data) => {
              const {buildingReducer} = store.getState();
              const {activeBuildingId} = buildingReducer;
              const lampId = makeUid(activeBuildingId, data);
              const cb = movementEvents[lampId];
              if (cb !== undefined) {
                cb();
              }
            });
          }else {
            client.event.subscribe(payload.url, (data) => {
              dispatch({
                type: payload.dataAction,
                data,
                props: action.props,
              });
            });
          }
          subs[payload.url] = true;
        }

      }else if(action.type === DS_EVENT_UNSUB && subs[payload.url] !== undefined) {
        client.event.unsubscribe(payload.url);
        subs[payload.url] = undefined;
      }
    }

    if (action.type === DS_CONNECT) {
      const {userId, username} = action;
      const credentials = {
        token: action.token,
        userId,
        username,
        type: 'user',
        version: 1,
      };
      client = createNewDs().login(credentials, (success, data) => {

        if(success) {
          dispatch({
            type: DS_CONNECT_SUCCESS,
          });

          // exec all actions which were done when DS was not connected
          while(queued.length > 0) {
            execAction(queued.shift());
          }

        }else {
          dispatch({
            type: DS_CONNECT_ERROR,
            err: data,
          });
        }
      });
      return;
    }else if(action.type === DS_DISCONNECT) {
      client.close();
      return next(action);
    }

    const connected = store.getState().dsReducer.connected;

    if (connected) {
      execAction(action);
    }else {
      queued.push(action);
    }

    return next(action);
  };
};

export const registerMovement = (id, cb) => {
  movementEvents[id] = cb;
};

export const unregisterMovement = (id) => {
  delete movementEvents[id];
};

import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Select from 'react-select';
import * as actions from '../actions/lampTypeActions';
import FormInput from '../components/FormInput';
import {LAMP_TYPE_INDEPENDANT, DEPICTION_RECT, LOCATION_STANDALONE, LOCATION_LINE, DEPICTION_CLOCK, LAMP_TYPE_SLAVE, LAMP_TYPE_MASTER} from '../constants/lampTypeTypes';
import FormSelect from '../components/FormSelect';
import ActionButton from '../components/ActionButton';

const getDefaultLampType = () => ({
  "type": "NEW_TYPE",
  "label": "New Lamp Type",
  "width": 150,
  "length": 1000,
  "basePower": 1.5,
  "locations": [LOCATION_STANDALONE],
  "relationType": LAMP_TYPE_INDEPENDANT,
  "depictionType": DEPICTION_RECT,
  "maxTheoreticalPower": 120,
  "radius": 250,
});

const convertLampTypeForApi = lampType => {
  const {basePower, length, width, maxTheoreticalPower, radius} = lampType;
  const lampTypeCp = {
    ...lampType,
    basePower: parseFloat(basePower),
    maxTheoreticalPower: parseFloat(maxTheoreticalPower),
  };
  if (lampType.depictionType === DEPICTION_RECT) {
    delete lampTypeCp.radius;
    lampTypeCp.width = parseFloat(width);
    lampTypeCp.length = parseFloat(length);
  }else if(lampType.depictionType === DEPICTION_CLOCK) {
    delete lampTypeCp.length;
    delete lampTypeCp.width;
    lampTypeCp.radius = parseFloat(radius);
  }
  return lampTypeCp;
};

const EditLampTypePage = ({match, lampTypesFetched, actions, lampType : existingLampType, updateLampTypeStatus, createLampTypeStatus}) => {
  const {lampTypeId} = match.params;
  const isNew = lampTypeId === 'new';
  const {getLampTypes, createLampType, updateLampType} = actions;
  const [lampType, setLampType] = useState();
  useEffect(() => {
    if (isNew) {
      setLampType(getDefaultLampType());
    }else {
      if (lampTypesFetched) {
        if (existingLampType.depictionType === DEPICTION_RECT) {
          setLampType({
            ...existingLampType,
            radius: 250,
          });
        }else if (existingLampType.depictionType === DEPICTION_CLOCK) {
          setLampType({
            ...existingLampType,
            length: 1000,
            width: 150,
          });
        }
      }else {
        getLampTypes();
      }
    }
  }, isNew ? []:[lampTypesFetched]);

  if (!lampType) {
    return null;
  }

  const save = () => {
    const lampTypeForApi = convertLampTypeForApi(lampType);
    if (isNew) {
      createLampType(lampTypeForApi);
    }else {
      updateLampType(lampTypeForApi);
    }
  };

  const setLampTypeProp = (prop, value) => {
    const newLampType = {...lampType};
    newLampType[prop] = value;
    setLampType(newLampType);
  };

  const {type, label, basePower, maxTheoreticalPower, depictionType, relationType, length, width, radius, locations} = lampType;

  let depictionTypeInputs = null;
  if (depictionType === DEPICTION_RECT) {
    depictionTypeInputs = (<>
      <FormInput id="length" type="number" min="1" label="Length" value={length} step="any"
        onChange={(value) => setLampTypeProp('length', value)} required />
      <FormInput id="width" type="number" min="1" label="Width" value={width} step="any"
        onChange={(value) => setLampTypeProp('width', value)} required />
    </>);
  }else if(depictionType === DEPICTION_CLOCK) {
    depictionTypeInputs = (<>
      <FormInput id="radius" type="number" min="1" label="Radius" value={radius} step="any"
        onChange={(value) => setLampTypeProp('radius', value)} required />
    </>);
  }

  const actionStr = isNew ? 'Create':'Edit';
  const depictionOptions = [
    {value: DEPICTION_RECT, label: 'Rectangle'},
    {value: DEPICTION_CLOCK, label: 'Clock'},
  ];
  const relationOptions = [
    {value: LAMP_TYPE_INDEPENDANT, label: 'Independant'},
    {value: LAMP_TYPE_MASTER, label: 'Master'},
    {value: LAMP_TYPE_SLAVE, label: 'Slave'},
  ];
  const locationOptions = [
    {value: LOCATION_STANDALONE, label: 'Standalone'},
    {value: LOCATION_LINE, label: 'Line'},
  ];
  return (
    <div className="edit-lamp-type-page">
      <Helmet>
        <title>{actionStr} Lamp Type | TriVIEW</title>
      </Helmet>
      <div className="container">
        <h1>{actionStr} Lamp Type</h1>
        <form onSubmit={(e) => { e.preventDefault(); save();}}>
          <FormInput id="type" type="text" label="Type" value={type}
            onChange={(value) => setLampTypeProp('type', value)} required />
          <FormInput id="label" type="text" label="Label" value={label}
            onChange={(value) => setLampTypeProp('label', value)} required />
          <FormInput id="basePower" type="number" min="0" label="Base Power" value={basePower} step="any"
            onChange={(value) => setLampTypeProp('basePower', value)} required />
          <FormInput id="maxTheoreticalPower" type="number" min="0" label="Max Power" value={maxTheoreticalPower}
            onChange={(value) => setLampTypeProp('maxTheoreticalPower', value)} required step="any" />
          <div className="form-group">
            <label>Locations</label>
            <Select
              classNamePrefix="select"
              closeMenuOnSelect={false}
              isClearable={true}
              isSearchable={false}
              isMulti
              onChange={(options) => setLampTypeProp('locations', options.map(option => option.value))}
              name="location"
              value={locationOptions.filter(option => locations.indexOf(option.value) > -1)}
              options={locationOptions}
            />
          </div>
          <FormSelect label={'Relation Type'} value={relationType} id="relationType"
            onChange={(value) => setLampTypeProp('relationType', value)}
            options={relationOptions} />
          <FormSelect label={'Depiction Type'} value={depictionType} id="depictionType"
            onChange={(value) => setLampTypeProp('depictionType', value)}
            options={depictionOptions} />
          {depictionTypeInputs}
          <ActionButton isSubmit={true} status={isNew ? createLampTypeStatus:updateLampTypeStatus} text={isNew ? 'Create':'Save'} style={{marginTop: '20px'}} />
        </form>
      </div>
    </div>
  );
};

EditLampTypePage.propTypes = {
  match: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  lampTypesFetched: PropTypes.bool.isRequired,
  lampType: PropTypes.object,
  createLampTypeStatus: PropTypes.string.isRequired,
  updateLampTypeStatus: PropTypes.string.isRequired,
};

function mapStateToProps(state, props) {
  const {lampTypesFetched, createLampTypeStatus, updateLampTypeStatus} = state.lampTypeReducer;
  const lampType = state.lampTypeReducer[props.match.params.lampTypeId];
  return {
    lampType,
    lampTypesFetched,
    createLampTypeStatus,
    updateLampTypeStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLampTypePage);

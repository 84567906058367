import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/authActions';
import { getTranslate } from 'react-localize-redux';
import ActionButton from '../components/ActionButton';

const ChangeProfilePage = ({actions, translate, changeProfileStatus, userId, firstname, lastname}) => {
  if (!userId) {
    return null;
  }
  const {changeProfile} = actions;
  const [lFirstname, setFirstname] = useState(firstname);
  const [lLastname, setLastname] = useState(lastname);

  const submit = (event) => {
    event.preventDefault();
    changeProfile(userId, lFirstname, lLastname);
  };

  return (
    <div className="change-profile-page">
      <Helmet>
        <title>{translate('page_title_change_profile')}</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{paddingTop: '10px'}}>
            <h2>{translate('profile_change')}</h2>
            <form onSubmit={submit}>
              <p>
                <label htmlFor="firstname">{translate('global_firstname')}:</label>
                <input type="text" value={lFirstname} id="firstname" className="form-control"
                  onChange={(event) => setFirstname(event.target.value)} />
              </p>
              <p>
                <label htmlFor="lastname">{translate('global_lastname')}:</label>
                <input type="text" value={lLastname} id="lastname" className="form-control"
                  onChange={(event) => setLastname(event.target.value)} />
              </p>
              <ActionButton
                isSubmit
                extraClass="btn-primary" status={changeProfileStatus}
                text={translate('global_save')} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

ChangeProfilePage.propTypes = {
  actions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  changeProfileStatus: PropTypes.string.isRequired,
  userId: PropTypes.number,
  lastname: PropTypes.string,
  firstname: PropTypes.string,
};

function mapStateToProps(state) {
  const {changeProfileStatus, id, firstname, lastname} = state.authReducer;
  const translate = getTranslate(state.localeReducer);
  return {
    changeProfileStatus,
    translate,
    userId: id,
    firstname,
    lastname,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeProfilePage);

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { getTranslate } from 'react-localize-redux';
import * as actions from '../actions/scenarioActions';
import * as buildingActions from '../actions/buildingActions';
import ScenarioSelect from './ScenarioSelect';
import ConfirmDialog from '../containers/ConfirmDialog';
import ActionButton from '../components/ActionButton';
import '../styles/scenario-container.scss';

class ScenarioContainer extends Component {
  constructor() {
    super();
    this.state = {
      showConfirmActivateDialog: false,
      showConfirmSaveDialog: false,
    };
    this.scenarioSave= this.scenarioSave.bind(this);
  }

  scenarioSave() {
    const {activeScenarioId, selectedScenario, actions} = this.props;
    const {saveScenario} = actions;
    if (activeScenarioId === selectedScenario.id) {
      this.setState({showConfirmSaveDialog: true});
    }else {
      saveScenario(selectedScenario.id);
    }
  }

  render() {
    const {showConfirmActivateDialog, showConfirmSaveDialog} = this.state;
    const {buildingActions, activeBuildingId, selectedScenario, canDeleteScenario,
      canSaveScenario,
      scenarioOptions,
      actions, deleteScenarioId, activeScenarioId, saveScenarioStatus, translate} = this.props;
    const {id: selectedScenarioId, needsSave} = selectedScenario;

    const {setScenarioReducerProps, deleteScenario, activateScenario, setSelectedScenario, saveScenario} = actions;
    const {setBuildingProps} = buildingActions;

    let deleteBtn = null;
    if (canDeleteScenario && selectedScenarioId !== activeScenarioId) {
      deleteBtn = (
        <button className="btn btn-danger"
          onClick={() => setScenarioReducerProps({deleteScenarioId: selectedScenarioId})}
        >
          {translate('global_delete')}
        </button>
      );
    }

    let activateSaveEl = null;

    if (selectedScenarioId === activeScenarioId) {
      activateSaveEl = (
        <ActionButton action={() => this.setState({showConfirmSaveDialog: true})}
          status={saveScenarioStatus} disabled={!canSaveScenario} text={translate('global_save_and_activate')} />
      );
    }else {
      activateSaveEl = (
        <Fragment>
          <button className="btn btn-primary" disabled={needsSave} onClick={() => this.setState({showConfirmActivateDialog: true})}>
            {translate('global_activate')}
          </button>
          <ActionButton action={() => this.scenarioSave()}
            status={saveScenarioStatus} disabled={!canSaveScenario} text={translate('global_save')} />
        </Fragment>
      );
    }

    return (
      <div className="scenario-container">
        <ConfirmDialog show={deleteScenarioId !== undefined} closeAction={() => setScenarioReducerProps({deleteScenarioId: undefined})}
          confirmAction={() => deleteScenario(deleteScenarioId)} text={translate('scenario_delete_confirm_text')}
          title={translate('scenario_delete')} />
        <ConfirmDialog show={showConfirmActivateDialog} closeAction={() => this.setState({showConfirmActivateDialog: false})}
          confirmAction={() => {this.setState({showConfirmActivateDialog: false}); activateScenario(selectedScenarioId);}} text={translate('scenario_activate_confirm_text')}
          title={translate('scenario_activate')} />
        <ConfirmDialog show={showConfirmSaveDialog} closeAction={() => this.setState({showConfirmSaveDialog: false})}
          confirmAction={() => {this.setState({showConfirmSaveDialog: false}); saveScenario(selectedScenarioId);}} text={translate('scenario_save_active_confirm_text')}
          title={translate('scenario_save_active')} />
        <label htmlFor="scenario">{translate('scenario_scenarios')}:</label>
        <ScenarioSelect value={selectedScenarioId} name="scenario" activeScenarioId={activeScenarioId}
          scenarioOptions={scenarioOptions}
        onChange={({value}) => setSelectedScenario(value)} />
        <div className="changes-block">
          {activateSaveEl}
          <button className="btn btn-primary"
            onClick={() => setBuildingProps(activeBuildingId, {scenarioEditingId: selectedScenarioId})}>{translate('global_edit')}
          </button>
          <button className="btn btn-primary"
            onClick={() => setBuildingProps(activeBuildingId, {scenarioEditingId: 'NEW'})}>
            {translate('global_create')}
          </button>
          {deleteBtn}
        </div>
        <div className={`unsaved-block ${needsSave === false ? 'hidden':''}`}>
          <p>{translate('scenario_unsaved_changes')}.</p>
        </div>
      </div>
    );
  }
}

ScenarioContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  buildingActions: PropTypes.object.isRequired,
  activeBuildingId: PropTypes.number.isRequired,
  canDeleteScenario: PropTypes.bool.isRequired,
  deleteScenarioId: PropTypes.string,
  selectedScenario: PropTypes.object.isRequired,
  activeScenarioId: PropTypes.string.isRequired,
  saveScenarioStatus: PropTypes.string.isRequired,
  scenarioOptions: PropTypes.array.isRequired,
  canSaveScenario: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
};

function mapStateToProps({buildingReducer, scenarioReducer, groupReducer, profileReducer, localeReducer}) {
  const {activeBuildingId} = buildingReducer;
  const {editingId : groupEditingId} = groupReducer;
  const {profileEditingId} = profileReducer;
  const {deleteScenarioId, saveScenarioStatus} = scenarioReducer;
  const building = buildingReducer[activeBuildingId];
  const {selectedScenarioId, activeScenarioId, scenarioIds, scenarioOptions} = building;
  return {
    activeBuildingId,
    activeScenarioId,
    selectedScenario: scenarioReducer[selectedScenarioId],
    canDeleteScenario: scenarioIds.length > 1,
    deleteScenarioId,
    saveScenarioStatus,
    scenarioOptions,
    profileEditingId,
    groupEditingId,
    canSaveScenario: profileEditingId === undefined && groupEditingId === undefined,
    translate: getTranslate(localeReducer),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    buildingActions: bindActionCreators(buildingActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScenarioContainer);

import {apiAction} from './helper';

export const API = 'API';
export const POST = 'POST';
export const GET = 'GET';
export const PUT = 'PUT';
export const DELETE = 'DELETE';
export const DS_RECORD_GET = 'DS_RECORD_GET';
export const DS_RECORD_SET = 'DS_RECORD_SET';
export const DS_EVENT_SUB = 'DS_EVENT_SUBSCRIBE';
export const DS_EVENT_UNSUB = 'DS_EVENT_UNSUB';
export const DS_LIST_GET = 'DS_LIST_GET';
export const DS_RPC = 'DS_RPC';
export const CONFIG_MODE_NVI_STATE = 'CONFIG_MODE_NVI_STATE';
export const CONFIG_MODE_NVO_RSSI = 'CONFIG_MODE_NVO_RSSI';
export const AXIS_X = 'AXIS_X';
export const AXIS_Y = 'AXIS_Y';
export const MOVEMENT_EVENT = 'MOVEMENT_EVENT';
export const TEXT_NONE = 'TEXT_NONE';
export const TEXT_MAC = 'TEXT_MAC';
export const TEXT_NAME = 'TEXT_NAME';
export const STARTED = 'STARTED';
export const DONE = 'DONE';
export const ERROR = 'ERROR';
export const INITIAL = 'INITIAL';
export const ZOOM = 'ZOOM';
export const LAMP = 'LAMP';
export const PS = 'PS';
export const SELECT_LAMPS = 'SELECT_LAMPS';
export const SELECT_PSES = 'SELECT_PSES';
export const SERIAL_RPC = 'SERIAL_RPC';
export const SERIAL_RPC_API = apiAction('SERIAL_RPC_API');
export const MODE_NONE = 'MODE_NONE';
export const EDIT_HITZONE_MODE = 'EDIT_HITZONE_MODE';
export const EDIT_ADJACENT_LAMPS_MODE = 'EDIT_ADJACENT_LAMPS_MODE';
export const DRAG_LEFT = 'DRAG_LEFT';
export const DRAG_RIGHT = 'DRAG_RIGHT';
export const DRAG_TOP = 'DRAG_TOP';
export const DRAG_BOTTOM = 'DRAG_BOTTOM';
export const LAMP_CLICK_MODE_WINK = 'LAMP_CLICK_MODE_WINK';
export const LAMP_CLICK_MODE_DIALOG = 'LAMP_CLICK_MODE_DIALOG';


export const UPGRADER_STATUS_STOPPED = 'UPGRADER_STATUS_STOPPED';
export const UPGRADER_STATUS_STARTING = 'UPGRADER_STATUS_STARTING';
export const UPGRADER_STATUS_CLEARED = 'UPGRADER_STATUS_CLEARED';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const SERVICE_STOPPED = 'SERVICE_STOPPED';
export const SERVICE_RUNNING = 'SERVICE_RUNNING';
export const SERVICE_UNKNOWN = 'SERVICE_UNKNOWN';
export const TRICORE_SERVICE = 'tricore';
export const TRIFLUX_SERVICE = 'triflux';
export const TRICOMMAND_SERVICE = 'tricommand';
export const ISC_DHCP_SERVER_SERVICE = 'isc-dhcp-server';

export const LAMP_SELECTION_DIALOG_MODE_NODES = 'LAMP_SELECTION_DIALOG_MODE_NODES';
export const LAMP_SELECTION_DIALOG_MODE_PPS = 'LAMP_SELECTION_DIALOG_MODE_PPS';

export const UPGRADE_PLAN_LAMP = 'UPGRADE_PLAN_LAMP';
export const UPGRADE_PLAN_PS = 'UPGRADE_PLAN_PS';
export const UPGRADE_ITEMS_PS = 'UPGRADE_ITEMS_PS';
export const UPGRADE_ITEMS_LAMP = 'UPGRADE_ITEMS_LAMP';

export const TRICOMMAND = 'tricommand';
export const TRIAGENT = 'triagent';
export const TRIVIEW_LOCAL = 'triview-local';

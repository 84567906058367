import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Dialog from '../components/DialogWrapper';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/planActions';
import '../styles/selection-dialog.scss';
import Variable from './Variable';
import EditAllVariables from './EditAllVariables';
import PsesInfo from './PsesInfo';
import PsesCommands from './PsesCommands';
import { getTranslate } from 'react-localize-redux';

const PsSelectionDialog = ({actions, showPsSelectionDialog, selectedObjects, selectedPsMacs, selectedObjectNames, translate}) => {
  if (showPsSelectionDialog === false) {
    return null;
  }

  const {setShowPsSelectionDialog} = actions;

  const objectEls = selectedObjectNames.map((objectName, i) => {
    const object = selectedObjects[objectName];
    return (
      <div key={i} className="col-md-12">
        <h4>{objectName}</h4>
        <table className="table custom-table-responsive">
          <thead>
            <tr>
              <th/>
              {
                object.variables.map((variableName) => {
                  return (
                    <th key={`${objectName}/${variableName}`}>
                      <EditAllVariables objectName={objectName} variableName={variableName} macs={selectedPsMacs} />
                    </th>
                  );
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              selectedPsMacs.map((mac, j) => {
                return (
                  <tr key={j}>
                    <th>{mac}</th>
                    {
                      object.variables.map((variableName, k) => {
                        return (
                          <td key={k}><Variable mac={mac} objectName={objectName} variableName={variableName} showLabel={false} /></td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  });

  const body = (
    <div className="row">
      <div className="col-md-12">
        <PsesCommands psMacs={selectedPsMacs} />
      </div>
      <div className="col-md-12">
        <PsesInfo psMacs={selectedPsMacs} />
      </div>
      {objectEls}
    </div>
  );

  return (
    <span className="selection-dialog">
      <Dialog title={translate('technical_selection')} visible={true} body={body} closeLabel={translate('global_close')}
        closeAction={() => setShowPsSelectionDialog(false)} />
    </span>
  );
};

PsSelectionDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  showPsSelectionDialog: PropTypes.bool.isRequired,
  selectedPsMacs: PropTypes.array.isRequired,
  selectedObjects: PropTypes.object.isRequired,
  selectedObjectNames: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {showPsSelectionDialog, selectedPsMacs} = state.planReducer;
  const selectedObjects = state.selectedObjectsReducer;
  const selectedObjectNames = selectedObjects.objectNames;
  const translate = getTranslate(state.localeReducer);
  return {
    showPsSelectionDialog,
    selectedPsMacs,
    selectedObjects,
    selectedObjectNames,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PsSelectionDialog);

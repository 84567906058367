import {SET_OBJECT, SET_OBJECTS} from '../constants/nodeActionTypes';
import objectAssign from 'object-assign';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {};

export default function objectReducer(state = initialState, action) {

  switch (action.type) {
    case SET_OBJECT:{
      const {name, key, variableIds} = action;
      let newState = objectAssign({}, state);
      newState[key] = {name, variableIds};
      return newState;
    }
    case SET_OBJECTS:{
      return objectAssign({}, state, action.objects);
    }

    default:
      return state;
  }
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import UserBuildingItem from './UserBuildingItem';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/buildingActions';

class UserBuildingList extends Component {
  render() {
    const {buildingIds, selectedClientId} = this.props;
    if (!buildingIds) {
      return <p>Loading...</p>;
    }

    return (
      <div>
        {buildingIds.map((id) => {
          return (
            <UserBuildingItem key={id} id={id} selectedClientId={selectedClientId} />
          );
        })}
      </div>
    );
  }
}

UserBuildingList.propTypes = {
  buildingIds: PropTypes.array,
  actions: PropTypes.object.isRequired,
  selectedClientId: PropTypes.number,
};

function mapStateToProps(state, props) {
  const {clientReducer} = state;
  const client = clientReducer[props.selectedClientId];
  let buildingIds;
  if (client) {
    buildingIds = client.buildingIds;
  }
  return {
    buildingIds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserBuildingList);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

const LampTypeItem = ({lampType, deleteLampType}) => {
  const {id, type, label, depictionType, relationType, locations} = lampType;

  return (
    <tr>
      <th>{type}</th>
      <td>{label}</td>
      <td>{depictionType}</td>
      <td>{relationType}</td>
      <td>{locations.join(', ')}</td>
      <td>
        <Link className="btn btn-primary" to={`/lamp-type/${id}`}>
          Edit
        </Link>
      </td>
      <td>
        <span className="btn btn-danger" onClick={() => deleteLampType()}>
          Delete
        </span>
      </td>
    </tr>
  );
};

LampTypeItem.propTypes = {
  id: PropTypes.number.isRequired,
  lampType: PropTypes.object,
  deleteLampType: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    lampType: state.lampTypeReducer[props.id],
  };
}

export default connect(
  mapStateToProps,
  null,
)(LampTypeItem);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/lampActions';
import '../styles/selected-lamp-control.scss';
import {parseIfInt} from '../utils/helper';
import ActionButton from '../components/ActionButton';
import LampTypeOption from '../containers/LampTypeOption';
import { getTranslate } from 'react-localize-redux';
import {LAMP_TYPE_MASTER} from '../constants/lampTypeTypes';
import SubLampItem from './SubLampItem';

const SelectedLampControl = ({actions, selectedLamp, translate, lampTypeIds, lampType, lampTypeAloneSlaveIds}) => {
  const {setLampProps, setLampType, addLampToLamp} = actions;
  let subLampEl = null;

  if (lampType.relationType === LAMP_TYPE_MASTER) {
    let subLampsEl = null;
    if (selectedLamp.lampIds) {
      subLampsEl = selectedLamp.lampIds.map((lampId) => {
        return <SubLampItem key={lampId} id={lampId} parentLampId={selectedLamp.id} />;
      });
    }
    subLampEl = (
      <span>
        <div>
          {subLampsEl}
        </div>
        <ActionButton action={() => addLampToLamp(selectedLamp.id, lampTypeAloneSlaveIds[0])}
          extraClass="btn-primary"
          style={{marginBottom: '10px', marginTop: '10px'}}
          text="Add slave lamp" />
      </span>
    );
  }

  return (
    <span className="selected-lamp-control">
      <select value={selectedLamp.typeId} onChange={(e) => setLampType(selectedLamp.id, e.target.value)}>
        {lampTypeIds.map(lampTypeId => (
          <LampTypeOption key={lampTypeId} id={lampTypeId} />
        ))}
      </select>
      <div className="form-half">
        <label htmlFor="x">x:</label>
        <input type="number" className="form-control" id="x" step="1" value={selectedLamp.x}
          onChange={(e) => setLampProps(selectedLamp.id, {x: parseIfInt(e.target.value)})} />
      </div>
      <div className="form-half">
        <label htmlFor="y">y:</label>
        <input type="number" className="form-control" id="y" step="1" value={selectedLamp.y}
          onChange={(e) => setLampProps(selectedLamp.id, {y: parseIfInt(e.target.value)})} />
      </div>
      <div className="form-half">
        <label htmlFor="rotation">{translate('technical_rotation')}:</label>
        <input type="number" className="form-control" id="rotation" min="0" step="90" max="90" value={selectedLamp.rotation}
          onChange={(e) =>setLampProps(selectedLamp.id, {rotation: parseIfInt(e.target.value)})} />
      </div>
      {subLampEl}
    </span>
  );
};

SelectedLampControl.propTypes = {
  actions: PropTypes.object.isRequired,
  selectedLamp: PropTypes.object,
  id: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  lampType: PropTypes.object.isRequired,
  lampTypeIds: PropTypes.array.isRequired,
  lampTypeAloneSlaveIds: PropTypes.array.isRequired,
};

function mapStateToProps({localeReducer, lampReducer, lampTypeReducer}, {id}) {
  const translate = getTranslate(localeReducer);
  const selectedLamp = lampReducer[id];
  const lampType = lampTypeReducer[selectedLamp.typeId];
  const {lampTypeAloneIds : lampTypeIds, lampTypeAloneSlaveIds} = lampTypeReducer;
  return {
    translate,
    selectedLamp,
    lampType,
    lampTypeIds,
    lampTypeAloneSlaveIds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectedLampControl);

import {GET_SERVICE_STATUS, START_SERVICE, STOP_SERVICE, RESTART_SERVICE} from '../constants/serviceActionTypes';
import {SERVICE_STOPPED, SERVICE_RUNNING, SERVICE_UNKNOWN,
  TRICORE_SERVICE, TRIFLUX_SERVICE, ISC_DHCP_SERVER_SERVICE} from '../constants/customTypes';
import objectAssign from 'object-assign';

const getServiceStatusType = (status) => {
  if (status.indexOf('running') !== -1) {
    return SERVICE_RUNNING;
  }else if(status.indexOf('stopped') !== -1) {
    return SERVICE_STOPPED;
  }
  return SERVICE_UNKNOWN;
};

const initialState = {
};

initialState[`${TRIFLUX_SERVICE}Status`] = SERVICE_UNKNOWN;
initialState[`${TRICORE_SERVICE}Status`] = SERVICE_UNKNOWN;
initialState[`${ISC_DHCP_SERVER_SERVICE}Status`] = SERVICE_UNKNOWN;

export default function serviceReducer(state = initialState, action) {

  switch (action.type) {
    case START_SERVICE.SUCCESS:
    case STOP_SERVICE.SUCCESS:
    case RESTART_SERVICE.SUCCESS:
    case GET_SERVICE_STATUS.SUCCESS:{
      const status = action.response;
      const {service} = action.props;
      const statusType = getServiceStatusType(status);
      let newState = objectAssign({}, state);
      newState[`${service}Status`] = statusType;
      return newState;
    }
    case GET_SERVICE_STATUS.START:{
      const {service} = action.props;
      let newState = objectAssign({}, state);
      newState[`${service}Status`] = SERVICE_UNKNOWN;
      return newState;
    }
    case RESTART_SERVICE.START:{
      const {service} = action.props;
      let newState = objectAssign({}, state);
      newState[`${service}Status`] = SERVICE_UNKNOWN;
      return newState;
    }

    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/dataportActions';
import {Link} from 'react-router-dom';

const DataportItem = ({dataport, actions}) => {
    const {setDataportReducerProps} = actions;
    const {id, name} = dataport;

    return (
      <tr>
        <td>{name}</td>
        <td>
          <Link className="btn btn-default" to={`/dataports/${id}`}>Edit</Link>
        </td>
        <td>
          <span className="btn btn-danger" onClick={() => setDataportReducerProps({deleteDataportId: id})}>
            Delete
          </span>
        </td>
      </tr>
    );
};

DataportItem.propTypes = {
  id: PropTypes.number.isRequired,
  dataport: PropTypes.object,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    dataport: state.dataportReducer[props.id],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataportItem);

import React from 'react';
import PropTypes from 'prop-types';
import '../styles/checkbox.scss';

export const LABEL_POSITION_BEFORE = "before";
export const LABEL_POSITION_AFTER = "after";

const Checkbox = ({id, name, checked, onChange, labelPosition, className, children, inline, noMargin}) => {
  let renderComponent;
  if (LABEL_POSITION_AFTER === labelPosition) {
    renderComponent = (
      <div className={`checkbox ${className} ${inline ? 'inline':''} ${noMargin ? 'no-margin':''}`}>
        <label htmlFor={id}>
          <input id={id} name={name} type="checkbox" checked={checked} onChange={() => onChange()}/>
          {children}
        </label>
      </div>
    );
  } else {
    renderComponent = (
      <div className={`checkbox checkbox-before ${className} ${inline ? 'inline':''} ${noMargin ? 'no-margin':''}`}>
        <label htmlFor={id}>
          {children}
          <input id={id} name={name} type="checkbox" checked={checked} onChange={() => onChange()}/>
        </label>
      </div>
    );
  }

  return renderComponent;

};

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  labelPosition: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  inline: PropTypes.bool.isRequired,
  noMargin: PropTypes.bool.isRequired,
};

Checkbox.defaultProps = {
  className: "",
  checked: false,
  labelPosition: LABEL_POSITION_AFTER,
  inline: false,
  noMargin: false,
};


export default Checkbox;

import {apiAction} from './helper';

export const GET_CLIENTS = apiAction('GET_CLIENTS');
export const SET_ACTIVE_CLIENT = 'SET_ACTIVE_CLIENT';
export const SET_BUILDING_IDS = 'SET_BUILDING_IDS';
export const SET_CLIENT_PROPS = 'SET_CLIENT_PROPS';
export const SET_SHOW_SELECT_CLIENT_DIALOG = 'SET_SHOW_SELECT_CLIENT_DIALOG';
export const SET_CLIENT_ROUTE_SLUG = 'SET_CLIENT_ROUTE_SLUG';
export const SET_CLIENT_REDUCER_PROPS = 'SET_CLIENT_REDUCER_PROPS';
export const UPDATE_CLIENT = apiAction('UPDATE_CLIENT');
export const DELETE_CLIENT = apiAction('DELETE_CLIENT');
export const CREATE_CLIENT = apiAction('CREATE_CLIENT');
export const SET_SELECTED_CLIENT_ID = 'SET_SELECTED_CLIENT_ID';

import React from 'react';
// import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import SubNav from '../components/SubNav';
import DataportsTechnicalTable from '../containers/DataportsTechnicalTable';

const DataportsTechnicalPage = () => {
  return (
    <div className="site-page">
      <Helmet>
        <title>TriLED - Dataport Info</title>
      </Helmet>
      <SubNav parentNav="monitor" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="alt-header">Dataports</h2>
          </div>
          <Link to={"/monitor/dataports/command"}>Run command</Link>
          <div className="col-12">
            <DataportsTechnicalTable />
          </div>
        </div>
      </div>
    </div>
  );
};

DataportsTechnicalPage.propTypes = {
};

export default DataportsTechnicalPage;

import {GET_ENERGY_DATA, GET_SIMULATE_ENERGY, CREATE_ENERGY_HEATMAP, SET_ENERGY_REDUCER_PROPS} from '../constants/energyActionTypes';
import {TRIVIEW_API} from '../constants/urls';
import {API, GET} from '../constants/customTypes';

export function createEnergyHeatmap(data, isActual) {
  return {
    type: CREATE_ENERGY_HEATMAP,
    data,
    isActual,
  };
}

export function getEnergyData(configId, startDate, endDate) {
  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      url: TRIVIEW_API.ENERGY.GET.replace(':configId', configId),
      urlParams: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
      method: GET,
      success: GET_ENERGY_DATA.SUCCESS,
      error: GET_ENERGY_DATA.ERROR,
      start: GET_ENERGY_DATA.START,
    },
  };
}

export function getSimulateEnergy(configId, scenarioId, startDate, endDate) {
  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      url: TRIVIEW_API.ENERGY.SIMULATE.replace(':configId', configId),
      urlParams: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        scenarioId,
      },
      method: GET,
      success: GET_SIMULATE_ENERGY.SUCCESS,
      error: GET_SIMULATE_ENERGY.ERROR,
      start: GET_SIMULATE_ENERGY.START,
    },
  };
}

export function setEnergyReducerProps(obj) {
  return {
    type: SET_ENERGY_REDUCER_PROPS,
    obj,
  };
}

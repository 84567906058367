import {GET_GROUPS_PROFILE_STATE, SET_GROUP_PROPS, SET_GROUP_EDITING, SET_GROUP_LAMPS,
GROUP_LAMPS_REDO, GROUP_LAMPS_UNDO, SET_DOUBLE_GROUP_LAMPS, SET_EDITING_GROUP_PROFILE, CANCEL_EDIT_GROUP,
SET_LAMP_GROUP_MAP, SET_LAMP_GROUP_MAP_ENTRY,
SAVE_GROUP, CREATE_GROUP, DELETE_GROUP} from '../constants/groupActionTypes';
import {DS_RPC} from '../constants/customTypes';

export function setGroupProps(key, obj) {
  return {
    type: SET_GROUP_PROPS,
    key,
    obj,
  };
}

export function getGroupsProfileState(buildingId) {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: `:site/${buildingId}/live/groupsState`,
      data: {buildingId},
      success: GET_GROUPS_PROFILE_STATE.SUCCESS,
      start: GET_GROUPS_PROFILE_STATE.START,
      error: GET_GROUPS_PROFILE_STATE.ERROR,
    },
    props: {
      buildingId,
    },
  };
}

export function setGroupEditing(key, groupEditing) {
  return {
    type: SET_GROUP_EDITING,
    key,
    groupEditing,
  };
}

export function setGroupLamps(id, lampIds) {
  return {
    type: SET_GROUP_LAMPS,
    id,
    lampIds,
  };
}

export function saveGroup() {
  return {
    type: SAVE_GROUP,
  };
}

export function createGroup(id, lampIds) {
  return {
    type: CREATE_GROUP,
    lampIds,
    id,
  };
}

export function deleteGroup(id) {
  return {
    type: DELETE_GROUP,
    id,
  };
}

export function groupLampsUndo() {
  return {
    type: GROUP_LAMPS_UNDO,
  };
}

export function groupLampsRedo() {
  return {
    type: GROUP_LAMPS_REDO,
  };
}

export function setDoubleGroupLamps(editingGroupId, editingLampIds, oldGroupId, oldLampIds) {
  return {
    type: SET_DOUBLE_GROUP_LAMPS,
    editingGroupId,
    editingLampIds,
    oldGroupId,
    oldLampIds,
  };
}

export function setEditingGroupProfile(profileId) {
  return {
    type: SET_EDITING_GROUP_PROFILE,
    profileId,
  };
}

export function cancelEditGroup() {
  return {
    type: CANCEL_EDIT_GROUP,
  };
}

export function setLampGroupMap(lampGroupMap) {
  return {
    type: SET_LAMP_GROUP_MAP,
    lampGroupMap,
  };
}

export function setLampGroupMapEntry(lampId, groupId) {
  return {
    type: SET_LAMP_GROUP_MAP_ENTRY,
    lampId,
    groupId,
  };
}

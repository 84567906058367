import React from 'react';
import LogoutControl from '../containers/LogoutControl';
import SelectedBuilding from '../containers/SelectedBuilding';
import BuildingList from '../containers/BuildingList';
import PropTypes from 'prop-types';
import SelectedClient from '../containers/cloud/SelectedClient';
import '../styles/sub-nav.scss';

const SubNav = ({ parentNav }) => {
  if (parentNav.indexOf('plan') > -1) {
    return (
      <div className="sub-nav">
        <div className="nav-triled-logo">
          <img src="/static/images/TriLed_logo.png" height="30px" />
        </div>
          <i className="fa fa-chevron-right seperator" aria-hidden="true"/>
          <SelectedClient />
          <i className="fa fa-chevron-right seperator" aria-hidden="true"/>
          <SelectedBuilding />
          <BuildingList />
        <div className="sub-nav-right">
          <LogoutControl />
        </div>
      </div>);
  } else {
    return (
      <div className="sub-nav">
        <div className="nav-triled-logo">
          <img src="/static/images/TriLed_logo.png" height="30px" />
        </div>
        <div className="sub-nav-right">
          <LogoutControl />
        </div>
      </div>);
  }
};

SubNav.propTypes = {
  parentNav: PropTypes.string.isRequired
};

export default SubNav;

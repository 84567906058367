import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import * as actions from '../actions/buildingActions';

const BuildingItem = ({building, actions}) => {
  const {setBuildingReducerProps} = actions;
  const {name, id} = building;
  return (
    <tr>
      <th>{name}</th>
      <td>
        <Link className="btn btn-primary" to={`/building/${id}`}>
          Edit
        </Link>
      </td>
      <td>
        <span className="btn btn-danger" onClick={() => setBuildingReducerProps({deleteBuildingId: id})}>
          Delete
        </span>
      </td>
    </tr>
  );
};

BuildingItem.propTypes = {
  id: PropTypes.number.isRequired,
  building: PropTypes.object,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    building: state.buildingReducer[props.id],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BuildingItem);

import React from 'react';
import PropTypes from 'prop-types';

const BaseLampZone = ({leftX, leftY, width, height, x, y,
  rotation, padding, color, opacity, className, ...rest}) => {
  return (
    <g className={className} transform={`translate(${x}, ${y}) rotate(${rotation})`} {...rest}>
      <rect fill={color} opacity={opacity} x={leftX - (padding / 2)} y={leftY - (padding / 2)}
        width={width + padding} height={height + padding} />
    </g>
  );
};

BaseLampZone.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  leftX: PropTypes.number,
  leftY: PropTypes.number,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  padding: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
};

BaseLampZone.defaultProps = {
  y: 0,
  rotation: 0,
  className: '',
  opacity: 1,
  color: 'blue',
  padding: 0,
};

// function mapStateToProps(state, props) {
//   const {lampReducer, lampTypeReducer, groupReducer} = state;
//   const lamp = lampReducer[props.id];
//   const {drawingLength: length, isEllipse} = lampTypeReducer[lamp.typeId];
//   const {x, top, bottom, right, left, parentId, y, rotation } = lamp;
//   const parentLamp = state.lampReducer[parentId];

//   const {lampGroupMap} = groupReducer;
//   let groupId;
//   if (parentLamp !== undefined) {
//     groupId = lampGroupMap[parentLamp.id];
//   }else {
//     groupId = lampGroupMap[lamp.id];
//   }
//   const group = groupReducer[groupId];

//   const {groupEditing} = groupReducer;
//   const groupEditingId = groupReducer.editingId;
//   const {groupMode, displayMode} = state.planReducer;
//   let profile;
//   if (group !== undefined) {
//     profile = state.profileReducer[group.profileId];
//   }
//   return {
//     x, top, bottom, right, left, length, group,
//     y, rotation, isEllipse,
//     groupMode, displayMode, profile, groupEditing, groupEditingId,
//     parentLamp,
//   };
// }

export default BaseLampZone;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import LampZoneDepiction from '../containers/LampZoneDepiction';
import {bindActionCreators} from 'redux';
import {LAMP_TYPE_MASTER} from '../constants/lampTypeTypes';
import LampZoneWrapper from '../containers/LampZoneWrapper';
import Zoom from './Zoom';
import * as actions from '../actions/lampActions';
import * as groupActions from '../actions/groupActions';
import * as profileActions from '../actions/profileActions';
import {isRoute} from '../utils/helper';
import {ROUTE_CONFIG} from '../constants/const';

const LampClickZone = ({group, currentRoute, x, y, rotation,
  groupActions, actions, profileActions,
  groupEditing, groupEditingId, lampType, lamp, id, ...rest}) => {
    if (!isRoute(currentRoute, ROUTE_CONFIG)) {
      return null;
    }
    const {setGroupProps} = groupActions;
    const {lampZoneClick} = actions;
    const {setProfileProps} = profileActions;

    let zoneClassName = 'pointer';
    let onClick;
    let onMouseOver = null;
    let onMouseOut = null;

    if (group === undefined) {
      zoneClassName = 'pointer-copy';
      onClick = () => {
        lampZoneClick(id);
      };
    }else {
      onClick = () => {
        lampZoneClick(id, group.id);
      };

      onMouseOver = () => {
        if (group !== undefined) {
          setGroupProps(group.id, {hover: true});
          setProfileProps(group.profileId, {singleHover: true});
        }
      };
      onMouseOut = () => {
        if (group !== undefined) {
          setGroupProps(group.id, {hover: false});
          setProfileProps(group.profileId, {singleHover: false});
        }
      };

      if (groupEditing === true && group.id !== groupEditingId) {
        zoneClassName = 'pointer-copy';
      }
    }

    const extra = {
      onClick,
      onMouseOver,
      onMouseOut,
      className: `click-zone ${zoneClassName}`,
    };

    if (lampType.relationType === LAMP_TYPE_MASTER && lamp.lampIds.length > 0) {
      return (
        <Zoom>
          <g transform={`translate(${x},${y}),rotate(${rotation})`} {...extra}>
            {lamp.lampIds.map(id => <LampZoneWrapper className="click-zone" key={id} id={id} color="transparent" />)}
          </g>
        </Zoom>
      );
    }

    return (
      <Zoom>
        <LampZoneDepiction className={zoneClassName} {...extra} {...rest} lamp={lamp} lampType={lampType} color="transparent" />
      </Zoom>
    );
};

LampClickZone.propTypes = {
  id: PropTypes.string.isRequired,
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  group: PropTypes.object,
  currentRoute: PropTypes.string.isRequired,
  profile: PropTypes.object,
  groupEditing: PropTypes.bool.isRequired,
  groupEditingId: PropTypes.string,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  groupActions: PropTypes.object.isRequired,
  profileActions: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

LampClickZone.defaultProps = {
  y: 0,
  rotation: 0,
};

function mapStateToProps(state, {id}) {
  const {lampReducer, lampTypeReducer, groupReducer, routingReducer} = state;
  const {currentRoute} = routingReducer;
  const {groupEditing, editingId: groupEditingId, lampGroupMap} = groupReducer;
  const lamp = lampReducer[id];
  const {typeId, x, y, rotation} = lamp;
  const lampType = lampTypeReducer[typeId];
  const groupId = lampGroupMap[id];
  const group = groupReducer[groupId];
  return {
    lamp,
    lampType,
    group,
    currentRoute,
    groupEditing,
    groupEditingId,
    x,
    y,
    rotation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    groupActions: bindActionCreators(groupActions, dispatch),
    profileActions: bindActionCreators(profileActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LampClickZone);

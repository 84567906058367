import {apiAction} from './helper';

export const GET_USERS = apiAction('GET_USERS');
export const CREATE_USER = apiAction('CREATE_USER');
export const UPDATE_USER = apiAction('UPDATE_USER');
export const DELETE_USER_BY_ID = apiAction('DELETE_USER_BY_ID');
export const SET_USER_REDUCER_PROPS = 'SET_USER_REDUCER_PROPS';
export const SAVE_USER = 'SAVE_USER';
export const GET_USER_CLIENT_ROLES = apiAction('GET_USER_CLIENT_ROLES');
export const SET_EDIT_USER_PROP = 'SET_EDIT_USER_PROP';
export const START_EDIT_USER = 'START_EDIT_USER';
export const GET_USER_BUILDING_ROLES = apiAction('GET_USER_BUILDING_ROLES');

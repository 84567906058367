import {SET_SHOW_LINE_CONTROL, SET_LINE_PROPS, SELECT_LINE, COPY_LINES, CLICK_LINE, DRAG_LINE,
  CREATE_LINE, DELETE_LINES, ADD_LAMP_TO_LINE, REMOVE_LAMP_FROM_LINE, SPREAD_LINE_LAMPS_EVENLY,
  ALIGN_SELECTED_LINES,
SET_LINE_DRAG, CHANGE_SELECT_ALL_LINES} from '../constants/lineActionTypes';

export function setShowLineControl(showLineControl) {
  return {
    type: SET_SHOW_LINE_CONTROL,
    showLineControl,
  };
}

export function setLineDrag(canDragLine) {
  return {
    type: SET_LINE_DRAG,
    canDragLine,
  };
}

export function setLineProps(id, obj) {
  return {
    type: SET_LINE_PROPS,
    id,
    obj,
  };
}

export function selectLine(selectedLineId) {
  return {
    type: SELECT_LINE,
    selectedLineId,
  };
}

export function createLine() {
  return {
    type: CREATE_LINE,
  };
}

export function addLampToLine(lineId) {
  return {
    type: ADD_LAMP_TO_LINE,
    lineId,
  };
}

export function deleteLines(lineIds) {
  return {
    type: DELETE_LINES,
    lineIds,
  };
}

export function removeLampFromLine(lineId, lampId) {
  return {
    type: REMOVE_LAMP_FROM_LINE,
    lineId,
    lampId,
  };
}

export function spreadLineLampsEvenly(lineId) {
  return {
    type: SPREAD_LINE_LAMPS_EVENLY,
    lineId,
  };
}

export function copyLines(lineIds) {
  return {
    type: COPY_LINES,
    lineIds,
  };
}

export function clickLine(lineId) {
  return {
    type: CLICK_LINE,
    lineId,
  };
}

export function dragLine(lineId, deltaX, deltaY) {
  return {
    type: DRAG_LINE,
    lineId,
    deltaX,
    deltaY,
  };
}

export function changeSelectAllLines(selected) {
  return {
    type: CHANGE_SELECT_ALL_LINES,
    selected,
  };
}

export function alignSelectedLines() {
  return {
    type: ALIGN_SELECTED_LINES,
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/buildingActions';

const ModeBtn = ({to, pathName, children, actions, ...props}) => {
  const regex = new RegExp('^' + to);
  const {setBuildingRoute} = actions;
  // <span className={`nav-btn ${regex.test(pathName) ? 'active':''}`}>
  return (
      <span to={to} {...props} className={`nav-btn ${regex.test(pathName) ? 'active':''}`}
        onClick={() => setBuildingRoute(to)}>
        {children}
      </span>
  );
};

ModeBtn.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  pathName: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    pathName: state.router.location.pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModeBtn);

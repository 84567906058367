import {SET_SCENARIOS, CREATE_SCENARIO_API, UPDATE_SCENARIO, DELETE_SCENARIO, SET_SCENARIO_REDUCER_PROPS,
SET_SCENARIO_PROPS, SAVE_SCENARIO_API} from '../constants/scenarioActionTypes';
import objectAssign from 'object-assign';
import {statusHandler} from '../utils/reducerHelper';
import {DONE, INITIAL} from '../constants/customTypes';

const initialState = {
  saveScenarioStatus: INITIAL,
};

const statusHandlerFunc = statusHandler([
  {
    apiAction: SAVE_SCENARIO_API,
    statusPropName: 'saveScenarioStatus',
  },
]);

export default function scenarioReducer(preState = initialState, action) {
  const state = statusHandlerFunc(preState, action);
  switch (action.type) {
    case SET_SCENARIOS:{
      const {scenarios} = action;
      return objectAssign({}, state, scenarios);
    }
    case SET_SCENARIO_REDUCER_PROPS:{
      return objectAssign({}, state, action.obj);
    }
    case UPDATE_SCENARIO:{
      const {scenario} = action;
      const newState = objectAssign({}, state);
      newState[scenario.id] = objectAssign({}, state[scenario.id], scenario, {
        needsSave: true,
      });
      return newState;
    }
    case CREATE_SCENARIO_API.SUCCESS:{
      const {scenario} = action;
      const newState = objectAssign({}, state);
      newState[scenario.id] = objectAssign({}, state[scenario.id], scenario);
      return newState;
    }
    case DELETE_SCENARIO.SUCCESS:{
      const {id} = action.props;
      const newState = objectAssign({}, state, {deleteScenarioId: undefined});
      delete newState[id];
      return newState;
    }
    case SET_SCENARIO_PROPS:{
      const {id, obj} = action;
      const newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[id], obj);
      return newState;
    }
    case SAVE_SCENARIO_API.SUCCESS:{
      const {scenarioId} = action.props;
      const {scenario} = action.response;
      const newState = objectAssign({}, state, {saveScenarioStatus: DONE});
      newState[scenarioId] = objectAssign({}, state[scenarioId], {
        updated: scenario.updated,
        needsSave: false,
      });
      return newState;
    }

    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../actions/buildingActions';
import {bindActionCreators} from 'redux';
import '../styles/rotation-control.scss';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const RotationControl = ({ actions, activeBuildingId, rotation, translate }) => {
  if (!activeBuildingId) {
    return null;
  }

  const { setBuildingRotation } = actions;
  return (
    <span className="rotation-control">
    <span className="btn-group">
      <ActionButton action={() => setBuildingRotation(activeBuildingId, rotation - 90)}
        extraClass="btn-default" icon="fa-undo"
        title={translate('global_rotate_left')} />
      <ActionButton action={() => setBuildingRotation(activeBuildingId, rotation + 90)}
        extraClass="btn-default" icon="fa-repeat"
        title={translate('global_rotate_right')} />
    </span>
  </span>
  );
};

RotationControl.propTypes = {
  rotation: PropTypes.number,
  actions: PropTypes.object.isRequired,
  activeBuildingId: PropTypes.number,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {buildingReducer} = state;
  const {activeBuildingId} = buildingReducer;
  const {rotation} = state.buildingReducer[activeBuildingId];
  const translate = getTranslate(state.localeReducer);
  return {
    activeBuildingId,
    rotation,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RotationControl);

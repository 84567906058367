import React, {Fragment} from 'react';
import PlanPageWrapper from './PlanPageWrapper';
import ReportsOverview from './ReportsOverview';
import ReportHistoryOverview from './ReportHistoryOverview';

const PlanReportsPage = () => {
  return (
    <Fragment>
      <ReportsOverview />
      <ReportHistoryOverview />
    </Fragment>
  );
};

export default PlanPageWrapper(PlanReportsPage, 'page_title_plan_reports');

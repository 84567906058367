import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import * as actions from '../actions/userActions';

const UserItem = ({user, actions}) => {
  const {setUserReducerProps} = actions;
  const {email, firstname, lastname, id, username} = user;
  let identifier = '';
  if (CLOUD) {
    identifier = email;
  }else if(LOCAL) {
    identifier = username;
  }

  return (
    <tr>
      <th>{lastname}</th>
      <th>{firstname}</th>
      <td>{identifier}</td>
      <td>
        <Link className="btn btn-primary" to={`/user/${id}`}>
          Edit
        </Link>
      </td>
      <td>
        <span className="btn btn-danger" onClick={() => setUserReducerProps({deleteUserId: id})}>
          Delete
        </span>
      </td>
    </tr>
  );
};

UserItem.propTypes = {
  id: PropTypes.number.isRequired,
  user: PropTypes.object,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    user: state.userReducer[props.id],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserItem);

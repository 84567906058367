import objectAssign from 'object-assign';
import {CLICK_LAMP, LAMP_ZONE_CLICK, CHANGE_SELECT_ALL_LAMPS, DELETE_SUB_LAMP,
  DRAG_LAMP, DRAG_HITZONE, LOCATE_LAMP, REMOVE_LAMP_LINK,
  CREATE_STANDALONE_LAMP, DELETE_SELECTED_STANDALONE_LAMPS,
  ALIGN_SELECTED_STANDALONE_LAMPS, COPY_SELECTED_STANDALONE_LAMPS,
  SET_LAMP_TYPE, SET_LAMP_PROPS, CHANGE_SELECT_LAMPS,
ADD_LAMP_TO_LAMP, ADD_SELECTED_LAMPS_PROPS} from '../constants/lampActionTypes';
import {setLampProps, setLampMacEntry, deleteLampMacEntry, changeSelectLamps,
  createLamp, setSelectedLampIds, changeSelectAllLamps,
addSelectedLampsProps, deleteLamp} from '../actions/lampActions';
import {setLampDialogMac, setLinkingSuccess, pollNodes, wink} from '../actions/nodeActions';
import {setGroupEditing, setGroupLamps, createGroup, setDoubleGroupLamps, setLampGroupMapEntry} from '../actions/groupActions';
import {setBuildingProps} from '../actions/buildingActions';
import {setLineProps} from '../actions/lineActions';
import { SELECT_LAMPS,
DRAG_LEFT, DRAG_TOP, DRAG_BOTTOM, LAMP_CLICK_MODE_DIALOG, LAMP_CLICK_MODE_WINK} from '../constants/customTypes';
import {getDeltaXDeltaYNormalized, removeItemsFromArray, removeSingleItemFromArray, isRoute} from '../utils/helper';
import {makeUid} from '../utils/idHelper';
import {ROUTE_DRAWING, ROUTE_TECHNICAL} from '../constants/const';

export const lampMiddleware = store => next => action => {
  const {dispatch} = store;
  const {type} = action;
  if (type === CLICK_LAMP) {
    const state = store.getState();
    const {nodeReducer, lampReducer, planReducer, routingReducer} = state;
    const {linking, linkingId} = nodeReducer;
    const {lampClickMode} = planReducer;
    const {currentRoute} = routingReducer;
    const {dragLamps} = lampReducer;
    const lamp = lampReducer[action.id];
    const macMap = lampReducer.lampMacMap;
    let prevMac = lamp.mac;
    if (lamp.parentId !== undefined) {
      prevMac = lampReducer[lamp.parentId].mac;
    }

    if (dragLamps === true) {
      return;
    }

    if (linking === true) {
      if (prevMac) {
        dispatch(setLampProps(macMap[prevMac], {mac: ''}));
        dispatch(deleteLampMacEntry(prevMac));
      }
      if (macMap[linkingId] !== undefined) {
        dispatch(setLampProps(macMap[linkingId], {mac: ''}));
      }
      dispatch(setLampProps(action.id, {mac: linkingId}));
      dispatch(setLinkingSuccess());
      dispatch(setLampMacEntry(linkingId, action.id));
      return;
    }

    if (state.planReducer.mode === SELECT_LAMPS) {

      dispatch(changeSelectLamps([action.id], !lamp.selected));

    }else if(isRoute(currentRoute, ROUTE_TECHNICAL) && prevMac.length > 0) {
      if (LAMP_CLICK_MODE_DIALOG === lampClickMode) {
        dispatch(setLampDialogMac(prevMac));
        dispatch(pollNodes([prevMac]));
      }else if(LAMP_CLICK_MODE_WINK === lampClickMode) {
        dispatch(wink(prevMac));
      }
    }else if(isRoute(currentRoute, ROUTE_DRAWING)) {
      dispatch(setLampProps(action.id, {selected: !lamp.selected}));
    }

  }else if(type === LAMP_ZONE_CLICK) {
    const state = store.getState();
    const {groupId: oldGroupId} = action;
    const {lampReducer, groupReducer, planReducer, buildingReducer, scenarioReducer} = state;
    const {dragStartDate} = planReducer;
    if ((new Date() - dragStartDate) > 300) {
      return;
    }
    const lamp = lampReducer[action.id];
    const groupEditingId = groupReducer.editingId;
    const oldGroup = groupReducer[oldGroupId];
    const editingGroup = groupReducer[groupEditingId];
    let lampIds = [];
    let {id} = action;
    if (lamp.lampIds && lamp.lampIds.length > 0) {
      lampIds = lamp.lampIds;
    }else {
      lampIds.push(lamp.id);
    }
    if (groupReducer.groupEditing === false) {
      if (oldGroupId !== undefined) {
        dispatch(setGroupEditing(oldGroupId, true));
      }else {
        const {activeBuildingId} = buildingReducer;
        const {selectedScenarioId} = buildingReducer[activeBuildingId];
        const {lastGroupId} = scenarioReducer[selectedScenarioId];
        const newGroupId = lastGroupId + 1;
        const newGroupIdStr = `${selectedScenarioId}_${newGroupId}`;
        dispatch(createGroup(newGroupIdStr, lampIds));
        dispatch(setLampGroupMapEntry(id, newGroupIdStr));
      }
    }else {
      let editingLampIds = [].concat(editingGroup.lampIds);
      let newGroupId = undefined;
      const index = editingLampIds.indexOf(lampIds[0]);
      if (index > -1) {
        editingLampIds = editingLampIds.filter(id => {
          return lampIds.indexOf(id) < 0;
        });
        dispatch(setGroupLamps(editingGroup.id, editingLampIds));
        dispatch(setLampGroupMapEntry(id, newGroupId));
      }else {
        editingLampIds = editingLampIds.concat(lampIds);
        newGroupId = groupEditingId;
        if (oldGroupId !== undefined) {
          let oldGroupLampIds = [].concat(oldGroup.lampIds);
          oldGroupLampIds = oldGroupLampIds.filter((id) => {
            return lampIds.indexOf(id) < 0;
          });
          // const groupIndex = oldGroupLamps.indexOf(lamp.id);
          // oldGroupLamps.splice(groupIndex, 1);
          dispatch(setDoubleGroupLamps(editingGroup.id, editingLampIds, oldGroupId, oldGroupLampIds));

        }else {
          dispatch(setGroupLamps(editingGroup.id, editingLampIds));
        }
        dispatch(setLampGroupMapEntry(id, newGroupId));
      }
    }
  }else if(type === CHANGE_SELECT_ALL_LAMPS) {
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {allLampIds} = buildingReducer[activeBuildingId];
    action.ids = allLampIds;
    dispatch(setSelectedLampIds([]));
    return next(action);
  }else if(type === ADD_LAMP_TO_LAMP) {
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lastLampId, allLampIds} = buildingReducer[activeBuildingId];
    let newLastLampId = lastLampId;
    action.newId = makeUid(activeBuildingId, ++newLastLampId);

    next(action);

    dispatch(setBuildingProps(activeBuildingId, {
      lastLampId: newLastLampId,
      allLampIds: allLampIds.concat([action.newId]),
    }));

  }else if(type === CREATE_STANDALONE_LAMP) {

    const {buildingReducer, lampTypeReducer} = store.getState();
    const {lampTypeAloneIds} = lampTypeReducer;
    const {activeBuildingId} = buildingReducer;
    const {lastLampId, lampIds, allLampIds} = buildingReducer[activeBuildingId];
    let lastLampIdCp = lastLampId;
    const lampId = makeUid(activeBuildingId, ++lastLampIdCp);
    dispatch(createLamp(lampId, lampTypeAloneIds[0], {
      x: 0,
      y: 0,
      selected: true,
      rotation: 0,
    }));

    dispatch(changeSelectAllLamps(false));
    dispatch(setSelectedLampIds([lampId]));

    dispatch(setBuildingProps(activeBuildingId, {
      lastLampId: lastLampIdCp,
      lampIds: lampIds.concat([lampId]),
      allLampIds: allLampIds.concat([lampId]),
    }));

  }else if(type === DELETE_SUB_LAMP) {
    const {lineId, subLampId, lampId} = action;
    const {lineReducer, buildingReducer, lampReducer} = store.getState();

    const {activeBuildingId} = buildingReducer;
    const {subLampIds: buildingSubLampIds, allLampIds} = buildingReducer[activeBuildingId];

    const parentLamp = lampReducer[lampId];
    const lampIdsCp = parentLamp.lampIds.concat([]);
    lampIdsCp.splice(lampIdsCp.indexOf(subLampId), 1);

    dispatch(setLampProps(lampId, {lampIds: lampIdsCp}));
    dispatch(deleteLamp(subLampId));

    dispatch(setBuildingProps(activeBuildingId, {
      subLampIds: removeSingleItemFromArray(buildingSubLampIds, subLampId),
      allLampIds: removeSingleItemFromArray(allLampIds, subLampId),
    }));
    if (lineId !== undefined) {
      const line = lineReducer[lineId];
      dispatch(setLineProps(lineId, {subLampIds: removeSingleItemFromArray(line.subLampIds, subLampId)}));
    }
    return next(action);

  }else if(type === ADD_SELECTED_LAMPS_PROPS) {
    const {buildingReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {allLampIds} = buildingReducer[activeBuildingId];

    action.lampIds = allLampIds;

    return next(action);

  }else if(type === DRAG_LAMP) {
    const {planReducer, buildingReducer, lampReducer, lineReducer} = store.getState();
    const {id, deltaX, deltaY} = action;
    const {xMap, yMap} = planReducer;
    const {activeBuildingId} = buildingReducer;
    const {zoomK, rotation : buildingRotation, allLampIds} = buildingReducer[activeBuildingId];
    const lamp = lampReducer[id];
    let totalRotation = buildingRotation;
    if (lamp.lineId !== undefined) {
      const line = lineReducer[lamp.lineId];
      totalRotation += line.rotation;
    }else if(lamp.parentId !== undefined) {
      const parentLamp = lampReducer[lamp.parentId];
      if (parentLamp.lineId !== undefined) {
        const line = lineReducer[parentLamp.lineId];
        totalRotation += line.rotation;
      }
    }

    const {nDeltaX, nDeltaY} = getDeltaXDeltaYNormalized(deltaX, deltaY, xMap, yMap, zoomK, totalRotation);
    const selectedLampIds = allLampIds.filter(lampId => {
      const lamp = lampReducer[lampId];
      return lamp.selected;
    });

    if (selectedLampIds.indexOf(id) === -1) {
      selectedLampIds.push(id);
    }

    action.lampIds = selectedLampIds;
    action.deltaX = nDeltaX;
    action.deltaY = nDeltaY;
    return next(action);

  }else if(type === DRAG_HITZONE) {
    const {planReducer, buildingReducer, lampReducer, lineReducer} = store.getState();
    const {id, deltaX, deltaY, dragType} = action;
    const {xMap, yMap} = planReducer;
    const {activeBuildingId} = buildingReducer;
    const {zoomK, rotation: buildingRotation} = buildingReducer[activeBuildingId];
    const lamp = lampReducer[id];

    let totalRotation = buildingRotation;
    if (lamp.lineId !== undefined) {
      const line = lineReducer[lamp.lineId];
      totalRotation += line.rotation;
    }else if(lamp.parentId !== undefined) {
      const parentLamp = lampReducer[lamp.parentId];
      totalRotation += lamp.rotation;
      if (parentLamp.lineId !== undefined) {
        const line = lineReducer[parentLamp.lineId];
        totalRotation += line.rotation;
      }
    }else if(lamp.rotation) {
      totalRotation += lamp.rotation;
    }

    const {nDeltaX, nDeltaY} = getDeltaXDeltaYNormalized(deltaX, deltaY, xMap, yMap, zoomK, totalRotation);

    if (dragType === DRAG_TOP) {
      dispatch(addSelectedLampsProps(id, {top: -nDeltaY}));

    }else if(dragType === DRAG_BOTTOM) {
      dispatch(addSelectedLampsProps(id, {bottom: nDeltaY}));

    }else if(dragType === DRAG_LEFT) {
      dispatch(addSelectedLampsProps(id, {left: -nDeltaX}));

    }else {
      dispatch(addSelectedLampsProps(id, {right: nDeltaX}));

    }
  }else if(type === SET_LAMP_TYPE) {
    const {lampTypeId} = action;
    const {lampTypeReducer} = store.getState();
    action.lampType = lampTypeReducer[lampTypeId];
    return next(action);

  }else if(type === LOCATE_LAMP) {
    const {id} = action;
    const cb = locateCbs[id];
    if (cb) {
      cb();
    }
    return next(action);
  }else if(type === REMOVE_LAMP_LINK) {
    const {mac} = action;
    const {lampReducer} = store.getState();
    const {lampMacMap} = lampReducer;
    const id = lampMacMap[mac];

    dispatch(setLampProps(id, {mac: ''}));
    dispatch(deleteLampMacEntry(mac));
  }else if(type === DELETE_SELECTED_STANDALONE_LAMPS) {
    const {buildingReducer, lampReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lampIds, allLampIds} = buildingReducer[activeBuildingId];
    const lampIdsCp = [].concat(lampIds);

    let index = lampIdsCp.length;
    const toDeleteIds = [];
    while (index--) {
      const lampId = lampIdsCp[index];
      const lamp = lampReducer[lampId];
      if (lamp.selected === true) {
        toDeleteIds.push(lampId);
        lampIdsCp.splice(index, 1);
      }
    }
    const newAllLampIds = removeItemsFromArray(allLampIds, toDeleteIds);

    dispatch(setBuildingProps(activeBuildingId, {lampIds: lampIdsCp, allLampIds: newAllLampIds}));
    toDeleteIds.forEach(id => {
      dispatch(deleteLamp(id));
    });
  }else if(type === ALIGN_SELECTED_STANDALONE_LAMPS) {
    const {buildingReducer, lampReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lampIds} = buildingReducer[activeBuildingId];

    const selectedLamps = [];
    const addSelectedLamps = lampId => {
      const {id, x, y, lampIds, selected} = lampReducer[lampId];
      if (selected) {
        selectedLamps.push({
          id,
          x,
          y,
        });
      }
      if (lampIds) {
        lampIds.forEach(subLampId => {
          const subLamp = lampReducer[subLampId];
          if (subLamp.selected) {
            selectedLamps.push({
              id: subLampId,
              x: x + subLamp.x,
              y: y + subLamp.y,
              masterX: x,
              masterY: y,
              isSub: true,
            });
          }
        });
      }
    };

    lampIds.forEach(lampId => addSelectedLamps(lampId));

    if (selectedLamps.length < 2) {
      return;
    }
    const lamp1 = selectedLamps[0];
    const lamp2 = selectedLamps[1];
    const diffX = Math.abs(lamp1.x - lamp2.x);
    const diffY = Math.abs(lamp1.y - lamp2.y);
    let sum = 0;
    selectedLamps.forEach(lamp => {
      if (diffX < diffY) {
        sum += lamp.x;
      }else {
        sum += lamp.y;
      }
    });

    const avg = Math.round(sum / selectedLamps.length * 100) / 100;
    action.selectedLamps = selectedLamps;
    action.avg = avg;
    action.prop = diffX < diffY ? 'x':'y';

    return next(action);

  }else if(type === COPY_SELECTED_STANDALONE_LAMPS) {
    const {buildingReducer, lampReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lampIds, lastLampId, allLampIds} = buildingReducer[activeBuildingId];
    const selectedLamps = lampIds.map(lampId => lampReducer[lampId]).filter(lamp => lamp.selected);
    const selectedLampIds = [];
    let lastLampIdCp = lastLampId;

    const newLampsObj = {};
    const newLampIds = selectedLamps.map(lamp => {
      selectedLampIds.push(lamp.id);
      const newLamp = objectAssign({}, lamp);
      const newId = ++lastLampIdCp;
      newLamp.id = makeUid(activeBuildingId, newId);
      if (lamp.lampIds) {
        newLamp.lampIds = lamp.lampIds.map(subLampId => {
          selectedLampIds.push(subLampId);
          const subLamp = lampReducer[subLampId];
          const newSubLamp = objectAssign({}, subLamp);
          const newId = ++lastLampIdCp;
          newSubLamp.id = makeUid(activeBuildingId, newId);
          newLampsObj[newSubLamp.id] = newSubLamp;
          return newSubLamp.id;
        });
      }
      newLampsObj[newLamp.id] = newLamp;
      return newLamp.id;
    });
    action.lampsObj = newLampsObj;
    action.selectedLampIds = selectedLampIds;

    next(action);
    dispatch(setBuildingProps(activeBuildingId, {
      lastLampId: lastLampIdCp,
      lampIds: lampIds.concat(newLampIds),
      allLampIds: allLampIds.concat(newLampIds),
    }));
  }else if(type === SET_LAMP_PROPS) {
    const {id, obj} = action;
    const {buildingReducer, lampReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lampIds} = buildingReducer[activeBuildingId];
    const {selectedLampIds} = lampReducer;
    if (obj.selected !== undefined && lampIds.indexOf(id) > -1) {
      const selectedLampIdsCp = [].concat(selectedLampIds);
      if (obj.selected) {
        selectedLampIdsCp.push(id);
      }else {
        const index = selectedLampIds.indexOf(id);
        if (index > -1) {
          selectedLampIdsCp.splice(index, 1);
        }
      }
      dispatch(setSelectedLampIds(selectedLampIdsCp));
    }
    return next(action);
  }else if(type === CHANGE_SELECT_LAMPS) {
    const {ids, selected} = action;
    const {buildingReducer, lampReducer} = store.getState();
    const {activeBuildingId} = buildingReducer;
    const {lampIds} = buildingReducer[activeBuildingId];
    const {selectedLampIds} = lampReducer;
    const selectedLampIdsCp = [].concat(selectedLampIds);
    for (const id of ids) {
      if (lampIds.indexOf(id) > -1) {
        if (selected) {
          selectedLampIdsCp.push(id);
        }else {
          const index = selectedLampIdsCp.indexOf(id);
          selectedLampIdsCp.splice(index, 1);
        }
      }
    }
    dispatch(setSelectedLampIds(selectedLampIdsCp));
    return next(action);
  }else {
    return next(action);
  }
};

const locateCbs = {};
export const registerLocateCb = (id, cb) => {
  locateCbs[id] = cb;
};

export const unregisterLocateCb = (id) => {
  delete locateCbs[id];
};

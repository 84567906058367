import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import EditDataportForm from '../containers/EditDataportForm';

const EditDataportPage = ({match}) => {
  const {dataportId} = match.params;
  return (
    <div className="not-found-page">
      <Helmet>
        <title>Edit Dataport | TriVIEW</title>
      </Helmet>
      <div className="container">
        <h1>Dataport</h1>
        <EditDataportForm id={dataportId} />
      </div>
    </div>
  );
};

EditDataportPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EditDataportPage;

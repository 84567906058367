import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/dataportActions';
import FormInput from '../components/FormInput';

class EditDataportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {...props.dataport};
    this.save = this.save.bind(this);
  }

  save() {
    const {updateDataport} = this.props.actions;
    updateDataport(this.state);
  }

  render() {
    if (!this.props.dataport) {
      return null;
    }
    const {name} = this.state;

    return (
      <form onSubmit={(e) => { e.preventDefault(); this.save();}}>
        <FormInput id="name" type="text" label="Name" value={name}
          onChange={(value) => this.setState({name: value})} required />
        <input type="submit" className="btn btn-primary" value="Save" />
      </form>
    );
  }
}

EditDataportForm.propTypes = {
  id: PropTypes.string.isRequired,
  dataport: PropTypes.object,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    dataport: state.dataportReducer[props.id],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDataportForm);

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {connect} from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const ScenarioSelect = ({scenarioOptions, name, value, onChange, activeScenarioId, translate}) => {
  const customStyles = {
    option: (provided, state) => {
      if (state.value === activeScenarioId && !state.isSelected) {
        return {
          ...provided,
          color: 'green',
        };
      }
      return {...provided};
    },
    singleValue: (provided, state) => {
      if (state.data.value === activeScenarioId) {
        return {
          ...provided,
          color: 'green',
        };
      }
      return {...provided};
    },
    menu: provided => ({...provided, zIndex: 3}),
  };

  const formatOptionLabel = (option) => {
    if (option.value === activeScenarioId) {
      return `${option.label} (${translate('scenario_active')})`;
    }
    return option.label;
  };

  return (
    <Select
      getOptionLabel={formatOptionLabel}
      isClearable={false}
      isSearchable={true}
      onChange={onChange}
      name={name}
      value={scenarioOptions.find(option => option.value === value)}
      options={scenarioOptions}
      styles={customStyles}
    />
  );
};

ScenarioSelect.propTypes = {
  name: PropTypes.string.isRequired,
  scenarioOptions: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  activeScenarioId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

function mapStateToProps({localeReducer}) {
  return {
    translate: getTranslate(localeReducer),
  };
}

export default connect(
  mapStateToProps,
  null,
)(ScenarioSelect);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import '../styles/dialog.scss';
import onClickOutside from 'react-onclickoutside';

class Dialog extends Component {
  componentDidMount() {
    document.body.classList.add('modal-open');
  }
  componentWillUnmount() {
    document.body.classList.remove('modal-open');
  }
  handleClickOutside() {
    this.props.closeAction();
  }
  render() {
    const {title, body, closeAction, footer, closeLabel} = this.props;
    return (
      <div className="dialog">
        <div className="header">
          {title}
        </div>
        <div className="body container-fluid">
          {body}
        </div>
        <div className="footer">
          <button type="button" className="btn btn-default"
            onClick={() => closeAction()}>
            {closeLabel}
          </button>
          {footer}
        </div>
      </div>
    );
  }
}
Dialog.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  closeAction: PropTypes.func.isRequired,
  footer: PropTypes.node,
  closeLabel : PropTypes.string.isRequired
};
export default onClickOutside(Dialog);

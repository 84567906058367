import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/lampTypeActions';
import LampTypeItem from '../containers/LampTypeItem';
import ConfirmDialog from '../containers/ConfirmDialog';

const LampTypesOverview = ({lampTypesFetched, lampTypeIds, actions}) => {
  const {deleteLampType, getLampTypes} = actions;
  const [deleteId, setDeleteId] = useState(undefined);
  useEffect(() => {
    if (!lampTypesFetched) {
      getLampTypes();
    }
  }, []);
  if (!lampTypesFetched) {
    return null;
  }
  const deleteAction = () => {
    deleteLampType(deleteId);
    setDeleteId(undefined);
  };

  return (
    <div className="lamp-types-overview">
      <ConfirmDialog show={deleteId !== undefined ? true:false} closeAction={() => setDeleteId(undefined)}
        confirmAction={() => deleteAction()} text="Deleting a lamp type is dangerous: all the building configurations that use this lampType will be broken. Are you sure you want to delete this lampType?" title="Delete Lamp Type" />
      <table className="table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Label</th>
            <th>DepictionType</th>
            <th>RelationType</th>
            <th>Locations</th>
            <th/>
            <th/>
          </tr>
        </thead>
        <tbody>
          {lampTypeIds.map(id => {
            return <LampTypeItem key={id} id={id} deleteLampType={() => setDeleteId(id)}/>;
          })}
        </tbody>
      </table>
    </div>
  );
};

LampTypesOverview.propTypes = {
  lampTypeIds: PropTypes.array,
  actions: PropTypes.object.isRequired,
  lampTypesFetched: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {lampTypeIds, lampTypesFetched} = state.lampTypeReducer;
  return {
    lampTypeIds,
    lampTypesFetched,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LampTypesOverview);

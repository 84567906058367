import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../actions/profileActions';
import * as groupActions from '../actions/groupActions';
import * as scenarioActions from '../actions/scenarioActions';
import {bindActionCreators} from 'redux';
import ProfileGraph from '../components/ProfileGraph';
import {PROFILE_COLORS, DARKLIGHT_PROFILE_COLORS, HIGHLIGHT_PROFILE_COLORS } from '../constants/colorConstants';
import '../styles/profile-item.scss';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const ProfileItem = ({ profile, browserWidth, active, groupEditing, defaultProfileId,
  selectedScenarioId, actions, groupActions, translate,
  scenarioActions}) => {
  if (profile === undefined) {
    return null;
  }
  const {setEditingGroupProfile} = groupActions;
  const colorRest = profile.color_number % 8;
  let backgroundColor;
  let selectEl = null;
  if (groupEditing === true) {
    selectEl = (
      <ActionButton action={() => { setEditingGroupProfile(profile.id); setProfileProps(profile.id, {hover: false}); }}
        extraClass={"btn-default " + (active === true ? 'active':'')}
        icon="fa-bullseye"
        title={translate('profile_select')} />
    );
  }
  if (active === true) {
    backgroundColor = DARKLIGHT_PROFILE_COLORS[colorRest];
  }else if(profile.hover === true || profile.singleHover === true) {
    backgroundColor = HIGHLIGHT_PROFILE_COLORS[colorRest];
  }else {
    backgroundColor = PROFILE_COLORS[colorRest];
  }
  const {deleteProfile, setProfileEditing, setProfileProps} = actions;
  const {setScenarioProps} = scenarioActions;
  const { name } = profile;

  return (
    <div className="profile-item" style={{backgroundColor, border: `2px solid ${backgroundColor}`}}
      onMouseOver={() => setProfileProps(profile.id, {hover: true})}
      onMouseOut={() => setProfileProps(profile.id, {hover: false})}>
      <span className="header">
        <span className="title">
          {name}
        </span>
        <span className="btn-group">
          {selectEl}
          <ActionButton action={() => setScenarioProps(selectedScenarioId, {defaultProfileId: profile.id})}
            extraClass={"btn-default" + (defaultProfileId === profile.id ? ' active':'')}
            icon="fa-bookmark"
            title={translate('profile_default_remark')} />
          <ActionButton action={() => setProfileEditing(profile.id)}
            extraClass={"btn-default"}
            icon="fa-pencil"
            title={translate('profile_edit')} />
          <ActionButton action={() => deleteProfile(profile.id)}
            extraClass={"btn-default"}
            icon="fa-times"
            title={translate('profile_delete')} />
        </span>
      </span>
      <div className="graph-box">
        <ProfileGraph profile={profile} browserWidth={browserWidth}/>
      </div>
    </div>
  );
};

ProfileItem.propTypes = {
  id: PropTypes.string.isRequired,
  profile: PropTypes.object,
  browserWidth: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  groupEditing: PropTypes.bool.isRequired,
  defaultProfileId: PropTypes.string.isRequired,
  selectedScenarioId: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  groupActions: PropTypes.object.isRequired,
  scenarioActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
};

ProfileItem.defaultProps = {
  showEnergyMeasurementBtn : false
};

function mapStateToProps(state, props) {
  const {profileReducer, groupReducer, generalReducer, buildingReducer,
  scenarioReducer} = state;
  const profile = profileReducer[props.id];
  const {browserWidth} = generalReducer;
  const {editingId, groupEditing} = groupReducer;
  let active = false;
  if (groupEditing === true) {
    const activeProfile = groupReducer[editingId].profileId;
    if (props.id === activeProfile) {
      active = true;
    }
  }
  const {activeBuildingId} = buildingReducer;
  const {selectedScenarioId} = buildingReducer[activeBuildingId];
  const {defaultProfileId} = scenarioReducer[selectedScenarioId];
  const translate = getTranslate(state.localeReducer);

  return {
    selectedScenarioId,
    defaultProfileId,
    groupEditing,
    active,
    profile,
    browserWidth,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    groupActions: bindActionCreators(groupActions, dispatch),
    scenarioActions: bindActionCreators(scenarioActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileItem);

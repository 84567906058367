import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as nodeActions from '../actions/nodeActions';
import * as apActions from '../actions/apActions';
import '../styles/ps-commands.scss';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';

const PsCommands = ({mac, node, power, nodeActions, translate, ap, apActions}) => {
  const {pingNodes, pollNodes, resetNode, setVariablesValueApi, forceReadConfig} = nodeActions;

  const {setCommissioning, setApEnabled} = apActions;
  let pingFails, registrationMode, enabled, forceReadConfigStatus;
  if (node !== undefined) {
    pingFails = node.pingFails;
    forceReadConfigStatus = node.forceReadConfigStatus;
  }
  if (ap !== undefined) {
    registrationMode = ap.registration_mode;
    enabled = ap.enabled;
  }
  let nodePingFails = null;
  if (pingFails > 0) {
    nodePingFails = (
      <span className="fails">{node.pingFails}</span>
    );
  }
  return (
    <span className="ps-commands">
      <ActionButton action={() => setVariablesValueApi([mac], 'Power' , 'nviOutput', power === 1 ? 0:1)}
        extraClass={(power === 1 ? "btn-success":"btn-danger")}
        icon="fa-power-off"
        text={translate('technical_switch_power')} />
      <ActionButton action={() => pingNodes([mac])}
        status={node.pingStatus} icon="fa-podcast"
        text={translate('technical_ping')} />{nodePingFails}
      <ActionButton action={() => pollNodes([mac])}
        status={node.pollStatus} icon="fa-refresh"
        text={translate('technical_poll')} />
      <ActionButton action={() => resetNode(mac)}
        status={node.resetStatus} icon="fa-undo"
        text={translate('technical_reset_ps')} />
      <ActionButton action={() => forceReadConfig(mac)} status={forceReadConfigStatus} icon="fa-refresh"
        text="Force read Config" />
      <ActionButton action={() => setCommissioning(mac, !registrationMode)}
        extraClass={(registrationMode === true ? 'btn-danger':'btn-default')}
        icon="fa-link"
        text={translate('technical_commission')} />
      <ActionButton action={() => setApEnabled(mac, !enabled)}
        extraClass={(enabled === true ? 'active':'')}
        text="Ap Active" />
    </span>
  );
};

PsCommands.propTypes = {
  mac: PropTypes.string,
  node: PropTypes.object,
  ap: PropTypes.object,
  apActions: PropTypes.object.isRequired,
  power: PropTypes.number,
  nodeActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state, {mac}) {
  const node = state.nodeReducer[mac];
  const ap = state.apReducer[mac];
  const powerVar = state.variableReducer[`${mac}/Power/nviOutput`];
  const translate = getTranslate(state.localeReducer);
  return {
    node,
    mac,
    ap,
    power: powerVar === undefined ? undefined:powerVar.value,
    translate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    apActions: bindActionCreators(apActions, dispatch),
    nodeActions: bindActionCreators(nodeActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PsCommands);

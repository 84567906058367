import {SET_EXTRA_VAR_TEMP_VALUE, INIT_EXTRA_VARS, SAVE_EXTRA_VAR_VALUE, SET_EXTRA_VAR_IS_EDITING,
CREATE_EXTRA_LAMP_VARS} from '../constants/extraVariableActionTypes';
import {CREATE_LAMP} from '../constants/lampActionTypes';
import objectAssign from 'object-assign';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {};

export default function extraVariableReducer(state = initialState, action) {

  switch (action.type) {
    case INIT_EXTRA_VARS:{
      const {varObj} = action;
      return objectAssign({}, state, varObj);
    }
    case SET_EXTRA_VAR_TEMP_VALUE:{
      const {name, id, value} = action;
      const path = `${id}/${name}`;
      let newState = objectAssign({}, state);

      newState[path] = objectAssign({}, state[path], {tValue: value});
      return newState;
    }
    case SAVE_EXTRA_VAR_VALUE:{
      const {name, id} = action;
      const path = `${id}/${name}`;
      let newState = objectAssign({}, state);
      const value = parseInt(state[path].tValue);
      newState[path] = objectAssign({}, state[path], {isEditing: false, value});
      return newState;
    }
    case SET_EXTRA_VAR_IS_EDITING:{
      const {name, id, isEditing} = action;
      const path = `${id}/${name}`;
      let newState = objectAssign({}, state);
      newState[path] = objectAssign({}, state[path], {isEditing});
      return newState;
    }
    case CREATE_LAMP:{
      const {id} = action;
      const path = `${id}/luxMax`;
      let newState = objectAssign({}, state);
      newState[path] = {
        value: 0,
        isEditing: false,
        tValue: "0",
      };
      return newState;
    }
    case CREATE_EXTRA_LAMP_VARS:{
      const {id} = action;
      const path = `${id}/luxMax`;
      let newState = objectAssign({}, state);
      newState[path] = {
        value: 0,
        isEditing: false,
        tValue: "0",
      };
      return newState;
    }

    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';
import * as actions from '../actions/dataportActions';

const DataportsCommandsRow = ({name, code, id}) => {
  return (
    <tr>
      <td>{name}</td>
      <td>{code}</td>
      <td><Link to={"/monitor/command/output/" + id}>output</Link></td>
    </tr>
  );
};

DataportsCommandsRow.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  code: PropTypes.number,
};

function mapStateToProps(state, {id}) {
  const {name, code} = state.dataportReducer[id];
  return {
    name,
    code,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataportsCommandsRow);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/planImgActions';
import '../styles/config-form.scss';
import ActionButton from '../components/ActionButton';

const SetPlanImgDefaultView = ({activeBuildingId, actions, planImgDefaultsSet}) => {
  const {setPlanImgDefaultView} = actions;
  if (activeBuildingId === undefined) {
    return null;
  }
  return (
    <span className="save-config">
      <ActionButton action={() => setPlanImgDefaultView()}
        extraClass={(planImgDefaultsSet ? 'btn-success':'btn-primary')}
        text="Set default view" />
    </span>
  );
};

SetPlanImgDefaultView.propTypes = {
  activeBuildingId: PropTypes.number,
  actions: PropTypes.object.isRequired,
  planImgDefaultsSet: PropTypes.bool.isRequired,
};

function mapStateToProps({buildingReducer, planImgReducer}) {
  const {activeBuildingId} = buildingReducer;
  const {planImgDefaultsSet} = planImgReducer;

  return {
    planImgDefaultsSet,
    activeBuildingId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetPlanImgDefaultView);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Dialog from '../components/DialogWrapper';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/nodeActions';
import LampCommands from './LampCommands';
import LampInfo from '../components/LampInfo';
import ExtraLampVars from '../components/ExtraLampVars';
import Variables from './Variables';
import { getTranslate } from 'react-localize-redux';
import '../styles/lamp-dialog.scss';

const LampDialog = ({ mac, node, actions, translate, pp, parentApIds }) => {
  if (!mac || (!node && !pp)) {
    return null;
  }
  let objects = [];
  let name;
  if (node) {
    name = node.name;
    if (node.objects !== undefined) {
      objects = node.objects;
    }
  }


  const { setLampDialogMac} = actions;

  const body = (
    <div className="row">
      <div className="col-md-12 commands">
        <LampCommands mac={mac} node={node} pp={pp} translate={translate} />
      </div>
      <div className="col-md-12">
        <LampInfo node={node} translate={translate} pp={pp} parentApIds={parentApIds} />
      </div>
      <span className="variables">
        <div className="col-md-6">
          <ExtraLampVars mac={mac} />
        </div>
        <Variables mac={mac} objects={objects} />
      </span>
    </div>
  );

  return (
    <span className="lamp-dialog">
      <Dialog title={`${mac}, ${name}`} visible={true} body={body} closeLabel={translate('global_close')}
        closeAction={() => setLampDialogMac(undefined)} />
    </span>
  );
};

LampDialog.propTypes = {
  mac: PropTypes.string,
  node: PropTypes.object,
  pp: PropTypes.object,
  actions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  parentApIds: PropTypes.array,
};

function mapStateToProps(state) {
  let node, pp, parentApIds;
  const {nodeReducer, ppReducer} = state;
  const {lampDialogMac: mac, ppDialogId: ppId} = nodeReducer;

  if (mac !== undefined) {
    const {idApsMap} = ppReducer;
    node = state.nodeReducer[mac];
    parentApIds = idApsMap[mac];
  }

  if (ppId !== undefined) {
    pp = ppReducer[ppId];
  }

  const translate = getTranslate(state.localeReducer);

  return {
    pp,
    mac,
    node,
    parentApIds,
    translate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LampDialog);

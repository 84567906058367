import {GET_CLIENTS, SET_ACTIVE_CLIENT, SET_BUILDING_IDS, SET_CLIENT_PROPS,
  SET_SHOW_SELECT_CLIENT_DIALOG, SET_CLIENT_ROUTE_SLUG,
  SET_CLIENT_REDUCER_PROPS, DELETE_CLIENT, UPDATE_CLIENT,
  CREATE_CLIENT, SET_SELECTED_CLIENT_ID,
} from '../constants/clientActionTypes';
import {GET_BUILDINGS, CREATE_BUILDING, DELETE_BUILDING} from '../constants/buildingActionTypes';
import objectAssign from 'object-assign';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {
  clientIds: [],
  activeClientKey: undefined,
  showSelectClientDialog: false,
  buildingClientId: undefined,
  clientLetters: [],
  clientOptions: [],
  selectedClientId: null,
};

function makeClientOptions(ids, obj) {
  return ids.map(id => ({value: id, label: obj[id].name}));
}

export default function clientReducer(state = initialState, action) {

  switch (action.type) {
    case GET_CLIENTS.SUCCESS:{
      const {ids, obj} = action;
      let clientLetters = [];
      const clientIds = [];
      let letterPos = {};
      ids.forEach((id) => {
        const {name} = obj[id];
        clientIds.push(id);
        const letter = name.substr(0, 1).toUpperCase();
        if (letterPos[letter] === undefined) {
          letterPos[letter] = clientLetters.length;
          clientLetters.push([letter, id]);

        }else {
          const index = letterPos[letter];
          clientLetters[index] = clientLetters[index].concat([id]);
        }
      });
      return objectAssign({}, state, {
        clientIds,
        clientLetters,
        clientOptions: makeClientOptions(ids, obj),
      }, action.obj);
    }

    case GET_BUILDINGS.SUCCESS:{
      const {response, clientId} = action;
      const newState = objectAssign({}, state);
      newState[clientId] = objectAssign({}, state[clientId], {
        buildingIds: response.map(({id}) => id),
      });
      return newState;
    }
    case CREATE_BUILDING.SUCCESS:{
      const {id} = action.response.configuration;
      const {clientId} = action.props;
      const newState = objectAssign({}, state);
      newState[clientId] = objectAssign({}, state[clientId], {
        buildingIds: state[clientId].buildingIds.concat([id]),
      });
      return newState;
    }
    case DELETE_BUILDING.SUCCESS:{
      const {clientId, id} = action.props;
      const newState = objectAssign({}, state);
      const buildingIds = [].concat(state[clientId].buildingIds);
      buildingIds.splice(buildingIds.indexOf(id), 1);
      newState[clientId] = objectAssign({}, state[clientId], {
        buildingIds,
      });
      return newState;
    }
    case SET_ACTIVE_CLIENT:{
      return objectAssign({}, state, {activeClientKey: action.id});
    }
    case SET_CLIENT_ROUTE_SLUG:
      return objectAssign({}, state, {routeSlug: action.slug});
    case SET_BUILDING_IDS:{
      let newState = objectAssign({}, state);
      newState[action.client] = objectAssign({}, state[action.client], {buildingIds: action.ids});
      return newState;
    }
    case SET_CLIENT_PROPS:{
      let newState = objectAssign({}, state);
      const {id, obj} = action;
      newState[id] = objectAssign({}, state[id], obj);
      return newState;
    }
    case SET_SHOW_SELECT_CLIENT_DIALOG:{
      const {show} = action;
      return objectAssign({}, state, {showSelectClientDialog: show});
    }
    case SET_CLIENT_REDUCER_PROPS:{
      return objectAssign({}, state, action.obj);
    }
    case DELETE_CLIENT.SUCCESS:{
      const {id} = action.props;
      const newState = objectAssign({}, state);
      if (state.activeClientKey === id) {
        newState.activeClientKey = undefined;
      }
      delete newState[id];
      newState.clientIds = state.clientIds.filter(clientId => clientId !== id);
      newState.deleteClientId = undefined;
      if (state.selectedClientId === id && newState.clientIds.length > 0) {
        newState.selectedClientId = newState.clientIds[0];
      }
      const clientLettersCp = [].concat(state.clientLetters);
      let removeIndex = -1;
      clientLettersCp.forEach((arr, i) => {
        const index = arr.indexOf(id);
        if (index > -1) {
          if (arr.length === 2) {
            removeIndex = i;
          }else {
            arr = [].concat(arr);
            arr.splice(index, 1);
          }
        }
      });
      if (removeIndex > -1) {
        clientLettersCp.splice(removeIndex, 1);
      }
      newState.clientLetters = clientLettersCp;
      return newState;
    }
    case UPDATE_CLIENT.SUCCESS:{
      const {id} = action.props;
      const newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[id], action.response, {editing: false});
      newState.clientOptions = makeClientOptions(state.clientIds, newState);
      return newState;
    }
    case CREATE_CLIENT.SUCCESS:{
      const {response: client} = action;
      const {id} = client;
      const newState = objectAssign({}, state);
      newState[id] = client;
      newState.clientIds = [id].concat(state.clientIds);
      newState.clientOptions = makeClientOptions(newState.clientIds, newState);
      const clientLettersCp = [].concat(state.clientLetters);
      const letter = client.name.substr(0, 1);
      const letters = state.clientLetters.map(arr => arr[0]);
      const index = letters.indexOf(letter);
      if (index > -1) {
        clientLettersCp[index] = clientLettersCp[index].concat([id]);
      }else {
        clientLettersCp.unshift([letter, id]);
      }
      newState.clientLetters = clientLettersCp;
      return newState;
    }
    case SET_SELECTED_CLIENT_ID:{
      const {id} = action;
      return objectAssign({}, state, {
        selectedClientId: id,
      });
    }

    default:
      return state;
  }
}

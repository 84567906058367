import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/nodeActions';
import LampsInfoItem from '../containers/LampsInfoItem';
import { getTranslate } from 'react-localize-redux';
import {LAMP_SELECTION_DIALOG_MODE_PPS, LAMP_SELECTION_DIALOG_MODE_NODES} from '../constants/customTypes';

const LampsInfo = ({lampIds, translate, selectionMode}) => {
  let extraInfoHeader = null;
  if (selectionMode === LAMP_SELECTION_DIALOG_MODE_PPS) {
    extraInfoHeader = (
      <React.Fragment>
        <th>{translate('technical_terminalid')}</th>
        <th>AccessPoint</th>
      </React.Fragment>
    );
  }else if(selectionMode === LAMP_SELECTION_DIALOG_MODE_NODES) {
    extraInfoHeader = (
      <th>ApIds</th>
    );
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th/>
          <th>{translate('technical_nodetype')}</th>
          {extraInfoHeader}
          <th>{translate('technical_hardwareversion')}</th>
          <th>{translate('technical_softwareversion')}</th>
        </tr>
      </thead>
      <tbody>
        {lampIds.map((id) => {
          return <LampsInfoItem key={id} id={id} />;
        })}
      </tbody>
    </table>

  );
};

LampsInfo.propTypes = {
  lampIds: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
  selectionMode: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.localeReducer),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LampsInfo);

import {GET_LAMP_TYPES, UPDATE_LAMP_TYPE, DELETE_LAMP_TYPE, CREATE_LAMP_TYPE} from '../constants/lampTypeActionTypes';
import {TRIVIEW_API} from '../constants/urls';
import {API, GET, POST, PUT, DELETE} from '../constants/customTypes';

export function getLampTypes() {
  return {
    type: API,
    auth: true,
    idProp: 'id',
    payload: {
      method: GET,
      url: TRIVIEW_API.LAMP_TYPES,
      success: GET_LAMP_TYPES.SUCCESS,
      error: GET_LAMP_TYPES.ERROR,
      start: GET_LAMP_TYPES.START,
    },
  };
}

export function createLampType(lampType) {
  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      method: POST,
      body: lampType,
      url: TRIVIEW_API.LAMP_TYPE.POST,
      success: CREATE_LAMP_TYPE.SUCCESS,
      error: CREATE_LAMP_TYPE.ERROR,
      start: CREATE_LAMP_TYPE.START,
    },
  };
}

export function updateLampType(lampType) {
  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      method: PUT,
      body: lampType,
      url: TRIVIEW_API.LAMP_TYPE.PUT,
      success: UPDATE_LAMP_TYPE.SUCCESS,
      error: UPDATE_LAMP_TYPE.ERROR,
      start: UPDATE_LAMP_TYPE.START,
    },
  };
}

export function deleteLampType(id) {
  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      method: DELETE,
      url: TRIVIEW_API.LAMP_TYPE.DELETE.replace(':id', id),
      success: DELETE_LAMP_TYPE.SUCCESS,
      error: DELETE_LAMP_TYPE.ERROR,
      start: DELETE_LAMP_TYPE.START,
    },
    props: {
      id,
    },
  };
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { getTranslate } from 'react-localize-redux';
import * as actions from '../actions/scenarioActions';
import * as buildingActions from '../actions/buildingActions';
import FormInput from '../components/FormInput';
import '../styles/scenario-edit.scss';

const newScenario = {
  name: 'New Scenario',
  profileIds: [],
  groupIds: [],
};

class ScenarioEdit extends Component {
  constructor(props) {
    super(props);
    const {scenario} = props;
    let toCpScenario = newScenario;
    if (scenario !== undefined) {
      toCpScenario = scenario;
    }
    this.state = {
      scenario: {...toCpScenario},
    };
    this.save = this.save.bind(this);
    this.setScenarioProp = this.setScenarioProp.bind(this);
  }

  save() {
    const {updateScenario, createScenario} = this.props.actions;
    const {scenario} = this.state;
    if (scenario.id) {
      updateScenario(scenario);
    }else {
      createScenario(scenario);
    }
  }

  setScenarioProp(key, value) {
    const {scenario} = this.state;
    const scenarioCp = {...scenario};
    scenarioCp[key] = value;
    this.setState({scenario: scenarioCp});
  }

  render() {
    const {activeBuildingId, buildingActions, translate} = this.props;
    const {setBuildingProps} = buildingActions;
    const {name, id} = this.state.scenario;
    return (
      <div className="scenario-edit">
        <h3>{translate('scenario')}</h3>
        <form onSubmit={(e) => {e.preventDefault(); this.save();}}>
          <FormInput id="name" type="text" label={translate('global_name')} value={name}
            onChange={(value) => this.setScenarioProp('name', value)} required />
          <button type="submit" className="btn btn-primary">
            {id !== undefined ? translate('global_save'):translate('global_create')}
          </button>{' '}
          <button className="btn btn-danger" type="button"
            onClick={() => setBuildingProps(activeBuildingId, {scenarioEditingId: undefined})}>
            {translate('global_cancel')}
          </button>
        </form>
      </div>
    );
  }
}

ScenarioEdit.propTypes = {
  actions: PropTypes.object.isRequired,
  buildingActions: PropTypes.object.isRequired,
  scenario: PropTypes.object,
  scenarioEditingId: PropTypes.string,
  activeBuildingId: PropTypes.number.isRequired,
  translate: PropTypes.func.isRequired,
};

function mapStateToProps({scenarioReducer, localeReducer}, {scenarioEditingId}) {
  const scenario = scenarioReducer[scenarioEditingId];
  return {
    scenario,
    translate: getTranslate(localeReducer),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    buildingActions: bindActionCreators(buildingActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScenarioEdit);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {CONFIG_MODE_NVI_STATE, CONFIG_MODE_NVO_RSSI} from '../constants/customTypes';
import {LIVE_MODE_MOVEMENT, LIVE_MODE_ENERGY_MONITORING} from '../constants/livePropTypes';
import * as actions from '../actions/lampActions';
import LampSvgText from './LampSvgText';
import CustomSvgText from '../components/CustomSvgText';
import {registerMovement, unregisterMovement} from 'CommMiddleware';
import {registerLocateCb, unregisterLocateCb} from '../middleware/lampMiddleware';
import Drag from '../components/Drag';
import { getLiveModeMovementStyle, getLiveModeEnergyMonitoringStyle } from '../utils/lampHelper';
import { getTranslate } from 'react-localize-redux';
import {isRoute} from '../utils/helper';
import {ROUTE_LIVE, ROUTE_TECHNICAL, ROUTE_CONFIG} from '../constants/const';

class BaseLamp extends Component {
  componentDidMount() {
    const {locateAnimation, movementAnimation} = this;
    const {id} = this.props;
    registerMovement(id, () => {
      movementAnimation.beginElement();
    });
    registerLocateCb(id, () => {
      locateAnimation.beginElement();
    });
  }

  componentWillUnmount() {
    const {id} = this.props;
    unregisterMovement(id);
    unregisterLocateCb(id);
  }

  render() {
    const {node, mode, nvoRssi, nviState, actions, id, mac, selected, x, y,
        currentRoute, liveMode, group, dragLamps, extraRotation,
      circleAnimationX, circleAnimationY,
      rotation, power, parentId,
        profile, translate} = this.props;
    const {clickLamp, setLampProps, dragLamp} = actions;
    let className = 'lamp';
    let profileState, active;
    let customText = null;

    const addClassName = (extraClass) => {
      className += ' ' + extraClass;
    };

    if (selected === true) {
      addClassName('selected');
    }

    if (isRoute(currentRoute, ROUTE_LIVE)) {
      if (group !== undefined) {
        profileState = group.profileState;
        if (LIVE_MODE_MOVEMENT === liveMode) {
          addClassName(getLiveModeMovementStyle(profileState));
        } else if (LIVE_MODE_ENERGY_MONITORING === liveMode && power !== undefined) {
          customText = power + ' ' + translate('live_power_unit');
          addClassName(getLiveModeEnergyMonitoringStyle(profileState, profile));
        } else {
          addClassName('profile-off');
        }
      } else {
        addClassName('profile-off');
      }

    } else if (isRoute(currentRoute, ROUTE_TECHNICAL) && selected === false) {
      if (node && node.id) {
        active = node.active;
        if (active) {
          addClassName('active');
        }else if(node.configured === false) {
          addClassName('unconfigured');
        }else if (node.online === false) {
          addClassName('offline');
        }else if(mode === CONFIG_MODE_NVI_STATE){
          if(nviState === 0) {
            addClassName('off');
          }else {
            addClassName('on');
          }
        }else if (mode === CONFIG_MODE_NVO_RSSI) {
          if (nvoRssi <= 40) {
            addClassName('rssi-bad');
          }else if(nvoRssi > 40 && nvoRssi <= 50) {
            addClassName('rssi-mediocre');
          }else {
            addClassName('rssi-good');
          }
        }
      }else if(mac) {
        addClassName('no-node');
      }
    } else if (isRoute(currentRoute, ROUTE_CONFIG)) {
      addClassName('on');
    }

    let lampSvg = (
      <g className={className}>
        {this.props.children}
      </g>
    );

    if (dragLamps === true) {
      const dragAction = (deltaX, deltaY) => {
        dragLamp(id, deltaX, deltaY);
      };

      lampSvg = (
        <Drag action={dragAction} >
          {lampSvg}
        </Drag>
      );
    }

    return (
      <g transform={`translate(${x},${y}) rotate(${rotation})`}
        onClick={() => clickLamp(id)}
        onMouseOver={() => setLampProps(id, {active: true})}
        onMouseOut={() => setLampProps(id, {active: false})}>
        {lampSvg}
        <circle cx={circleAnimationX} cy={circleAnimationY} r="0" className="mov-circle">
          <animate attributeName="r" ref={(el) => this.movementAnimation = el} begin="indefinite" dur="2200ms" repeatCount="1" from="50" to="1000"/>
        </circle>
        <circle cx={circleAnimationX} cy={circleAnimationY} r="0" className="locate-circle">
          <animate attributeName="r" ref={(el) => this.locateAnimation = el} begin="indefinite" dur="4000ms" repeatCount="1" from="5000" to="50"/>
        </circle>
        <LampSvgText rotation={extraRotation} mac={mac} id={id} parentId={parentId} />
        <CustomSvgText rotation={extraRotation} customText={customText} />
      </g>
    );
  }
}

BaseLamp.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  node: PropTypes.object,
  mode: PropTypes.string.isRequired,
  group: PropTypes.object,
  profile: PropTypes.object,
  nvoRssi: PropTypes.number,
  nviState: PropTypes.number,
  actions: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  mac: PropTypes.string,
  currentRoute: PropTypes.string.isRequired,
  liveMode: PropTypes.string,
  liveMonitoringStats: PropTypes.number,
  dragLamps: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  circleAnimationX: PropTypes.number.isRequired,
  circleAnimationY: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
  extraRotation: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  power: PropTypes.number,
  parentId: PropTypes.string,
};

BaseLamp.defaultProps = {
  y: 0,
  rotation: 0,
};

function mapStateToProps(state, {id, mac, parentId}) {
  const {lampReducer, groupReducer, planReducer, liveReducer, profileReducer, routingReducer} = state;
  const {lampGroupMap} = groupReducer;
  let group;
  if (parentId !== undefined) {
    group = groupReducer[lampGroupMap[parentId]];
    mac = lampReducer[parentId].mac;
  }else {
    group = groupReducer[lampGroupMap[id]];
  }

  let profile = null;
  const {dragLamps} = lampReducer;
  const {currentRoute} = routingReducer;
  const {liveMode} = liveReducer;
  if (isRoute(currentRoute, ROUTE_LIVE) && group !== undefined) {
    profile = profileReducer[group.profileId];
  }

  let node, nviState, nvoRssi;
  if (mac) {
    node = state.nodeReducer[mac];
    const nvoRssiVar = state.variableReducer[`${mac}/Node/nvoRssi`];
    const nviStateVar = state.variableReducer[`${mac}/LED/nviState`];
    nvoRssi = nvoRssiVar === undefined ? undefined:nvoRssiVar.value;
    nviState = nviStateVar === undefined ? undefined:nviStateVar.value;
  }

  return {
    node,
    dragLamps,
    mode: planReducer.configMode,
    nviState,
    currentRoute,
    liveMode,
    group,
    profile,
    nvoRssi,
    mac,
    translate : getTranslate(state.localeReducer),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseLamp);

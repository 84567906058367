import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/uniqueObjectsActions';
import ObjectNameFilter from './ObjectNameFilter';
import Checkbox from '../components/Checkbox';

const ObjectsFilter = ({objectNames, actions}) => {
  const {changeUniqueSelectedAll} = actions;
  return (
    <div>
      <h3>Objects <Checkbox checked inline onChange={() => changeUniqueSelectedAll(true)}/> <Checkbox inline onChange={() => changeUniqueSelectedAll(false)} /></h3>
      {objectNames.map(objectName => <ObjectNameFilter key={objectName} objectName={objectName} />)}
    </div>
  );
};

ObjectsFilter.propTypes = {
  objectNames: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {objectNames} = state.uniqueObjectsReducer;
  return {
    objectNames,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ObjectsFilter);

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {clientKey} from '../utils/idHelper';
import * as actions from '../actions/reportsActions';
import * as buildingActions from '../actions/buildingActions';
import ReportItem from './ReportItem';
import ActionButton from '../components/ActionButton';
import ConfirmDialog from '../containers/ConfirmDialog';

const ReportsOverview = ({reportIds, actions, clientId, buildingActions}) => {
  const {getReports, deleteReport} = actions;
  const {setBuildingRoute} = buildingActions;
  const [deleteReportId, setDeleteReportId] = useState(undefined);

  useEffect(() => {
    if (!reportIds) {
      getReports(clientId);
    }
  }, []);

  if (!reportIds) {
    return null;
  }

  const deleteCfm = () => {
    deleteReport(clientId, deleteReportId);
    setDeleteReportId(undefined);
  };

  return (
    <>
      <ConfirmDialog show={deleteReportId !== undefined} closeAction={() => setDeleteReportId(undefined)}
        confirmAction={() => deleteCfm()} text={'Are you sure you want to delete this report?'}
        title={'Delete Report'} />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2>Reports</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>Frequency</th>
                  <th>Language</th>
                  <th>Emails</th>
                  <th/>
                  <th/>
                </tr>
              </thead>
              <tbody>
                {reportIds.map(reportId => (
                  <ReportItem key={reportId} id={reportId}
                    deleteAction={setDeleteReportId} clientId={clientId}
                  />
                ))}
              </tbody>
            </table>
            <div>
              <ActionButton action={() => setBuildingRoute('/plan/report/')} text={'Create report'} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ReportsOverview.propTypes = {
  reportIds: PropTypes.array,
  clientId: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  buildingActions: PropTypes.object.isRequired,
};

function mapStateToProps({clientReducer, reportsReducer}) {
  const {activeClientKey} = clientReducer;
  const reportIds = reportsReducer[clientKey(activeClientKey)];

  return {
    reportIds,
    clientId: activeClientKey,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    buildingActions: bindActionCreators(buildingActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsOverview);

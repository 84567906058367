import React from 'react';
import PropTypes from 'prop-types';
import * as actions from '../actions/alertActions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import '../styles/alert.scss';

const Alert = ({ alert, actions }) => {
  if (alert === undefined) {
    return null;
  }
  const {removeAlert} = actions;
  const { msg, id } = alert;
  return (
    <div className="alert">
      <span className="closebtn" onClick={() => removeAlert(id)}>&times;</span>
      {msg}
    </div>
  );
};

Alert.propTypes = {
  alert: PropTypes.object,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state, props) {
  const alert = state.alertReducer[props.id];

  return {
    alert,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alert);

import {createStore, compose, applyMiddleware} from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';
import forwardLoginMiddleware from '../middleware/forwardLoginMiddleware';
import apiMiddleware from '../middleware/apiMiddleware';
import {commMiddleware} from 'CommMiddleware';
import alertMiddleware from '../middleware/alertMiddleware';
import planMiddleware from '../middleware/planMiddleware';
import buildingMiddleware from '../middleware/buildingMiddleware';
import {psMiddleware} from '../middleware/psMiddleware';
import {lampMiddleware} from '../middleware/lampMiddleware';
import nodeMiddleware from '../middleware/nodeMiddleware';
import clientMiddleware from '../middleware/clientMiddleware';
import serialMiddleware from '../middleware/serialMiddleware';
import groupMiddleware from '../middleware/groupMiddleware';
import profileMiddleware from '../middleware/profileMiddleware';
import lineMiddleware from '../middleware/lineMiddleware';
import userMiddleware from '../middleware/userMiddleware';
import planImgMiddleware from '../middleware/planImgMiddleware';
import firmwareMiddleware from '../middleware/firmwareMiddleware';
import languageMiddleware from '../middleware/languageMiddleware';
import liveMiddleware from '../middleware/liveMiddleware';
import filterMiddleware from '../middleware/filterMiddleware';
import apMiddleware from '../middleware/apMiddleware';
import authMiddleware from '../middleware/authMiddleware';
import routingMiddleware from '../middleware/routingMiddleware';
import dataportMiddleware from '../middleware/dataportMiddleware';
import scenarioMiddleware from '../middleware/scenarioMiddleware';

export const history = createBrowserHistory();
const connectRouterHistory = connectRouter(history);
const reactRouterMiddleware = routerMiddleware(history);

const commonMiddleware = [
  reactRouterMiddleware,
  thunk,
  forwardLoginMiddleware,
  commMiddleware,
  apiMiddleware,
  alertMiddleware,
  planMiddleware,
  buildingMiddleware,
  psMiddleware,
  nodeMiddleware,
  lampMiddleware,
  clientMiddleware,
  serialMiddleware,
  groupMiddleware,
  profileMiddleware,
  lineMiddleware,
  userMiddleware,
  planImgMiddleware,
  firmwareMiddleware,
  languageMiddleware,
  liveMiddleware,
  filterMiddleware,
  apMiddleware,
  authMiddleware,
  routingMiddleware,
  dataportMiddleware,
  scenarioMiddleware,
];

let cloudMiddleware = [];
if (CLOUD) {
  cloudMiddleware = [
    require('../middleware/retentionMiddleware').default,
    require('../middleware/energyMiddleware').default,
    require('../middleware/movementMiddleware').default,
    require('../middleware/reportsMiddleware').default,
    require('../middleware/lampTypeMiddleware').default,
  ];
}

function configureStoreProd(initialState) {
  localStorage.debug = 'trimonitor:global';
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument

  ].concat(commonMiddleware).concat(cloudMiddleware);

  return createStore(createRootReducer(history), initialState, compose(
    applyMiddleware(...middlewares)
    )
  );
}

function configureStoreDev(initialState) {
  localStorage.debug = 'trimonitor:*';
  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    // reduxImmutableStateInvariant(),

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument

  ].concat(commonMiddleware).concat(cloudMiddleware);

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(createRootReducer(history), initialState, composeEnhancers(
    applyMiddleware(...middlewares)
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(connectRouterHistory(nextReducer));
    });
  }

  return store;
}
// const configureStore = configureStoreProd;
const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreProd;

export default configureStore;

import React from 'react';
import PropTypes from 'prop-types';
import Lamp from '../containers/Lamp';
import LampDepiction from '../containers/LampDepiction';
import {DEPICTION_RECT} from '../constants/lampTypeTypes';

const MasterLamp = ({lamp, lampType, extraRotation}) => {
  const {lampIds} = lamp;
  const {length, depictionType} = lampType;
  let lineStartX = 0;
  if (depictionType === DEPICTION_RECT) {
    lineStartX = length / 2;
  }
  return (
    <g>
      <LampDepiction lamp={lamp} lampType={lampType} extraRotation={extraRotation} />
      <g transform={`translate(${lamp.x},${lamp.y !== undefined ? lamp.y:0})`}>
        {lampIds.map(lampId => <Lamp key={lampId} id={lampId} extraRotation={extraRotation} lineStartX={lineStartX} />)}
      </g>
    </g>
  );
};

MasterLamp.propTypes = {
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  extraRotation: PropTypes.number.isRequired,
};

export default MasterLamp;

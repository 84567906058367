import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/nodeActions';

const Variable = ({mac, objectName, variableName, variable, actions, path, showLabel}) => {
  if (variable === undefined) {
    return <span className="variable">Not found</span>;
  }
  const {setVariableValue, setVariableEditing, setVariablesValueApi} = actions;
  const submitFunc = () => {
    setVariablesValueApi([mac], objectName, variableName, variable.strValue);
    setVariableEditing(path, false);
  };
  if (variable.editing === true) {
    return (
      <form className="variable" onSubmit={(event) => {
        event.preventDefault();
        submitFunc();
      }}>
        {variableName}: <input type="number" min="0" className="form-control" value={variable.strValue}
          onChange={(e) => setVariableValue(path, e.target.value)} autoFocus />
        &nbsp;
        <i className="pointer fa fa-times" aria-hidden="true"
          onClick={() => setVariableEditing(path, false)} />
        &nbsp;
        <i className="pointer fa fa-floppy-o" aria-hidden="true"
          onClick={() => submitFunc()}/>
        <input className="hidden" type="submit" />
      </form>
    );
  }

  let editPencil = null;
  if (variable.writeable === true) {
    editPencil = (
      <i className="fa pointer fa-pencil" aria-hidden="true"
        onClick={() => setVariableEditing(path, !variable.editing)}/>
    );
  }

  let label = null;
  if (showLabel === true) {
    label = (
      <span>{variableName}:&nbsp;</span>
    );
  }

  let fails = null;
  if (variable.fails > 0) {
    fails = (
      <span className="fails">{variable.fails}</span>
    );
  }
  return (
    <span className="variable">
      {label} {variable.value} {editPencil} {fails}
    </span>
  );
};

Variable.propTypes = {
  mac: PropTypes.string,
  objectName: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  variableName: PropTypes.string.isRequired,
  variable: PropTypes.object,
  path: PropTypes.string,
  showLabel: PropTypes.bool.isRequired,
};

function mapStateToProps(state, {mac, objectName, variableName}) {
  const path = `${mac}/${objectName}/${variableName}`;
  const variable = state.variableReducer[path];
  return {
    variable,
    path,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Variable);

import {SET_PLAN_PROPS, SET_SHOW_PLAN_CONTROL, INITIALIZE_SELECT_RECT,
  SET_COORD_MAP, SET_ROTATION, STOP_SELECT_RECT,
  DRAG_SELECT_RECT, SET_MODE,
  SET_SHOW_PS_SELECTION_DIALOG, CHANGE_SELECT_ALL, SET_SELECTED_PS_MACS,
  SET_GROUP_MODE, SET_PLAN_REDUCER_PROPS,
  AUTO_CALC_HITZONES, SET_SHOW_PS_NODES_SELECTION_DIALOG,
  CALC_ADJACENCY, SET_PLAN_SVG_EL, SET_ZOOM_RECT, CALC_PLAN_SIZE_PROPS, SET_PLAN_WIDTH_HEIGHT,
  SET_POINTS, EXEC_PLAN_SUBSCRIBES, EXEC_PLAN_UNSUBSCRIBES,
  SET_LAMP_CLICK_MODE, SET_PLAN_DRAG_START, CLOSE_LAMP_SELECTION_DIALOG,
  SHOW_LAMP_NODES_SELECTION_DIALOG, SHOW_LAMP_PPS_SELECTION_DIALOG,
} from '../constants/planActionTypes';

export function setPlanProps(id, obj) {
    return {
      type: SET_PLAN_PROPS,
      obj,
      id,
    };
}

export function setShowPlanControl(showPlanControl) {
  return {
    type: SET_SHOW_PLAN_CONTROL,
    showPlanControl,
  };
}

export function setCoordMap(xMap, yMap, svgCoordOffsetX, svgCoordOffsetY) {
  return {
    type: SET_COORD_MAP,
    xMap,
    yMap,
    svgCoordOffsetX,
    svgCoordOffsetY,
  };
}

export function setRotation(rotation) {
  return {
    type: SET_ROTATION,
    rotation,
  };
}

export function initializeSelectRect(x, y) {
  return {
    type: INITIALIZE_SELECT_RECT,
    x,
    y,
  };
}

export function stopSelectRect() {
  return {
    type: STOP_SELECT_RECT,
  };
}

export function dragSelectRect(width, height) {
  return {
    type: DRAG_SELECT_RECT,
    width,
    height,
  };
}

export function setMode(mode) {
  return {
    type: SET_MODE,
    mode,
  };
}

export function showLampNodesSelectionDialog() {
  return {
    type: SHOW_LAMP_NODES_SELECTION_DIALOG,
  };
}

export function closeLampSelectionDialog() {
  return {
    type: CLOSE_LAMP_SELECTION_DIALOG,
  };
}

export function setShowPsSelectionDialog(show) {
  return {
    type: SET_SHOW_PS_SELECTION_DIALOG,
    show,
  };
}

export function showLampPpsSelectionDialog() {
  return {
    type: SHOW_LAMP_PPS_SELECTION_DIALOG,
  };
}

export function setShowPsNodesSelectionDialog(show) {
  return {
    type: SET_SHOW_PS_NODES_SELECTION_DIALOG,
    show,
  };
}

export function changeSelectAll(selected) {
  return {
    type: CHANGE_SELECT_ALL,
    selected,
  };
}

export function setSelectedPsMacs(psMacs) {
  return {
    type: SET_SELECTED_PS_MACS,
    psMacs,
  };
}

export function setGroupMode(mode) {
  return {
    type: SET_GROUP_MODE,
    mode,
  };
}

export function autoCalcHitzones() {
  return {
    type: AUTO_CALC_HITZONES,
  };
}

export function calcAdjacency() {
  return {
    type: CALC_ADJACENCY,
  };
}

export function setPlanSvgEl(svgEl, svgPt) {
  return {
    type: SET_PLAN_SVG_EL,
    svgEl,
    svgPt,
  };
}

export function setZoomRect(x, y, width, height) {
  return {
    type: SET_ZOOM_RECT,
    x, y, width, height,
  };
}

export function calcPlanSizeProps() {
  return {
    type: CALC_PLAN_SIZE_PROPS,
  };
}

export function setPlanWidthHeight(width, height) {
  return {
    type: SET_PLAN_WIDTH_HEIGHT,
    width,
    height,
  };
}

export function setLampClickMode(lampClickMode) {
  return {
    type: SET_LAMP_CLICK_MODE,
    lampClickMode,
  };
}

export function setPoints(points) {
  return {
    type: SET_POINTS,
    points,
  };
}

export function setPlanDragStart(dragStartDate) {
  return {
    type: SET_PLAN_DRAG_START,
    dragStartDate,
  };
}

export function setPlanReducerProps(obj) {
  return {
    type: SET_PLAN_REDUCER_PROPS,
    obj,
  };
}

export function execPlanSubscribes(dataportSlug, buildingId, activeScenarioId, selectedScenarioId) {
  return {
    type: EXEC_PLAN_SUBSCRIBES,
    dataportSlug, buildingId, activeScenarioId, selectedScenarioId,
  };
}

export function execPlanUnsubscribes(dataportSlug, buildingId) {
  return {
    type: EXEC_PLAN_UNSUBSCRIBES,
    dataportSlug, buildingId,
  };
}

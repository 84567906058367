import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/dataportActions';
import SubNav from '../components/SubNav';
import CommandOutput from '../components/CommandOutput';

const DataportCommandOutputPage = ({actions, dataportId, dataport}) => {
  if (dataport === undefined) {
    return null;
  }

  const {name, sessionId, output} = dataport;
  if (sessionId === undefined) {
    return null;
  }

  const {getDataportCommandOutput} = actions;
  useEffect(() => {
    getDataportCommandOutput(dataportId, name, sessionId);
  }, []);

  return (
    <div className="site-page">
      <Helmet>
        <title>{name} | TriLED Monitor</title>
      </Helmet>
      <SubNav parentNav="monitor" />
      <div className="container site-detail-page">
        <div className="row">
          <div className="col-md-12">
            <h2>{name}</h2>
            <CommandOutput output={output} />
          </div>
        </div>
      </div>
    </div>

  );
};

DataportCommandOutputPage.propTypes = {
  actions: PropTypes.object.isRequired,
  dataportId: PropTypes.string,
  dataport: PropTypes.object,
};

function mapStateToProps({dataportReducer}, props) {
  const {dataportId} = props.match.params;
  const dataport = dataportReducer[dataportId];

  return {
    dataport,
    dataportId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataportCommandOutputPage);

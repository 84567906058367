import {SET_PLAN_IMG_PROPS, SET_IMG_DRAG, SET_PLAN_IMG_DEFAULT_VIEW,
UPLOAD_PLAN_IMG, DRAG_PLAN_IMG} from '../constants/planImgActionTypes';
import {TRIVIEW_API} from '../constants/urls';
import {API, POST} from '../constants/customTypes';

export function setPlanImgProps(id, obj) {
  return {
    type: SET_PLAN_IMG_PROPS,
    id,
    obj,
  };
}

export function setImgDrag(imgDrag) {
  return {
    type: SET_IMG_DRAG,
    imgDrag,
  };
}

export function uploadPlanImg(buildingId, formData) {
  return {
    type: API,
    auth: true,
    form: true,
    payload: {
      url: TRIVIEW_API.BUILDINGS.UPLOAD.replace(':id', buildingId),
      method: POST,
      body: formData,
      start: UPLOAD_PLAN_IMG.START,
      success: UPLOAD_PLAN_IMG.SUCCESS,
      error: UPLOAD_PLAN_IMG.ERROR,
    },
    props: {
      buildingId,
    },
  };
}

export function dragPlanImg(id, deltaX, deltaY) {
  return {
    type: DRAG_PLAN_IMG,
    id,
    deltaX,
    deltaY,
  };
}

export function setPlanImgDefaultView() {
  return {
    type: SET_PLAN_IMG_DEFAULT_VIEW,
  };
}

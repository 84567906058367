import {GET_CLIENTS, SET_ACTIVE_CLIENT, SET_CLIENT_PROPS, SET_CLIENT_ROUTE_SLUG,
  SET_SHOW_SELECT_CLIENT_DIALOG, SET_CLIENT_REDUCER_PROPS,
  UPDATE_CLIENT, DELETE_CLIENT, CREATE_CLIENT,
  SET_SELECTED_CLIENT_ID,
} from '../constants/clientActionTypes';
import {TRIVIEW_API} from '../constants/urls';
import {API, GET, PUT, DELETE, POST} from '../constants/customTypes';

export function getClients() {
  return {
    type: API,
    auth: true,
    idProp: 'id',
    payload: {
      method: GET,
      url: TRIVIEW_API.CLIENTS.GET,
      success: GET_CLIENTS.SUCCESS,
      error: GET_CLIENTS.ERROR,
      start: GET_CLIENTS.START,
    }
  };
}

export function setClientRouteSlug(slug) {
  return {
    type: SET_CLIENT_ROUTE_SLUG,
    slug,
  };
}

export function setActiveClient(id) {
  return {
    type: SET_ACTIVE_CLIENT,
    id,
  };
}

export function setClientProps(id, obj) {
  return {
    type: SET_CLIENT_PROPS,
    id,
    obj,
  };
}

export function setShowSelectClientDialog(show) {
  return {
    type: SET_SHOW_SELECT_CLIENT_DIALOG,
    show,
  };
}

export function setClientReducerProps(obj) {
  return {
    type: SET_CLIENT_REDUCER_PROPS,
    obj,
  };
}

export function createClient(client) {
  return {
    type: API,
    auth: true,
    payload: {
      method: POST,
      body: client,
      url: TRIVIEW_API.CLIENTS.POST,
      success: CREATE_CLIENT.SUCCESS,
      error: CREATE_CLIENT.ERROR,
      start: CREATE_CLIENT.START,
    },
  };
}

export function updateClient(client) {
  return {
    type: API,
    auth: true,
    payload: {
      method: PUT,
      body: client,
      url: TRIVIEW_API.CLIENTS.PUT,
      success: UPDATE_CLIENT.SUCCESS,
      error: UPDATE_CLIENT.ERROR,
      start: UPDATE_CLIENT.START,
    },
    props: {
      id: client.id,
    },
  };
}

export function deleteClient(id) {
  return {
    type: API,
    auth: true,
    payload: {
      method: DELETE,
      url: TRIVIEW_API.CLIENTS.DELETE.replace(':id', id),
      success: DELETE_CLIENT.SUCCESS,
      error: DELETE_CLIENT.ERROR,
      start: DELETE_CLIENT.START,
    },
    props: {
      id,
    },
  };
}

export function setSelectedClientId(id) {
  return {
    type: SET_SELECTED_CLIENT_ID,
    id,
  };
}

import {SET_AP_PROPS, DECOMMISSION_NODE, DECOMMISSION_SELECTED,
  CHANGE_PROP_ALL_APS, SET_AP_ENABLED, CHANGE_PROP_NODE_ALL_APS,
  SET_MULTI_AP_PROPS, DECOMMISSION, SET_COMMISSIONING, GET_APS} from '../constants/apActionTypes';
import {DS_RPC} from '../constants/customTypes';

export function getAps(dataport) {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: ':site/aps',
      success: GET_APS.SUCCESS,
      error: GET_APS.ERROR,
      start: GET_APS.START,
    },
    props: {
      dataport,
    },
  };
}

export function setApProps(id, obj) {
  return {
    type: SET_AP_PROPS,
    id,
    obj,
  };
}

export function setCommissioning(id, commissioning) {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: `:site/commissioning`,
      data: {
        id,
        value: commissioning,
      },
      success: SET_COMMISSIONING.SUCCESS,
      error: SET_COMMISSIONING.ERROR,
      start: SET_COMMISSIONING.START,
    },
    props: {
      id,
      commissioning,
    }
  };
}


export function changePropAllAps(obj) {
  return {
    type: CHANGE_PROP_ALL_APS,
    obj,
  };
}

export function changePropNodeAllAps(obj) {
  return {
    type: CHANGE_PROP_NODE_ALL_APS,
    obj,
  };
}

export function setMultiApProps(ids, obj) {
  return {
    type: SET_MULTI_AP_PROPS,
    ids,
    obj
  };
}

export function decommission(apId, terminalIds) {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: ':site/decommission',
      data: {
        apId,
        terminalIds,
      },
      success: DECOMMISSION.SUCCESS,
      error: DECOMMISSION.ERROR,
      start: DECOMMISSION.START,
    },
    props: {
      apId,
    },
  };
}

export function decommissionNode(ids) {
  return {
    type: DECOMMISSION_NODE,
    ids,
  };
}

export function decommissionSelected() {
  return {
    type: DECOMMISSION_SELECTED,
  };
}

export function setApEnabled(id, value) {
  return {
    type: DS_RPC,
    replaceSite: true,
    payload: {
      url: ':site/ap/enabled',
      data: {
        id,
        value,
      },
      success: SET_AP_ENABLED.SUCCESS,
      error: SET_AP_ENABLED.ERROR,
      start: SET_AP_ENABLED.START,
    },
    props: {
      id,
      value,
    },
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Dialog from '../components/DialogWrapper';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/planActions';
import '../styles/selection-dialog.scss';
import Variable from './Variable';
import EditAllVariables from './EditAllVariables';
import LampsInfo from './LampsInfo';
import LampsCommands from './LampsCommands';
import { getTranslate } from 'react-localize-redux';
import {LAMP_SELECTION_DIALOG_MODE_PPS} from '../constants/customTypes';

const LampSelectionDialog = ({actions, showLampSelectionDialog, selectedObjects, selectedLampMacs, selectedObjectNames, translate,
  selectedIds, lampSelectionDialogMode, showSelectionInfo, showSelectionCommands}) => {
  if (showLampSelectionDialog === false) {
    return null;
  }

  const {closeLampSelectionDialog} = actions;

  const objectEls = selectedObjectNames.map((objectName, i) => {
    const object = selectedObjects[objectName];
    return (
      <div key={i} className="col-md-12">
        <h4>{objectName}</h4>
        <table className="table custom-table-responsive">
          <thead>
            <tr>
              <th/>
              {
                object.variables.map((variableName) => {
                  return (
                    <th key={`${objectName}/${variableName}`}>
                      <EditAllVariables objectName={objectName} variableName={variableName} macs={selectedLampMacs} />
                    </th>
                  );
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              selectedLampMacs.map((mac, j) => {
                return (
                  <tr key={j}>
                    <th>{mac}</th>
                    {
                      object.variables.map((variableName, k) => {
                        return (
                          <td key={k}><Variable mac={mac} objectName={objectName} variableName={variableName} showLabel={false} /></td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  });

  let commandsEl = null;
  if (showSelectionCommands) {
    commandsEl = (
      <div className="col-md-12">
        <LampsCommands lampIds={selectedIds} lampMacs={selectedLampMacs} />
      </div>
    );
  }

  let infoEl = null;
  if (showSelectionInfo) {
    infoEl = (
      <div className="col-md-12">
        <LampsInfo lampIds={selectedIds} selectionMode={lampSelectionDialogMode} />
      </div>
    );
  }

  const body = (
    <div className="row">
      {commandsEl}
      {infoEl}
      {objectEls}
    </div>
  );

  return (
    <span className="selection-dialog">
      <Dialog title="Lamps selection" visible={true} body={body} closeLabel={translate('global_close')}
        closeAction={() => closeLampSelectionDialog()} />
    </span>
  );
};

LampSelectionDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  showLampSelectionDialog: PropTypes.bool.isRequired,
  selectedLampMacs: PropTypes.array.isRequired,
  selectedObjects: PropTypes.object.isRequired,
  selectedObjectNames: PropTypes.array.isRequired,
  selectedIds: PropTypes.array.isRequired,
  lampSelectionDialogMode: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  showSelectionInfo: PropTypes.bool.isRequired,
  showSelectionCommands: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {localeReducer, planReducer, selectedObjectsReducer, filterReducer} = state;
  const {showLampSelectionDialog, selectedLampMacs, selectedPpIds, lampSelectionDialogMode} = planReducer;
  let selectedIds = selectedLampMacs;
  if (lampSelectionDialogMode === LAMP_SELECTION_DIALOG_MODE_PPS) {
    selectedIds = selectedPpIds;
  }
  const {showSelectionCommands, showSelectionInfo} = filterReducer;
  const selectedObjects = selectedObjectsReducer;
  const selectedObjectNames = selectedObjects.objectNames;
  const translate = getTranslate(localeReducer);
  return {
    showLampSelectionDialog,
    showSelectionCommands,
    showSelectionInfo,
    selectedIds,
    selectedLampMacs,
    selectedObjects,
    selectedObjectNames,
    lampSelectionDialogMode,
    translate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LampSelectionDialog);

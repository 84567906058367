import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as apActions from '../actions/apActions';
import * as nodeActions from '../actions/nodeActions';
import * as psActions from '../actions/psActions';
import * as ppActions from '../actions/ppActions';
import LampList from '../components/LampList';
import ActionButton from '../components/ActionButton';
import UpgradeFirmwareLabels from '../components/UpgradeFirmwareLabels';
import { getTranslate } from 'react-localize-redux';

const PsItem = ({node, power, ps, nodeActions, apActions, firmwareStatus, translate, ap, clientIds, psActions,
ppActions,}) => {
  if (node === undefined) {
    return <span>No Node</span>;
  }
  const {setPsDialogMac, setVariablesValueApi, setNodeProps, pollNodes, setLinking} = nodeActions;
  const {setApProps, setCommissioning} = apActions;
  const {setMultiPpProps} = ppActions;
  const {locatePs, removePsLink} = psActions;
  const {id, linking, online,
  selected, pollStatus, progress, fails, upgraded} = node;
  const {registration_mode, showLamps, enabled, name, socketConnected} = ap;

  let locateBtn = null;
  let removeLinkBtn = null;

  let linkingClass = 'btn-default';
  if (linking) {
    linkingClass = 'btn-primary';
  }else if(ps !== undefined) {
    linkingClass = 'btn-success';

    locateBtn = (
      <ActionButton action={() => locatePs(ps.id)}
        extraClass={"btn-default"}
        icon="fa-map-marker"
        title="Locate" />
    );

    removeLinkBtn = (
      <ActionButton action={() => removePsLink(id)}
        extraClass="btn-default"
        icon="fa-ban"
        title="Remove link" />
    );
  }

  let psBodyClass = 'ps-body';
  if (ps && ps.active) {
    psBodyClass += ' active';
  }

  if (online === false || socketConnected === false) {
    psBodyClass += ' offline';
  }

  if (showLamps === true) {
    psBodyClass += ' show-lamps';
  }

  let apEnabledEl = null;
  if (enabled === false) {
    apEnabledEl = (
      <span className="label label-danger">Dect Disabled</span>
    );
  }

  return (
    <div className="ps-item ">
      <div className={psBodyClass}
      onMouseOver={() => setNodeProps(id, {active: true})}
      onMouseOut={() => setNodeProps(id, {active: false})}>
        <span className="ps-header">
          <i className={"pointer fa fa-" + (showLamps ? 'minus':'plus')} aria-hidden="true"
          onClick={() => setApProps(id, {showLamps: !showLamps})}/>
          {' ' + name} | {id} {apEnabledEl}
          <span className="badge number-lamps">{clientIds.length}</span>
        </span>
        <span className="btn-group">
          <ActionButton action={() => setVariablesValueApi([id], 'Power' , 'nviOutput', power === 1 ? 0:1)}
            extraClass={"btn " + (power === 1 ? "btn-success":"btn-danger")}
            icon="fa-power-off"
            title={translate('technical_switch_power')} />
          <ActionButton action={() => setCommissioning(id, !registration_mode)}
            extraClass={"btn " + (registration_mode ? "btn-danger":"btn-default")}
            icon="fa-link"
            title={translate('technical_commission')} />
          <ActionButton action={() => setLinking(id, !linking)}
            extraClass={linkingClass}
            icon="fa-external-link"
            title={translate('technical_link')} />
          {removeLinkBtn}
          {locateBtn}
          <ActionButton action={() => {setPsDialogMac(id); pollNodes([id]);}}
            extraClass={"btn-default"}
            icon="fa-info"
            title={translate('technical_info')} />
          <ActionButton action={() => pollNodes([id])}
            status={pollStatus}
            icon="fa-refresh"
            title={translate('technical_refresh')}/>
          <ActionButton action={() => setNodeProps(id, {selected: !selected})}
            extraClass={"btn-default" + (selected === true ? ' active selected':'')}
            icon="fa-bullseye"
            title={translate('technical_focus')} />
          <ActionButton action={() => setMultiPpProps(clientIds, {selected: true})}
            extraClass={"btn-default"}
            icon="fa-bars"
            title={translate('technical_selectall_nodes_ps')} />
          <ActionButton action={() => setMultiPpProps(clientIds, {selected: false})}
            extraClass={"btn-default"}
            icon="fa-bars slash"
            title={translate('technical_deselectall_nodes_ps')} />
        </span>
        <UpgradeFirmwareLabels status={firmwareStatus} upgraded={upgraded} syncing={false} progress={progress} fails={fails} />
      </div>
      {showLamps ?
        <LampList lamps={clientIds} />
        :
        null
      }
    </div>
  );
};

PsItem.propTypes = {
  node: PropTypes.object,
  ap: PropTypes.object.isRequired,
  power: PropTypes.number,
  ps: PropTypes.object,
  nodeActions: PropTypes.object.isRequired,
  apActions: PropTypes.object.isRequired,
  psActions: PropTypes.object.isRequired,
  ppActions: PropTypes.object.isRequired,
  firmwareStatus: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  clientIds: PropTypes.array.isRequired,
};

function mapStateToProps(state, props) {
  const {buildingReducer, firmwareReducer, filterReducer, psReducer} = state;
  const {activeBuildingId} = buildingReducer;
  const {status} = firmwareReducer;
  const clientIds = filterReducer[props.id];
  let ps;
  const psMap = psReducer[activeBuildingId];
  if (psMap) {
    const psId = psMap[props.id];
    ps = state.psReducer[psId];
  }
  const powerVar = state.variableReducer[`${props.id}/Power/nviOutput`];

  return {
    node: state.nodeReducer[props.id],
    power: powerVar === undefined ? undefined:powerVar.value,
    ps: ps,
    ap: state.apReducer[props.id],
    firmwareStatus: status,
    translate: getTranslate(state.localeReducer),
    clientIds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    nodeActions: bindActionCreators(nodeActions, dispatch),
    apActions: bindActionCreators(apActions, dispatch),
    psActions: bindActionCreators(psActions, dispatch),
    ppActions: bindActionCreators(ppActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PsItem);

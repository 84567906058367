import React from 'react';
import PropTypes from 'prop-types';
import '../styles/on-off.scss';

const OnOff = ({on}) => {
  return (
    <span className="on-off">
      <i className={'fa ' + (on ? 'online fa-check-circle-o':'offline fa-times-circle-o')}
      aria-hidden="true"/>
    </span>
  );
};
OnOff.propTypes = {
  on: PropTypes.bool,
};
export default OnOff;

import {LOCATION_CHANGE} from 'connected-react-router';
import {SET_ROUTE} from '../constants/routingActionTypes';

export default store => next => action => {
  const {dispatch} = store;
  if (action.type === LOCATION_CHANGE) {
    next(action);

    const {pathname} = action.payload.location;
    dispatch({
      type: SET_ROUTE,
      route: pathname,
    });
  }
  return next(action);
};

import {RECALCULATE_FILTERS, SET_FILTER_NODE_TYPE_VERSIONS, SET_FILTER_NODE_TYPE_VERSION_VALUE,
  RESET_FILTERS, ADD_SINGLE_NODE_TYPE_VERSION,
SET_FILTER_REDUCER_PROPS, SET_FILTER_REDUCER_PROPS_AND_RECALC} from '../constants/filterActionTypes';

export function recalculateFilters() {
  return {
    type: RECALCULATE_FILTERS,
  };
}

export function setFilterNodeTypeVersions(nodeTypes, nodeTypeObj, versionObj) {
  return {
    type: SET_FILTER_NODE_TYPE_VERSIONS,
    nodeTypes,
    nodeTypeObj,
    versionObj,
    midd: {
      dataportSlug: true,
    },
  };
}

export function setFilterNodeTypeVersionValue(key, value) {
  return {
    type: SET_FILTER_NODE_TYPE_VERSION_VALUE,
    key,
    value,
  };
}

export function setFilterReducerProps(obj) {
  return {
    type: SET_FILTER_REDUCER_PROPS,
    obj,
  };
}

export function setFilterReducerPropsAndRecalc(obj) {
  return {
    type: SET_FILTER_REDUCER_PROPS_AND_RECALC,
    obj,
  };
}

export function resetFilters() {
  return {
    type: RESET_FILTERS,
    midd: {
      dataportSlug: true,
    },
  };
}

export function addSingleNodeTypeVersion(nodeType, swVersion) {
  return {
    type: ADD_SINGLE_NODE_TYPE_VERSION,
    nodeType,
    swVersion,
    midd: {
      dataportSlug: true,
    },
  };
}

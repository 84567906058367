import {SET_LAMPS, SET_LAMP_PROPS, SET_LAMP_MAC_ENTRY, CHANGE_SELECT_LAMPS, ADD_SELECTED_LAMPS_PROPS,
  SET_LAMP_MAC_MAP, DELETE_LAMP_MAC_ENTRY, CHANGE_SELECT_ALL_LAMPS, SET_LAMPS_GROUP, CREATE_LAMP,
  DELETE_LAMP, SET_LAMP_TYPE, SET_DRAG_LAMPS, ADD_LAMP_TO_LAMP,
  SET_LAMPS_PROPS, SET_SHOW_STANDALONE_LAMP_CONTROL,
  DRAG_LAMP, COPY_SELECTED_STANDALONE_LAMPS,
  ALIGN_SELECTED_STANDALONE_LAMPS, SET_SELECTED_LAMP_IDS,
} from '../constants/lampActionTypes';
import {LAMP_TYPE_MASTER} from '../constants/lampTypeTypes';
import objectAssign from 'object-assign';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {
  dragLamps: false,
  lampMacMap: {},
  showStandaloneLampControl: false,
  selectedLampIds: [],
};

const createDefaultLamp = () => {
  return {
    selected: false,
    mac: "",
    tops: [],
    bots: [],
    rights: [],
    lefts: [],
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    lampIds: [],
  };
};

export default function lampReducer(state = initialState, action) {

  switch (action.type) {
    case SET_LAMPS:{
      const {lamps} = action;
      return objectAssign({}, state, lamps);
    }
    case SET_LAMP_PROPS:{
      const {id, obj} = action;
      let newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[id], obj);
      return newState;
    }
    case SET_LAMP_MAC_MAP:{
      let newState = objectAssign({}, state);
      const lampMacMap = {...state.lampMacMap, ...action.lampMacMap};
      return objectAssign({}, newState, {lampMacMap});
    }
    case SET_LAMP_MAC_ENTRY:{
      const {id, mac} = action;
      let obj = {};
      obj[mac] = id;
      const lampMacMap = {...state.lampMacMap, ...obj};
      return objectAssign({}, state, {lampMacMap});
    }
    case DELETE_LAMP_MAC_ENTRY:{
      const {mac} = action;
      let newMap = objectAssign({}, state.lampMacMap);
      delete newMap[mac];
      return objectAssign({}, state, {lampMacMap: newMap});
    }
    case CHANGE_SELECT_LAMPS:{
      let newState = objectAssign({}, state);
      action.ids.forEach((id) => {
        newState[id] = objectAssign({}, state[id], {selected: action.selected});
      });
      return newState;
    }
    case CHANGE_SELECT_ALL_LAMPS:{
      let newState = objectAssign({}, state);
      action.ids.forEach((id) => {
        newState[id] = objectAssign({}, state[id], {selected: action.selected});
      });
      return newState;
    }
    case SET_LAMPS_GROUP:{
      const {lampIds, lampGroupObj} = action;
      let newState = objectAssign({}, state);
      lampIds.forEach((lampId) => {
        const group = lampGroupObj[lampId];
        newState[lampId] = objectAssign({}, newState[lampId], {group});
      });
      return newState;
    }
    case ADD_SELECTED_LAMPS_PROPS:{
      let newState = objectAssign({}, state);
      let count = 0;
      const keys = Object.keys(action.obj);
      action.lampIds.forEach((id) => {
        const lamp = state[id];
        if (lamp.selected === true) {
          newState[id] = objectAssign({}, lamp);
          keys.forEach((key) => {
            if (newState[id][key] !== undefined) {
              newState[id][key] = newState[id][key] + action.obj[key];
            }
          });
          count++;
        }
      });
      if (count === 0) {
        newState[action.id] = objectAssign({}, state[action.id]);
        keys.forEach((key) => {
          newState[action.id][key] = newState[action.id][key] + action.obj[key];
        });
      }
      return newState;
    }
    case CREATE_LAMP:{
      let newState = objectAssign({}, state);
      const {id, extraProps, lampTypeId} = action;
      const defaultLamp = createDefaultLamp();
      newState[id] = objectAssign(defaultLamp, {
        id,
        typeId: lampTypeId,
        selected: false,
      }, extraProps);
      return newState;
    }
    case DELETE_LAMP:{
      let newState = objectAssign({}, state);
      const {id} = action;
      const {selectedLampIds} = state;
      const index = selectedLampIds.indexOf(id);
      if (index !== -1) {
        const selectedLampIdsCp = [].concat(selectedLampIds);
        selectedLampIdsCp.splice(index, 1);
        newState.selectedLampIds = selectedLampIdsCp;
      }
      delete newState[id];
      return newState;
    }
    case SET_LAMP_TYPE:{
      let newState = objectAssign({}, state);
      const {id, lampType} = action;
      let obj = {typeId: lampType.id};
      if (lampType.relationType === LAMP_TYPE_MASTER) {
        obj.lampIds = [];
      }else {
        obj.lampIds = undefined;
      }

      newState[id] = objectAssign({}, state[id], obj);
      return newState;
    }
    case SET_DRAG_LAMPS:{
      return objectAssign({}, state, {dragLamps: action.dragLamps});
    }
    case ADD_LAMP_TO_LAMP:{
      let newState = objectAssign({}, state);
      const {newId, id, lampTypeId} = action;
      const defaultLamp = createDefaultLamp();
      newState[newId] = objectAssign(defaultLamp, {
        id: newId,
        rotation: 0,
        typeId: lampTypeId,
        lampIds: [],
        x: 0,
        y: 0,
        parentId: id,
      });
      let lampIdsCopy = [].concat(state[id].lampIds);
      lampIdsCopy.push(newId);

      newState[id] = objectAssign({}, state[id], {
        lampIds: lampIdsCopy,
      });

      return newState;
    }
    case SET_LAMPS_PROPS:{
      const {lamps} = action;
      let newState = objectAssign({}, state);
      lamps.forEach((lamp) => {
        newState[lamp.id] = objectAssign({}, state[lamp.id], lamp);
      });
      return newState;
    }
    case SET_SHOW_STANDALONE_LAMP_CONTROL:{
      const {value} = action;
      return objectAssign({}, state, {
        showStandaloneLampControl: value,
      });
    }
    case ALIGN_SELECTED_STANDALONE_LAMPS:{
      const {avg, prop, selectedLamps} = action;
      const newState = objectAssign({}, state);
      selectedLamps.forEach(lamp => {

        const obj = {};
        if (lamp.isSub) {
          obj[prop] = avg - lamp[`master${prop.toUpperCase()}`];
        }else {
          obj[prop] = avg;
        }
        newState[lamp.id] = objectAssign({}, state[lamp.id], obj);
      });
      return newState;
    }
    case DRAG_LAMP:{
      const {deltaX, deltaY, lampIds} = action;
      const newState = objectAssign({}, state);
      lampIds.forEach(lampId => {
        const lamp = state[lampId];
        let y;
        if (lamp.y !== undefined) {
          y = lamp.y + deltaY;
        }
        newState[lampId] = objectAssign({}, lamp, {
          x: lamp.x + deltaX,
          y,
        });
      });
      return newState;
    }
    case COPY_SELECTED_STANDALONE_LAMPS:{
      const {lampsObj, selectedLampIds} = action;
      const newState = objectAssign({}, state, lampsObj);
      selectedLampIds.forEach(lampId => {
        newState[lampId] = objectAssign({}, state[lampId], {selected: false});
      });
      return newState;
    }
    case SET_SELECTED_LAMP_IDS:{
      const {selectedLampIds} = action;
      return objectAssign({}, state, {selectedLampIds});
    }


    default:
      return state;
  }
}

import {CLEAR_LIVE_PROPS, GENERATE_ENERGY_MONITORING_STATS, SET_LIVE_MODE} from '../constants/liveActionTypes';
import {LIVE_MODE_MOVEMENT} from '../constants/livePropTypes';
import objectAssign from 'object-assign';

const initialState = {
  liveMode : LIVE_MODE_MOVEMENT,
  liveMonitoringStats : new Map()
};

export default function liveReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_LIVE_PROPS: {
      return objectAssign({}, state, {
        maxPower: undefined,
        totalPower: undefined,
      });
    }
    case GENERATE_ENERGY_MONITORING_STATS:{
      const {totalPower, maxPower} = action;
      return objectAssign({}, state, {
        totalPower,
        maxPower,
      });
    }
    case SET_LIVE_MODE:{
      return objectAssign({}, state, {liveMode: action.mode});
    }
    default: {
      return state;
    }
  }
}

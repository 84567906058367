import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setActiveClient} from '../actions/clientActions';
import '../styles/client-link.scss';

const ClientLink = ({ client, dispatch, clickAction }) => {
  if (client === undefined) {
    return null;
  }

  const { name, id } = client;
  return (
    <li onClick={() => {
      dispatch(setActiveClient(id));
      clickAction();
    }} className="client-link">
      {name}
    </li>
  );
};

ClientLink.propTypes = {
  client: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  clickAction: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  const client = state.clientReducer[props.id];

  return {
    client,
  };
}

export default connect(
  mapStateToProps,
  null,
)(ClientLink);

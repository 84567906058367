import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/clientActions';
import {slugify} from '../utils/helper';

const newClient = {
  name: 'New Client',
  slug: 'new-client',
};

class CreateClientItem extends Component {
  constructor(props) {
    super(props);

    this.state = {...newClient};
    this.create = this.create.bind(this);
  }

  create() {
    const {createClient} = this.props.actions;
    createClient(this.state);
    this.setState({...newClient});
  }

  render() {
    const {name, slug} = this.state;

    return (
      <tr>
        <td>
          <input type="text" value={name} className="form-control"
          onChange={(e) => { this.setState({name: e.target.value}); this.setState({slug: slugify(e.target.value)});}} />
        </td>
        <td>{slug}</td>
        <td>
          <button className="btn btn-primary" onClick={this.create}>Create</button>
        </td>
      </tr>
    );
  }
}

CreateClientItem.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(CreateClientItem);

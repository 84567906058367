export const SET_LAMPS = 'SET_LAMPS';
export const CLICK_LAMP = 'CLICK_LAMP';
export const SET_LAMP_PROPS = 'SET_LAMP_PROPS';
export const SET_LAMP_MAC_MAP = 'SET_LAMP_MAC_MAP';
export const SET_LAMP_MAC_ENTRY = 'SET_LAMP_MAC_ENTRY';
export const DELETE_LAMP_MAC_ENTRY = 'DELETE_LAMP_MAC_ENTRY';
export const CHANGE_SELECT_LAMPS = 'CHANGE_SELECT_LAMPS';
export const CHANGE_SELECT_ALL_LAMPS = 'SELECT_ALL_LAMPS';
export const SET_LAMPS_GROUP = 'SET_LAMPS_GROUP';
export const ADD_SELECTED_LAMPS_PROPS = 'ADD_SELECTED_LAMPS_PROPS';
export const LAMP_ZONE_CLICK = 'LAMP_ZONE_CLICK';
export const CREATE_LAMP = 'CREATE_LAMP';
export const DELETE_LAMP = 'DELETE_LAMP';
export const SET_LAMP_TYPE = 'SET_LAMP_TYPE';
export const SET_DRAG_LAMPS = 'SET_DRAG_LAMPS';
export const ADD_LAMP_TO_LAMP = 'ADD_LAMP_TO_LAMP';
export const DELETE_SUB_LAMP = 'DELETE_SUB_LAMP';
export const SET_LAMPS_PROPS = 'SET_LAMPS_PROPS';
export const DRAG_LAMP = 'DRAG_LAMP';
export const DRAG_SUB_LAMP = 'DRAG_SUB_LAMP';
export const DRAG_HITZONE = 'DRAG_HITZONE';
export const LOCATE_LAMP = 'LOCATE_LAMP';
export const REMOVE_LAMP_LINK = 'REMOVE_LAMP_LINK';
export const SET_SHOW_STANDALONE_LAMP_CONTROL = 'SET_SHOW_STANDALONE_LAMP_CONTROL';
export const CREATE_STANDALONE_LAMP = 'CREATE_STANDALONE_LAMP';
export const DELETE_SELECTED_STANDALONE_LAMPS = 'DELETE_SELECTED_STANDALONE_LAMPS';
export const ALIGN_SELECTED_STANDALONE_LAMPS = 'ALIGN_SELECTED_STANDALONE_LAMPS';
export const COPY_SELECTED_STANDALONE_LAMPS = 'COPY_SELECTED_STANDALONE_LAMPS';
export const SET_SELECTED_LAMP_IDS = 'SET_SELECTED_LAMP_IDS';

import React from 'react';
// import PropTypes from 'prop-types';
import PlanPageWrapper from './PlanPageWrapper';
import FirmwareOverview from './FirmwareOverview';

const PlanFirmwarePage = () => {
  return (
    <FirmwareOverview />
  );
};

// PlanFirmwarePage.propTypes = {
// };

export default PlanPageWrapper(PlanFirmwarePage, 'page_title_plan_firmware');

export const DEPICTION_RECT = 'RECT';
export const DEPICTION_CLOCK = 'CLOCK';
export const DepictionTypes = [
  DEPICTION_CLOCK,
  DEPICTION_RECT,
];

export const LAMP_TYPE_MASTER = 'MASTER';
export const LAMP_TYPE_SLAVE = 'SLAVE';
export const LAMP_TYPE_INDEPENDANT = 'INDEPENDANT';
export const RelationTypes = [
  LAMP_TYPE_MASTER,
  LAMP_TYPE_SLAVE,
  LAMP_TYPE_INDEPENDANT,
];

export const LOCATION_STANDALONE = 'STANDALONE';
export const LOCATION_LINE = 'LINE';
export const LocationTypes = [
  LOCATION_STANDALONE,
  LOCATION_LINE,
];

import fileDownload from 'js-file-download';
import { API } from '../constants/customTypes';
import {setPrevRoute} from '../actions/authActions';
import { apiLogger } from '../utils/logger';
import { replace } from 'connected-react-router';
import {addAlert} from '../actions/alertActions';
import {isObject} from '../utils/helper';

const apiMiddleware = store => next => action => {
  const {dispatch} = store;
  if (action.type !== API) {
    return next(action);
  }
  const {payload, props} = action;

  function parseResponse(response) {
    if (action.idProp !== undefined) {
      let ids = [];
      let obj = {};
      response.forEach((result) => {
        ids.push(result[action.idProp]);
        obj[result[action.idProp]] = result;
      });
      dispatch({
        type: payload.success,
        ids,
        obj,
        props,
      });

      } else {
        dispatch({type: payload.success, response, props});
      }
  }

  function returnDownload(response) {
    const {status} = response;

    response.blob().then((blob) => {
      if (status < 200 || status >= 300) {
        dispatch({type: payload.error, err: 'status: ' + status, props, response: blob});
      } else {
        fileDownload(blob, action.filename);
      }
    }).catch((err) => {
      if (status < 200 || status >= 300) {
        dispatch({type: payload.error, err: 'status: ' + status, props, response});
      } else {
        apiLogger('err', err);
        dispatch({type: payload.success, props});
      }
    });
  }

  function checkStatus(response){
    const {status} = response;

    if (status === 401) {
      const currentUrl = store.getState().router.location.pathname;
      const isReset = currentUrl.indexOf('/user/password-reset') > -1;
      if (currentUrl !== '/login' && currentUrl !== '/user/forgot-password' && !isReset) {
        dispatch(setPrevRoute(currentUrl));
        dispatch(replace('/login'));
      }
      // return dispatch({type: payload.error, err: 'status: ' + status, props, response: json});
    }

    response.json().then((json) => {
      if (status < 200 || status >= 300) {

        if (status >= 400 && status < 500 && action.showErrors) {
          let msg = '';
          if (status === 422 && json.errors) {
            msg = json.errors.map(error => {
              if (isObject(error)) {
                if (isObject(error.msg)) {
                  return error.msg.message;
                }else {
                  return error.msg;
                }
              }
            }).join(' ,');

          }else if (json.message) {
            msg = json.message;
          }
          dispatch(addAlert(msg));
        }

        dispatch({type: payload.error, err: 'status: ' + status, props, response: json});
      } else {
        parseResponse(json);
      }
    }).catch((err) => {
      console.log('response.json error:', err);
      if (status < 200 || status >= 300) {
        dispatch({type: payload.error, err: 'status: ' + status, props, response});
      } else {
        dispatch({type: payload.success, props});
      }
    });
  }
  let headers, body;

  if (action.download === true) {
    body = payload.body;
  } else if (action.form === true) {
    headers = {
      'Accept': 'application/json',
    };
    body = payload.body;
  } else {
    headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
    try {
      body = JSON.stringify(payload.body);
    }catch(err) {
      apiLogger('json stringify err', err);
    }
  }

  if (CLOUD) {
    if (action.payload.token) {
      headers['Authorization'] = `Token ${action.payload.token}`;
    }
  }else if (LOCAL) {
    // const sessionToken = localStorage.local_triview_token;
    // const username = localStorage.local_triview_username;
    // headers['session-token'] = sessionToken;
    // headers['username'] = username;
  }

  dispatch({type: payload.start, props: props});
  let url = payload.url;
  if(payload.urlParams !== undefined) {
    const params = new URLSearchParams();
    Object.keys(payload.urlParams).map(key => params.append(key, payload.urlParams[key]));
    url += `?${params.toString()}`;
  }

  const params = {
    method: payload.method,
    body: body,
    headers,
    credentials: action.auth ? 'include':'same-origin',
  };

  if (action.download === true) {
    fetch(url, params).then(returnDownload);
  } else {
    fetch(url, params)
    .then(checkStatus)
    .catch((err) => {
      console.log('ERORR', err);
      dispatch({type: payload.error, err, props});
    });
  }
};

export default apiMiddleware;

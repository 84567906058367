import {SET_USER_CONFIG_PERMISSIONS, SET_PASSWORD,
  SET_USER_IDENTIFIER, LOGIN, RE_AUTH, SET_USER_PROPS,
  GET_DEEPSTREAM_TOKEN, FORGOT_PASSWORD, RESET_PASSWORD,
  CHANGE_PASSWORD, CHANGE_PROFILE,
  SET_PREV_ROUTE, LOGOUT, GET_ME} from '../constants/authActionTypes';
import {API, POST, GET, PUT} from '../constants/customTypes';
import {TRIVIEW_API} from '../constants/urls';

export function setUserIdentifier(userIdentifier) {
  return {
    type: SET_USER_IDENTIFIER,
    userIdentifier,
  };
}

export function setPassword(password) {
  return {
    type: SET_PASSWORD,
    password,
  };
}

export function reAuth(token) {
  return {
    type: API,
    payload: {
      url: TRIVIEW_API.RE_AUTH,
      method: POST,
      body: {token},
      success: RE_AUTH.SUCCESS,
      start: RE_AUTH.START,
      error: RE_AUTH.ERROR,
    }
  };
}

export function login(userIdentifier, password) {
  const body = {password};
  if (CLOUD) {
    body.email = userIdentifier;
  }else if(LOCAL) {
    body.username = userIdentifier;
  }

  return {
    type: API,
    auth: true,
    showErrors: true,
    payload: {
      url: TRIVIEW_API.LOGIN,
      method: POST,
      body,
      success: LOGIN.SUCCESS,
      start: LOGIN.START,
      error: LOGIN.ERROR,
    }
  };
}

export function forgotPassword(email) {
  return {
    type: API,
    showErrors: true,
    payload: {
      url: TRIVIEW_API.FORGOT_PASSWORD,
      method: POST,
      body: {email},
      success: FORGOT_PASSWORD.SUCCESS,
      start: FORGOT_PASSWORD.START,
      error: FORGOT_PASSWORD.ERROR,
    }
  };
}

export function resetPassword(userId, token, password) {
  return {
    type: API,
    showErrors: true,
    payload: {
      url: TRIVIEW_API.RESET_PASSWORD,
      method: POST,
      body: {
        userId,
        token,
        password,
      },
      success: RESET_PASSWORD.SUCCESS,
      start: RESET_PASSWORD.START,
      error: RESET_PASSWORD.ERROR,
    }
  };
}

export function changePassword(userId, oldPassword, newPassword) {
  return {
    type: API,
    showErrors: true,
    auth: true,
    payload: {
      url: TRIVIEW_API.CHANGE_PASSWORD,
      method: POST,
      body: {
        id: userId,
        oldPassword,
        newPassword,
      },
      success: CHANGE_PASSWORD.SUCCESS,
      start: CHANGE_PASSWORD.START,
      error: CHANGE_PASSWORD.ERROR,
    }
  };
}

export function changeProfile(userId, firstname, lastname) {
  return {
    type: API,
    showErrors: true,
    auth: true,
    payload: {
      url: TRIVIEW_API.CHANGE_PROFILE,
      method: PUT,
      body: {
        id: userId,
        firstname,
        lastname,
      },
      success: CHANGE_PROFILE.SUCCESS,
      start: CHANGE_PROFILE.START,
      error: CHANGE_PROFILE.ERROR,
    },
    props: {
      firstname,
      lastname,
    },
  };
}

export function setPrevRoute(routeName) {
  return {
    type: SET_PREV_ROUTE,
    routeName,
  };
}

export function setUserProps(obj) {
  return {
    type: SET_USER_PROPS,
    obj,
  };
}

export function getMe() {
  return {
    type: API,
    auth: true,
    payload: {
      url: TRIVIEW_API.ME,
      method: GET,
      success: GET_ME.SUCCESS,
      start: GET_ME.START,
      error: GET_ME.ERROR,
    }
  };
}

export function logout() {
  return {
    type: API,
    auth: true,
    payload: {
      url: TRIVIEW_API.LOGOUT,
      method: POST,
      success: LOGOUT.SUCCESS,
      start: LOGOUT.START,
      error: LOGOUT.ERROR,
    }
  };
}
export function setUserConfigPermissions(configId, permissions) {
  return {
    type: SET_USER_CONFIG_PERMISSIONS,
    configId,
    permissions,
  };
}

export function getDsToken(username, userId) {
  return {
    type: API,
    auth: true,
    payload: {
      url: TRIVIEW_API.DEEPSTREAM.TOKEN,
      method: GET,
      success: GET_DEEPSTREAM_TOKEN.SUCCESS,
      start: GET_DEEPSTREAM_TOKEN.START,
      error: GET_DEEPSTREAM_TOKEN.ERROR,
    },
    props: {
      username,
      userId,
    },
  };
}

import {apiAction} from './helper';

export const SET_VARIABLE = 'SET_VARIABLE';
export const SET_OBJECT = 'SET_OBJECT';
export const TRANSFORM_ALL_OBJECTS = 'TRANSFORM_ALL_OBJECTS';
export const SET_VARIABLES = 'SET_VARIABLES';
export const SET_OBJECTS = 'SET_OBJECTS';
export const TRANSFORM_OBJECTS = 'TRANSFORM_OBJECTS';
export const SET_LAMP_DIALOG_MAC = 'SET_LAMP_DIALOG_MAC';
export const SET_PS_DIALOG_MAC = 'SET_PS_DIALOG_MAC';
export const SET_VARIABLE_VALUE = 'SET_VARIABLE_VALUE';
export const SET_VARIABLE_EDITING = 'SET_VARIABLE_EDITING';
export const SET_VARIABLES_VALUE_API = apiAction('SET_VARIABLES_VALUE_API');
export const PING_NODES = apiAction('PING_NODES');
export const GET_NODES = apiAction('GET_NODES');
export const POLL_NODES = apiAction('POLL_NODES');
export const FORCE_READ_CONFIG = apiAction('FORCE_READ_CONFIG');
export const SET_NODE_PROPS = 'SET_NODE_PROPS';
export const SET_NODES_PROPS = 'SET_NODES_PROPS';
export const SET_NODES_MULTI_PROPS = 'SET_NODES_MULTI_PROPS';
export const SET_LINKING = 'SET_LINKING';
export const WINK = apiAction('WINK');
export const RESET_NODE = apiAction('RESET_NODE');
export const CHANGE_SELECT_NODES = 'CHANGE_SELECT_NODES';
export const SET_LINKING_SUCCESS = 'SET_LINKING_SUCCESS';
export const SAVE_SMALI_OBJECTS = apiAction('SAVE_SMALI_OBJECTS');

import React from 'react';
import PropTypes from 'prop-types';

const BaseLampBorderZone = ({left, top, bottom, right, x, y,
  borderThickness, showTop, showRight, showLeft, showBot,
  rotation, padding, color, className, ...rest}) => {
    const halfThickness = borderThickness / 2;
    let topEl = null, botEl = null, leftEl = null, rightEl = null;
    if (showTop) {
      topEl = <rect fill={color} height={borderThickness} stroke="none" width={left + right + borderThickness} x={-left - halfThickness} y={-top - halfThickness} />;
    }
    if (showBot) {
      botEl = <rect fill={color} height={borderThickness} stroke="none" width={left + right + borderThickness} x={-left - halfThickness} y={bottom - halfThickness} />;
    }

    if (showLeft) {
      leftEl = <rect fill={color} height={top + bottom + borderThickness} stroke="none" width={borderThickness} x={-left - halfThickness} y={-top - halfThickness} />;
    }

    if (showRight) {
      rightEl = <rect fill={color} height={top + bottom + borderThickness} stroke="none" width={borderThickness} x={right - halfThickness} y={-top - halfThickness} />;
    }
  return (
    <g className={className} transform={`translate(${x}, ${y}) rotate(${rotation})`} {...rest}>
      {topEl}
      {botEl}
      {leftEl}
      {rightEl}
    </g>
  );
};

BaseLampBorderZone.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  padding: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  borderThickness: PropTypes.number.isRequired,
  showLeft: PropTypes.bool.isRequired,
  showRight: PropTypes.bool.isRequired,
  showBot: PropTypes.bool.isRequired,
  showTop: PropTypes.bool.isRequired,
};

BaseLampBorderZone.defaultProps = {
  y: 0,
  rotation: 0,
  className: '',
  color: 'black',
};

export default BaseLampBorderZone;

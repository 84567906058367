import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import '../styles/not-found-page.scss';
import SubNav from '../components/SubNav';
import { getTranslate } from 'react-localize-redux';

const NotFoundPage = ({translate}) => {
  return (
    <div className="not-found-page">
      <Helmet>
        <title>{translate('global_page_not_found')}</title>
      </Helmet>
      <SubNav parentNav="monitor" />
      <div className="not-found-content">
        <h4>
          {translate('global_page_not_found')}
        </h4>
        <Link to="/"> {translate('global_go_back_to_home')} </Link>
      </div>
    </div>
  );
};

NotFoundPage.propTypes = {
  translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const translate = getTranslate(state.localeReducer);
  return {
    translate
  };
}

export default connect(
  mapStateToProps,
  null,
)(NotFoundPage);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/nodeActions';
import * as apActions from '../actions/apActions';
import '../styles/lamp-commands.scss';
import ActionButton from '../components/ActionButton';

const LampCommands = ({mac, actions, node, translate, pp, apActions}) => {
  let winkStatus, decommissionStatus, pingStatus, pollStatus, pingFails, forceReadConfigStatus, resetStatus;
  const {wink, pingNodes, pollNodes, forceReadConfig, resetNode} = actions;
  const {decommission, decommissionNode} = apActions;

  const decommissionFunc = () => {
    if(pp !== undefined) {
      decommission(pp.parentId, [pp.terminal_id]);
    }else {
      decommissionNode([mac]);
    }
  };

  if (node !== undefined) {
    winkStatus = node.winkStatus;
    decommissionStatus = node.decommissionStatus;
    pingStatus = node.pingStatus;
    pollStatus = node.pollStatus;
    pingFails = node.pingFails;
    forceReadConfigStatus = node.forceReadConfigStatus;
    resetStatus = node.resetStatus;
  }

  let fails = null;
  if (pingFails > 0) {
    fails = (
      <span className="fails">{pingFails}</span>
    );
  }
  return (
      <span className="lamp-commands">
        <ActionButton action={() => wink(mac)} status={winkStatus} icon="fa-lightbulb-o"
          text={translate('technical_wink')} />
        <ActionButton action={() => decommissionFunc()} status={decommissionStatus} icon="fa-trash"
          text={translate('technical_decommission')} />
        <ActionButton action={() => pingNodes([mac])} status={pingStatus} icon="fa-podcast"
          text={translate('technical_ping')} />{fails}
        <ActionButton action={() => pollNodes([mac])} status={pollStatus} icon="fa-refresh"
          text={translate('technical_poll')} />
        <ActionButton action={() => forceReadConfig(mac)} status={forceReadConfigStatus} icon="fa-refresh"
          text="Force read Config" />
        <ActionButton action={() => resetNode(mac)} status={resetStatus} icon="fa-refresh"
          text="Reset" />
      </span>
  );
};

LampCommands.propTypes = {
  mac: PropTypes.string,
  node: PropTypes.object,
  pp: PropTypes.object,
  actions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  apActions: PropTypes.object.isRequired,
};

// function mapStateToProps(state, {mac}) {
//   const node = state.nodeReducer[mac];
//   const translate = getTranslate(state.localeReducer);
//   return {
//     node,
//     mac,
//     translate
//   };
// }

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    apActions: bindActionCreators(apActions, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(LampCommands);

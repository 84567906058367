import React from 'react';
import PropTypes from 'prop-types';
import {DraggableCore} from 'react-draggable';

const Drag = ({children, action, dragStart }) => {

  const drag = (event, data) => {
    action(data.deltaX, data.deltaY);
  };

  const onStart = () => {
    if (dragStart) {
      dragStart();
    }
  };

  return (
    <DraggableCore onDrag={drag} onStart={() => onStart()}>
      {children}
    </DraggableCore>
  );
};

Drag.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.func.isRequired,
  dragStart: PropTypes.func,
};

export default Drag;

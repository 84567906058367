import {SET_USER_CONFIG_PERMISSIONS, SET_PASSWORD,
  SET_USER_IDENTIFIER, LOGIN, RE_AUTH, SET_USER_PROPS,
  FORGOT_PASSWORD, RESET_PASSWORD, CHANGE_PASSWORD,
  CHANGE_PROFILE,
  SET_PREV_ROUTE, GET_ME} from '../constants/authActionTypes';
import objectAssign from 'object-assign';
import {statusHandler} from '../utils/reducerHelper';
import {INITIAL} from '../constants/customTypes';

const initialState = {
  userIdentifier: '',
  password: '',
  pending: false,
  error: false,
  token: '',
  prevRoute: '',
  groups: [],
  permissions: [],
  firstname: '',
  lastname: '',
  staff: false,
  loggedIn: false,
  forgotPasswordStatus: INITIAL,
  resetPasswordStatus: INITIAL,
  changePasswordStatus: INITIAL,
  changeProfileStatus: INITIAL,
};

const statusHandlerFunc = statusHandler([
  {
    apiAction: FORGOT_PASSWORD,
    statusPropName: 'forgotPasswordStatus',
  },
  {
    apiAction: RESET_PASSWORD,
    statusPropName: 'resetPasswordStatus',
  },
  {
    apiAction: CHANGE_PASSWORD,
    statusPropName: 'changePasswordStatus',
  },
  {
    apiAction: CHANGE_PROFILE,
    statusPropName: 'changeProfileStatus',
  },
]);

export default function authReducer(originalState = initialState, action) {

  const state = statusHandlerFunc(originalState, action);

  switch (action.type) {
    case SET_USER_CONFIG_PERMISSIONS:{
      const newState = objectAssign({}, state);
      newState[action.configId] = action.permissions;
      return newState;
    }
    case SET_USER_IDENTIFIER:
      return objectAssign({}, state, {userIdentifier: action.userIdentifier});
    case SET_PASSWORD:
      return objectAssign({}, state, {password: action.password});
    case LOGIN.ERROR:
      return objectAssign({}, state, {error: true, pending: false});
    case LOGIN.START:
      return objectAssign({}, state, {error: false, pending: true});
    case LOGIN.SUCCESS:
      return objectAssign({}, state, action.response);
    case SET_PREV_ROUTE:
      return objectAssign({}, state, {prevRoute: action.routeName});
    case RE_AUTH.SUCCESS:
      localStorage.trimonitor_token = action.response.token;
      return objectAssign({}, state, {token: action.response.token});
    case SET_USER_PROPS:{
      return objectAssign({}, state, action.obj);
    }
    case GET_ME.SUCCESS:{
      return objectAssign({}, state, action.response, {loggedIn: true});
    }
    case CHANGE_PROFILE.SUCCESS:{
      return objectAssign({}, state, action.props);
    }

    default:
      return state;
  }
}

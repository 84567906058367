import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const LampTypeOption = ({ lampType }) => {
  const {id, label} = lampType;
  return (
    <option value={id}>
      {label}
    </option>
  );
};

LampTypeOption.propTypes = {
  lampType: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  const {lampTypeReducer} = state;
  const lampType = lampTypeReducer[props.id];

  return {
    lampType,
  };
}

export default connect(
  mapStateToProps,
  null,
)(LampTypeOption);

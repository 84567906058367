import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Dialog from '../components/DialogWrapper';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/firmwareActions';
import UpgradeNodeType from './UpgradeNodeType';
import '../styles/update-dialog.scss';
import {LAMP} from '../constants/customTypes';
import ActionButton from '../components/ActionButton';
import { getTranslate } from 'react-localize-redux';
import Checkbox from '../components/Checkbox';
import DateTimeInput from '../components/DateTimeInput';

class UpgradeDialog extends Component {
  componentDidUpdate() {
    // if (this.logger && prevProps.site && prevProps.site.updateLogs && this.props.site.updateLogs.length > prevProps.site.updateLogs.length) {
    //   this.logger.scrollTop = this.logger.scrollHeight;
    // }
  }

  render() {
    const {actions, showFirmwareDialog, nodeTypes, mode, translate, schedule,
    startDate, stopDate, upgradeIsScheduled, scheduledStartDate, scheduledStopDate, apOnOff, pollAndSync} = this.props;
    if (showFirmwareDialog === false) {
      return null;
    }

    const {setShowUpgradeDialog, setFirmwareReducerProps, startUpgrade, cancelScheduledUpgrade} = actions;

    let lampOptionsEl = null;

    let datesEl = null;

    if (schedule === true) {
      datesEl = (
        <span>
          <div className="form-inline">
            <div className="form-group">
              <label htmlFor="startDate">Start Date:&nbsp;</label>
              <DateTimeInput value={startDate} id="startDate" className="form-control"
                onChange={(value) => setFirmwareReducerProps({startDate: value})}/>
            </div>
          </div>
          <div className="form-inline">
            <div className="form-group">
              <label htmlFor="stopDate">Stop Date:&nbsp;</label>
              <DateTimeInput value={stopDate} id="stopDate" className="form-control"
                onChange={(value) => setFirmwareReducerProps({stopDate: value})}/>
            </div>
          </div>
          <div className="form-inline">
            <div className="form-group">
              <Checkbox checked={apOnOff}
                 onChange={() => setFirmwareReducerProps({apOnOff: !apOnOff})}>
                Turn aps on at start and off at stop
              </Checkbox>
            </div>
          </div>
        </span>
      );
    }

    if (mode === LAMP) {
      lampOptionsEl = (
        <span>
          <div className="form-inline">
            <div className="form-group">
              <Checkbox checked={pollAndSync}
                 onChange={() => setFirmwareReducerProps({pollAndSync: !pollAndSync})}>
                 Poll before update and synchronize after
               </Checkbox>
            </div>
          </div>
          <div className="form-inline">
            <div className="form-group">
              <Checkbox checked={schedule}
                 onChange={() => setFirmwareReducerProps({schedule: !schedule})}>
                 Schedule
               </Checkbox>
            </div>
          </div>
        </span>
      );
    }

    let body = (
      <div className="row">
        <div className="col-md-12">
          {lampOptionsEl}
          {datesEl}
          {nodeTypes.map((nodeType) => {
            return <UpgradeNodeType key={nodeType} nodeType={nodeType} />;
          })
          }
        </div>
      </div>
    );

    let footer = (
      <ActionButton action={() => startUpgrade()}
        extraClass="btn-primary"
        text={translate('technical_start_upgrade')}/>
    );

    if (upgradeIsScheduled === true) {
      body = (
        <div className="row">
          <div className="col-md-12">
            <p>Update scheduled:</p>
            <ul>
              <li>StartDate: {scheduledStartDate}</li>
              <li>StopDate: {scheduledStopDate}</li>
            </ul>
          </div>
        </div>
      );

      footer = (
        <ActionButton action={() => cancelScheduledUpgrade()}
          extraClass="btn-danger"
          text="Cancel Upgrade"/>
      );

    }

    return (
      <span className="upgrade-dialog">
        <Dialog title={`Upgrade`} visible={true} body={body}
          closeLabel={translate('global_close')}
          closeAction={() => setShowUpgradeDialog(false)} footer={footer} />
      </span>
    );
  }
}

UpgradeDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  showFirmwareDialog: PropTypes.bool.isRequired,
  nodeTypes: PropTypes.array.isRequired,
  mode: PropTypes.string,
  translate: PropTypes.func.isRequired,
  schedule: PropTypes.bool.isRequired,
  startDate: PropTypes.string.isRequired,
  stopDate: PropTypes.string.isRequired,
  upgradeIsScheduled: PropTypes.bool.isRequired,
  scheduledStartDate: PropTypes.string.isRequired,
  scheduledStopDate: PropTypes.string.isRequired,
  apOnOff: PropTypes.bool.isRequired,
  pollAndSync: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {firmwareReducer} = state;
  const {nodeTypes, showFirmwareDialog, mode,
    schedule, startDate, stopDate, upgradeIsScheduled, scheduledStartDate, scheduledStopDate,
  apOnOff, pollAndSync} = firmwareReducer;
  const translate = getTranslate(state.localeReducer);
  return {
    showFirmwareDialog,
    nodeTypes,
    mode,
    translate,
    schedule,
    startDate,
    stopDate,
    upgradeIsScheduled,
    scheduledStartDate,
    scheduledStopDate,
    apOnOff,
    pollAndSync,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpgradeDialog);

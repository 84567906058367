import React from 'react';
import PropTypes from 'prop-types';
import ClockLamp from '../containers/ClockLamp';
import RectLamp from '../containers/RectLamp';
import {DEPICTION_RECT} from '../constants/lampTypeTypes';

const LampDepiction = ({...rest}) => {
  const {depictionType} = rest.lampType;
  if (depictionType === DEPICTION_RECT) {
    return <RectLamp {...rest} />;
  }
  return <ClockLamp {...rest} />;
};

LampDepiction.propTypes = {
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  extraRotation: PropTypes.number.isRequired,
};

export default LampDepiction;

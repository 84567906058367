import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/nodeActions';
import HardwareVersion from '../components/HardwareVersion';
import SoftwareVersion from '../components/SoftwareVersion';
import { getTranslate } from 'react-localize-redux';

const PsesInfo = ({psMacs, nodes, translate, aps}) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th/>
          <th>{translate('technical_nodetype')}</th>
          <th>{translate('technical_hardwareversion')}</th>
          <th>{translate('technical_softwareversion')}</th>
          <th>{translate('technical_dectmode')}</th>
          <th>{translate('technical_dectversion')}</th>
          <th>{translate('technical_ip')}</th>
        </tr>
      </thead>
      <tbody>
        {psMacs.map((mac) => {
          const node = nodes[mac];
          const ap = aps[mac];
          let dectMode, dectVersion, ip;
          let hwVersion, swVersion, nodeType;
          if (node !== undefined) {
            nodeType = node.node_type;
            hwVersion = node.hardware_version;
            swVersion = node.software_version;
          }
          if (ap !== undefined) {
            dectMode = ap.mode;
            dectVersion = ap.version;
            ip = ap.ip_address;
          }
          return (
            <tr key={mac}>
              <td>{mac}</td>
              <td>{nodeType}</td>
              <td><HardwareVersion hardwareVersion={hwVersion} /></td>
              <td><SoftwareVersion softwareVersion={swVersion} /></td>
              <td>{dectMode}</td>
              <td>{dectVersion}</td>
              <td>{ip}</td>
            </tr>
          );
        })}
      </tbody>
    </table>

  );
};

PsesInfo.propTypes = {
  psMacs: PropTypes.array.isRequired,
  nodes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  aps: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    nodes: state.nodeReducer,
    aps: state.apReducer,
    translate: getTranslate(state.localeReducer)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PsesInfo);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/lampActions';
import ActionButton from '../components/ActionButton';
import DragLampsCheckbox from './DragLampsCheckbox';
import ModeControl from '../containers/ModeControl';
import SelectedLampControl from '../containers/SelectedLampControl';
import '../styles/drawing-control.scss';
import '../styles/standalone-lamp-control.scss';

const StandaloneLampControl = ({actions, showStandaloneLampControl, selectedLampIds}) => {
  const {createStandaloneLamp, deleteSelectedStandaloneLamps, setShowStandaloneLampControl, alignSelectedStandaloneLamps,
  copySelectedStandaloneLamps, changeSelectAllLamps} = actions;
  let collapseEl = null;
  let selectedLampEl = null;
  if (selectedLampIds.length === 1) {
    selectedLampEl = (
      <SelectedLampControl id={selectedLampIds[0]} />
    );
  }
  if (showStandaloneLampControl) {
    collapseEl = (
      <span>
        <ModeControl showSelectPses={false} />
        {selectedLampEl}
        <div className="inline">
          <div>
            <ActionButton action={() => createStandaloneLamp()}
              extraClass="btn-primary"
              text="Create" />
          </div>
          <div>
            <ActionButton action={() => deleteSelectedStandaloneLamps()}
              extraClass="btn-primary"
              text="Delete selected" />
          </div>
          <div>
            <ActionButton action={() => alignSelectedStandaloneLamps()}
              extraClass="btn-primary"
              text="Align selected" />
          </div>
          <div>
            <ActionButton action={() => copySelectedStandaloneLamps()}
              extraClass="btn-primary"
              text="Copy" />
          </div>
          <div>
            <ActionButton action={() => changeSelectAllLamps(false)}
              extraClass="btn-primary"
              text="Deselect" />
          </div>
        </div>
        <DragLampsCheckbox />
      </span>
    );
  }

  return (
    <span className="drawing-control standalone-lamp-control">
      <div className="row">
        <div className="col-md-12">
          <h4 onClick={() => setShowStandaloneLampControl(!showStandaloneLampControl)}
          className="pointer">
          <i className={"fa " + (showStandaloneLampControl ? "fa-minus":"fa-plus")}
          aria-hidden="true"/>
            Standalone Lamp Control
          </h4>
        </div>
      </div>
      {collapseEl}
    </span>
  );
};

StandaloneLampControl.propTypes = {
  actions: PropTypes.object.isRequired,
  showStandaloneLampControl: PropTypes.bool.isRequired,
  selectedLampIds: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  const {showStandaloneLampControl, selectedLampIds} = state.lampReducer;
  return {
    showStandaloneLampControl,
    selectedLampIds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StandaloneLampControl);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import UpgradeSwVersion from './UpgradeSwVersion';

const UpgradeNodeType = ({nodeType, versions}) => {
  return (
    <div className="upgrade-node-type">
      <h5>{nodeType}</h5>
      {versions.map((swVersion, i) => {
        return <UpgradeSwVersion key={i} swVersion={swVersion} nodeType={nodeType} />;
      })
      }
    </div>
  );
};

UpgradeNodeType.propTypes = {
  nodeType: PropTypes.string.isRequired,
  versions: PropTypes.array.isRequired,
};

function mapStateToProps({firmwareReducer}, {nodeType}) {
  return {
    versions: firmwareReducer[nodeType],
  };
}

export default connect(
  mapStateToProps,
  null,
)(UpgradeNodeType);

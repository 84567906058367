import {SET_BROWSER_DIMENSIONS} from '../constants/generalActionTypes';
import objectAssign from 'object-assign';


// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
const initialState = {
  browserWidth: 1920,
  browserHeight: 1080,
};

export default function generalReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BROWSER_DIMENSIONS:{
      const {browserWidth, browserHeight} = action;
      return objectAssign({}, state, {browserWidth, browserHeight});
    }

    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import FilterSwVersion from './FilterSwVersion';

const FilterNodeType = ({nodeType, versions, dataportSlug}) => {
  return (
    <div className="upgrade-node-type">
      <h5>{nodeType}</h5>
      {versions.map((swVersion, i) => {
        return <FilterSwVersion key={i} swVersion={swVersion} dataportSlug={dataportSlug} nodeType={nodeType} />;
      })
      }
    </div>
  );
};

FilterNodeType.propTypes = {
  nodeType: PropTypes.string.isRequired,
  versions: PropTypes.array.isRequired,
  dataportSlug: PropTypes.string.isRequired,
};

function mapStateToProps({filterReducer}, {nodeType, dataportSlug}) {
  return {
    versions: filterReducer[`${dataportSlug}/${nodeType}`],
  };
}

export default connect(
  mapStateToProps,
  null,
)(FilterNodeType);

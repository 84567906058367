import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/clientActions';
import ClientItem from '../containers/ClientItem';
import ConfirmDialog from '../containers/ConfirmDialog';
import CreateClientItem from '../components/CreateClientItem';

class ClientsOverview extends Component {
  componentDidMount() {
    const {actions, clientIds} = this.props;
    const {getClients} = actions;
    if (clientIds.length === 0) {
      getClients();
    }
  }

  render() {
    const {clientIds, deleteClientId, actions} = this.props;
    const {setClientReducerProps, deleteClient} = actions;
    if (!clientIds) {
      return <p>No clients</p>;
    }

    return (
      <div className="clients-overview">
        <ConfirmDialog show={deleteClientId !== undefined} closeAction={() => setClientReducerProps({deleteClientId: undefined})}
          confirmAction={() => deleteClient(deleteClientId)} text="Are you sure you want to delete this client? Also make sure you deleted all buildings of this client before you delete this client."
          title="Delete Client" />
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Slug</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            <CreateClientItem />
            {clientIds.map(clientId => {
              return <ClientItem key={clientId} id={clientId} />;
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

ClientsOverview.propTypes = {
  clientIds: PropTypes.array,
  deleteClientId: PropTypes.number,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {deleteClientId, clientIds} = state.clientReducer;
  return {
    clientIds,
    deleteClientId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientsOverview);

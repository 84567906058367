import React from 'react';
import PropTypes from 'prop-types';
import BaseLamp from '../containers/BaseLamp';

const ClockLamp = ({lamp, lampType, children, extraRotation}) => {
  const {x, y, selected, mac, id, rotation, ...rest} = lamp;
  const {radius} = lampType;
  return (
    <BaseLamp x={x} y={y} mac={mac} id={id} selected={selected} extraRotation={extraRotation}
     circleAnimationX={0} circleAnimationY={0} rotation={rotation} {...rest}>
      <circle r={radius} />;
      <line x1={-radius} y1={-radius} x2={radius} y2={radius} strokeWidth="40" stroke="grey" />
      <line x1={-radius} y1={radius} x2={radius} y2={-radius} strokeWidth="40" stroke="grey" />
      {children}
    </BaseLamp>
  );
};

ClockLamp.propTypes = {
  lamp: PropTypes.object.isRequired,
  lampType: PropTypes.object.isRequired,
  children: PropTypes.node,
  extraRotation: PropTypes.number.isRequired,
};

export default ClockLamp;

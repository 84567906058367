
export const PROFILE_ON = 'PROFILE_ON';
export const PROFILE_STANDBY = 'PROFILE_STANDBY';
export const PROFILE_OFF = 'PROFILE_OFF';

export const STANDARD_FLOW = 'standard';
export const BASELINE_FLOW = 'baseline';

export const BEHAVIOUR_STANDARD = 'standard';
export const BEHAVIOUR_DAYLIGHT = 'daylight';

export const PROFILE_FILTER_ALL = 'PROFILE_FILTER_ALL';
export const PROFILE_FILTER_BUILDING = 'PROFILE_FILTER_BUILDING';

export const PROFILE_VALIDATION_ERRORS_OBJ ={
  standbyErr: false,
  onErr: false,
  timeoutOnErr: false,
  timeoutStandbyErr: false,
  nameErr: false,
};


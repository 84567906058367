import {GET_DATAPORT, GET_DATAPORTS, SET_DATAPORT_REDUCER_PROPS,
  UPDATE_DATAPORT, DELETE_DATAPORT, CREATE_DATAPORT, GET_DATAPORTS_TECHNICAL,
  GET_DATAPORT_INFO,
  REFRESH_DATAPORT_INFO,
  DATAPORT_COMMAND_STDOUT_EVENT,
  DATAPORT_COMMAND_CODE_EVENT,
  SET_DATAPORT_PROPS,
  EXECUTE_DATAPORT_COMMAND_RPC,
  GET_DATAPORT_COMMAND_OUTPUT,
  UPDATE_DATAPORT_PACKAGE_RPC,
} from '../constants/dataportActionTypes';
import {INITIAL} from '../constants/customTypes';
import {statusHandler} from '../utils/reducerHelper';
import objectAssign from 'object-assign';

const initialState = {
  dataportIds: [],
  selectedDpIds: [],
  dataportOptions: [],
  technicalDataportsFetched: false,
  refreshDataportInfoStatus: INITIAL,
  output: '',
  dataportsCommand: '',
};

const dpDefaultProps = {
  selected: false,
  command: '',
};

const statusHandlerFunc = statusHandler([
  {
    apiAction: REFRESH_DATAPORT_INFO,
    statusPropName: 'refreshDataportInfoStatus',
  },
]);

export default function dataportReducer(originalState = initialState, action) {
  const state = statusHandlerFunc(originalState, action);
  switch (action.type) {
    case GET_DATAPORT.SUCCESS:{
      const newState = objectAssign({}, state);
      const {name, capabilities} = action.response;
      let canDaylight = false;
      let canBaseline = false;
      capabilities.forEach((capabilityObj) => {
        if (capabilityObj.capable === true && capabilityObj.enabled === true) {
          if (capabilityObj.name === 'baseline') {
            canBaseline = true;
          }else if (capabilityObj.name === 'daylight') {
            canDaylight = true;
          }
        }
      });
      newState[name] = objectAssign({}, state[name], {canDaylight, canBaseline});
      return newState;
    }
    case GET_DATAPORTS.SUCCESS:{
      const {ids, obj} = action;
      const dataportOptions = ids.map(id => ({value: id, label: obj[id].name}));
      return objectAssign({}, dpDefaultProps, state, {dataportIds: ids, dataportOptions}, obj);
    }
    case UPDATE_DATAPORT.SUCCESS:{
      const {id, name} = action.response;
      const newState = objectAssign({}, state);
      newState[id] = objectAssign({}, state[id], action.response);
      newState.dataportOptions = state.dataportOptions.map(obj => {
        if (obj.value === id) {
          return objectAssign({}, obj, {name});
        }
        return obj;
      });
      return newState;
    }
    case CREATE_DATAPORT.SUCCESS:{
      const {id} = action.response;
      const newState = objectAssign({}, state);
      newState[id] = objectAssign({}, dpDefaultProps, action.response);
      newState.dataportIds = [id].concat(state.dataportIds);
      newState.dataportOptions = [{value: id, label: action.response.name}].concat(state.dataportOptions);
      return newState;
    }
    case DELETE_DATAPORT.SUCCESS:{
      const {id} = action.props;
      return objectAssign({}, state, {
        deleteDataportId: undefined,
        dataportOptions: state.dataportOptions.filter(({value}) => value !== id),
        dataportIds: state.dataportIds.filter(dataportId => dataportId !== id),
      });
    }
    case SET_DATAPORT_REDUCER_PROPS:{
      return objectAssign({}, state, action.obj);
    }
    case SET_DATAPORT_PROPS:{
      const {id, obj} = action;
      const newState = objectAssign({}, state);
      if (obj.selected !== undefined) {
        const selectedDpIds = [].concat(state.selectedDpIds);
        if (obj.selected === true) {
          selectedDpIds.push(id);
        }else {
          selectedDpIds.splice(selectedDpIds.indexOf(id), 1);
        }
        newState.selectedDpIds = selectedDpIds;
      }
      newState[id] = objectAssign({}, state[id], obj);
      return newState;
    }
    case GET_DATAPORTS_TECHNICAL.SUCCESS:{
      const newState = objectAssign({}, state, {technicalDataportsFetched: true});
      const dataports = action.response;
      newState.dataportIds = dataports.map(dataport => {
        newState[dataport.id] = objectAssign({}, dpDefaultProps, state[dataport.id], dataport);
        return dataport.id;
      });
      return newState;
    }
    case REFRESH_DATAPORT_INFO.SUCCESS:
    case GET_DATAPORT_INFO.SUCCESS:{
      const dataport = action.response;
      const newState = objectAssign({}, state);
      newState[dataport.id] = objectAssign({}, dpDefaultProps, state[dataport.id], dataport);
      return newState;
    }
    case DATAPORT_COMMAND_CODE_EVENT:{
      const {props, data} = action;
      const {sessionId, dataportId} = props;
      const oldDp = state[dataportId];
      if (sessionId === oldDp.sessionId) {
        const newState = objectAssign({}, state);
        newState[dataportId] = objectAssign({}, oldDp, {code: data === null ? -1:data});
        return newState;
      }else {
        return state;
      }
    }
    case DATAPORT_COMMAND_STDOUT_EVENT:{
      const {props, data} = action;
      const {sessionId, dataportId} = props;
      const oldDp = state[dataportId];
      if (sessionId === oldDp.sessionId) {
        const {charCount} = oldDp;
        let newCharCount = charCount;
        newCharCount += data.data.length;
        const newOutput = oldDp.output.concat([data]);
        const max = 50000;

        while (newCharCount > max) {
          const len = newOutput[0].data.length;
          if (newCharCount - len > max) {
            newOutput.splice(0, 1);
            newCharCount -= len;
          }else {
            newOutput[0] = objectAssign({}, newOutput[0], {data: newOutput[0].data.slice(- len - (max - newCharCount))});
            newCharCount = max;
          }
        }

        const newState = objectAssign({}, state);
        newState[dataportId] = objectAssign({}, oldDp, {output: newOutput, charCount: newCharCount});
        return newState;
      }else {
        return state;
      }
    }
    case UPDATE_DATAPORT_PACKAGE_RPC.SUCCESS:
    case EXECUTE_DATAPORT_COMMAND_RPC.SUCCESS:{
      const {dataportId, sessionId} = action.props;
      const newState = objectAssign({}, state);
      newState[dataportId] = objectAssign({}, state[dataportId], {sessionId, output: [], code: -99, charCount: 0});
      return newState;
    }
    case GET_DATAPORT_COMMAND_OUTPUT.SUCCESS:{
      const output = action.response;
      const {dataportId} = action.props;
      const newState = objectAssign({}, state);
      newState[dataportId] = objectAssign({}, state[dataportId], {output});
      return newState;
    }


    default:
      return state;
  }
}

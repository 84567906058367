import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/buildingActions';
import FormInput from '../components/FormInput';
import DataStorageOptions from '../components/DataStorageOptions';
import {slugify} from '../utils/helper';

const newBuilding = {
  name: 'New Building',
  slug: 'new-building',
  width: 0,
  height: 0,
};

class EditBuildingPage extends Component {
  constructor(props) {
    super(props);
    const {building} = props;
    if (!building) {
      this.state = {...newBuilding};
    }else {
      this.state = {...building};
    }
    this.save = this.save.bind(this);
  }

  save() {
    const {id, width, height} = this.state;
    const {clientId} = this.props;
    const {updateBuilding, createBuilding} = this.props.actions;
    const building = {...this.state,
      width: parseFloat(width),
      height: parseFloat(height),
    };
    if (id !== undefined) {
      updateBuilding(building);
    }else {
      createBuilding(building, parseInt(clientId));
    }
  }

  render() {
    const {id, name, slug, width, height, dataportId, dataportSlug} = this.state;

    let dataportEl = null;
    if (CLOUD) {
      const DataportSelect = require('../containers/DataportSelect').default;
      dataportEl = (
        <span>
          <label htmlFor="dataport">Dataport:</label>
          <DataportSelect value={dataportId} name="dataport" onChange={(obj) => this.setState({dataportId: obj ? obj.value:null})} />
        </span>
      );
    }else if (LOCAL) {
      dataportEl = dataportSlug;
    }
    let dataStorageEl = null;
    if (id !== undefined) {
      dataStorageEl = (
        <>
          <h1>Data Storage Options</h1>
          <DataStorageOptions configId={id} />
        </>
      );
    }

    return (
      <div className="container">
        <h1>Building</h1>
        <form onSubmit={(e) => { e.preventDefault(); this.save();}}>
          <FormInput id="name" type="text" label="Name" value={name}
            onChange={(value) => { this.setState({name: value}); this.setState({slug: slugify(value)});}} required />
          <p>Slug: {slug}</p>
          <FormInput id="width" type="number" label="Width" value={width} required
            min="0" onChange={(val) => this.setState({width: val})} />
          <FormInput id="height" type="number" label="Height" value={height}
            min="0" onChange={(val) => this.setState({height: val})} required />
          <div style={{marginBottom: '15px'}}>
            {dataportEl}
          </div>
          <button className="btn btn-primary" >
            {id === undefined ? 'Create':'Update'}
          </button>
        </form>
        {dataStorageEl}
      </div>
    );
  }
}

EditBuildingPage.propTypes = {
  building: PropTypes.object,
  actions: PropTypes.object.isRequired,
  clientId: PropTypes.string,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  const {buildingId, clientId} = props.match.params;
  return {
    building: state.buildingReducer[buildingId],
    clientId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditBuildingPage);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Lamp from './Lamp';
import Drag from '../components/Drag';
import LampClickZone from './LampClickZone';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/lineActions';
import Ps from './Ps';
import {isRoute} from '../utils/helper';
import {ROUTE_DRAWING} from '../constants/const';

const Line = ({line, canDragLine, actions, currentRoute}) => {
  const {dragLine, clickLine} = actions;
  const {length, x, y, lampIds, rotation, id, psId} = line;
  const transform = `translate(${x},${y}),rotate(${rotation})`;

  const onClick = () => {
    if (isRoute(currentRoute, ROUTE_DRAWING)) {
      clickLine(line.id);
    }
  };

  const lampEls = [];
  const lampClickZoneEls = [];
  lampIds.forEach(id => {
    lampEls.push(
      <Lamp key={id} id={id} extraRotation={rotation} />
    );

    lampClickZoneEls.push(
      <LampClickZone key={id} id={id} />
    );
  });

  const lineEl = (
    <g transform={transform} className={"line " + (line.selected === true ? 'selected':'')} onClick={onClick}>
      <line x1="0" y1="0" x2={`${length}`} y2="0" />

      {lampEls}
      {lampClickZoneEls}

      {line.showPs === true ?
          <Ps id={psId} />
          :
          null
      }
    </g>
  );
  const dragAction = (deltaX, deltaY) => {
    dragLine(id, deltaX, deltaY);
  };

  if (canDragLine) {
    return (
      <Drag action={dragAction}>
        {lineEl}
      </Drag>
    );
  }
  return lineEl;
};

Line.propTypes = {
  actions: PropTypes.object.isRequired,
  line: PropTypes.object,
  canDragLine: PropTypes.bool.isRequired,
  currentRoute: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

function mapStateToProps(state, props) {
  const {canDragLine} = state.lineReducer;
  const {currentRoute} = state.routingReducer;

  return {
    line: state.lineReducer[props.id],
    canDragLine,
    currentRoute,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Line);

import React from 'react';
import {Helmet} from 'react-helmet';
import '../styles/not-found-page.scss';
import {Link} from 'react-router-dom';
import UsersOverview from '../containers/UsersOverview';

const UsersPage = () => {
  return (
    <div className="users-page">
      <Helmet>
        <title>Users | TriVIEW</title>
      </Helmet>
      <div className="container">
        <h1>Users</h1>
        <Link className="btn btn-primary" to="/user/new">Create</Link>
        <UsersOverview />
      </div>
    </div>
  );
};

export default UsersPage;

export const translateSoftwareVersion = function(hexVersion) {

  if (hexVersion === undefined || hexVersion.length < 8) {
    return 'Not found';
  }
  let translatedVersion = 'V ';

  translatedVersion += parseInt(hexVersion.substr(4, 2), 16) + '.';
  translatedVersion += parseInt(hexVersion.substr(6, 2), 16) + '.';
  translatedVersion += parseInt(hexVersion.substr(8, 2), 16);

  return translatedVersion;
};

export const translateHardwareVersion = function(hexVersion) {

  if (hexVersion === undefined || hexVersion.length < 8) {
    return 'Not found';
  }
  const lastValue = parseInt(hexVersion.substr(-2), 16);

  const alfa = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase();

  let rev, bom, tripVNr;
  if (0 === lastValue) {
    tripVNr = parseInt(hexVersion.substr(2, 2), 16);
    rev = alfa[parseInt(hexVersion.substr(4, 2), 16) - 1];
    bom = alfa[parseInt(hexVersion.substr(6, 2), 16) - 1];
  }else {
    tripVNr = parseInt(hexVersion.substr(2, 4), 16);
    rev = alfa[parseInt(hexVersion.substr(6, 2), 16) - 1];
    bom = alfa[lastValue - 1];
  }

  for (let i = String(tripVNr).length; i < 3; i++) {
    tripVNr = '0' + tripVNr;
  }

  rev = rev === undefined ? '0':rev;
  bom = bom === undefined ? '0':bom;

  return `TRIP${tripVNr}R${rev}B${bom}`;
};

export const getUniqueNodeVersions = (nodes) => {
  let nodeTypes = [];
  let nodeTypeObj = {};
  let versionObj = {};

  for (const node of nodes) {
    if (node === undefined) {
      continue;
    }

    let {node_type: nodeType, software_version: swVersion} = node;
    if (nodeTypeObj[nodeType] === undefined) {
      nodeTypeObj[nodeType] = [swVersion];
      nodeTypes.push(nodeType);
      versionObj[`${nodeType}/${swVersion}`] = '';
    }else {
      const key = `${nodeType}/${swVersion}`;
      if (versionObj[key] === undefined) {
        nodeTypeObj[nodeType].push(swVersion);
        versionObj[key] = '';
      }
    }
  }

  return {nodeTypes, nodeTypeObj, versionObj};
};

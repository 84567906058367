import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../actions/languageActions';
import {getLanguages, getTranslate, getActiveLanguage } from 'react-localize-redux';
import {bindActionCreators} from 'redux';

const LanguageSelector = ({ actions, languages, currentLanguage, translate }) => {
  return (
    <ul className="nav navbar-nav navbar-right">
        {
          languages.map((language) => {
              {
                if (currentLanguage !== language.code) {
                  return (
                    <li key={'li_' + language.code}>
                      <a href="#" key={language.code} onClick={() => {
                          actions.setLanguage(language.code);
                        }}>
                        {translate('global_lang_' + language.code)  + ' '}
                      </a>
                    </li>);
                } else {
                  return (
                  <li key={'li_' + language.code} className="active">
                    <a href="#" key={language.code}>
                    {translate('global_lang_' + language.code)  + ' '}
                    </a>
                  </li>);
                }
              }
          })
        }
    </ul>
  );
};

LanguageSelector.propTypes = {
  actions: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localeReducer),
  languages: getLanguages(state.localeReducer),
  currentLanguage: getActiveLanguage(state.localeReducer).code
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LanguageSelector);
